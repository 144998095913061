import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const CustomLink = ({ className, slug, linkText }) => {
  return (
    <Link
      className={`text-primary fsize-12 font-M d-block w-max-content ${className}`}
      to={slug}
    >
      {linkText}
    </Link>
  );
};

CustomLink.propTypes = {
  slug: PropTypes.string,
  linkText: PropTypes.string,
  className: PropTypes.string
};

export default CustomLink;

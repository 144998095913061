export const GET_TESTS_REQUEST = "GET_TESTS_REQUEST";
export const GET_TESTS_RESPONSE = "GET_TESTS_RESPONSE";
export const DELETE_TEST_REQUEST = "DELETE_TEST_REQUEST";
export const ADD_TESTS_REQUEST = "ADD_TESTS_REQUEST";

export const EDIT_TEST_REQUEST = "EDIT_TEST_REQUEST";
export const GET_SINGLE_TEST_REQUEST = "GET_SINGLE_TEST_REQUEST";
export const GET_SINGLE_TEST_RESPONSE = "GET_SINGLE_TEST_RESPONSE";
export const GET_SINGLE_TEST_REPORT_REQUEST =
  "GET_SINGLE_TEST_REPORT_REQUEST";
export const GET_SINGLE_TEST_REPORT_RESPONSE =
  "GET_SINGLE_TEST_REPORT_RESPONSE";

export const SET_TEST_FILTERS = "SET_TEST_FILTERS";
export const SET_TEST_BOOK_FILTERS = "SET_TEST_BOOK_FILTERS";
export const GET_BOOK_TEST_REQUEST = "GET_BOOK_TEST_REQUEST";
export const GET_BOOK_TEST_RESPONSE = "GET_BOOK_TEST_RESPONSE";
export const CHECK_FOR_CAN_BE_EDIT_TEST_REQUEST =
  "CHECK_FOR_CAN_BE_EDIT_TEST_REQUEST";
export const CHECK_FOR_CAN_BE_EDIT_TEST_RESPONSE =
  "CHECK_FOR_CAN_BE_EDIT_TEST_RESPONSE";

export const EXPORT_TEST_REPORT = "EXPORT_TEST_REPORT";

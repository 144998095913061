import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import { Typography } from "@material-ui/core";
import EmptyState from "../../../components/EmptyState";
import { getBooksDashboardResponse } from "../../../store/Dashboard/actions";
import Table from "../../../components/Table";
import TableContainer from "@material-ui/core/TableContainer";
import { Formik, Form } from "formik";
import Search from "../../../components/Search";
import BookSingleRow from "../BookSingleRow";

function BookDetails() {
  const dispatch = useDispatch();

  const booksList = useSelector((state) => state.dashboard.list);

  const lang = useSelector((state) => state.locale.lang);

  const isLoading = useSelector((state) => state.loader);
  useEffect(() => {
    dispatch(getBooksDashboardResponse());
  }, []);

  const {
    books: { title, bookTitle, isbn, bookReleaseDate }
  } = messages[lang];
  const [filterBookList, setFilterBookList] = useState(booksList);

  const [searchName, setsearchName] = useState("");

  const searchBook = (searchParam) => {
    if (searchParam) {
      const updatedList = filterBookList.filter((item) =>
        item.book.title
          .toLowerCase()
          .includes(searchParam.toLowerCase())
      );
      console.log(updatedList);

      setFilterBookList(updatedList);
    } else {
      setFilterBookList(booksList);
    }
  };
  useEffect(() => {
    searchBook(searchName);
  }, [searchName, booksList]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        <Formik>
          {() => (
            <Form>
              <Search
                name="search"
                placeholder="Search"
                onChange={(e) => setsearchName(e.target.value)}
              />
              <button className="d-none"></button>
            </Form>
          )}
        </Formik>
      </div>
      <TableContainer>
        <Table
          headlines={["", bookTitle, bookReleaseDate, isbn]}
          rows={filterBookList.map((row) => (
            <BookSingleRow key={row.book.id} row={row} />
          ))}
        />
      </TableContainer>
      {!isLoading && !booksList.length && <EmptyState />}
    </>
  );
}

export default BookDetails;

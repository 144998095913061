import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { createProgramRequest } from "../../../store/Programs/actions";
import PropTypes from "prop-types";

function ProgramForm({
  mode,
  setFormModalOpen,
  isOpen,
  selectedProgram
}) {
  const lang = useSelector((state) => state.locale.lang);
  const { mode: stringMode } = mode;

  const {
    programs: { addTitle, editTitle, programNameLabel }
  } = messages[lang];
  const formRef = useRef();
  const dispatch = useDispatch();

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSubmit = ({ name }) => {
    dispatch(
      createProgramRequest({
        program: {
          name
        }
      })
    );
    setFormModalOpen(false);
  };

  return (
    <Modal
      title={stringMode === "add" ? addTitle : editTitle}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={() => setFormModalOpen(false)}
    >
      <Formik
        initialValues={{
          name: selectedProgram?.name
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Program name is required.")
        })}
      >
        {() => (
          <Form>
            <Input name="name" label={programNameLabel} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

ProgramForm.propTypes = {
  mode: PropTypes.object,
  setFormModalOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  selectedProgram: PropTypes.object
};

export default ProgramForm;

import S3 from "react-aws-s3";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const CONFIG = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: "Acore_admin",
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  s3Url: process.env.REACT_APP_S3URL
};

// export const CONFIG = {
//   bucketName: "acore-staging",
//   dirName: "Acore_admin",
//   region: "us-east-1",
//   accessKeyId: "AKIAU6N53UP7LVGDX4U6",
//   secretAccessKey: "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",
//   s3Url: "https://acore-staging.s3.amazonaws.com"
// };

export const uploadToS3 = async (type, file, fileName) => {
  const S3Client = new S3(CONFIG);
  const uniqueFileName = `${new Date().getTime()}_${fileName.replace(
    /\s/g,
    ""
  )}`;
  if (file) {
    switch (type) {
      case "pdf":
        return await S3Client.uploadFile(file, uniqueFileName)
          .then((data) => {
            return pdfjsLib
              .getDocument(data.location)
              .promise.then((res) => {
                return {
                  numPages: res?._pdfInfo?.numPages,
                  link: data.location
                };
              });
          })
          .catch((err) => console.error(err));
      case "image":
        return await S3Client.uploadFile(file, fileName)
          .then((data) => {
            return { link: data.location };
          })
          .catch((err) => console.error(err));
      default:
        return null;
    }
  }
};

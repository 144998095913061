import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  editedCategory: null,
  addedCategory: null,
  searchKeyword: ""
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CATEGORIES_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.ADD_CATEGORY_RESPONSE:
      return { ...state, addedCategory: action.payload };
    case types.EDIT_CATEGORY_RESPONSE:
      return { ...state, editedCategory: action.payload };
    case types.SET_CATEGORY_SEARCH:
      return { ...state, searchKeyword: action.payload };
    default:
      return state;
  }
};

export default categoriesReducer;

import * as types from "./types";

export const getAuthorsRequest = (data) => ({
  type: types.GET_AUTHORS_REQUEST,
  payload: data
});

export const getAuthorsResponse = (data) => ({
  type: types.GET_AUTHORS_RESPONSE,
  payload: data
});

export const deleteAuthorsRequest = (data) => ({
  type: types.DELETE_AUTHORS_REQUEST,
  payload: data
});

export const addAuthorRequest = (data) => ({
  type: types.ADD_AUTHOR_REQUEST,
  payload: data
});

export const addAuthorResponse = (data) => ({
  type: types.ADD_AUTHOR_RESPONSE,
  payload: data
});

export const editAuthorRequest = (data) => ({
  type: types.EDIT_AUTHOR_REQUEST,
  payload: data
});

export const editAuthorResponse = (data) => ({
  type: types.EDIT_AUTHOR_RESPONSE,
  payload: data
});

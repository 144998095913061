import { call, put } from "redux-saga/effects";

import * as apis from "../../network/apis/dashboard";
import {
  getBooksDashboardRequest,
  setInsightsRequest
} from "./actions";

export function* getInsightsSaga({ payload }) {
  try {
    const response = yield call(apis.getInsights, payload);
    yield put(
      setInsightsRequest({ Insights: response.data.insights })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getBooksDashbosrdSaga({ payload }) {
  try {
    const response = yield call(apis.getBooksDashbosrd, payload);

    yield put(
      getBooksDashboardRequest({
        list: response.data.books,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

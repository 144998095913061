export const GET_PROMOCODES_LIST_REQUEST =
  "GET_PROMOCODES_LIST_REQUEST";
export const GET_PROMOCODES_LIST_RESPONSE =
  "GET_PROMOCODES_LIST_RESPONSE";

export const ADD_PROMOCODES_REQUEST = "ADD_PROMOCODES_REQUEST";
export const ADD_PROMOCODES_RESPONSE = "ADD_PROMOCODES_RESPONSE";
export const DELETE_PROMOCODE_REQUEST = "DELETE_PROMOCODE_REQUEST";

export const GET_SINGLE_PROMOCODE_REQUEST =
  "GET_SINGLE_PROMOCODE_REQUEST";
export const GET_SINGLE_PROMOCODE_RESPONSE =
  "GET_SINGLE_PROMOCODE_RESPONSE";

export const SET_PROMOCODES_FILTERS = "SET_PROMOCODES_FILTERS";

import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import Modal from "../Modal";

const DeleteModal = ({
  isOpen,
  handleDelete,
  deleteTitle,
  deleteMessage,
  cancelCallback,
  handleCancel
}) => {
  const { messages } = useIntl();

  const {
    buttons: { deleteBtn }
  } = messages;

  const handleModalCancel = () => {
    if (cancelCallback) cancelCallback();
    handleCancel?.();
  };

  return (
    <Modal
      title={deleteTitle}
      hasActions={true}
      isOpen={isOpen}
      description={deleteMessage}
      submitBtnClassName="danger"
      submitBtnText={deleteBtn}
      handleModalCancel={handleModalCancel}
      handleModalSubmit={handleDelete}
    ></Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  deleteTitle: PropTypes.string,
  deleteMessage: PropTypes.string,
  handleDelete: PropTypes.func,
  cancelCallback: PropTypes.func,
  handleCancel: PropTypes.func
};

DeleteModal.defaultProps = {
  isOpen: false,
  deleteTitle: "Delete",
  deleteMessage: "",
  handleDelete: () => {}
};

export default DeleteModal;

import React from "react";
import VideocamIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";
import AssignmentIcon from "@material-ui/icons/Assignment";

export const numbersOnly = (event) => {
  let key = window.event ? event.keyCode : event.which;
  if (
    event.keyCode == 8 ||
    event.keyCode == 46 ||
    event.keyCode == 37 ||
    event.keyCode == 39 ||
    (key >= 96 && key <= 105) // allow numbers from numpad
  ) {
    return true;
  } else if (key < 48 || key > 57) {
    return event.preventDefault();
  } else return true;
};
export function formatOptions(element, id, label, value) {
  if (!element) {
    // Handle the case when element is undefined or null
    return null;
  }

  return {
    id: element[id] || "", // Provide a default value if id is undefined
    label: element[label] || "", // Provide a default label if not present
    value: element[value || id] || "" // Use value or id, and provide a fallback
  };
}
export const getSupplementaryTypeLabel = (type) => {
  switch (type) {
    case 0:
    case "video":
      return {
        id: 0,
        label: "video",
        icon: <VideocamIcon fontSize="small" />
      };
    case 1:
    case "image":
      return {
        id: 1,
        label: "image",
        icon: <ImageIcon fontSize="small" />
      };
    case 2:
    case "DICOM":
      return {
        id: 2,
        label: "dicom",
        icon: <AssignmentIcon fontSize="small" />
      };
    default:
      break;
  }
};

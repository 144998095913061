import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteCategoryRequest } from "../../../store/Categories/actions";
import { deleteAction } from "../../../store/ActionsBtn/actions";

const CategoryDelete = ({ isOpen, lang }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    categories: { deleteCategory, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteCategoryRequest(deleteId));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteCategory}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
    />
  );
};

CategoryDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

CategoryDelete.defaultProps = {
  isOpen: false
};

export default CategoryDelete;

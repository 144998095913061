export const GET_ALL_FINAL_TEST_REQUEST =
  "GET_ALL_FINAL_TEST_REQUEST";
export const GET_ALL_FINAL_TEST_RESPONSE =
  "GET_ALL_FINAL_TEST_RESPONSE";
export const GET_SINGLE_FINAL_TEST_REQUEST =
  "GET_SINGLE_FINAL_TEST_REQUEST";
export const GET_SINGLE_FINAL_TEST_RESPONSE =
  "GET_SINGLE_FINAL_TEST_RESPONSE";
export const SET_FINAL_TEST_REQUEST = "SET_FINAL_TEST_REQUEST";
export const SET_FINAL_TEST_RESPONSE = "SET_FINAL_TEST_RESPONSE";
export const EDIT_FINAL_TEST_REQUEST = "EDIT_FINAL_TEST_REQUEST";
export const EDIT_FINAL_TEST_RESPONSE = "EDIT_FINAL_TEST_RESPONSE";
export const DELETE_FINAL_TEST_REQUEST = "DELETE_FINAL_TEST_REQUEST";
export const DELETE_FINAL_TEST_RESPONSE =
  "DELETE_FINAL_TEST_RESPONSE";
export const SET_FINAL_TEST_SEARCH = "SET_FINAL_TEST_SEARCH";

import * as Yup from "yup";
import messages from "../../../assets/locale/messages";
import { SUPPORTED_IMAGES_FORMATS } from "../../../utils/Constants";
import { getSupplementaryTypeLabel } from "../../../utils/Helpers";

export const ADD_EDIT_SUPPLEMENTARY_SCHEMA = (
  lang,
  selectedMaterialType
) => {
  const {
    supplementaries: {
      validation: { titleRequired, fileRequired, imageFormat }
    }
  } = messages[lang];

  return Yup.object().shape({
    title: Yup.string().required(titleRequired),
    file:
      selectedMaterialType === "image"
        ? Yup.mixed()
            .required(fileRequired)
            .test(
              "imageFormat",
              imageFormat,
              (value) =>
                value && SUPPORTED_IMAGES_FORMATS.includes(value.type)
            )
        : Yup.mixed().required(fileRequired)
  });
};

export const getInitialValues = (supplementary) => {
  return {
    title: supplementary?.title || "",
    diagnosis: supplementary?.diagnosis || "",
    description: supplementary?.description || "",
    material_type: supplementary?.material_type
      ? getSupplementaryTypeLabel(supplementary?.material_type).id
      : 0,
    file: supplementary?.url || ""
  };
};

import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { setQuizzesBookSortMethod } from "../../../store/Quizzes/actions";
import { ASC, DESC } from "../../../utils/Constants";

function SortingWrapper(props) {
  // const { setSortedData } = props;
  const [order, setOrder] = React.useState("");
  // const [orderBy, setOrderBy] = React.useState("");
  const dispatch = useDispatch();

  const handleSortRequest = () => {
    // const column = e.target.title;
    const newOrder =
      order == ""
        ? ASC
        : order == ASC
        ? DESC
        : order == DESC
        ? ""
        : "";
    console.log(order == "", "newOrder", newOrder);

    setOrder(newOrder);
    // setOrderBy(column);
    dispatch(setQuizzesBookSortMethod({ sortMethod: newOrder }));
  };

  // useEffect(() => {
  //   setSortedData((data) => {
  //     const newData = [...data];
  //     newData.sort((a, b) => {
  //       if (orderBy === "page") {
  //         return order === DESC
  //           ? b.books[0]?.book_page - a.books[0]?.book_page
  //           : a.books[0]?.book_page - b.books[0]?.book_page;
  //       }
  //       if (orderBy === "quizTitle") {
  //         const nameA = a[orderBy]?.toUpperCase();
  //         const nameB = b[orderBy]?.toUpperCase();
  //         if (nameA < nameB) {
  //           return order === DESC ? 1 : -1;
  //         }
  //         if (nameA > nameB) {
  //           return order === DESC ? -1 : 1;
  //         }
  //         return 0;
  //       }
  //       if (orderBy === "book") {
  //         const nameA = a.books[0].book.title?.toUpperCase();
  //         const nameB = b.books[0].book.title?.toUpperCase();
  //         if (nameA < nameB) {
  //           return order === DESC ? 1 : -1;
  //         }
  //         if (nameA > nameB) {
  //           return order === DESC ? -1 : 1;
  //         }
  //         return 0;
  //       }
  //     });
  //     return newData;
  //   });
  // }, [order, orderBy]);

  return (
    <div
      style={{ cursor: "pointer", display: "flex" }}
      onClick={handleSortRequest}
      title={props.columnKey}
    >
      {props.children}
      {order === ASC && <ArrowUpward />}
      {order === DESC && <ArrowDownward />}
    </div>
  );
}

SortingWrapper.propTypes = {
  children: PropTypes.node,
  setSortedData: PropTypes.func,
  columnKey: PropTypes.string
};
export default SortingWrapper;

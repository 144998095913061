import React from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import DicomViewer from "../DicomViewer";
import messages from "../../assets/locale/messages";
import styles from "./MaterialPreview.module.scss";
import { getSupplementaryTypeLabel } from "../../utils/Helpers";
import { ImgDisplay } from "../../hooks/ImgDisplay";

const MaterialPreview = ({
  src,
  type,
  dicomImages,
  className,
  hasClear,
  onClear
}) => {
  const lang = useSelector((state) => state.locale.lang);
  console.log("MaterialPreview src : ", src);

  const { shared } = messages[lang];

  const renderPreview = () => {
    switch (type) {
      case 0: //video
        return (
          <ReactPlayer
            className={styles.preview}
            url={src}
            width="100%"
            height="100%"
            controls={true}
          />
        );
      case 1: //image
        return (
          <ImgDisplay
            src={src}
            className={`${styles.preview} ${className}`}
            hasClear={hasClear}
            onClear={onClear}
          />
        );
      case 2: //dicom
        return (
          <DicomViewer
            imageIds={dicomImages}
            className={styles.preview}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      {!src && (
        <div
          className={`${styles.emptyPreview} d-flex align-items-center justify-content-center fsize-20`}
        >
          {shared[`${getSupplementaryTypeLabel(type).label}Preview`]}
        </div>
      )}
      {renderPreview()}
    </div>
  );
};

MaterialPreview.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.number.isRequired,
  dicomImages: PropTypes.arrayOf(PropTypes.string),
  hasClear: PropTypes.bool,
  onClear: PropTypes.func
};

MaterialPreview.defaultProps = {
  src: "",
  className: "",
  type: 0,
  dicomImages: [],
  hasClear: false
};

export default MaterialPreview;

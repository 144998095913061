import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import DeleteModal from "../../../components/DeleteModal";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import { deleteSurveyRequest } from "../../../store/Surveys/actions";

const SupplementaryDelete = ({ isOpen }) => {
  const { locale, messages } = useIntl();
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    surveys: { deleteSurvey, deleteMessage }
  } = messages;

  const handleDelete = () => {
    dispatch(deleteSurveyRequest(deleteId));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteSurvey}
      isOpen={isOpen}
      locale={locale}
      handleDelete={handleDelete}
    />
  );
};

SupplementaryDelete.propTypes = {
  isOpen: PropTypes.bool
};

SupplementaryDelete.defaultProps = {
  isOpen: false
};

export default SupplementaryDelete;

import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import logo from "../../assets/images/logo.png";
import messages from "../../assets/locale/messages";
import styles from "./AuthLayout.module.scss";

const AuthLayout = ({ title, body }) => {
  const lang = useSelector((state) => state.locale.lang);

  const {
    auth: { copyRights }
  } = messages[lang];

  return (
    <div className={styles.authWrapper}>
      <Grid container className={styles.container}>
        <Grid item xs={7} className={styles.content}>
          <Typography
            variant="h5"
            component="h1"
            className="w-80 mb-4"
          >
            {title}
          </Typography>
          {body}
          <hr />
          <div className="d-flex align-items-center justify-content-between text-gray">
            <img src={logo} alt="medblend-logo" width="60px" />
            <p className="m-0 fsize-12">{copyRights}</p>
          </div>
        </Grid>
        <Grid item xs={5} className={styles.image}></Grid>
      </Grid>
    </div>
  );
};

AuthLayout.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node
};

AuthLayout.defaultProps = {
  title: ""
};

export default AuthLayout;

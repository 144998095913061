import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../components/Button";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import SurveysList from "./SurveysList";
import SupplementaryDelete from "./SurverysDelete";
import AssignSupplementary from "./AssignSurvey";
import Filters from "./Filters";

const Surverys = () => {
  const { messages, locale } = useIntl();
  const list = useSelector((state) => state.surveys.list);
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const isAssign = useSelector((state) => state.actionsBtn.add);

  const {
    surveys: { title, addSurvey }
  } = messages;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Filters />
        </Grid>
        <Grid
          item
          xs={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addSurvey}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            slug={ROUTE_PATHS["addSurvey"]}
          />
        </Grid>
      </Grid>
      <SurveysList list={list} lang={locale} />
      <SupplementaryDelete isOpen={!!deleteId} lang={locale} />
      <AssignSupplementary isOpen={!!isAssign} lang={locale} />
    </>
  );
};

export default Surverys;

import * as types from "./types";

export const getQuizzesList = (payload) => ({
  type: types.GET_QUIZZES_LIST,
  payload
});

export const setQuizzesListResponse = (payload) => ({
  type: types.SET_QUIZZES_LIST,
  payload
});

export const addQuiz = (payload) => ({
  type: types.ADD_QUIZ,
  payload
});

export const assignQuizToBook = (payload) => ({
  type: types.ASSIGN_QUIZ_TO_BOOK,
  payload
});
export const importQuizzesToBook = (payload) => ({
  type: types.IMPORT_QUIZZES_TO_BOOK,
  payload
});

export const deleteQuiz = (payload) => ({
  type: types.DELETE_QUIZ,
  payload
});

export const getBookQuizzes = (payload) => ({
  type: types.GET_BOOK_QUIZ,
  payload
});

export const setBookQuizzes = (payload) => ({
  type: types.SET_BOOK_QUIZ,
  payload
});

export const unassignQuizFromBook = (payload) => ({
  type: types.UNASSIGN_BOOK_QUIZ,
  payload
});

export const getQuizDetails = (payload) => ({
  type: types.GET_QUIZ_DETAILS,
  payload
});

export const setQuizDetails = (payload) => ({
  type: types.SET_QUIZ_DETAILS,
  payload
});
export const editQuiz = (payload) => ({
  type: types.EDIT_QUIZ,
  payload
});
export const assignQuizBtn = (payload) => ({
  type: types.ASSIGN_QUIZ_BTN,
  payload
});
export const editAssignQuizToBook = (payload) => ({
  type: types.EDIT_ASSIGN_QUIZ_BTN,
  payload
});
export const deleteQuizAction = (payload) => ({
  type: types.DELETE_QUIZ_ACTION,
  payload
});
export const unassignBookQuizAction = (payload) => ({
  type: types.UNASSIGN_BOOK_QUIZ_ACTION,
  payload
});

export const checkForCanBeEditQuizRequest = (data) => ({
  type: types.CHECK_FOR_CAN_BE_EDIT_QUIZ_REQUEST,
  payload: data
});

export const checkForCanBeEditQuizResponse = (data) => ({
  type: types.CHECK_FOR_CAN_BE_EDIT_QUIZ_RESPONSE,
  payload: data
});

export const setQuizzesBookFilter = (data) => ({
  type: types.SET_QUIZZES_BOOK_FILTER,
  payload: data
});

export const setQuizzesBookSortMethod = (data) => ({
  type: types.SET_QUIZZES_BOOK_SORT_METHOD,
  payload: data
});

export const setQuizzesPage = (data) => ({
  type: types.SET_QUIZZES_PAGE,
  payload: data
});

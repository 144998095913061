import * as Yup from "yup";

export const ADD_ADMIN_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Admin name is required."),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Admin Email is required.")
});

export const EDIT_ADMIN_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Admin name is required.")
});

export const getInitialValues = (admin) => {
  return {
    name: admin?.name || "",
    email: admin?.email || ""
  };
};

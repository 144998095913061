import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deletePromoCodeRequest } from "../../../store/Promocodes/actions";

import { deleteAction } from "../../../store/ActionsBtn/actions";

const DeletePromocodes = ({ isOpen, lang }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    promoCodes: { deletePromoCodes, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deletePromoCodeRequest(deleteId));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deletePromoCodes}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
    />
  );
};

DeletePromocodes.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

DeletePromocodes.defaultProps = {
  isOpen: false
};

export default DeletePromocodes;

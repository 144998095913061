import { axiosInstance } from "./";

const getSupplementaries = async (params) => {
  return await axiosInstance.get(`supplementary_materials`, {
    params
  });
};

const deleteSupplementary = async (id) => {
  return await axiosInstance.delete(`supplementary_materials/${id}`);
};

const addSupplementary = async (payload) => {
  return await axiosInstance.post(`supplementary_materials`, payload);
};

const assignSupplementaryToBook = async (payload) => {
  return await axiosInstance.post(
    `book_supplementary_materials`,
    payload
  );
};

const unassignSupplementaryFromBook = async (id) => {
  return await axiosInstance.delete(
    `book_supplementary_materials/${id}`
  );
};
const editBookPageSupplementary = async ({ book_page, id }) => {
  return await axiosInstance.patch(
    `book_supplementary_materials/${id}`,
    { book_page }
  );
};

const editSupplementary = async ({ data, id }) => {
  return await axiosInstance.patch(
    `supplementary_materials/${id}`,
    data
  );
};

const getSingleSupplementary = async (id) => {
  return await axiosInstance.get(`supplementary_materials/${id}`);
};

const reassignSupplementaryToBookVersion = async ({ id, data }) => {
  return await axiosInstance.get(
    `book_supplementary_materials/${id}/re_assign_supplementary_materials/`,
    {
      params: { ...data }
    }
  );
};

export {
  getSupplementaries,
  deleteSupplementary,
  addSupplementary,
  assignSupplementaryToBook,
  unassignSupplementaryFromBook,
  editSupplementary,
  getSingleSupplementary,
  reassignSupplementaryToBookVersion,
  editBookPageSupplementary
};

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import { deleteSupplementaryRequest } from "../../../store/Supplementaries/actions";

const SupplementaryDelete = ({ isOpen, lang }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    supplementaries: { deleteSupplementary, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteSupplementaryRequest(deleteId));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteSupplementary}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
    />
  );
};

SupplementaryDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

SupplementaryDelete.defaultProps = {
  isOpen: false
};

export default SupplementaryDelete;

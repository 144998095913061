import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import Search from "../../components/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstitutionsRequest,
  setInstitutionsFilters
} from "../../store/Institutions/actions";
// import { getBookSupplementraiesRequest } from "../../store/Books/actions";

const Filters = () => {
  const dispatch = useDispatch();
  const perPage = 10;
  const title = useSelector((state) => state.institutes.title);

  const handleSearch = (e) => {
    dispatch(
      setInstitutionsFilters({
        title: e.target.value
      })
    );
  };

  useEffect(() => {
    function filterSupplementariesList() {
      dispatch(
        getInstitutionsRequest({
          name: title,
          page: 1,
          items: perPage
        })
      );
    }
    filterSupplementariesList();
  }, [title]);

  useEffect(() => {
    return () => {
      dispatch(
        setInstitutionsFilters({
          title: null,
          selectedType: null
        })
      );
    };
  }, []);

  return (
    <Formik initialValues={{ title: "" }} onSubmit={handleSearch}>
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Search
                name="search"
                placeholder="Search"
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
          <button className="d-none"></button>
        </Form>
      )}
    </Formik>
  );
};

Filters.propTypes = {
  bookId: PropTypes.string
};

Filters.defaultProps = {
  bookId: null
};

export default Filters;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { FieldArray, Form, Formik } from "formik";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Input from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import Button from "../../../components/Button";
import {
  ADD_EDIT_SUPPLEMENTARY_SCHEMA,
  getInitialValuesAdd,
  getInitialValuesEdit
} from "../SurveyForm/validation";
import { showHideLoader } from "../../../store/Loader/actions";
import {
  addSurveysRequest,
  editSurveyRequest,
  getSingleSurveyRequest,
  getSingleSurveyResponse
} from "../../../store/Surveys/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import { questions_types } from "../SurveyForm/questions_types";
import "./SurveyDetails.scss";

const SurveyForm = () => {
  const dispatch = useDispatch();
  const { locale, messages } = useIntl();
  let params = useParams();
  const theme = useTheme();
  const booksList = useSelector((state) => state.books.list);
  const singleSurvey = useSelector(
    (state) => state.surveys.singleSurvey
  );
  const [booksOptions, setBooksOptions] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const [destroy, setDestroy] = useState(false);
  const [mode, setMode] = useState("add");
  const [selectedQuestionType, setSelectedQuestionType] = useState(0);
  const {
    survey: { singleSelect, multiSelect }
  } = messages;
  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
    return () => {
      dispatch(getSingleSurveyResponse({}));
    };
  }, []);

  useEffect(() => {
    if (params?.id) {
      setMode("edit");
      dispatch(getSingleSurveyRequest(params.id));
    }
  }, [params]);

  useEffect(() => {
    setBooksOptions(
      booksList.map(({ id, title }) => ({
        id,
        value: title,
        label: title
      }))
    );
  }, [booksList]);

  const handleSelectedBook = (id) => {
    setSelectedBook(_.find(booksList, ["id", id]));
  };

  const handleAnswers = (id, setFieldValue, field) => {
    if (id === 1) {
      setFieldValue(
        field,
        [1, 2, 3, 4, 5].map((num) => ({ answer_choice: num }))
      );
    } else if (id === 2) {
      setFieldValue(field, [
        {
          answer_choice: "OPEN ENDED QUESTION DOESNOT NEED AN ANSWER"
        }
      ]);
    } else {
      setFieldValue(field, [
        { answer_choice: "" },
        { answer_choice: "" }
      ]);
    }
  };

  useEffect(() => {
    if (mode === "edit" && booksOptions.length) {
      handleSelectedBook(singleSurvey.book_id);
    }
  }, [singleSurvey, booksOptions]);

  const handleSubmit = (values) => {
    const valuesCopy = { ...values };
    const payload = {
      ...valuesCopy,
      survey_questions_attributes:
        valuesCopy.survey_questions_attributes.map((x) =>
          x.question_type === 1
            ? {
                ...x,
                survey_question_answer_choices_attributes: [
                  1, 2, 3, 4, 5
                ].map((val) => ({ answer_choice: val }))
              }
            : x
        )
    };

    dispatch(showHideLoader(true));
    if (mode === "add") {
      dispatch(addSurveysRequest(payload));
    } else {
      dispatch(
        editSurveyRequest({
          data: payload,
          id: singleSurvey.id
        })
      );
    }
  };

  const {
    surveys: {
      addSurvey,
      titleLabel,
      questionType,
      surveyDetails,
      questionTitle,
      answer: answerText,
      addAQuestion,
      removeQuestion
    },
    books: { book }
  } = messages;

  function AddRemoveNewQuestion(push) {
    return (
      <>
        <div className="mx-2 mb-3">
          <AddCircleIcon
            onClick={() =>
              push({
                question_type: 0,
                question: "",
                survey_question_answer_choices_attributes: [
                  {
                    answer_choice: ""
                  },
                  {
                    answer_choice: ""
                  }
                ]
              })
            }
          />
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {surveyDetails}
        </Typography>
      </div>

      <Formik
        initialValues={
          mode === "add"
            ? getInitialValuesAdd()
            : getInitialValuesEdit(singleSurvey)
        }
        onSubmit={() => {}}
        validationSchema={ADD_EDIT_SUPPLEMENTARY_SCHEMA(
          locale,
          selectedQuestionType
        )}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <div>
            <fieldset disabled className="survey-form">
              <Grid container spacing={5}>
                <Grid item xs={12} className="pb-0">
                  <Input name="title" isDisabled label={titleLabel} />
                  <CustomSelect
                    options={booksOptions}
                    placeholder={book}
                    name="book_id"
                    disabled
                    bgColor={theme.palette.primary.light}
                    isMenuRelative={true}
                    handleSelectChange={handleSelectedBook}
                  />
                  <FieldArray name="survey_questions_attributes">
                    {({ push, replace }) => {
                      return !values?.survey_questions_attributes
                        ?.length
                        ? null
                        : values?.survey_questions_attributes?.map(
                            (question, questionIndex) => {
                              return (
                                !question._destroy && (
                                  <div className="d-flex flex-row my-5 col-md-10">
                                    <Paper
                                      style={{ padding: "2rem" }}
                                      className="row align-items-center justify-content-center flex-fill "
                                    >
                                      <Input
                                        name={`survey_questions_attributes[${questionIndex}].question`}
                                        label={questionTitle}
                                        inputShape="textarea"
                                        isDisabled
                                        row="1"
                                      />
                                      <CustomSelect
                                        disabled
                                        options={[
                                          ...questions_types?.values()
                                        ]}
                                        placeholder={questionType}
                                        name={`survey_questions_attributes[${questionIndex}].question_type`}
                                        bgColor={
                                          theme.palette.primary.light
                                        }
                                        isMenuRelative={true}
                                        handleSelectChange={(id) => {
                                          handleAnswers(
                                            id,
                                            setFieldValue,
                                            `survey_questions_attributes[${questionIndex}].survey_question_answer_choices_attributes`
                                          );
                                        }}
                                      />
                                      {values
                                        .survey_questions_attributes[
                                        questionIndex
                                      ].question_type === 0 && (
                                        <div className="mb-4">
                                          <label
                                            key={questionIndex}
                                            className="no-pointer d-flex align-items-start"
                                          >
                                            <RadioGroup
                                              name={`survey_questions_attributes[${questionIndex}].is_mcq_single_choice`}
                                              className="flex-nowrap flex-row"
                                              value={`${values.survey_questions_attributes[questionIndex].is_mcq_single_choice}`}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `survey_questions_attributes[${questionIndex}].is_mcq_single_choice`,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <FormControlLabel
                                                value="true"
                                                control={<Radio />}
                                                label={singleSelect}
                                              />
                                              <FormControlLabel
                                                value="false"
                                                control={<Radio />}
                                                label={multiSelect}
                                              />
                                            </RadioGroup>
                                          </label>
                                        </div>
                                      )}
                                      <FieldArray
                                        name={`survey_questions_attributes[${questionIndex}].survey_question_answer_choices_attributes`}
                                      >
                                        {({ push, replace }) => {
                                          return (
                                            <Form>
                                              {question.question_type ===
                                                1 && (
                                                <div className="m-4">
                                                  {`(4)Strongly Agree - (3) Agree - (2) Disagree - (1) Strongly Disagree`}
                                                </div>
                                              )}
                                              {question.question_type ===
                                                0 &&
                                                question.survey_question_answer_choices_attributes.map(
                                                  (
                                                    answer,
                                                    answerIndex,
                                                    arr
                                                  ) => {
                                                    return (
                                                      !answer._destroy && (
                                                        <div
                                                          className="mx-5 d-flex"
                                                          key={JSON.stringify(
                                                            answerIndex
                                                          )}
                                                        >
                                                          <div className="flex-fill">
                                                            <Input
                                                              name={`survey_questions_attributes[${questionIndex}].survey_question_answer_choices_attributes[${answerIndex}].answer_choice`}
                                                              isDisabled={
                                                                true
                                                              }
                                                              label={
                                                                answerText
                                                              }
                                                              inputShape="textarea"
                                                              row="1"
                                                            />
                                                          </div>
                                                        </div>
                                                      )
                                                    );
                                                  }
                                                )}
                                            </Form>
                                          );
                                        }}
                                      </FieldArray>
                                    </Paper>
                                  </div>
                                )
                              );
                            }
                          );
                    }}
                  </FieldArray>
                </Grid>
                <Grid item xs={6} className="pb-0"></Grid>
              </Grid>
            </fieldset>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SurveyForm;

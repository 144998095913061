import { axiosInstance } from "./";

export const getPromoCodesList = async (params) => {
  return await axiosInstance.get(`promo_codes`, {
    params
  });
};

export const addPromoCode = async (payload) => {
  return await axiosInstance.post(`promo_codes`, payload);
};
export const getSinglePromocode = async (id) => {
  return await axiosInstance.get(`promo_codes/${id}`);
};

export const deletePromoCode = async (id) => {
  return await axiosInstance.delete(`promo_codes/${id}`);
};

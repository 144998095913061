import * as types from "./types";

export const getAdminsRequest = (data) => ({
  type: types.GET_ADMINS_REQUEST,
  payload: data
});

export const getAdminsResponse = (data) => ({
  type: types.GET_ADMINS_RESPONSE,
  payload: data
});

export const deleteAdminsRequest = (data) => ({
  type: types.DELETE_ADMINS_REQUEST,
  payload: data
});

export const addAdminRequest = (data) => ({
  type: types.ADD_ADMIN_REQUEST,
  payload: data
});

export const addAdminResponse = (data) => ({
  type: types.ADD_ADMIN_RESPONSE,
  payload: data
});

export const editAdminRequest = (data) => ({
  type: types.EDIT_ADMIN_REQUEST,
  payload: data
});

export const editAdminResponse = (data) => ({
  type: types.EDIT_ADMIN_RESPONSE,
  payload: data
});

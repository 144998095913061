import * as types from "./types";

export const getEditorsRequest = (data) => ({
  type: types.GET_EDITORS_REQUEST,
  payload: data
});

export const getEditorsResponse = (data) => ({
  type: types.GET_EDITORS_RESPONSE,
  payload: data
});

export const deleteEditorsRequest = (data) => ({
  type: types.DELETE_EDITORS_REQUEST,
  payload: data
});

export const addEditorRequest = (data) => ({
  type: types.ADD_EDITOR_REQUEST,
  payload: data
});

export const addEditorResponse = (data) => ({
  type: types.ADD_EDITOR_RESPONSE,
  payload: data
});

export const editEditorRequest = (data) => ({
  type: types.EDIT_EDITOR_REQUEST,
  payload: data
});

export const editEditorResponse = (data) => ({
  type: types.EDIT_EDITOR_RESPONSE,
  payload: data
});

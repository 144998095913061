import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { useTheme, withStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Switch as MSwitch
} from "@material-ui/core";

const Switch = ({ name, label, disabled }) => {
  const theme = useTheme();

  const CustomSwitch = withStyles({
    root: {
      cursor: disabled ? "not-allowed" : "pointer"
    },
    switchBase: {
      "&$disabled": {
        color: theme.palette.primary.main
      },
      "&$checked + $track": {
        backgroundColor: theme.palette.primary.main
      }
    },
    checked: {},
    track: {},
    disabled: {}
  })(MSwitch);

  return (
    <Field name={name}>
      {({ field }) => (
        <FormControlLabel
          control={
            <CustomSwitch
              name={name}
              checked={field.value}
              disabled={disabled}
              color="primary"
              {...field}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
};

Switch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

Switch.defaultProps = {
  name: "",
  label: "",
  disabled: false
};

export default Switch;

import { combineReducers } from "redux";
import locale from "./Lang/reducer";
import actionsBtn from "./ActionsBtn/reducers";
import admins from "./Admins/reducers";
import authors from "./Authors/reducers";
import editors from "./Editors/reducers";
import programs from "./Programs/reducers";
import users from "./Users/reducers";
import directors from "./Directors/reducers";
import categories from "./Categories/reducers";
import books from "./Books/reducers";
import auth from "./Auth/reducers";
import snackbar from "./Snackbar/reducers";
import loader from "./Loader/reducers";
import supplementaries from "./Supplementaries/reducers";
import quizzes from "./Quizzes/reducers";
import promoCodes from "./Promocodes/reducers";
import registeredUsers from "./RegisteredUser/reducers";
import countries from "./Countries/reducers";
import jobs from "./Jobs/reducers";
import surveys from "./Surveys/reducers";
import tests from "./Tests/reducers";
import institutes from "./Institutions/reducers";
import dashboard from "./Dashboard/reducers";
import finalTestsReducer from "./FinalTests/reducers";
export default combineReducers({
  finalTestsReducer,
  dashboard,
  locale,
  actionsBtn,
  admins,
  authors,
  editors,
  directors,
  programs,
  users,
  categories,
  books,
  auth,
  snackbar,
  loader,
  supplementaries,
  quizzes,
  surveys,
  tests,
  institutes,
  promoCodes,
  registeredUsers,
  countries,
  jobs
});

import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/jobs";
import { getJobsResponse } from "./actions";

export function* getJobsSaga({ payload }) {
  try {
    const response = yield call(apis.getJobs, payload);
    yield put(
      getJobsResponse({
        list: response.data.jobs,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { FieldArray, Form, Formik, Field } from "formik";
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Paper,
  Radio,
  useTheme
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import RemoveIcon from "@material-ui/icons/Remove";

import { ROUTE_PATHS } from "../../../utils/PathsNames";
import CustomLink from "../../../components/Link";
import {
  getSingleBookRequest,
  getSingleBookResponse,
  getBookSectionsRequest,
  getBookSectionsResponse
} from "../../../store/Books/actions";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import {
  ADD_EDIT_TEST_SCHEMA,
  getInitialValuesAdd,
  getInitialValuesEdit
} from "./validation";
import { showHideLoader } from "../../../store/Loader/actions";
import {
  addTestsRequest,
  getSingleTestRequest,
  getSingleTestResponse,
  editTestRequest,
  checkForCanBeEditTestRequest,
  checkForCanBeEditTestResponse
} from "../../../store/Tests/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import CustomSelect from "../../../components/Select";
import MaterialPreview from "../../../components/MaterialPreview";
import { formatOptions } from "../../../utils/Helpers";
import Upload from "./testImageUploading";
import styles from "./PreTestPostTestForm.module.scss";

const PreTestPostTestForm = () => {
  const dispatch = useDispatch();
  const { locale, messages } = useIntl();

  const {
    preTestPostTest: {
      addTest,
      editTest,
      titleLabel,
      questionTitle,
      answersOptions,
      book,
      prePage,
      postPage,
      answerText,
      mandatory,
      optional,
      selectTestTypeLabel,
      upload,
      uploadImageLabel,
      explanation,
      questionPoints,
      testAnswered,
      addSection,
      noOption,
      notes,
      noteHint,
      sectionName,
      none
    },
    buttons: { submit }
  } = messages;

  let params = useParams();

  const theme = useTheme();
  const [destroy, setDestroy] = useState(false);
  const [mode, setMode] = useState("add");
  const [isDicom, setIsDicom] = useState(false);

  const [
    sectionsInBookOptionsSelect,
    setsectionsInBookOptionsSelect
  ] = useState(null);

  const books = useSelector((state) => state.books.list);

  const sectionsInBook = useSelector(
    (state) => state.books.sectionsInBookOptions
  );
  const [optionsToGetSection, setOptionsToGetSection] =
    useState(sectionsInBook);
  const singleTest = useSelector((state) => state.tests.singleTest);
  const CanBeEditTest = true;
  const singleBook = useSelector((state) => state.books.singleBook);
  useEffect(() => {
    if (params?.id)
      dispatch(
        getBookSectionsRequest({
          bookId: singleTest?.book_id,
          prePage: singleTest?.pre_page,
          postPage: singleTest?.post_page
        })
      );

    setOptionsToGetSection(sectionsInBook);
  }, [params, singleTest]);
  useEffect(async () => {
    setIsDicom(false);
    await dispatch(getBooksRequest({ lookup: true }));
  }, []);

  useEffect(async () => {
    if (sectionsInBookOptionsSelect)
      await dispatch(
        getBookSectionsRequest(sectionsInBookOptionsSelect)
      );
  }, [sectionsInBookOptionsSelect]);

  useEffect(async () => {
    if (params?.id)
      await dispatch(
        getBookSectionsRequest({
          bookId: singleTest?.book_id,
          from: singleTest?.pre_page,
          to: singleTest?.post_page,
          page: -1
        })
      );
  }, [singleTest]);

  useEffect(() => {
    setOptionsToGetSection(sectionsInBook);
  }, [sectionsInBook]);

  useEffect(() => {
    if (params?.id) {
      setMode("edit");
      dispatch(getSingleTestRequest(params.id));
      dispatch(checkForCanBeEditTestRequest({ id: params?.id }));
    }
  }, [params]);

  useEffect(() => {
    return () => {
      dispatch(getSingleBookResponse(null));
      dispatch(checkForCanBeEditTestResponse(true));
      dispatch(getSingleTestResponse({}));

      dispatch(getBookSectionsResponse({ list: [], meta: null }));
    };
  }, []);

  const handleSubmit = (values) => {
    console.log(destroy);
    const payload = { ...values };
    dispatch(showHideLoader(true));
    if (mode === "add") {
      dispatch(addTestsRequest(payload));
    } else {
      dispatch(
        editTestRequest({
          data: payload,
          id: singleTest.id
        })
      );
    }
  };

  const AddRemoveNewQuestion = (push) => {
    return (
      <>
        <div className="mx-2 mb-3">
          <AddCircleIcon
            className="cursor-pointer"
            onClick={() =>
              push({
                question: "",
                points: null,
                test_answer_choices_attributes: [
                  {
                    currentTab: "text",
                    answer: "",
                    image_url: "",
                    correct: false,
                    justification: ""
                  },
                  {
                    currentTab: "text",
                    answer: "",
                    image_url: "",
                    correct: false,
                    justification: ""
                  }
                ]
              })
            }
          />
        </div>
      </>
    );
  };
  const AddAnswer = (push, values, questionIndex) => {
    return () => {
      push({
        answer: "",
        currentTab:
          values.test_questions_attributes[questionIndex]
            .test_answer_choices_attributes[0].currentTab,
        image_url: "",
        correct: false,
        justification: ""
      });
    };
  };

  const removeAnswer = (replace, answerIndex, answer) => {
    return () => {
      replace(answerIndex, {
        ...answer,
        correct: false,
        _destroy: true
      });
    };
  };

  const RemoveQuestion = (
    question,
    remove,
    questionIndex,
    replace,
    mode,
    setDestroy
  ) => {
    return () => {
      if (!question.id) {
        remove(questionIndex);
        return;
      }
      replace(questionIndex, {
        ...question,
        _destroy: true
      });
      if (mode === "edit") setDestroy(true);
    };
  };

  const setTabValue = (
    setFieldValue,
    questionIndex,
    values,
    type
  ) => {
    const newArray = [];
    values.test_questions_attributes[
      `${questionIndex}`
    ].test_answer_choices_attributes.forEach((answer) => {
      if (answer?.id) {
        let deletedAns = {
          ...answer,
          correct: false,
          _destroy: true
        };
        newArray.push(deletedAns);
      }
    });

    const withTwoEmptyValues = [
      ...newArray,
      ...[
        {
          answer: "",
          correct: false,
          justification: "",
          image_url: "",
          currentTab: type
        },
        {
          answer: "",
          correct: false,
          justification: "",
          image_url: "",
          currentTab: type
        }
      ]
    ];

    const question =
      values.test_questions_attributes[`${questionIndex}`];

    question.test_answer_choices_attributes = withTwoEmptyValues;

    setFieldValue(
      `test_questions_attributes[${questionIndex}]`,
      question
    );
  };

  return (
    <>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {mode === "edit" ||
          JSON.parse(sessionStorage.getItem("bookSelected"))?.id
            ? editTest
            : addTest}
        </Typography>
      </div>
      <Formik
        initialValues={
          sessionStorage.getItem("bookSelected")
            ? JSON.parse(sessionStorage.getItem("bookSelected"))
            : mode === "add"
            ? getInitialValuesAdd()
            : getInitialValuesEdit(singleTest)
        }
        onSubmit={handleSubmit}
        validationSchema={ADD_EDIT_TEST_SCHEMA(locale)}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <fieldset
                disabled={!CanBeEditTest}
                className={
                  !CanBeEditTest ? styles.disableFormEdit : null
                }
              >
                <Grid container spacing={5}>
                  <Grid item className="pb-0">
                    <Input
                      name="title"
                      label={titleLabel}
                      isRequired={true}
                      type="text"
                    />
                    <div className="mb-4">
                      <label>{selectTestTypeLabel}</label>
                      <RadioGroup
                        name="is_mandatory"
                        className="flex-nowrap flex-row"
                        value={`${values.is_mandatory}`}
                        onChange={(e) => {
                          setFieldValue(
                            "is_mandatory",
                            e.target.value
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={mandatory}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={optional}
                        />
                      </RadioGroup>
                    </div>
                    <div className={styles.selectTitle}>
                      {mode === "edit" && (
                        <label className={styles.label}>
                          {book} <span>*</span>{" "}
                        </label>
                      )}
                      <CustomSelect
                        options={books.map((book) =>
                          formatOptions(book, "id", "title")
                        )}
                        placeholder={book}
                        name="book_id"
                        bgColor={theme.palette.primary.light}
                        isRequired={true}
                        isMenuRelative={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFieldValue("book_id", e.id);
                          setOptionsToGetSection([]);

                          values.test_questions_attributes.map(
                            (question, questionIndex) => {
                              setFieldValue(
                                `test_questions_attributes[${questionIndex}].section_id`,
                                null
                              );
                            }
                          );
                        }}
                        handleSelectChange={() => {
                          if (values.book_id)
                            dispatch(
                              getSingleBookRequest(values.book_id)
                            );
                        }}
                        containerClass="mb-4"
                      />
                    </div>
                    <div className="d-flex mb-4">
                      <div className={styles.mr2}>
                        <Input
                          name="pre_page"
                          label={prePage}
                          isRequired={true}
                          type="number"
                          onInput={() => {
                            setOptionsToGetSection([]);

                            values.test_questions_attributes.map(
                              (question, questionIndex) => {
                                setFieldValue(
                                  `test_questions_attributes[${questionIndex}].section_id`,
                                  null
                                );
                              }
                            );
                          }}
                          onChange={(e) => {
                            setFieldValue(
                              "pre_page",
                              e.target?.value
                            );
                          }}
                        />
                      </div>

                      <div>
                        <Input
                          name="post_page"
                          label={postPage}
                          isRequired={true}
                          type="number"
                          max={singleBook?.number_of_pages}
                          onInput={() => {
                            setOptionsToGetSection([]);

                            values.test_questions_attributes.map(
                              (question, questionIndex) => {
                                setFieldValue(
                                  `test_questions_attributes[${questionIndex}].section_id`,
                                  null
                                );
                              }
                            );
                          }}
                          onChange={(e) => {
                            setFieldValue(
                              "post_page",
                              e.target?.value
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <p>{noteHint}</p>
                      <Input
                        inputShape="textarea"
                        showCounter={true}
                        charLimit={800}
                        name="note"
                        label={notes}
                        isRequired={false}
                        type="text"
                      />
                    </div>

                    <div className="mb-4">
                      <FieldArray name="test_questions_attributes">
                        {({ push, replace, remove }) => {
                          return !values?.test_questions_attributes
                            ?.length
                            ? null
                            : values?.test_questions_attributes?.map(
                                (question, questionIndex) => {
                                  return (
                                    !question._destroy && (
                                      <div className="d-flex">
                                        <Paper
                                          style={{ padding: "2rem" }}
                                          className="row align-items-center justify-content-center flex-fill mb-4"
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              style={{
                                                width: "100%"
                                              }}
                                            >
                                              <Input
                                                name={`test_questions_attributes[${questionIndex}].question`}
                                                label={questionTitle}
                                                isRequired={true}
                                              />
                                            </div>

                                            <a
                                              data-bs-toggle="collapse"
                                              href={`#collapseExample${questionIndex}`}
                                              role="button"
                                              aria-expanded="false"
                                              aria-controls="collapseExample"
                                              style={{
                                                width: "100px"
                                              }}
                                              className="ml-2 mb-4"
                                            >
                                              {uploadImageLabel}
                                            </a>
                                          </div>

                                          <div
                                            className={
                                              !values
                                                ?.test_questions_attributes[
                                                questionIndex
                                              ].image_url
                                                ? " collapse p-2 mb-2 "
                                                : "collapse show p-2 mb-2"
                                            }
                                            id={`collapseExample${questionIndex}`}
                                          >
                                            <div className="d-flex align-items-center justify-content-between">
                                              <Grid
                                                item
                                                className="pb-0"
                                                xs={6}
                                              >
                                                <div className="text-center">
                                                  <div className="mb-3 d-flex flex-column align-items-center">
                                                    <MaterialPreview
                                                      type={1}
                                                      src={
                                                        values
                                                          ?.test_questions_attributes[
                                                          questionIndex
                                                        ].image_url
                                                      }
                                                    />
                                                  </div>
                                                  <Upload
                                                    label={upload}
                                                    name={`test_questions_attributes[${questionIndex}].image_url`}
                                                    accept="image/*"
                                                    isImage={true}
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    isRequired={true}
                                                    isMultiple={false}
                                                  />
                                                </div>
                                              </Grid>
                                            </div>
                                          </div>
                                          <Input
                                            style={{ width: "100%" }}
                                            name={`test_questions_attributes[${questionIndex}].points`}
                                            label={questionPoints}
                                            isRequired={true}
                                            type="number"
                                            step="0.1"
                                          />

                                          <div
                                            className={
                                              styles.relativeContainer
                                            }
                                          >
                                            <div
                                              className={
                                                styles.selectTitle
                                              }
                                            >
                                              {mode === "edit" && (
                                                <label
                                                  className={
                                                    styles.label
                                                  }
                                                >
                                                  {sectionName}{" "}
                                                </label>
                                              )}
                                              <Field
                                                isSearchable={true}
                                                disabled={
                                                  !values.post_page ||
                                                  !values.pre_page ||
                                                  !values.book_id
                                                }
                                                component="select"
                                                name={`test_questions_attributes[${questionIndex}].section_id`}
                                                className={`form-control ${styles.selectField}`}
                                                onClick={() =>
                                                  setsectionsInBookOptionsSelect(
                                                    {
                                                      prePage:
                                                        values.pre_page,
                                                      postPage:
                                                        values.post_page,
                                                      bookId:
                                                        values.book_id,
                                                      page: -1
                                                    }
                                                  )
                                                }
                                              >
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                  hidden
                                                >
                                                  {addSection}
                                                </option>

                                                <option
                                                  disabled
                                                ></option>

                                                {optionsToGetSection?.length >
                                                0 ? (
                                                  <>
                                                    {[
                                                      {
                                                        id: "",
                                                        value: "",
                                                        name: `${none}`
                                                      },
                                                      ...optionsToGetSection
                                                    ].map(
                                                      (section) => {
                                                        return (
                                                          <>
                                                            <option
                                                              value={
                                                                section.id
                                                              }
                                                            >
                                                              {
                                                                section?.name
                                                              }
                                                            </option>
                                                            <option
                                                              disabled
                                                            ></option>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <option
                                                      value=""
                                                      selected
                                                      disabled
                                                      hidden
                                                    >
                                                      {addSection}
                                                    </option>
                                                    <option
                                                      key={0}
                                                      disabled
                                                    >
                                                      {noOption}
                                                    </option>
                                                  </>
                                                )}
                                              </Field>
                                            </div>
                                            {values?.book_id ? (
                                              <div
                                                onClick={() => {
                                                  if (
                                                    mode === "edit"
                                                  ) {
                                                    sessionStorage.setItem(
                                                      "bookSelected",
                                                      JSON.stringify({
                                                        id: singleTest.id,
                                                        ...values
                                                      })
                                                    );
                                                    return;
                                                  } else {
                                                    sessionStorage.setItem(
                                                      "bookSelected",
                                                      JSON.stringify(
                                                        values
                                                      )
                                                    );
                                                  }
                                                }}
                                              >
                                                <CustomLink
                                                  slug={`${ROUTE_PATHS.bookDetails}/${values?.book_id}?test=true`}
                                                  linkText={
                                                    addSection
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>

                                          <FieldArray
                                            name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes`}
                                          >
                                            {({
                                              push,
                                              replace,
                                              remove
                                            }) => {
                                              return (
                                                <Form>
                                                  <Typography
                                                    variant="h6"
                                                    component="h6"
                                                    className="text-secondary my-4"
                                                  >
                                                    {answersOptions}
                                                  </Typography>
                                                  <ul className="nav nav-tabs">
                                                    <li
                                                      className="nav-item"
                                                      role="presentation"
                                                    >
                                                      <button
                                                        className={
                                                          (question
                                                            ?.test_answer_choices_attributes[0]
                                                            .id &&
                                                            question
                                                              ?.test_answer_choices_attributes[0]
                                                              .image_url ===
                                                              "" &&
                                                            question
                                                              .test_answer_choices_attributes[0]
                                                              .answer !==
                                                              "") ||
                                                          question
                                                            .test_answer_choices_attributes[0]
                                                            .currentTab ===
                                                            "text"
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                        }
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#text_${questionIndex}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`text_${questionIndex}`}
                                                        aria-selected="false"
                                                        onClick={() =>
                                                          setTabValue(
                                                            setFieldValue,
                                                            questionIndex,
                                                            values,
                                                            "text"
                                                          )
                                                        }
                                                      >
                                                        Text
                                                      </button>
                                                    </li>
                                                    <li
                                                      className="nav-item"
                                                      role="presentation"
                                                    >
                                                      <button
                                                        className={
                                                          question
                                                            ?.test_answer_choices_attributes[0]
                                                            .id &&
                                                          question
                                                            ?.test_answer_choices_attributes[0]
                                                            .image_url !==
                                                            "" &&
                                                          question
                                                            .test_answer_choices_attributes[0]
                                                            .answer ===
                                                            ""
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                        }
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#image_${questionIndex}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`image_${questionIndex}`}
                                                        aria-selected="false"
                                                        onClick={() =>
                                                          setTabValue(
                                                            setFieldValue,
                                                            questionIndex,
                                                            values,
                                                            "image"
                                                          )
                                                        }
                                                      >
                                                        Image
                                                      </button>
                                                    </li>
                                                    <li
                                                      className="nav-item"
                                                      role="presentation"
                                                    >
                                                      <button
                                                        className={
                                                          question
                                                            ?.test_answer_choices_attributes[0]
                                                            .id &&
                                                          question
                                                            ?.test_answer_choices_attributes[0]
                                                            .image_url !==
                                                            "" &&
                                                          question
                                                            .test_answer_choices_attributes[0]
                                                            .answer !==
                                                            ""
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                        }
                                                        data-bs-toggle="tab"
                                                        data-bs-target={`#image_text_${questionIndex}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`image_text_${questionIndex}`}
                                                        aria-selected="false"
                                                        onClick={() =>
                                                          setTabValue(
                                                            setFieldValue,
                                                            questionIndex,
                                                            values,
                                                            "text_image"
                                                          )
                                                        }
                                                      >
                                                        Image with
                                                        Text
                                                      </button>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    className="tab-content py-4"
                                                    id="myTabContent"
                                                  >
                                                    <div
                                                      className={
                                                        (question
                                                          ?.test_answer_choices_attributes[0]
                                                          .id &&
                                                          question
                                                            ?.test_answer_choices_attributes[0]
                                                            .image_url ===
                                                            "" &&
                                                          question
                                                            .test_answer_choices_attributes[0]
                                                            .answer !==
                                                            "") ||
                                                        question
                                                          .test_answer_choices_attributes[0]
                                                          .currentTab ===
                                                          "text"
                                                          ? "tab-pane fade show active"
                                                          : "tab-pane "
                                                      }
                                                      id={`text_${questionIndex}`}
                                                      role="tabpanel"
                                                      aria-labelledby={`text_${questionIndex}`}
                                                    >
                                                      {question.test_answer_choices_attributes.map(
                                                        (
                                                          answer,
                                                          answerIndex,
                                                          arr
                                                        ) => {
                                                          return (
                                                            !answer._destroy && (
                                                              <div
                                                                className="mx-5 d-flex"
                                                                key={JSON.stringify(
                                                                  answerIndex
                                                                )}
                                                              >
                                                                <div className="flex-fill">
                                                                  <Input
                                                                    name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes[${answerIndex}].answer`}
                                                                    label={
                                                                      answerText
                                                                    }
                                                                    isRequired={
                                                                      true
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="mx-2">
                                                                  {arr?.filter(
                                                                    (
                                                                      x
                                                                    ) =>
                                                                      !x._destroy
                                                                  )[0] && (
                                                                    <div className="d-flex">
                                                                      <AddIcon
                                                                        className="me-2 mt-2 cursor-pointer"
                                                                        onClick={() => {
                                                                          push(
                                                                            {
                                                                              answer:
                                                                                "",
                                                                              currentTab:
                                                                                values
                                                                                  .test_questions_attributes[
                                                                                  questionIndex
                                                                                ]
                                                                                  .test_answer_choices_attributes[0]
                                                                                  .currentTab,
                                                                              image_url:
                                                                                "",
                                                                              correct: false,
                                                                              justification:
                                                                                ""
                                                                            }
                                                                          );
                                                                        }}
                                                                      />
                                                                      {}
                                                                      <div className="mx-2 mt-2 cursor-pointer">
                                                                        {values.test_questions_attributes[
                                                                          questionIndex
                                                                        ].test_answer_choices_attributes.every(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el.correct ===
                                                                            false
                                                                        ) ===
                                                                        true ? (
                                                                          <CheckIcon
                                                                            color="success"
                                                                            className="me-2"
                                                                            onClick={() => {
                                                                              replace(
                                                                                answerIndex,
                                                                                {
                                                                                  ...answer,
                                                                                  correct: true
                                                                                }
                                                                              );
                                                                            }}
                                                                          />
                                                                        ) : (
                                                                          values
                                                                            .test_questions_attributes[
                                                                            questionIndex
                                                                          ]
                                                                            .test_answer_choices_attributes[
                                                                            answerIndex
                                                                          ]
                                                                            .correct ===
                                                                            true && (
                                                                            <SettingsBackupRestoreIcon
                                                                              onClick={() => {
                                                                                replace(
                                                                                  answerIndex,
                                                                                  {
                                                                                    ...answer,
                                                                                    correct: false
                                                                                  }
                                                                                );
                                                                              }}
                                                                            />
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>

                                                                {question.test_answer_choices_attributes.filter(
                                                                  (
                                                                    x
                                                                  ) =>
                                                                    !x._destroy
                                                                )
                                                                  .length >
                                                                  2 && (
                                                                  <div className="mx-2 mt-2 cursor-pointer">
                                                                    <RemoveIcon
                                                                      className="me-2"
                                                                      onClick={() => {
                                                                        if (
                                                                          !answer.id
                                                                        ) {
                                                                          remove(
                                                                            answerIndex
                                                                          );
                                                                          return;
                                                                        }
                                                                        replace(
                                                                          answerIndex,
                                                                          {
                                                                            ...answer,
                                                                            answer:
                                                                              "deleted",
                                                                            correct: false,

                                                                            _destroy: true
                                                                          }
                                                                        );
                                                                      }}
                                                                    />
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </div>

                                                    <div
                                                      className={
                                                        mode ===
                                                          "edit" &&
                                                        question
                                                          ?.test_answer_choices_attributes[0]
                                                          .id &&
                                                        question
                                                          ?.test_answer_choices_attributes[0]
                                                          .image_url !==
                                                          "" &&
                                                        question
                                                          .test_answer_choices_attributes[0]
                                                          .answer ===
                                                          ""
                                                          ? "tab-pane fade show active"
                                                          : "tab-pane fade"
                                                      }
                                                      id={`image_${questionIndex}`}
                                                      role="tabpanel"
                                                      aria-labelledby={`image_${questionIndex}`}
                                                    >
                                                      {question.test_answer_choices_attributes.map(
                                                        (
                                                          answer,
                                                          answerIndex,
                                                          arr
                                                        ) => {
                                                          return (
                                                            !answer._destroy && (
                                                              <div
                                                                className="mx-5 d-flex"
                                                                key={JSON.stringify(
                                                                  answerIndex
                                                                )}
                                                              >
                                                                <div className="flex-fill">
                                                                  <Grid
                                                                    item
                                                                    xs={
                                                                      6
                                                                    }
                                                                    className="pb-0"
                                                                  >
                                                                    <div className="d-flex flex-column align-items-center text-center">
                                                                      <div className="mb-3 d-flex flex-column align-items-center w-100">
                                                                        <MaterialPreview
                                                                          type={
                                                                            1
                                                                          }
                                                                          src={
                                                                            values
                                                                              ?.test_questions_attributes[
                                                                              questionIndex
                                                                            ]
                                                                              .test_answer_choices_attributes[
                                                                              answerIndex
                                                                            ]
                                                                              .image_url
                                                                          }
                                                                        />
                                                                      </div>
                                                                      <Upload
                                                                        label={
                                                                          upload
                                                                        }
                                                                        name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes[${answerIndex}].image_url`}
                                                                        accept="image/*"
                                                                        isImage={
                                                                          true
                                                                        }
                                                                        setFieldValue={
                                                                          setFieldValue
                                                                        }
                                                                        isRequired={
                                                                          true
                                                                        }
                                                                        isMultiple={
                                                                          isDicom
                                                                            ? true
                                                                            : false
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </Grid>
                                                                </div>
                                                                <div className="mx-2">
                                                                  {arr?.filter(
                                                                    (
                                                                      x
                                                                    ) =>
                                                                      !x._destroy
                                                                  )[0] && (
                                                                    <div className="d-flex">
                                                                      <AddIcon
                                                                        className="me-2 mt-2 cursor-pointer"
                                                                        onClick={() => {
                                                                          push(
                                                                            {
                                                                              answer:
                                                                                "",
                                                                              currentTab:
                                                                                values
                                                                                  .test_questions_attributes[
                                                                                  questionIndex
                                                                                ]
                                                                                  .test_answer_choices_attributes[0]
                                                                                  .currentTab,
                                                                              image_url:
                                                                                "",
                                                                              correct: false,
                                                                              justification:
                                                                                ""
                                                                            }
                                                                          );
                                                                        }}
                                                                      />
                                                                      <div className="mx-2 mt-2 cursor-pointer">
                                                                        {values.test_questions_attributes[
                                                                          questionIndex
                                                                        ].test_answer_choices_attributes.every(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el.correct ===
                                                                            false
                                                                        ) ===
                                                                        true ? (
                                                                          <CheckIcon
                                                                            color="success"
                                                                            className="me-2"
                                                                            onClick={() => {
                                                                              replace(
                                                                                answerIndex,
                                                                                {
                                                                                  ...answer,
                                                                                  correct: true
                                                                                }
                                                                              );
                                                                            }}
                                                                          />
                                                                        ) : (
                                                                          values
                                                                            .test_questions_attributes[
                                                                            questionIndex
                                                                          ]
                                                                            .test_answer_choices_attributes[
                                                                            answerIndex
                                                                          ]
                                                                            .correct ===
                                                                            true && (
                                                                            <SettingsBackupRestoreIcon
                                                                              onClick={() => {
                                                                                replace(
                                                                                  answerIndex,
                                                                                  {
                                                                                    ...answer,
                                                                                    correct: false
                                                                                  }
                                                                                );
                                                                              }}
                                                                            />
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                                {question.test_answer_choices_attributes.filter(
                                                                  (
                                                                    x
                                                                  ) =>
                                                                    !x._destroy
                                                                )
                                                                  .length >
                                                                  2 && (
                                                                  <div className="mx-2 mt-2 cursor-pointer">
                                                                    <RemoveIcon
                                                                      className="me-2"
                                                                      onClick={() =>
                                                                        replace(
                                                                          answerIndex,
                                                                          {
                                                                            ...answer,
                                                                            correct: false,

                                                                            _destroy: true
                                                                          }
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                    <div
                                                      className={
                                                        mode ===
                                                          "edit" &&
                                                        question
                                                          ?.test_answer_choices_attributes[0]
                                                          .id &&
                                                        question
                                                          .test_answer_choices_attributes[0]
                                                          .image_url !==
                                                          "" &&
                                                        question
                                                          .test_answer_choices_attributes[0]
                                                          .answer !==
                                                          ""
                                                          ? "tab-pane fade show active"
                                                          : "tab-pane fade"
                                                      }
                                                      id={`image_text_${questionIndex}`}
                                                      role="tabpanel"
                                                      aria-labelledby={`image_text_${questionIndex}`}
                                                    >
                                                      {question.test_answer_choices_attributes.map(
                                                        (
                                                          answer,
                                                          answerIndex,
                                                          arr
                                                        ) => {
                                                          return (
                                                            !answer._destroy && (
                                                              <div
                                                                className="mx-5 d-flex"
                                                                key={JSON.stringify(
                                                                  answerIndex
                                                                )}
                                                              >
                                                                <div className="flex-fill">
                                                                  <Input
                                                                    name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes[${answerIndex}].answer`}
                                                                    label={
                                                                      answerText
                                                                    }
                                                                    isRequired={
                                                                      true
                                                                    }
                                                                  />
                                                                  <div className="flex-fill">
                                                                    <Grid
                                                                      item
                                                                      xs={
                                                                        6
                                                                      }
                                                                      className="pb-0"
                                                                    >
                                                                      <div className="d-flex flex-column align-items-center text-center">
                                                                        <div className="mb-3 d-flex flex-column align-items-center w-100">
                                                                          <MaterialPreview
                                                                            type={
                                                                              1
                                                                            }
                                                                            src={
                                                                              values
                                                                                ?.test_questions_attributes[
                                                                                questionIndex
                                                                              ]
                                                                                .test_answer_choices_attributes[
                                                                                answerIndex
                                                                              ]
                                                                                .image_url
                                                                            }
                                                                          />
                                                                        </div>
                                                                        <Upload
                                                                          label={
                                                                            upload
                                                                          }
                                                                          name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes[${answerIndex}].image_url`}
                                                                          accept="image/*"
                                                                          isImage={
                                                                            true
                                                                          }
                                                                          setFieldValue={
                                                                            setFieldValue
                                                                          }
                                                                          isRequired={
                                                                            true
                                                                          }
                                                                          isMultiple={
                                                                            isDicom
                                                                              ? true
                                                                              : false
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                  </div>
                                                                </div>
                                                                <div className="mx-2">
                                                                  {arr?.filter(
                                                                    (
                                                                      x
                                                                    ) =>
                                                                      !x._destroy
                                                                  )[0] && (
                                                                    <div className="d-flex">
                                                                      <AddIcon
                                                                        className="me-2 mt-2 cursor-pointer"
                                                                        onClick={AddAnswer(
                                                                          push,
                                                                          values,
                                                                          questionIndex
                                                                        )}
                                                                      />
                                                                      <div className="mx-2 mt-2 cursor-pointer">
                                                                        {values.test_questions_attributes[
                                                                          questionIndex
                                                                        ].test_answer_choices_attributes.every(
                                                                          (
                                                                            el
                                                                          ) =>
                                                                            el.correct ==
                                                                            false
                                                                        ) ===
                                                                        true ? (
                                                                          <CheckIcon
                                                                            color="success"
                                                                            className="me-2"
                                                                            onClick={() => {
                                                                              replace(
                                                                                answerIndex,
                                                                                {
                                                                                  ...answer,
                                                                                  correct: true
                                                                                }
                                                                              );
                                                                            }}
                                                                          />
                                                                        ) : (
                                                                          values
                                                                            .test_questions_attributes[
                                                                            questionIndex
                                                                          ]
                                                                            .test_answer_choices_attributes[
                                                                            answerIndex
                                                                          ]
                                                                            .correct ===
                                                                            true && (
                                                                            <SettingsBackupRestoreIcon
                                                                              onClick={() => {
                                                                                replace(
                                                                                  answerIndex,
                                                                                  {
                                                                                    ...answer,
                                                                                    correct: false
                                                                                  }
                                                                                );
                                                                              }}
                                                                            />
                                                                          )
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                                {question.test_answer_choices_attributes.filter(
                                                                  (
                                                                    x
                                                                  ) =>
                                                                    !x._destroy
                                                                )
                                                                  .length >
                                                                  2 && (
                                                                  <div className="mx-2 mt-2 cursor-pointer">
                                                                    <RemoveIcon
                                                                      className="me-2"
                                                                      onClick={removeAnswer(
                                                                        replace,
                                                                        answerIndex,
                                                                        answer
                                                                      )}
                                                                    />
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="my-4">
                                                    <Input
                                                      name={`test_questions_attributes[${questionIndex}].justification`}
                                                      label={
                                                        explanation
                                                      }
                                                      isRequired={
                                                        false
                                                      }
                                                    />
                                                    <div className="mb-3 d-flex flex-row align-items-end w-50 gap-3">
                                                      <MaterialPreview
                                                        type={1}
                                                        src={
                                                          values
                                                            ?.test_questions_attributes[
                                                            questionIndex
                                                          ]
                                                            .justification_image_url
                                                        }
                                                      />
                                                      <Upload
                                                        label={upload}
                                                        name={`test_questions_attributes[${questionIndex}].justification_image_url`}
                                                        accept="image/*"
                                                        isImage={true}
                                                        setFieldValue={
                                                          setFieldValue
                                                        }
                                                        isRequired={
                                                          false
                                                        }
                                                        isMultiple={
                                                          isDicom
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Form>
                                              );
                                            }}
                                          </FieldArray>
                                        </Paper>
                                        <div
                                          className="d-flex flex-row mb-4  align-items-center mx-5"
                                          key={JSON.stringify(
                                            questionIndex
                                          )}
                                        >
                                          {values
                                            .test_questions_attributes
                                            .length > 1 &&
                                            !question?._destroy &&
                                            values.test_questions_attributes.filter(
                                              (x) => !x._destroy
                                            ).length !== 1 && (
                                              <div className="mx-2 mb-3">
                                                <RemoveIcon
                                                  onClick={RemoveQuestion(
                                                    question,
                                                    remove,
                                                    questionIndex,
                                                    replace,
                                                    mode,
                                                    setDestroy
                                                  )}
                                                  className="cursor-pointer"
                                                  disabled={
                                                    values.test_questions_attributes.filter(
                                                      (x) =>
                                                        !x._destroy
                                                    ).length === 1
                                                  }
                                                />
                                              </div>
                                            )}
                                          {AddRemoveNewQuestion(push)}
                                        </div>
                                      </div>
                                    )
                                  );
                                }
                              );
                        }}
                      </FieldArray>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="pb-0"></Grid>
                </Grid>
              </fieldset>
              <div className="d-block mt-3 ">
                <Button
                  text={submit}
                  className="primary-lg"
                  type="submit"
                  data-toggle="tooltip"
                  data-placement="top"
                  disabled={!CanBeEditTest}
                  title={!CanBeEditTest ? testAnswered : ""}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PreTestPostTestForm;

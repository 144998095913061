import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  selectedType: null,
  title: null,
  singleInstitution: {}
};

const InstitutionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_INSTITUTIONS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.SET_INSTITUTION_FILTERS:
      return {
        ...state,
        title: action.payload.title
      };
    case types.GET_SINGLE_INSTITUTION_RESPONSE:
      return { ...state, singleInstitution: action.payload };
    default:
      return state;
  }
};

export default InstitutionsReducer;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import _ from "lodash";
import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { add, edit } from "../../../store/ActionsBtn/actions";
import messages from "../../../assets/locale/messages";
import CustomSelect from "../../../components/Select";
import { getBooksRequest } from "../../../store/Books/actions";
import {
  ASSIGN_SUPPLEMENTARY_SCHEMA,
  getInitialValues
} from "./validations";
import {
  assignSupplementaryToBookRequest,
  editBookPageForSupplementaryRequest
} from "../../../store/Supplementaries/actions";

const AssignSupplementary = ({ isOpen, lang, page, singleBook }) => {
  let params = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [mode, setMode] = useState("add");
  const booksList = useSelector((state) => state.books.list);
  const editId = useSelector((state) => state.actionsBtn.edit);
  const supplementary_material_id = useSelector(
    (state) => state.actionsBtn.add
  );

  const [booksOptions, setBooksOptions] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
  }, []);

  useEffect(() => {
    mode === "edit" ? setSelectedBook(singleBook) : null;
  }, [singleBook]);

  useEffect(() => {
    if (booksList.length) {
      setBooksOptions(
        booksList.map(({ id, title }) => ({
          id,
          value: title,
          label: title
        }))
      );
    }
  }, [booksList]);

  useEffect(() => {
    params?.id ? setMode("edit") : null;
  }, [params]);

  const handleModalCancel = () => {
    mode === "add" ? dispatch(add(false)) : dispatch(edit(false));
  };

  const handleSelectedBook = (id) => {
    setSelectedBook(_.find(booksList, ["id", id]));
  };

  const handleSubmit = (values) => {
    if (mode === "add") {
      const addData = {
        ...values,
        supplementary_material_id
      };
      dispatch(assignSupplementaryToBookRequest(addData));
    } else {
      const editData = {
        book_page: values.book_page,
        id: editId
      };
      dispatch(
        editBookPageForSupplementaryRequest({
          data: editData,
          book_id: singleBook?.id
        })
      );
    }
  };

  const {
    supplementaries: {
      assignToBook,
      bookPageLabel,
      editAssignedPage
    },
    books: { book }
  } = messages[lang];

  return (
    <Modal
      title={mode === "add" ? assignToBook : editAssignedPage}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={getInitialValues(page)}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={ASSIGN_SUPPLEMENTARY_SCHEMA(
          lang,
          selectedBook?.number_of_pages,
          mode === "edit" ? true : false
        )}
      >
        {() => (
          <Form>
            {mode === "add" && (
              <CustomSelect
                options={booksOptions}
                placeholder={book}
                name="book_id"
                bgColor={theme.palette.primary.light}
                isRequired={true}
                isMenuRelative={true}
                handleSelectChange={handleSelectedBook}
              />
            )}
            <Input
              name="book_page"
              label={bookPageLabel}
              isRequired={true}
              isNumber={true}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

AssignSupplementary.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  singleBook: PropTypes.object,
  page: PropTypes.number
};

AssignSupplementary.defaultProps = {
  isOpen: false
};

export default AssignSupplementary;

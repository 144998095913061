import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import Table from "../../../components/Table";
import History from "../../../routes/History";
import {
  assignQuizBtn,
  deleteQuizAction
} from "../../../store/Quizzes/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import SortingWrapper from "./SortingWrapper";

const QuizzesList = ({ lang, list }) => {
  const dispatch = useDispatch();
  const [sortedData, setSortedData] = useState(list);
  useEffect(() => {
    setSortedData(list);
  }, [list]);

  const {
    quizzes: { quizTitle },
    shared: { deleteLabel, editLabel, view }
  } = messages[lang];
  const columnsTitles = [
    { title: quizTitle, key: "quizTitle" },
    { title: "Book", key: "book" },
    { title: "Page", key: "page" }
  ];
  const handleAssignToBook = (id) => {
    dispatch(assignQuizBtn(id));
  };
  const handleDelete = (id) => {
    dispatch(deleteQuizAction(id));
    // dispatch(deleteQuiz({ id }));
  };
  const quizzesBookFilterId = useSelector(
    (state) => state.quizzes.quizzesBookFilter
  );

  return (
    <Table
      headlines={columnsTitles.map((s) => {
        // sorting is only for pages for now
        if (s.key !== "page") return s.title;
        return (
          <SortingWrapper
            columnKey={s.key}
            setSortedData={setSortedData}
            key={s.key}
          >
            {s.title}
          </SortingWrapper>
        );
      })}
      hasActions={true}
      actionAlignment={"center"}
      rows={sortedData.map((row) => {
        return (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row?.title}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {!!row.books?.length &&
                row.books.map((book) => {
                  if (
                    quizzesBookFilterId &&
                    book?.book.id != quizzesBookFilterId
                  )
                    return null;
                  return (
                    <div key={book?.book.title}>
                      {book?.book.title}
                    </div>
                  );
                })}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {!!row.books?.length &&
                row.books.map((book) => {
                  if (
                    quizzesBookFilterId &&
                    book?.book.id != quizzesBookFilterId
                  )
                    return null;
                  return (
                    <div key={book?.book_page}>{book?.book_page}</div>
                  );
                })}
            </TableCell>
            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <Tooltip title={"Assign quiz to book"}>
                <span>
                  <IconButton
                    onClick={() => handleAssignToBook(row.id)}
                    aria-label="assign"
                  >
                    <MenuBookIcon
                      className="table__assign"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={view}>
                <IconButton
                  aria-label="view"
                  onClick={() => {
                    History.push({
                      pathname: `${ROUTE_PATHS.viewQuiz}/${row.id}`,
                      state: { mode: "view" }
                    });
                  }}
                >
                  <VisibilityIcon
                    className="table__view"
                    fontSize="medium"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={editLabel}>
                <IconButton
                  onClick={() => {
                    History.push({
                      pathname: `${ROUTE_PATHS.editQuiz}/${row.id}`,
                      state: { mode: "edit" }
                    });
                  }}
                  aria-label="edit"
                >
                  <EditIcon
                    className="table__edit"
                    fontSize="medium"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
    />
  );
};
QuizzesList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object)
};

export default QuizzesList;

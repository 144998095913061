import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/admins";
import { add, edit } from "../ActionsBtn/actions";
import { loginResponse } from "../Auth/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  addAdminResponse,
  editAdminResponse,
  getAdminsRequest,
  getAdminsResponse
} from "./actions";

export function* getAdminsSaga({ payload }) {
  try {
    const response = yield call(apis.getAdmins, payload);
    yield put(
      getAdminsResponse({
        list: response.data.admins,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteAdminsSaga({ payload }) {
  try {
    const response = yield call(apis.deleteAdmin, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].admins.deleteAdminSuccess
      })
    );
    yield put(getAdminsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addAdminSaga({ payload }) {
  try {
    const response = yield call(apis.addAdmin, payload);
    yield put(addAdminResponse(response.data));
    yield put(add(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].admins.addAdminSuccess
      })
    );
    yield put(getAdminsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* editAdminSaga({ payload }) {
  try {
    const response = yield call(apis.editAdmin, payload);
    yield put(editAdminResponse(response.data));
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].admins.editAdminSuccess
      })
    );
    if (payload.currentAdmin.id === payload.id) {
      yield put(
        loginResponse({
          ...payload.currentAdmin,
          name: response.data.admin.name
        })
      );
    }
    yield put(getAdminsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

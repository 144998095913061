import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import messages from "../../assets/locale/messages";
import AllInboxIcon from "@material-ui/icons/AllInbox";

const EmptyState = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    shared: { emptyStateMsg }
  } = messages[lang];

  return (
    <div className="text-center mt-5">
      <AllInboxIcon className="fsize-50 mb-2" />
      <Typography variant="h5" component="h5" className="text-center">
        {emptyStateMsg}
      </Typography>
    </div>
  );
};

export default EmptyState;

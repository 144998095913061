import { call, put } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/programs";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  addProgramResponse,
  addProgramsResponse,
  addUsersInsideProgramResponse,
  getProgramsRequest,
  getUsersInsideProgramRequest
} from "./actions";

export function* getProgramsSaga({ payload }) {
  try {
    const response = yield call(apis.getPrograms, payload);
    yield put(addProgramsResponse(response.data.data.programs));
  } catch (err) {
    console.error(err);
  }
}

export function* getProgramSaga({ payload }) {
  try {
    const response = yield call(apis.getProgram, payload);
    yield put(addProgramResponse(response.data.program));
  } catch (err) {
    console.error(err);
  }
}

export function* getUsersProgramSaga({ payload }) {
  try {
    const response = yield call(apis.getUsersInsideProgram, payload);
    yield put(
      addUsersInsideProgramResponse({
        users: response.data.data.users,
        meta: response.data.data.pagination
      })
    );

    yield put(addProgramResponse(response.data.program));
  } catch (err) {
    console.error(err);
  }
}

export function* deleteProgramSaga({ payload }) {
  try {
    const response = yield call(apis.deletePrograms, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].programs.deleteProgramSuccess
      })
    );
    yield put(getProgramsRequest());
  } catch (err) {
    console.error(err);
  }
}

export function* addProgramSaga({ payload }) {
  try {
    const response = yield call(apis.createPrograms, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].programs.addProgramSuccess
      })
    );
    yield put(getProgramsRequest());
  } catch (err) {
    console.error(err);
  }
}

export function* assignDirectorToProgramSaga({ payload }) {
  try {
    const response = yield call(
      apis.assignDirectorToProgram,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].programs.assignDirectorSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* assignUsersToProgramSaga({ payload }) {
  try {
    const response = yield call(apis.assignUsersToProgram, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].programs.assignUsersSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* assignUsersFromFileToProgramSaga({ payload }) {
  try {
    const response = yield call(
      apis.assignUsersFromFileToProgram,
      payload
    );
    yield put(
      getUsersInsideProgramRequest({
        id: payload?.id,
        page: 1,
        items: 10
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].programs.assignUsersSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* assignBooksToProgramSaga({ payload }) {
  try {
    const response = yield call(apis.assignBooksToProgram, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].programs.assignBooksSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* deleteUserFromProgramSaga({ payload }) {
  try {
    const response = yield call(apis.deleteUserFromProgram, payload);

    yield put(
      getUsersInsideProgramRequest({
        id: payload?.id,
        page: 1,
        items: 10
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].programs.removeUserSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import LinkIcon from "@material-ui/icons/Link";
import { uploadToS3 } from "../../../utils/S3";
import styles from "./Upload.module.scss";

const Upload = ({
  label,
  name,
  setFieldValue,
  accept,
  handlePreviewSrc,
  isShowName,
  isRequired,
  initialFileData,
  disabled,
  isMultiple
}) => {
  const [fileData, setFileData] = useState({});
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    initialFileData &&
      isInitial &&
      setFileData({ ...fileData, ...initialFileData });
  }, [initialFileData]);

  const uploadFilesToS3 = async (sourceFiles) => {
    const files = !sourceFiles?.length
      ? [sourceFiles]
      : [...sourceFiles];
    const urls = [];
    for (let i = 0; i < files.length; i++) {
      await uploadToS3("image", files[i], files[i].name).then(
        (data) => {
          urls.push(data.link);
        }
      );
    }
    return urls;
  };
  const handleChange = async (event) => {
    const files = event.target.files;
    setIsInitial(false);
    let url = await uploadFilesToS3(files[0]);
    if (files.length) {
      setFieldValue(name, url[0]);
      if (isMultiple) {
        const filesAsArray = [...files];
        let filesSrc = [];
        filesAsArray.forEach((file) => {
          filesSrc.push(URL.createObjectURL(file));
        });
        handlePreviewSrc(filesSrc);
      } else {
        const file = files[0];
        const src = URL.createObjectURL(file);
        handlePreviewSrc(src);
        setFileData({ link: src, name: file.name });
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Field name={name}>
        {() => (
          <>
            <input
              className="d-none"
              name={name}
              id={name}
              type="file"
              accept={accept}
              disabled={disabled}
              multiple={isMultiple}
              onChange={handleChange}
            />
            <label
              className={`${styles.label} ${
                disabled ? styles.disabled : ""
              }`}
              htmlFor={name}
            >
              {label}{" "}
              {isRequired && (
                <span className="error-msg fsize-14">*</span>
              )}
            </label>
            {isShowName && fileData?.name && (
              <a
                href={fileData?.link}
                target="_blank"
                rel="noreferrer"
                className="fsize-14 mx-2 text-primary"
              >
                <LinkIcon /> {fileData.name}
              </a>
            )}
          </>
        )}
      </Field>
      <ErrorMessage
        component="div"
        className="error-msg"
        name={name}
      />
    </div>
  );
};

Upload.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  setFieldValue: PropTypes.func,
  accept: PropTypes.string,
  handlePreviewSrc: PropTypes.func,
  isShowName: PropTypes.bool,
  isRequired: PropTypes.bool,
  initialFileData: PropTypes.object,
  disabled: PropTypes.bool,
  isMultiple: PropTypes.bool
};

Upload.defaultProps = {
  label: "Upload",
  accept: "",
  isShowName: false,
  isRequired: false,
  initialFileData: {},
  disabled: false,
  isMultiple: false,
  setFieldValue: () => {},
  handlePreviewSrc: () => {}
};

export default Upload;

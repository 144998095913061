import { axiosInstance } from ".";

export const getPrograms = async (params) => {
  return await axiosInstance.get(`programs`, { params });
};

export const getProgram = async (id) => {
  return await axiosInstance.get(`programs/${id}`);
};

export const deletePrograms = async (id) => {
  return await axiosInstance.delete(`programs/${id}`);
};

export const createPrograms = async (data) => {
  return await axiosInstance.post(`programs`, data);
};

export const assignDirectorToProgram = async ({ id, data }) => {
  return await axiosInstance.post(
    `programs/${id}/assign_director`,
    data
  );
};
export const assignUsersToProgram = async ({ id, data }) => {
  return await axiosInstance.post(
    `programs/${id}/assign_users`,
    data
  );
};
export const assignUsersFromFileToProgram = async ({ id, data }) => {
  return await axiosInstance.post(
    `programs/${id}/assign_users`,
    data,
    {
      headers: {
        "content-type": "multipart/form-data"
      }
    }
  );
};
export const assignBooksToProgram = async ({ id, data }) => {
  return await axiosInstance.post(
    `programs/${id}/assign_books`,
    data
  );
};
export const deleteUserFromProgram = async ({ id, userId }) => {
  return await axiosInstance.delete(`programs/${id}/users/${userId}`);
};
export const getUsersInsideProgram = async ({ id, page, items }) => {
  return await axiosInstance.get(
    `programs/${id}/users?page=${page}&items=${items}`
  );
};

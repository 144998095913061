import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { ROUTE_PATHS } from "../../utils/PathsNames";
import messages from "../../assets/locale/messages";
import {
  getPromoCodesListRequest,
  setPromoCodesFilters
} from "../../store/Promocodes/actions";
import Search from "../../components/Search";
import Button from "../../components/Button";
import PromoCodesList from "./PromoCodesList";
import DeletePromocodes from "./DeletePromoCodes";

const PromoCodes = () => {
  const perPage = 10;
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const searchKeyword = useSelector(
    (state) => state.promoCodes.searchKeyword
  );

  const promoCodesList = useSelector(
    (state) => state.promoCodes.list
  );

  const {
    promoCodes: { title, addPromocodes }
  } = messages[lang];

  useEffect(() => {
    dispatch(
      getPromoCodesListRequest({
        page: 1,
        items: perPage,
        name: searchKeyword
      })
    );
  }, []);
  useEffect(() => {
    function filterPromoCodesList() {
      const params = {
        page: 1,
        items: perPage,
        name: searchKeyword
      };
      dispatch(getPromoCodesListRequest(params));
    }
    filterPromoCodesList();
  }, [searchKeyword]);

  useEffect(() => {
    return () => {
      dispatch(
        setPromoCodesFilters({
          searchKeyword: ""
        })
      );
    };
  }, []);

  const handleSearch = (e) => {
    dispatch(
      setPromoCodesFilters({
        searchKeyword: e.target.value
      })
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>

      <Grid container>
        <Grid item md={6}>
          <Formik initialValues={{ search: "" }}>
            {() => (
              <Form>
                <Search
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <button className="d-none"></button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          md={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addPromocodes}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            slug={ROUTE_PATHS["addPromoCodes"]}
          />
        </Grid>
      </Grid>
      {promoCodesList && (
        <PromoCodesList list={promoCodesList} lang={lang} />
      )}
      <DeletePromocodes isOpen={!!deleteId} lang={lang} />
    </>
  );
};

export default PromoCodes;

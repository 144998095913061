import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  addedEditor: null
};

const editorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_EDITORS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.ADD_EDITOR_RESPONSE:
      return { ...state, addedEditor: action.payload };
    default:
      return state;
  }
};

export default editorReducer;

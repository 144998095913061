import { call, put } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/directors";
import { showHideSnackbar } from "../Snackbar/actions";
import { getDirectorsRequest, getDirectorsResponse } from "./actions";

export function* getDirectorsSaga({ payload }) {
  try {
    const response = yield call(apis.getDirectors, payload);
    yield put(
      getDirectorsResponse({
        list: response.data.data.program_directors,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteDirectorsSaga({ payload }) {
  try {
    const response = yield call(apis.deleteDirector, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].directors.deleteDirectorSuccess
      })
    );
    yield put(getDirectorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addDirectorSaga({ payload }) {
  try {
    const response = yield call(apis.addDirector, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].directors.addDirectorSuccess
      })
    );
    yield put(getDirectorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

// export function* editAuthorSaga({ payload }) {
//   try {
//     const response = yield call(apis.editAuthor, payload);
//     yield put(editAuthorResponse(response.data));
//     yield put(edit(false));
//     yield put(
//       showHideSnackbar({
//         isOpen: true,
//         type: "success",
//         message: messages[response.lang].authors.editAuthorSuccess
//       })
//     );
//     yield put(getAuthorsRequest({ page: 1, items: 10 }));
//   } catch (err) {
//     console.error(err);
//   }
// }

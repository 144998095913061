export const ROUTE_PATHS = {
  root: "/",
  dashboard: "/dashboard",
  finalTestForm: "/finalTestForm",
  finalTest: "/finalTest",
  programs: "/programs",
  programsEdit: "/programsEdit",
  login: "/login",
  admins: "/admins",
  authors: "/authors",
  editors: "/editors",
  directors: "/directors",
  categories: "/categories",
  books: "/books",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  createPassword: "/create-password",
  addBook: "/add-book",
  editBook: "/edit-book",
  bookDetails: "/book",
  supplementaries: "/supplementaries",
  addSupplementary: "/add-supplementary",
  editSupplementary: "/edit-supplementary",
  supplementaryDetails: "/supplementary",
  quizzes: "/quizzes",
  addQuiz: "/add-quiz",
  surveys: "/surveys",
  addSurvey: "/add-survey",
  editSurvey: "/edit-survey",
  surveysDetails: "/surveys",
  surveysStatistics: "/surveys-statistics",
  editQuiz: "/edit-quiz",
  viewQuiz: "/view-quiz",
  preTestPostTest: "/tests",
  addPreTestPostTest: "/add-test",
  editPreTestPostTest: "/edit-test",
  preTestPostTestDetails: "/test",
  userTestReports: "/test-report",
  registeredUsers: "/registered-users",
  institute: "/institute",
  addInstitution: "/add-institute",
  editInstitute: "/edit-institute",
  promoCodes: "/promocodes",
  addPromoCodes: "/add-promocode",
  promoCodeDetails: "/promocode",
  institution: "/institution",
  institutionDetails: "/institute"
};

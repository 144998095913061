import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { getBooksRequest } from "../../../store/Books/actions";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { axiosInstance } from "../../../network/apis";
import { useParams } from "react-router-dom";
import { showHideSnackbar } from "../../../store/Snackbar/actions";

const SortSupplementaries = ({ isOpen, setOpenSortModal }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const isLoading = useSelector((state) => state.loader);
  const { id } = useParams();

  const validationSchema = null;

  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
  }, []);

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleModalCancel = () => {
    setOpenSortModal(false);
  };

  const handleSubmit = async () => {
    const payload = {
      book_page: Number(page),
      book_id: Number(id),
      page_supplementary_materials: pageData.map((item, index) => ({
        id: item.id,
        order: index + 1
      }))
    };

    await axiosInstance.patch(
      "/book_supplementary_materials/edit_order",
      payload
    );

    setOpenSortModal(false);

    dispatch(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "Supplementaries order saved successfully"
      })
    );
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...pageData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setPageData(copyListItems);
  };

  const getPageItems = async (page) => {
    await axiosInstance
      .get("/book_supplementary_materials", {
        params: {
          book_id: id,
          book_page: page
        }
      })
      .then((res) => {
        setPageData(
          res.data.book_supplementary_materials.sort(
            (a, b) => a.order - b.order
          )
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getPageItems(1);
  }, []);

  return (
    <Modal
      title={"Sort Supplementaries"}
      hasActions={true}
      isSubmitDisabled={pageData?.length === 0}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={{}}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <TextField
              label="Page"
              placeholder={"Page"}
              type="number"
              inputProps={{ min: 1 }}
              disabled={isLoading}
              onChange={async (e) => {
                if (e.target.value.includes(".")) {
                  setPage(e.target.value.replace(".", ""));
                  e.preventDefault();
                  return;
                } else if (e.target.value !== "") {
                  setPage(e.target.value);
                  setPageData([]);
                  setLoading(true);
                  if (Number(e.target.value) > 0) {
                    getPageItems(e.target.value);
                  } else {
                    setPage(1);
                  }
                }
              }}
              className="col mx-2"
              value={page}
            />

            <div className="text-danger">
              {page <= 0 && <>Enter positive integers</>}
            </div>

            <div>
              {!loading && pageData?.length === 0 && (
                <>There is no supplementaries for this page</>
              )}
            </div>

            {pageData.map((supplementary, index) => (
              <div
                style={{
                  backgroundColor: "lightblue",
                  margin: "10px",
                  padding: "4px",
                  fontSize: "15px"
                }}
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                key={index}
                draggable
              >
                {supplementary.material_title}
              </div>
            ))}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

SortSupplementaries.propTypes = {
  isOpen: PropTypes.bool,
  setOpenSortModal: PropTypes.func
};

export default SortSupplementaries;

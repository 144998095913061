import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Table from "../../../components/Table";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import { getInstitutionsRequest } from "../../../store/Institutions/actions";
// import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { useIntl } from "react-intl";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

const InstitutionsList = ({ list }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { messages } = useIntl();
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.institutes.meta);
  const title = useSelector((state) => state.institutes.title);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getInstitutionsRequest({
        title: title,
        page: page,
        items: perPage
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(deleteAction(id));
  };

  const handleView = (id) => {
    history.push(`${ROUTE_PATHS["institute"]}/${id}`);
  };

  const handleEdit = (id) => {
    history.push(`${ROUTE_PATHS["editInstitute"]}/${id}`);
  };

  const {
    institute: { name, startDate, endDate },
    shared: { view, editLabel, deleteLabel }
  } = messages;
  return (
    <>
      <Table
        headlines={[name, startDate, endDate]}
        hasActions={true}
        rows={list?.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {moment(row.subscription_start_date).format(
                "DD-MM-YYYY"
              )}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {moment(row.subscription_end_date).format("DD-MM-YYYY")}
            </TableCell>

            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <Tooltip title={view}>
                <span>
                  <IconButton
                    onClick={() => handleView(row.id)}
                    aria-label="view"
                  >
                    <VisibilityIcon
                      className="table__view"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={editLabel}>
                <span>
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />

      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

InstitutionsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object)
};

export default InstitutionsList;

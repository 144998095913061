import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Paper,
  Radio,
  useTheme
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";

import Input from "../../../components/Input";
import {
  getInitialValuesAdd,
  getInitialValuesEdit
} from "./validation";
import {
  getSingleTestRequest,
  getSingleTestResponse
} from "../../../store/Tests/actions";
import { getBookSectionsRequest } from "../../../store/Books/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import CustomSelect from "../../../components/Select";
import { formatOptions } from "../../../utils/Helpers";
import styles from "./PreTestPostTestDetails.module.scss";
import MaterialPreview from "../../../components/MaterialPreview";

const PreTestPostTestDetails = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const {
    preTestPostTest: {
      titleLabel,
      questionTitle,
      answersOptions,
      viewTest,
      book,
      prePage,
      postPage,
      mandatory,
      answerText,
      optional,
      selectTestTypeLabel,
      explanation,
      questionPoints,
      noSection,
      notes,
      noteHint,
      sectionName
    }
  } = messages;

  let params = useParams();

  const theme = useTheme();
  const [mode, setMode] = useState("add");
  const [isDicom, setIsDicom] = useState(false);

  const books = useSelector((state) => state.books.list);
  const singleTest = useSelector((state) => state.tests.singleTest);
  const sectionsInBook = useSelector(
    (state) => state.books.sectionsInBookOptions
  );
  useEffect(() => {
    setIsDicom(false);
    console.log(isDicom);
    dispatch(getBooksRequest({ lookup: true }));
    return () => {
      dispatch(getSingleTestResponse({}));
    };
  }, []);

  useEffect(async () => {
    await dispatch(
      getBookSectionsRequest({
        bookId: singleTest?.book_id,
        from: singleTest?.pre_page,
        to: singleTest?.post_page
      })
    );
  }, [singleTest]);
  useEffect(() => {
    if (params?.id) {
      setMode("edit");
      dispatch(getSingleTestRequest(params.id));
    }
  }, [params]);

  const getSectionName = (id) => {
    let secName;
    sectionsInBook.map((sec) => {
      if (id === sec.id) secName = sec.name;
    });

    return secName;
  };
  console.log(mode);

  return (
    <>
      <Typography variant="h4" component="h1" className="mb-4">
        {viewTest}
      </Typography>
      <Formik
        initialValues={
          mode === "add"
            ? getInitialValuesAdd()
            : getInitialValuesEdit(singleTest)
        }
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <fieldset disabled={true}>
                <Grid container spacing={5}>
                  <Grid item className="pb-0">
                    <Input
                      name="title"
                      label={titleLabel}
                      isRequired={false}
                      type="text"
                    />
                    <div className="mb-4">
                      <label>{selectTestTypeLabel}</label>
                      <RadioGroup
                        name="is_mandatory"
                        className="flex-nowrap flex-row"
                        value={`${values.is_mandatory}`}
                        onChange={(e) => {
                          setFieldValue(
                            "is_mandatory",
                            e.target.value
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={mandatory}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={optional}
                        />
                      </RadioGroup>
                    </div>
                    <div
                      className={`${styles.field} ${styles.selectTitle}`}
                    >
                      <label className={styles.label}>{book} </label>

                      <CustomSelect
                        options={books.map((book) =>
                          formatOptions(book, "id", "title")
                        )}
                        placeholder={book}
                        name="book_id"
                        bgColor={theme.palette.primary.light}
                        isRequired={false}
                        isMenuRelative={true}
                        isSearchable={true}
                        containerClass="mb-4"
                      />
                    </div>

                    <div className="d-flex mb-4">
                      <div
                        className={`${styles.mr2} ${styles.selectTitle}`}
                      >
                        <label className={styles.label}>
                          {prePage}{" "}
                        </label>

                        <Input
                          name="pre_page"
                          label={prePage}
                          isRequired={false}
                          type="number"
                        />
                      </div>
                      <div
                        className={`${styles.mr2} ${styles.selectTitle}`}
                      >
                        <label className={styles.label}>
                          {postPage}{" "}
                        </label>
                        <Input
                          name="post_page"
                          label={postPage}
                          isRequired={false}
                          type="number"
                        />
                      </div>
                    </div>
                    {values.note && (
                      <div>
                        <p>{noteHint}</p>
                        <Input
                          inputShape="textarea"
                          showCounter={true}
                          charLimit={800}
                          name="note"
                          label={notes}
                          isRequired={false}
                          type="text"
                        />
                      </div>
                    )}

                    <div className="mb-4">
                      <FieldArray name="test_questions_attributes">
                        {() => {
                          return !values?.test_questions_attributes
                            ?.length
                            ? null
                            : values?.test_questions_attributes?.map(
                                (question, questionIndex) => {
                                  return (
                                    !question._destroy && (
                                      <div className="d-flex">
                                        <Paper
                                          style={{ padding: "2rem" }}
                                          className="row align-items-center justify-content-center flex-fill mb-4"
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              style={{
                                                width: "100%"
                                              }}
                                            >
                                              <Input
                                                name={`test_questions_attributes[${questionIndex}].question`}
                                                label={questionTitle}
                                                isRequired={false}
                                              />
                                            </div>
                                          </div>

                                          {question.image_url && (
                                            <Grid
                                              item
                                              xs={6}
                                              className="py-0 mb-4"
                                            >
                                              <img
                                                src={
                                                  question.image_url
                                                }
                                                alt="medblend"
                                                width="300px"
                                                className="img-thumbnail m-3"
                                              />
                                            </Grid>
                                          )}

                                          <Input
                                            style={{ width: "100%" }}
                                            name={`test_questions_attributes[${questionIndex}].points`}
                                            label={questionPoints}
                                            isRequired={false}
                                            type="number"
                                            step="0.1"
                                          />
                                          <div
                                            className={`${styles.field} ${styles.selectTitle}`}
                                          >
                                            <label
                                              className={styles.label}
                                            >
                                              {sectionName}{" "}
                                            </label>
                                            <Input
                                              style={{
                                                width: "100%"
                                              }}
                                              value={
                                                values
                                                  .test_questions_attributes[
                                                  questionIndex
                                                ].section_id
                                                  ? getSectionName(
                                                      values
                                                        .test_questions_attributes[
                                                        questionIndex
                                                      ].section_id
                                                    )
                                                  : noSection
                                              }
                                              name={`test_questions_attributes[${questionIndex}].section_id`}
                                            />
                                          </div>
                                          <FieldArray
                                            name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes`}
                                          >
                                            {() => {
                                              return (
                                                <Form>
                                                  <Typography
                                                    variant="h6"
                                                    component="h6"
                                                    className="text-secondary my-4"
                                                  >
                                                    {answersOptions}
                                                  </Typography>

                                                  <div className=" py-4">
                                                    <div className=" show active">
                                                      {question.test_answer_choices_attributes.map(
                                                        (
                                                          answer,
                                                          answerIndex
                                                        ) => {
                                                          return (
                                                            !answer._destroy && (
                                                              <div
                                                                className="mx-5 d-flex align-items-center"
                                                                key={JSON.stringify(
                                                                  answerIndex
                                                                )}
                                                              >
                                                                <span className="mb-2 mr-1 ">
                                                                  {answerIndex +
                                                                    1}{" "}
                                                                  -
                                                                </span>
                                                                <div className="flex-fill ml-1 d-flex align-items-center">
                                                                  {answer.image_url && (
                                                                    <div
                                                                      item
                                                                      className="pb-0"
                                                                    >
                                                                      <img
                                                                        src={
                                                                          answer.image_url
                                                                        }
                                                                        alt="medblend"
                                                                        width="100px"
                                                                        className={`img-thumbnail m-3 ${styles.img}`}
                                                                      />
                                                                    </div>
                                                                  )}
                                                                  {answer.answer && (
                                                                    <div className="flex-fill">
                                                                      <Input
                                                                        name={`test_questions_attributes[${questionIndex}].test_answer_choices_attributes[${answerIndex}].answer`}
                                                                        label={
                                                                          answerText
                                                                        }
                                                                        isRequired={
                                                                          false
                                                                        }
                                                                      />
                                                                    </div>
                                                                  )}
                                                                  {values
                                                                    .test_questions_attributes[
                                                                    questionIndex
                                                                  ]
                                                                    .test_answer_choices_attributes[
                                                                    answerIndex
                                                                  ]
                                                                    .correct && (
                                                                    <span
                                                                      className={`${styles.icon} alert alert-success`}
                                                                      role="alert"
                                                                    >
                                                                      <CheckCircle
                                                                        color="success"
                                                                        className="me-2"
                                                                      />
                                                                    </span>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            )
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="my-4">
                                                    {values
                                                      .test_questions_attributes[
                                                      questionIndex
                                                    ]
                                                      .justification && (
                                                      <>
                                                        <Input
                                                          name={`test_questions_attributes[${questionIndex}].justification`}
                                                          label={
                                                            explanation
                                                          }
                                                          isRequired={
                                                            false
                                                          }
                                                        />
                                                        <div className="mb-3 d-flex flex-row align-items-end w-50 gap-3">
                                                          <MaterialPreview
                                                            type={1}
                                                            src={
                                                              values
                                                                ?.test_questions_attributes[
                                                                questionIndex
                                                              ]
                                                                .justification_image_url
                                                            }
                                                          />
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </Form>
                                              );
                                            }}
                                          </FieldArray>
                                        </Paper>
                                      </div>
                                    )
                                  );
                                }
                              );
                        }}
                      </FieldArray>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="pb-0"></Grid>
                </Grid>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PreTestPostTestDetails;

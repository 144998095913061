import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import messages from "../../../assets/locale/messages";
import { Typography } from "@material-ui/core";
import { CHANGE_PASSWORD_SCHEMA } from "./validations";
import { changePasswordRequest } from "../../../store/Auth/actions";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const currentAdminId = useSelector((state) => state.auth.login.id);

  const {
    inputs: { oldPassword, newPassword, confirmNewPassword },
    auth: {
      show,
      hide,
      changePassword: { title }
    },
    buttons: { updatePassword }
  } = messages[lang];

  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState(false);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      changePasswordRequest({
        ...values,
        id: currentAdminId,
        resetForm
      })
    );
  };

  return (
    <>
      <div className="mb-4">
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
      </div>
      <div className="wrapper-bg">
        <Formik
          initialValues={{
            old_password: "",
            password: "",
            confirmNewPassword: ""
          }}
          onSubmit={handleSubmit}
          validationSchema={CHANGE_PASSWORD_SCHEMA(lang)}
        >
          {() => (
            <Form>
              <Input
                inputIcon={isShowOldPassword ? hide : show}
                type={!isShowOldPassword ? "password" : "text"}
                label={oldPassword}
                name="old_password"
                onIconClick={() =>
                  setIsShowOldPassword(!isShowOldPassword)
                }
              />
              <Input
                inputIcon={isShowNewPassword ? hide : show}
                type={!isShowNewPassword ? "password" : "text"}
                label={newPassword}
                name="password"
                onIconClick={() =>
                  setIsShowNewPassword(!isShowNewPassword)
                }
              />
              <Input
                inputIcon={isShowConfirmPassword ? hide : show}
                type={!isShowConfirmPassword ? "password" : "text"}
                label={confirmNewPassword}
                name="confirmNewPassword"
                onIconClick={() =>
                  setIsShowConfirmPassword(!isShowConfirmPassword)
                }
              />
              <div className="d-flex align-items-center justify-content-between mb-4">
                <Button
                  type={"submit"}
                  text={updatePassword}
                  className="primary-lg"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;

import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./DatePicker.module.scss";

const DatePicker = ({
  name,
  setFieldValue,
  placeholder,
  format,
  isDisabled,
  minDate,
  ...props
}) => {
  return (
    <div>
      <Field name={name}>
        {({ field }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              {...field}
              id="date-picker-dialog"
              placeholder={placeholder}
              value={field.value || null}
              format={format}
              disabled={isDisabled}
              onChange={(value) => setFieldValue(name, value)}
              className={styles.datePicker}
              InputProps={{ readOnly: true }}
              minDate={minDate}
              clearable
              {...props}
            />
          </MuiPickersUtilsProvider>
        )}
      </Field>
    </div>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  setFieldValue: PropTypes.func,
  format: PropTypes.string,
  isDisabled: PropTypes.bool,
  minDate: PropTypes.string
};

DatePicker.defaultProps = {
  name: "",
  placeholder: "",
  format: "dd/MM/yyyy",
  setFieldValue: () => {},
  isDisabled: false
};

export default DatePicker;

import { axiosInstance } from "./";

const getSurveys = async (params) => {
  return await axiosInstance.get(`surveys`, {
    params
  });
};

const deleteSurvey = async (id) => {
  return await axiosInstance.delete(`surveys/${id}`);
};

const addSurvey = async (payload) => {
  return await axiosInstance.post(`surveys`, payload);
};

const getSingleSurveyStatisctics = async (payload) => {
  return await axiosInstance.get(
    `survey_questions/${payload.id}/statistics`,
    {
      params: { id: payload.id }
    }
  );
};

const editSurvey = async ({ data, id }) => {
  return await axiosInstance.patch(`surveys/${id}`, data);
};

const getSingleSurvey = async (id) => {
  return await axiosInstance.get(`surveys/${id}`);
};

const checkForCanBeEditSurvey = async (id) => {
  return await axiosInstance.get(`surveys/${id}/can_edit_survey`);
};

const exportSurveyReport = async (paylod) => {
  return await axiosInstance.get(`surveys/export_survey_report`, {
    params: {
      email: paylod.email,
      survey_id: paylod.id
    }
  });
};

export {
  getSurveys,
  deleteSurvey,
  addSurvey,
  editSurvey,
  getSingleSurvey,
  getSingleSurveyStatisctics,
  checkForCanBeEditSurvey,
  exportSurveyReport
};

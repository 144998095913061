import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/Button";

const useStyles = makeStyles(() => ({
  actions: {
    padding: "0 24px 20px 24px"
  }
}));

const Modal = ({
  title,
  description,
  children,
  hasActions,
  isOpen,
  isSubmitDisabled,
  submitBtnText,
  handleModalSubmit,
  handleModalCancel,
  submitBtnClassName
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    _.isFunction(handleModalCancel) && handleModalCancel();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          {children}
        </DialogContent>
        {hasActions && (
          <DialogActions className={classes.actions}>
            <Button
              text="Cancel"
              onClick={handleClose}
              className="default"
            />
            <Button
              text={submitBtnText}
              disabled={isSubmitDisabled}
              onClick={handleModalSubmit}
              className={submitBtnClassName}
              type="submit"
            />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hasActions: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  children: PropTypes.node,
  submitBtnText: PropTypes.string,
  handleModalSubmit: PropTypes.func,
  handleModalCancel: PropTypes.func,
  submitBtnClassName: PropTypes.string
};

Modal.defaultProps = {
  title: "",
  description: "",
  hasActions: false,
  isOpen: false,
  isSubmitDisabled: false,
  submitBtnText: "Submit",
  submitBtnClassName: "primary",
  handleModalSubmit: () => {},
  handleModalCancel: () => {}
};

export default Modal;

import React, { useEffect, useState } from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { Box, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getInsightsRequest } from "../../../store/Dashboard/actions";

function Insights() {
  const [lastUpdated, setLastUpdated] = useState(null);
  const [timeAgo, setTimeAgo] = useState("");

  const data = useSelector((state) => state.dashboard.Insights);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInsightsRequest());
  }, []);

  const fetchData = () => {
    dispatch(getInsightsRequest());

    const now = new Date();
    setLastUpdated(new Date());

    localStorage.setItem("lastUpdated", now.toString());
  };

  const calculateTimeAgo = (date) => {
    if (!date) return "Never";

    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    let interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;

    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutes ago`;

    return `${seconds} seconds ago`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeAgo(calculateTimeAgo(lastUpdated));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  useEffect(() => {
    setTimeAgo(calculateTimeAgo(lastUpdated));
  }, [lastUpdated]);

  useEffect(() => {
    const storedLastUpdated = localStorage.getItem("lastUpdated");
    if (storedLastUpdated) {
      setLastUpdated(new Date(storedLastUpdated));
    }
  }, []);

  return (
    <>
      <AutorenewIcon /> <span>Last updated {timeAgo}</span>
      <div className="w-100 border-bottom mt-3 mr-3 border-2"></div>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button variant="contained" onClick={fetchData}>
          {" "}
          <AutorenewIcon /> Refresh
        </Button>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              className="1"
              sx={{
                position: "relative",
                width: "100%",
                height: "200px",
                background:
                  "linear-gradient(100deg, #4428eb 20%, #a99cf6 90%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "20px",
                borderRadius: "8px"
              }}
            >
              <Box sx={{ fontSize: "50px" }}>
                {data.Insights?.total_users ?? 0}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "8px",
                  left: "15px",
                  fontSize: "18px",
                  color: "rgba(255, 255, 255, 0.7)"
                }}
              >
                Subscribers
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              className="2"
              sx={{
                position: "relative",
                width: "100%",
                height: "200px",
                background:
                  "linear-gradient(100deg, #2196F3 20%, #21CBF3 90%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "20px",
                borderRadius: "8px"
              }}
            >
              <Box sx={{ fontSize: "50px" }}>
                {data.Insights?.total_transactions}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "8px",
                  left: "15px",
                  fontSize: "18px",
                  color: "rgba(255, 255, 255, 0.7)"
                }}
              >
                Purchases
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              className="3"
              sx={{
                position: "relative",
                width: "100%",
                height: "200px",
                background:
                  "linear-gradient(100deg, #eb2891 20%, #f698ca 90%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "20px",
                borderRadius: "8px"
              }}
            >
              <Box
                sx={{
                  fontSize: "50px"
                }}
              >
                {data.Insights?.total_payments_amount}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "8px",
                  left: "15px",
                  fontSize: "18px",
                  color: "rgba(255, 255, 255, 0.7)"
                }}
              >
                Revenue
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Insights;

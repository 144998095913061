import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Pagination from "../../../components/Pagination";
import {
  getPromoCodesListRequest,
  getPromoCodesListResponse
} from "../../../store/Promocodes/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import EmptyState from "../../../components/EmptyState";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";

const PromoCodesList = ({ list, lang }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.promoCodes.meta);
  const isLoading = useSelector((state) => state.loader);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getPromoCodesListRequest({
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };

  const {
    promoCodes: { name, offer },
    shared: { view, deleteLabel }
  } = messages[lang];
  const handleDelete = (id) => {
    dispatch(deleteAction(id));
  };
  const handleView = (id) => {
    history.push(`${ROUTE_PATHS["promoCodeDetails"]}/${id}`);
  };

  useEffect(() => {
    return () => {
      dispatch(getPromoCodesListResponse({ list: [], meta: {} }));
    };
  }, []);

  return (
    <>
      <Table
        headlines={[name, offer]}
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.percentage}
            </TableCell>
            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <Tooltip title={view}>
                <span>
                  <IconButton
                    onClick={() => handleView(row.id)}
                    aria-label="view"
                  >
                    <VisibilityIcon
                      className="table__view"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

PromoCodesList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object)
};

export default PromoCodesList;

export const GET_REGISTEREDUSER_REQUEST =
  "GET_SUPPLEMENTRIES_REQUEST";
export const GET_REGISTEREDUSER_RESPONSE =
  "GET_REGISTEREDUSER_RESPONSE";

export const DELETE_REGISTEREDUSER_REQUEST =
  "DELETE_REGISTEREDUSER_REQUEST";

export const GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB_REQUEST =
  "GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB__REQUEST";
export const GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB_RESPONSE =
  "GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB__RESPONSE";

export const SET_REGISTEREDUSER_FILTERS =
  "SET_REGISTEREDUSER_FILTERS";

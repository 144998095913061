export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST";
export const GET_SELECTED_PROGRAMS_REQUEST =
  "GET_SELECTED_PROGRAMS_REQUEST";
export const CREATE_PROGRAMS_REQUEST = "CREATE_PROGRAMS_REQUEST";
export const DELETE_PROGRAMS_REQUEST = "DELETE_PROGRAMS_REQUEST";
export const GET_USERS_PROGRAMS_REQUEST =
  "GET_USERS_PROGRAMS_REQUEST";
export const ADD_USERS_PROGRAMS_RESPONSE =
  "ADD_USERS_PROGRAMS_RESPONSE";
export const ADD_PROGRAMS_RESPONSE = "ADD_PROGRAMS_RESPONSE";
export const ADD_SELECTED_PROGRAMS_RESPONSE =
  "ADD_SELECTED_PROGRAMS_RESPONSE";
export const SET_SELECTED_DIRECTOR = "SET_SELECTED_DIRECTOR";
export const SET_SELECTED_USERS = "SET_SELECTED_USERS";
export const SET_SELECTED_BOOKS = "SET_SELECTED_BOOKS";
export const ASSIGN_DIRECTOR_TO_PROGRAM =
  "ASSIGN_DIRECTOR_TO_PROGRAM";
export const ASSIGN_USERS_TO_PROGRAM = "ASSIGN_USERS_TO_PROGRAM";
export const REMOVE_USERS_FROM_PROGRAM = "REMOVE_USERS_FROM_PROGRAM";
export const ASSIGN_USERS_FROM_FILE_TO_PROGRAM =
  "ASSIGN_USERS_FROM_FILE_TO_PROGRAM";
export const ASSIGN_BOOKS_TO_PROGRAM = "ASSIGN_BOOKS_TO_PROGRAM";

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteAdminsRequest } from "../../../store/Admins/actions";
import {
  deleteAction,
  deleteSectionAction
} from "../../../store/ActionsBtn/actions";

const AdminDelete = ({ isOpen, lang }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    admins: { deleteAdmin, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteAdminsRequest(deleteId));
    dispatch(deleteAction(false));
  };
  const handleModalCancel = () => {
    dispatch(deleteAction(false));
    dispatch(deleteSectionAction(false));
  };
  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteAdmin}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
      handleCancel={handleModalCancel}
    />
  );
};

AdminDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

AdminDelete.defaultProps = {
  isOpen: false
};

export default AdminDelete;

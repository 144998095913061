import { axiosInstance } from "./";

const login = async (payload) => {
  return await axiosInstance.post(`sessions`, payload);
};

const logout = async () => {
  return await axiosInstance.delete(`sessions`);
};

const forgotPassword = async (payload) => {
  return await axiosInstance.post(`admins/forgot_password`, payload);
};

const changePassword = async ({ id, old_password, password }) => {
  return await axiosInstance.patch(`admins/${id}/change_password`, {
    old_password,
    password
  });
};

const createPassword = async (payload) => {
  return await axiosInstance.post(
    `sessions/create_password`,
    payload
  );
};

export {
  login,
  logout,
  forgotPassword,
  changePassword,
  createPassword
};

import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { setCurrentLang } from "../store/Lang/actions";
import messages from "../assets/locale/messages";
import Theme from "../utils/Theme";
import Layout from "./Layout";
import Snackbar from "./../components/Snackbar";
import Loader from "./../components/Loader";
import { initCornerstone } from "../utils/Cornerstone";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(setCurrentLang(lang));
    initCornerstone();
  }, []);

  return (
    <IntlProvider
      locale={lang}
      messages={messages[lang]}
      defaultLocale="en"
    >
      <ThemeProvider theme={Theme}>
        <Router history={history}>
          <Layout lang={lang}>{Routes}</Layout>
        </Router>
      </ThemeProvider>
      <Snackbar />
      {isLoading && <Loader />}
    </IntlProvider>
  );
};

export default App;

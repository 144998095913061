import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import messages from "../../../assets/locale/messages";
import BookCover from "../../../components/BookCover";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import Switch from "../../../components/Switch";
import Upload from "../../../components/Upload";
import UploadImg from "../../FinalTests/FinalTestForm/finalTestImageUploading";

import {
  addBookRequest,
  editBookRequest,
  getBooksRequest,
  getSingleBookRequest,
  getSingleBookResponse
} from "../../../store/Books/actions";
import { getCategoriesRequest } from "../../../store/Categories/actions";
import { CERTIFICATE_TYPES } from "../../../utils/Constants";
import { uploadToS3 } from "../../../utils/S3";
import styles from "./BookForm.module.scss";
import { ADD_BOOK_SCHEMA, getInitialValues } from "./validations";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { showHideLoader } from "../../../store/Loader/actions";
import ImageNotSupportedIcon from "../../../assets/svgs/image-not-support";

const BookForm = () => {
  let params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const categoriesList = useSelector(
    (state) => state.categories.list
  );
  // console.log("categoriesList", categoriesList);

  const booksInCategoryList = useSelector(
    (state) => state.books.list
  );

  const singleBook = useSelector((state) => {
    return state.books.singleBook?.certificate
      ? _.merge(
          state.books.singleBook,
          _.omit(
            {
              ...state.books.singleBook?.certificate,
              certificateId: state.books.singleBook?.certificate?.id
            },
            ["id"]
          )
        )
      : state.books.singleBook;
  });
  console.log("single book aa", singleBook?.senior_editors?.length);

  const [bookCoverPreview, setBookCoverPreview] = useState(null);

  // const [imgEditorPreview, setImgEditorPreview] = useState(null);
  const [mode, setMode] = useState("add");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [booksInCategoryOptions, setBooksInCategoryOptions] =
    useState([]);
  useEffect(() => {
    if (params?.id) {
      getBooksInCategory();
    }

    getCategories({
      paginate: false
    });
    return () => {
      dispatch(getSingleBookResponse({}));
    };
  }, []);
  const getCategories = (params) => {
    dispatch(getCategoriesRequest(params));
  };
  const getBooksInCategory = () => {
    dispatch(getBooksRequest({ paginate: false }));
  };
  useEffect(() => {
    if (categoriesList.length) {
      setCategoriesOptions(
        categoriesList.map(({ id, name }) => ({
          id,
          value: name,
          label: name
        }))
      );
    }
  }, [categoriesList]);

  useEffect(() => {
    if (params?.id) {
      setMode("edit");
      dispatch(getSingleBookRequest(params?.id));
    } else {
      return null;
    }
  }, [params]);
  useEffect(() => {
    if (mode === "edit") {
      setBookCoverPreview(singleBook?.cover_photo);
    }
  }, [singleBook]);
  useEffect(() => {
    setBooksInCategoryOptions(
      booksInCategoryList?.map(({ id, title }) => ({
        id,
        value: title,
        label: title
      }))
    );
  }, [booksInCategoryList]);
  console.log("mode selected ", mode);

  const handleSubmit = async ({
    certificate_description,
    credit_type,
    certificate_title,
    credits,
    submission_date,
    publish_students,
    publish_authors,
    published,
    ...values
  }) => {
    dispatch(showHideLoader(true));
    let publishStatus = "";
    if (published) {
      publishStatus = publish_students
        ? publish_authors
          ? "authors_students"
          : "students"
        : publish_authors
        ? "authors"
        : "not_published";
    }
    if (mode === "add") {
      let addBookData =
        certificate_title || credits
          ? {
              ...values,
              certificate_attributes: {
                certificate_description,
                certificate_title,
                credit_type,
                credits,
                submission_date
              }
            }
          : _.omit(values, [
              "certificate_description",
              "certificate_title",
              "credit_type",
              "credits",
              "submission_date",
              "publish_students",
              "publish_authors",
              "published"
            ]);

      addBookData = { ...addBookData, publish_state: publishStatus };
      console.log("values.faculty", values.faculty);
      uploadToS3(
        "pdf",
        values.front_matter,
        values.front_matter.name
      ).then((res) => {
        addBookData.front_matter = res.link;
      });

      uploadToS3("pdf", values.faculty, values.faculty.name).then(
        (res) => {
          console.log("result of upload: " + res);
          addBookData.faculty = res.link;
        }
      );

      uploadToS3(
        "image",
        values.cover_photo,
        values.cover_photo.name
      ).then((data) => {
        addBookData.cover_photo = data.link;
        uploadToS3("pdf", values.pdf, values.pdf.name).then((res) => {
          addBookData.number_of_pages = res.numPages;
          addBookData.pdf = res.link;
          dispatch(addBookRequest(addBookData));
        });
      });
    } else {
      let editedData =
        certificate_title || credits
          ? _.omit(
              {
                ...values,
                certificate_attributes: {
                  certificate_description,
                  certificate_title,
                  credit_type,
                  credits,
                  submission_date
                }
              },
              [
                "certificate_description",
                "certificate_title",
                "credit_type",
                "credits",
                "submission_date",
                "certificate",
                "has_subscribers",
                "publish_students",
                "publish_authors",
                "published"
              ]
            )
          : _.omit(
              {
                ...values,
                certificate_attributes: {
                  _destroy: true,
                  id: singleBook?.certificateId
                }
              },
              [
                "certificate_description",
                "certificate_title",
                "credit_type",
                "credits",
                "submission_date",
                "certificate",
                "has_subscribers",
                "publish_students",
                "publish_authors",
                "published"
              ]
            );

      editedData = { ...editedData, publish_state: publishStatus };
      // Book cover upload
      editedData.cover_photo =
        typeof editedData.cover_photo === "string"
          ? editedData.cover_photo
          : await uploadToS3(
              "image",
              editedData.cover_photo,
              editedData.cover_photo.name
            ).then((data) => data.link);
      //Front Matter upload
      editedData.front_matter =
        typeof editedData.front_matter === "string"
          ? editedData.front_matter
          : await uploadToS3(
              "pdf",
              values.front_matter,
              values.front_matter.name
            ).then((res) => res.link);

      // Faculty upload
      editedData.faculty =
        typeof editedData.faculty === "string"
          ? editedData.faculty
          : await uploadToS3(
              "pdf",
              values.faculty,
              values.faculty.name
            ).then((res) => res.link);
      // Book pdf upload
      if (singleBook.published) {
        editedData = _.omit(editedData, ["pdf", "number_of_pages"]);
      } else {
        editedData.pdf =
          typeof editedData.pdf === "string"
            ? editedData.pdf
            : await uploadToS3(
                "pdf",
                editedData.pdf,
                editedData.pdf.name
              ).then((res) => {
                editedData.number_of_pages = res.numPages;
                return res.link;
              });
      }
      console.log("editedData", editedData);

      await dispatch(
        editBookRequest({ data: editedData, id: singleBook.id })
      );
    }
  };
  const {
    books: {
      addBook,
      editBook,
      bookTitle,
      bookAuthor,
      bookPrice,
      bookBrief,
      bookVersion,
      bookOlderVersion,
      bookEdition,
      bookIsbn,
      isBookFeatured,
      bookReleaseDate,
      submissionDate,
      publishStatus,
      booksCoverDimensions,
      uploadBookCover,
      uploadBookPdf,
      uploadFontMatterPdf,
      uploadFacultyPdf
    },
    categories,
    certificate: {
      certificateCredits,
      certificateType,
      certificateTitle,
      certificateDescription,
      certificateSectionTitle,
      hintCertificate,
      hintCertificateCredit
    }
  } = messages[lang];

  const handlePreviewSrc = (src) => {
    setBookCoverPreview(src);
  };
  // const handlePreviewSrcImgEditor = (src) => {
  //   setImgEditorPreview(src);
  // };
  const handleCategoryChange = async (id) => {
    const params = {
      category_id: id,
      lookup: true
    };
    await dispatch(getBooksRequest(params));
  };

  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {mode === "add" ? addBook : editBook}
        </Typography>
      </div>
      <div className={styles.wrapper}>
        <Formik
          initialValues={
            mode === "add"
              ? getInitialValues()
              : getInitialValues(singleBook)
          }
          onSubmit={handleSubmit}
          validationSchema={ADD_BOOK_SCHEMA(lang)}
          enableReinitialize
        >
          {({ setFieldValue, errors, values }) => {
            console.log("values", values);
            console.log("errors", errors);
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={6} className="pb-0">
                    <Input
                      name="title"
                      label={bookTitle}
                      isRequired={true}
                    />
                    <Input
                      name="author"
                      label={bookAuthor}
                      isRequired={true}
                    />
                    <CustomSelect
                      options={categoriesOptions}
                      placeholder={categories.title}
                      disabled={
                        singleBook?.has_older_version ||
                        singleBook?.deprecation === "pending"
                      }
                      name="category_id"
                      bgColor={theme.palette.primary.light}
                      isRequired={true}
                      handleSelectChange={(id) => {
                        handleCategoryChange(id);
                        setFieldValue("old_version_id", "");
                      }}
                    />
                    {mode === "edit" && (
                      <p>
                        Regarding PayPal policy: Existing subscribers
                        are billed the new price only after a 10-day
                        prior email pre-notification, billing cycles
                        within ten days of the price change are not
                        changed until the next billing cycle.
                      </p>
                    )}

                    <Input
                      name="price"
                      isDisabled={
                        singleBook?.deprecation === "pending"
                      }
                      label={bookPrice}
                      isRequired={true}
                      min={1}
                      inputShape="currency"
                    />
                    <Input
                      isRequired={true}
                      name="book_version"
                      type="number"
                      label={bookVersion}
                    />
                    <div className={styles.relativeContainer}>
                      <Field
                        disabled={
                          singleBook?.has_older_version ||
                          (mode === "edit" &&
                            !singleBook?.has_older_version)
                        }
                        component="select"
                        placeholder={bookOlderVersion}
                        name="old_version_id"
                        className={`form-control ${styles.selectField}`}
                      >
                        <option value="" selected disabled hidden>
                          {bookOlderVersion}
                        </option>
                        <option value="">none</option>
                        <option disabled></option>

                        {booksInCategoryOptions.length > 0 ? (
                          booksInCategoryOptions?.map((book) => {
                            return (
                              <>
                                <option key={book.id} value={book.id}>
                                  {book.label}
                                </option>
                                <option disabled></option>
                              </>
                            );
                          })
                        ) : (
                          <option key={0} disabled>
                            No option
                          </option>
                        )}
                      </Field>
                    </div>

                    {/* <CustomSelect
                    options={booksInCategoryOptions}
                    disabled={singleBook?.has_older_version}
                    placeholder={bookOlderVersion}
                    name=""
                    bgColor={theme.palette.primary.light}
                  /> */}
                    <Input
                      name="edition"
                      type="number"
                      label={bookEdition}
                    />
                    <Input
                      name="isbn"
                      label={bookIsbn}
                      isRequired={true}
                    />
                  </Grid>
                  <Grid item xs={6} className="pb-0">
                    <div className="d-flex flex-column align-items-center text-center">
                      <div className="mb-3 d-flex flex-column align-items-center">
                        <BookCover src={bookCoverPreview} size="sm" />
                        <small className="d-inline-block mt-4">
                          {booksCoverDimensions}
                        </small>
                      </div>
                      <Upload
                        label={uploadBookCover}
                        name="cover_photo"
                        accept="image/*"
                        isImage={true}
                        setFieldValue={setFieldValue}
                        handlePreviewSrc={handlePreviewSrc}
                        isRequired={true}
                      />
                    </div>
                    <Input
                      name="brief"
                      label={bookBrief}
                      inputShape="textarea"
                      showCounter={true}
                      charLimit={800}
                      isRequired={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <DatePicker
                      value={values.release_date}
                      placeholder={bookReleaseDate}
                      setFieldValue={setFieldValue}
                      name="release_date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Upload
                      label={uploadBookPdf}
                      name="pdf"
                      setFieldValue={setFieldValue}
                      accept="application/pdf"
                      isShowName={true}
                      isRequired={true}
                      initialFileData={
                        mode === "add"
                          ? {}
                          : {
                              link: singleBook?.pdf,
                              name: singleBook?.title
                            }
                      }
                      // disabled={
                      //   singleBook?.published ||
                      //   singleBook?.has_subscribers
                      // }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Checkbox
                      name="is_featured"
                      label={isBookFeatured}
                    />
                  </Grid>
                  <Grid container spacing={2} xs={6}>
                    <Switch
                      name="published"
                      label={publishStatus}
                      disabled={
                        singleBook?.published &&
                        singleBook?.has_subscribers
                      }
                    />
                    <Checkbox
                      name="publish_authors"
                      label={"Authors"}
                      disabled={!values?.published}
                    />
                    <Checkbox
                      name="publish_students"
                      label={"Students"}
                      disabled={!values?.published}
                    />
                    <div
                      style={{
                        color: "#b70020",
                        fontSize: "0.9rem",
                        fontWeight: "lighter"
                      }}
                    >
                      {errors["publish_authors_publish_students"]}
                    </div>
                  </Grid>
                </Grid>
                {/* new section */}
                <hr className="mb-5" />
                <Grid container>
                  <Grid container>
                    <Upload
                      label={uploadFontMatterPdf}
                      name="front_matter"
                      accept="application/pdf"
                      isShowName={true}
                      setFieldValue={setFieldValue}
                      isRequired={true}
                      initialFileData={
                        mode === "add"
                          ? {}
                          : {
                              link: singleBook?.front_matter,
                              name:
                                singleBook?.title + " front_matter"
                            }
                      }
                    />
                  </Grid>

                  <Grid item>
                    <Upload
                      label={uploadFacultyPdf}
                      name="faculty"
                      accept="application/pdf"
                      isShowName={true}
                      setFieldValue={setFieldValue}
                      isRequired={true}
                      initialFileData={
                        mode === "add"
                          ? {}
                          : {
                              link: singleBook?.faculty,
                              name: singleBook?.title + " faculty"
                            }
                      }
                    />
                  </Grid>
                  <Grid container>
                    <FieldArray name="senior_editors">
                      {(arrayHelpers) => {
                        return (
                          <Grid container>
                            {values?.senior_editors?.map(
                              (editor, index) => (
                                <Grid
                                  container
                                  spacing={2} // Adjust spacing between children
                                  sx={{ width: "100%", margin: 0 }}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      margin: "0 auto",
                                      backgroundColor: "#f3f6fc",
                                      height: "2px",
                                      width: "90%"
                                    }}
                                  />

                                  <Grid item xs={4}>
                                    <Input
                                      name={`senior_editors[${index}].name`}
                                      label={`Editor Name ${
                                        index + 1
                                      }`}
                                      isRequired={true}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Input
                                      label={`Job Title ${index + 1}`}
                                      name={`senior_editors[${index}].title`}
                                      isRequired={true}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <UploadImg
                                      name={`senior_editors[${index}].photo`}
                                      accept="image/*"
                                      label={"editor image"}
                                      isImage={true}
                                      setFieldValue={setFieldValue}
                                      // handlePreviewSrc={
                                      //   handlePreviewSrcImgEditor
                                      // }
                                      isMultiple={false}
                                      isRequired={true}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginBottom: "10px"
                                    }}
                                  >
                                    {values?.senior_editors[index]
                                      ?.photo ? (
                                      <img
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "25px"
                                        }}
                                        src={
                                          values?.senior_editors[
                                            index
                                          ]?.photo
                                        }
                                        alt="Preview"
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center"
                                        }}
                                      >
                                        <ImageNotSupportedIcon />
                                        <p
                                          style={{ fontSize: "10px" }}
                                        >
                                          Not Supported
                                        </p>
                                      </div>
                                    )}
                                    <Grid
                                      item
                                      style={{
                                        display: "flex",
                                        flexDirection: "column"
                                      }}
                                    >
                                      {/* <Grid item> */}
                                      <AddCircleIcon
                                        className=""
                                        onClick={() =>
                                          arrayHelpers.push({
                                            editor_name: "",
                                            job_title: "",
                                            photo: ""
                                          })
                                        }
                                      />
                                      {/* </Grid> */}
                                      {values?.senior_editors
                                        ?.length != 1 && (
                                        <RemoveCircleIcon
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        );
                      }}
                    </FieldArray>
                  </Grid>

                  <Grid container>
                    <Input
                      label={"Authors number"}
                      name="number_of_authors"
                      type="number"
                      isNumber={false}
                      isRequired={true}
                    />
                  </Grid>

                  <Grid container>
                    <Input
                      label={"speckers number"}
                      name="number_of_speakers"
                      type="number"
                      isNumber={false}
                      isRequired={true}
                    />
                  </Grid>
                </Grid>

                <hr className="mb-5" />
                <div className="certificate">
                  <Typography
                    variant="h6"
                    component="h3"
                    className="mb-4"
                  >
                    {certificateSectionTitle}
                  </Typography>
                  <p className="m-0">{hintCertificate + ","}</p>
                  <p className="mb-4">{hintCertificateCredit}</p>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <CustomSelect
                        options={CERTIFICATE_TYPES}
                        placeholder={certificateType}
                        name="credit_type"
                        bgColor={theme.palette.primary.light}
                        onChange={(e) => {
                          console.log("eeeee", e);
                          const { value } = e;
                          setFieldValue("credit_type", value);
                          if (value === "MEDBLEND") {
                            setFieldValue("credits", "");
                            setFieldValue("submission_date", null);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        name="certificate_title"
                        label={certificateTitle}
                        isRequired={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        name="credits"
                        label={certificateCredits}
                        isRequired={false}
                        isDisabled={
                          values.credit_type === "MEDBLEND"
                            ? true
                            : false
                        }
                        inputShape="currency"
                        currencyPrefix={null}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        placeholder={submissionDate}
                        setFieldValue={setFieldValue}
                        name="submission_date"
                        value={values.submission_date}
                        isDisabled={
                          values.credit_type === "MEDBLEND"
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Input
                        name="certificate_description"
                        label={certificateDescription}
                        inputShape="textarea"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="d-block mt-3">
                  <Button
                    text={mode === "add" ? addBook : editBook}
                    className="primary-lg"
                    type="submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BookForm;

import * as Yup from "yup";
import messages from "../../../assets/locale/messages";

export const CREATE_PASSWORD_SCHEMA = (lang) => {
  const {
    inputs: {
      passwordConfirmationRequired,
      passwordRequired,
      passwordMatch
    }
  } = messages[lang];

  return Yup.object().shape({
    password: Yup.string().required(passwordRequired),
    confirmNewPassword: Yup.string()
      .required(passwordConfirmationRequired)
      .oneOf([Yup.ref("password"), null], passwordMatch)
  });
};

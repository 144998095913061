import { takeEvery } from "redux-saga/effects";
import * as AdminsTypes from "./Admins/types";
import * as AuthorsTypes from "./Authors/types";
import * as EditorsTypes from "./Editors/types";
import * as AdminsSagas from "./Admins/sagas";
import * as AuthorsSagas from "./Authors/sagas";
import * as EditorsSagas from "./Editors/sagas";
import * as ProgramsSagas from "./Programs/sagas";
import * as ProgramsTypes from "./Programs/types";
import * as UsersSagas from "./Users/sagas";
import * as UsersTypes from "./Users/types";
import * as DirectorsSagas from "./Directors/sagas";
import * as DirectorsTypes from "./Directors/types";
import * as CategoriesTypes from "./Categories/types";
import * as CategoriesSagas from "./Categories/sagas";
import * as BooksTypes from "./Books/types";
import * as BooksSagas from "./Books/sagas";
import * as AuthTypes from "./Auth/types";
import * as AuthSagas from "./Auth/sagas";
import * as SupplementariesTypes from "./Supplementaries/types";
import * as SupplementariesSagas from "./Supplementaries/sagas";
import * as QuizzesTypes from "./Quizzes/types";
import * as QuizzesSaga from "./Quizzes/sagas";
import * as PromoCodesTypes from "./Promocodes/types";
import * as PromoCodesSaga from "./Promocodes/sagas";
import * as SurveysTypes from "./Surveys/types";
import * as SurveysSagas from "./Surveys/sagas";
import * as InstitutionsTypes from "./Institutions/types";
import * as InstitutionsSagas from "./Institutions/sagas";
import * as CountriesTypes from "./Countries/types";
import * as CountriesSaga from "./Countries/sagas";
import * as JobsTypes from "./Jobs/types";
import * as JobsSaga from "./Jobs/sagas";
import * as RegisteredUserTypes from "./RegisteredUser/types";
import * as RegisteredUserSaga from "./RegisteredUser/sagas";
import * as TestTypes from "./Tests/types";
import * as TestSaga from "./Tests/sagas";
import * as DashboardTypes from "./Dashboard/types";
import * as DashboardSaga from "./Dashboard/sagas";
import * as finalTestTypes from "./FinalTests/types";
import * as finalTestSaga from "./FinalTests/sagas";
export function* watchAll() {
  //finalTest
  yield takeEvery(
    finalTestTypes.DELETE_FINAL_TEST_REQUEST,
    finalTestSaga.deleteSingleFinalTestSaga
  );
  yield takeEvery(
    finalTestTypes.EDIT_FINAL_TEST_REQUEST,
    finalTestSaga.editSingleFinalTestSaga
  );
  yield takeEvery(
    finalTestTypes.SET_FINAL_TEST_REQUEST,
    finalTestSaga.setNewSingleFinalTestsSaga
  );
  yield takeEvery(
    finalTestTypes.GET_SINGLE_FINAL_TEST_REQUEST,
    finalTestSaga.getSingleFinalTestsSaga
  );
  yield takeEvery(
    finalTestTypes.GET_ALL_FINAL_TEST_REQUEST,
    finalTestSaga.getAllFinalTestsSaga
  );
  //Dashboard
  yield takeEvery(
    DashboardTypes.GET_INSIGHTS_REQUEST,
    DashboardSaga.getInsightsSaga
  );
  yield takeEvery(
    DashboardTypes.GET_BOOKS_REQUEST,
    DashboardSaga.getBooksDashbosrdSaga
  );
  //Admin
  yield takeEvery(
    AdminsTypes.GET_ADMINS_REQUEST,
    AdminsSagas.getAdminsSaga
  );
  yield takeEvery(
    AdminsTypes.DELETE_ADMINS_REQUEST,
    AdminsSagas.deleteAdminsSaga
  );
  yield takeEvery(
    AdminsTypes.ADD_ADMIN_REQUEST,
    AdminsSagas.addAdminSaga
  );
  yield takeEvery(
    AdminsTypes.EDIT_ADMIN_REQUEST,
    AdminsSagas.editAdminSaga
  );
  // authors
  yield takeEvery(
    AuthorsTypes.GET_AUTHORS_REQUEST,
    AuthorsSagas.getAuthorsSaga
  );
  yield takeEvery(
    AuthorsTypes.DELETE_AUTHORS_REQUEST,
    AuthorsSagas.deleteAuthorsSaga
  );
  yield takeEvery(
    AuthorsTypes.ADD_AUTHOR_REQUEST,
    AuthorsSagas.addAuthorSaga
  );
  yield takeEvery(
    AuthorsTypes.EDIT_AUTHOR_REQUEST,
    AuthorsSagas.editAuthorSaga
  );
  // editors
  yield takeEvery(
    EditorsTypes.GET_EDITORS_REQUEST,
    EditorsSagas.getEditorsSaga
  );
  yield takeEvery(
    EditorsTypes.DELETE_EDITORS_REQUEST,
    EditorsSagas.deleteEditorsSaga
  );
  yield takeEvery(
    EditorsTypes.ADD_EDITOR_REQUEST,
    EditorsSagas.addEditorSaga
  );
  yield takeEvery(
    EditorsTypes.EDIT_EDITOR_REQUEST,
    EditorsSagas.editEditorSaga
  );

  yield takeEvery(
    CategoriesTypes.GET_CATEGORIES_REQUEST,
    CategoriesSagas.getCategoriesSaga
  );
  yield takeEvery(
    CategoriesTypes.DELETE_CATEGORY_REQUEST,
    CategoriesSagas.deleteCategorySaga
  );
  //Books
  yield takeEvery(
    BooksTypes.GET_BOOKS_REQUEST,
    BooksSagas.getBooksSaga
  );
  yield takeEvery(
    BooksTypes.ADD_BOOK_REQUEST,
    BooksSagas.addBookSaga
  );
  yield takeEvery(
    BooksTypes.EDIT_BOOK_REQUEST,
    BooksSagas.editBookSaga
  );
  yield takeEvery(
    BooksTypes.DELETE_BOOK_REQUEST,
    BooksSagas.deleteBookSaga
  );
  yield takeEvery(
    BooksTypes.DELETE_SECTIONS_REQUEST,
    BooksSagas.deleteTestSaga
  );
  yield takeEvery(
    BooksTypes.GET_SINGLE_BOOK_REQUEST,
    BooksSagas.getSingeBookSaga
  );
  yield takeEvery(
    BooksTypes.GET_BOOKS_IN_CATEGORY_REQUEST,
    BooksSagas.getBooksInCategorySaga
  );
  yield takeEvery(
    BooksTypes.GET_BOOK_SUPPLEMENTRAIES_REQUEST,
    BooksSagas.getBookSupplementariesSaga
  );

  yield takeEvery(
    BooksTypes.GET_BOOK_SECTIONS_REQUEST,
    BooksSagas.getBookSectionsSaga
  );

  yield takeEvery(
    BooksTypes.SET_BOOK_SECTIONS_REQUEST,
    BooksSagas.setBookSectionsSaga
  );
  yield takeEvery(
    BooksTypes.EDIT_BOOK_SECTIONS_REQUEST,
    BooksSagas.editBookSectionsSaga
  );
  //
  yield takeEvery(
    CategoriesTypes.ADD_CATEGORY_REQUEST,
    CategoriesSagas.addCategorySaga
  );
  yield takeEvery(
    CategoriesTypes.EDIT_CATEGORY_REQUEST,
    CategoriesSagas.editCategorySaga
  );
  yield takeEvery(AuthTypes.LOGIN_REQUEST, AuthSagas.loginSaga);
  yield takeEvery(AuthTypes.LOGOUT_REQUEST, AuthSagas.logoutSaga);
  yield takeEvery(
    AuthTypes.FORGOT_PASSWORD_REQUEST,
    AuthSagas.forgotPasswordSaga
  );
  yield takeEvery(
    AuthTypes.CHANGE_PASSWORD_REQUEST,
    AuthSagas.changePasswordSaga
  );
  yield takeEvery(
    AuthTypes.CREATE_PASSWORD_REQUEST,
    AuthSagas.createPasswordSaga
  );
  yield takeEvery(
    SupplementariesTypes.DELETE_SUPPLEMENTARY_REQUEST,
    SupplementariesSagas.deleteSupplementarySaga
  );
  yield takeEvery(
    SupplementariesTypes.GET_SUPPLEMENTRIES_REQUEST,
    SupplementariesSagas.getSupplementariesSaga
  );
  yield takeEvery(
    SupplementariesTypes.ADD_SUPPLEMENTRIES_REQUEST,
    SupplementariesSagas.addSupplementarySaga
  );
  yield takeEvery(
    SupplementariesTypes.ASSIGN_BOOK_TO_SUPPLEMENTARY_REQUEST,
    SupplementariesSagas.assignSupplementaryToBookSaga
  );
  yield takeEvery(
    SupplementariesTypes.UNASSIGN_BOOK_FROM_SUPPLEMENTARY_REQUEST,
    SupplementariesSagas.unassignSupplementaryFromBookSaga
  );
  yield takeEvery(
    SupplementariesTypes.EDIT_SUPPLEMENTARY_REQUEST,
    SupplementariesSagas.editSupplementarySaga
  );

  yield takeEvery(
    SupplementariesTypes.REASSIGN_SUPPLEMENTARY_TO_BOOK_VERSION_REQUEST,
    SupplementariesSagas.reassignSupplementaryToBookVersionSaga
  );

  yield takeEvery(
    SupplementariesTypes.GET_SINGLE_SUPPLEMENTARY_REQUEST,
    SupplementariesSagas.getSingleSupplementarySaga
  );
  yield takeEvery(
    SupplementariesTypes.EDIT_BOOK_PAGE_FOR_SUPPLEMENTARY_REQUEST,
    SupplementariesSagas.editBookPageForSupplementarySaga
  );
  yield takeEvery(
    QuizzesTypes.GET_QUIZZES_LIST,
    QuizzesSaga.getQuizzesListSaga
  );
  yield takeEvery(QuizzesTypes.ADD_QUIZ, QuizzesSaga.addQuizSaga);
  // Promocodes
  yield takeEvery(
    PromoCodesTypes.GET_PROMOCODES_LIST_REQUEST,
    PromoCodesSaga.getPromoCodesListSaga
  );
  yield takeEvery(
    PromoCodesTypes.ADD_PROMOCODES_REQUEST,
    PromoCodesSaga.addPromoCodesSaga
  );

  yield takeEvery(
    PromoCodesTypes.GET_SINGLE_PROMOCODE_REQUEST,
    PromoCodesSaga.getSinglePromocodeSaga
  );

  yield takeEvery(
    PromoCodesTypes.DELETE_PROMOCODE_REQUEST,
    PromoCodesSaga.deletePromoCodeSaga
  );

  // Surveys
  yield takeEvery(
    SurveysTypes.DELETE_SURVEY_REQUEST,
    SurveysSagas.deleteSurveySaga
  );
  yield takeEvery(
    SurveysTypes.GET_SURVEYS_REQUEST,
    SurveysSagas.getSurveysSaga
  );
  yield takeEvery(
    SurveysTypes.ADD_SURVEYS_REQUEST,
    SurveysSagas.addSurveySaga
  );
  yield takeEvery(
    SurveysTypes.EDIT_SURVEY_REQUEST,
    SurveysSagas.editSurveySaga
  );
  yield takeEvery(
    SurveysTypes.GET_SINGLE_SURVEY_REQUEST,
    SurveysSagas.getSingleSurveySaga
  );
  yield takeEvery(
    SurveysTypes.GET_SINGLE_SURVEY_STATISTCS_REQUEST,
    SurveysSagas.getSingleSurveyStatisticsSaga
  );
  yield takeEvery(
    SurveysTypes.EXPORT_SURVEY_REPORT,
    SurveysSagas.exportSurveyReportSaga
  );
  yield takeEvery(
    SurveysTypes.CHECK_FOR_CAN_BE_EDIT_SURVEY_REQUEST,
    SurveysSagas.checkForCanBeEditSurveySaga
  );
  // Institutions
  yield takeEvery(
    InstitutionsTypes.DELETE_INSTITUTION_REQUEST,
    InstitutionsSagas.deleteInstitutionSaga
  );
  yield takeEvery(
    InstitutionsTypes.GET_INSTITUTIONS_REQUEST,
    InstitutionsSagas.getInstitutionsSaga
  );
  yield takeEvery(
    InstitutionsTypes.ADD_INSTITUTIONS_REQUEST,
    InstitutionsSagas.addInstitutionSaga
  );
  yield takeEvery(
    InstitutionsTypes.EDIT_INSTITUTION_REQUEST,
    InstitutionsSagas.editInstitutionSaga
  );
  yield takeEvery(
    InstitutionsTypes.GET_SINGLE_INSTITUTION_REQUEST,
    InstitutionsSagas.getSingleInstitutionSaga
  );
  // QUIZZES
  yield takeEvery(
    QuizzesTypes.ASSIGN_QUIZ_TO_BOOK,
    QuizzesSaga.assignQuizToBookSaga
  );
  yield takeEvery(
    QuizzesTypes.DELETE_QUIZ,
    QuizzesSaga.deleteQuizSaga
  );
  yield takeEvery(
    QuizzesTypes.GET_BOOK_QUIZ,
    QuizzesSaga.getBookQuizzesSaga
  );
  yield takeEvery(
    QuizzesTypes.UNASSIGN_BOOK_QUIZ,
    QuizzesSaga.unassignQuizFromBookSaga
  );
  yield takeEvery(
    QuizzesTypes.GET_QUIZ_DETAILS,
    QuizzesSaga.getQuizDetailsSaga
  );
  yield takeEvery(
    QuizzesTypes.EDIT_ASSIGN_QUIZ_BTN,
    QuizzesSaga.editAssignQuizToBookSaga
  );
  yield takeEvery(QuizzesTypes.EDIT_QUIZ, QuizzesSaga.editQuizSaga);

  yield takeEvery(
    QuizzesTypes.CHECK_FOR_CAN_BE_EDIT_QUIZ_REQUEST,
    QuizzesSaga.checkForCanBeEditQuizSaga
  );

  yield takeEvery(
    QuizzesTypes.IMPORT_QUIZZES_TO_BOOK,
    QuizzesSaga.importQuizzesToBookSaga
  );

  yield takeEvery(
    RegisteredUserTypes.GET_REGISTEREDUSER_REQUEST,
    RegisteredUserSaga.getRegisteredUserSaga
  );
  yield takeEvery(
    CountriesTypes.GET_COUNTRIES_REQUEST,
    CountriesSaga.getCountriesSaga
  );
  yield takeEvery(JobsTypes.GET_JOBS_REQUEST, JobsSaga.getJobsSaga);
  // Tests
  yield takeEvery(TestTypes.ADD_TESTS_REQUEST, TestSaga.addTestSaga);
  yield takeEvery(TestTypes.GET_TESTS_REQUEST, TestSaga.getTestsSaga);
  yield takeEvery(
    TestTypes.GET_SINGLE_TEST_REQUEST,
    TestSaga.getSingleTestSaga
  );
  yield takeEvery(TestTypes.EDIT_TEST_REQUEST, TestSaga.editTestSaga);
  yield takeEvery(
    TestTypes.DELETE_TEST_REQUEST,
    TestSaga.deleteTestSaga
  );

  yield takeEvery(
    TestTypes.GET_BOOK_TEST_REQUEST,
    TestSaga.getBookTestsSaga
  );
  yield takeEvery(
    TestTypes.GET_SINGLE_TEST_REPORT_REQUEST,
    TestSaga.getSingleTestReportSaga
  );
  yield takeEvery(
    TestTypes.EXPORT_TEST_REPORT,
    TestSaga.exportTestReportSaga
  );
  yield takeEvery(
    TestTypes.CHECK_FOR_CAN_BE_EDIT_TEST_REQUEST,
    TestSaga.checkForCanBeEditTestSaga
  );
  // programs
  yield takeEvery(
    ProgramsTypes.GET_PROGRAMS_REQUEST,
    ProgramsSagas.getProgramsSaga
  );
  yield takeEvery(
    ProgramsTypes.GET_SELECTED_PROGRAMS_REQUEST,
    ProgramsSagas.getProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.GET_USERS_PROGRAMS_REQUEST,
    ProgramsSagas.getUsersProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.CREATE_PROGRAMS_REQUEST,
    ProgramsSagas.addProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.DELETE_PROGRAMS_REQUEST,
    ProgramsSagas.deleteProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.ASSIGN_DIRECTOR_TO_PROGRAM,
    ProgramsSagas.assignDirectorToProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.ASSIGN_BOOKS_TO_PROGRAM,
    ProgramsSagas.assignBooksToProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.ASSIGN_USERS_TO_PROGRAM,
    ProgramsSagas.assignUsersToProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.ASSIGN_USERS_FROM_FILE_TO_PROGRAM,
    ProgramsSagas.assignUsersFromFileToProgramSaga
  );
  yield takeEvery(
    ProgramsTypes.REMOVE_USERS_FROM_PROGRAM,
    ProgramsSagas.deleteUserFromProgramSaga
  );
  // director
  yield takeEvery(
    DirectorsTypes.GET_DIRECTORS_REQUEST,
    DirectorsSagas.getDirectorsSaga
  );
  yield takeEvery(
    DirectorsTypes.ADD_DIRECTOR_REQUEST,
    DirectorsSagas.addDirectorSaga
  );
  yield takeEvery(
    DirectorsTypes.DELETE_DIRECTORS_REQUEST,
    DirectorsSagas.deleteDirectorsSaga
  );
  // users
  yield takeEvery(
    UsersTypes.GET_USERS_REQUEST,
    UsersSagas.getUsersSaga
  );
}

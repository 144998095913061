import { axiosInstance } from ".";

const getEditors = async (params) => {
  return await axiosInstance.get(`editors`, { params });
};

const deleteEditor = async (id) => {
  return await axiosInstance.delete(`editors/${id}`);
};

const addEditor = async (payload) =>
  await axiosInstance.post(`editors`, payload);

const assigneBookToEditor = async (payload) =>
  await axiosInstance.post(
    `editors/assign_books${payload.editorId}`,
    payload
  );

const editEditor = async ({ name, id, book_ids }) =>
  await axiosInstance.patch(`editors/${id}`, { name, book_ids });

export {
  getEditors,
  deleteEditor,
  addEditor,
  editEditor,
  assigneBookToEditor
};

import { TableCell, TableRow } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiButton from "@mui/material/Button";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import Table from "../../../components/Table";
import { assignUsersFromFileToProgram } from "../../../store/Programs/actions";

function AssignUsers({ id }) {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const {
    programs: { assignUsers },
    shared: { assign, upload }
  } = messages[lang];

  const uploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const handleDirectorSubmit = () => {
    const formData = new FormData();
    formData.append("program[users_file]", file);
    dispatch(
      assignUsersFromFileToProgram({
        id,
        data: formData
      })
    );
    setUsersFromFile([]);
  };

  const [usersFromFile, setUsersFromFile] = useState([]);
  const readXLSX = (e) => {
    const f = e.target.files[0];
    setFile(f);
    var reader = new FileReader();
    reader.onload = function (e) {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheetNameList = workbook.SheetNames;
      const jsonData = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheetNameList[0]]
      );
      setUsersFromFile(jsonData);
    };
    reader.readAsBinaryString(f);
  };
  const usersFromFileHeaders = [
    "country id",
    "email",
    "first name",
    "second name"
  ];

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        {assignUsers}
      </AccordionSummary>
      <AccordionDetails>
        <input
          type="file"
          style={{ display: "none" }}
          ref={uploadRef}
          onChange={readXLSX}
        />
        <MuiButton
          variant="outlined"
          onClick={() => {
            if (uploadRef.current) uploadRef.current.click();
          }}
        >
          {upload}
        </MuiButton>
        {!!usersFromFile.length && (
          <Table
            headlines={usersFromFileHeaders}
            rows={usersFromFile.map((row) => (
              <TableRow className="table__row" key={row.email}>
                <TableCell className="table__cell" align="left">
                  {row.country_id}
                </TableCell>
                <TableCell className="table__cell" align="left">
                  {row.email}
                </TableCell>
                <TableCell className="table__cell" align="left">
                  {row.first_name}
                </TableCell>
                <TableCell className="table__cell" align="left">
                  {row.second_name}
                </TableCell>
              </TableRow>
            ))}
          />
        )}
      </AccordionDetails>
      <AccordionActions>
        <Button
          onClick={() => {
            handleDirectorSubmit();
          }}
          text={assign}
        />
      </AccordionActions>
    </Accordion>
  );
}

AssignUsers.propTypes = {
  id: PropTypes.string,
  users: PropTypes.array
};

export default AssignUsers;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Form, Formik } from "formik";
import { Grid, Typography, useTheme } from "@material-ui/core";
import Input from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import Upload from "../../../components/Upload";
import Button from "../../../components/Button";
import MaterialPreview from "../../../components/MaterialPreview";
import messages from "../../../assets/locale/messages";
import {
  ADD_EDIT_SUPPLEMENTARY_SCHEMA,
  getInitialValues
} from "./validation";
import { MATERIAL_TYPES } from "../../../utils/Constants";
import { uploadToS3 } from "../../../utils/S3";
import { getSupplementaryTypeLabel } from "../../../utils/Helpers";
import { showHideLoader } from "../../../store/Loader/actions";
import {
  addSupplementariesRequest,
  editSupplementaryRequest,
  getSingleSupplementaryRequest
} from "../../../store/Supplementaries/actions";
import "./SupplementaryForm.scss";

const SupplementaryForm = () => {
  const dispatch = useDispatch();
  let params = useParams();
  const theme = useTheme();
  const lang = useSelector((state) => state.locale.lang);
  const singleSupplementary = useSelector(
    (state) => state.supplementaries.singleSupplementary
  );

  const [mode, setMode] = useState("add");
  const [filePreview, setFilePreview] = useState(null);
  const [selectedMaterialType, setSelectedMaterialType] = useState(0);
  const [dicomFilesPreview, setDicomFilesPreview] = useState([]);

  const [isDicom, setIsDicom] = useState(false);
  const [isUploadChange, setIsUploadChange] = useState(false);

  useEffect(() => {
    if (params?.id) {
      setMode("edit");
      dispatch(getSingleSupplementaryRequest(params.id));
    } else {
      return null;
    }
  }, [params]);

  useEffect(() => {
    setIsDicom(selectedMaterialType === 2);
  }, [selectedMaterialType]);

  useEffect(() => {
    if (mode === "edit") {
      singleSupplementary.material_type &&
        setSelectedMaterialType(
          getSupplementaryTypeLabel(singleSupplementary.material_type)
            .id
        );
      if (singleSupplementary.url) {
        if (
          getSupplementaryTypeLabel(singleSupplementary.material_type)
            .id === 2
        ) {
          setDicomFilesPreview(singleSupplementary.url);
        } else {
          setFilePreview(encodeURI(...singleSupplementary.url));
        }
      }
    }
  }, [singleSupplementary]);

  const handlePreviewSrc = (src) => {
    setIsUploadChange(true);
    isDicom ? setDicomFilesPreview(src) : setFilePreview(src);
  };

  const handleMaterialChange = (id, setFieldValue) => {
    setSelectedMaterialType(id);
    setFieldValue("file", "");
    setFilePreview(null);
  };

  const uploadFilesToS3 = async (sourceFiles) => {
    const files = !sourceFiles?.length
      ? [sourceFiles]
      : [...sourceFiles];
    const urls = [];
    for (let i = 0; i < files.length; i++) {
      await uploadToS3("image", files[i], files[i].name).then(
        (data) => {
          urls.push(data.link);
        }
      );
    }
    return urls;
  };

  const handleSubmit = async (values) => {
    dispatch(showHideLoader(true));
    if (mode === "add") {
      let addData = {
        ...values,
        url: []
      };
      addData.url = await uploadFilesToS3(values.file);
      dispatch(addSupplementariesRequest(_.omit(addData, ["file"])));
    } else {
      let editData = {
        ...values,
        url: []
      };
      if (isUploadChange) {
        editData.url = await uploadFilesToS3(values.file);
      } else {
        editData.url = values.file;
      }
      dispatch(
        editSupplementaryRequest({
          data: _.omit(editData, ["file"]),
          id: singleSupplementary.id
        })
      );
    }
  };

  const {
    supplementaries: {
      addSupplementary,
      titleLabel,
      descriptionLabel,
      materialType,
      uploadSupplementary,
      selectTypeHint,
      diagnosisLabel,
      editSupplementary
    }
  } = messages[lang];

  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {mode === "add" ? addSupplementary : editSupplementary}
        </Typography>
      </div>
      <div className="wrapper-bg">
        <Formik
          initialValues={
            mode === "add"
              ? getInitialValues()
              : getInitialValues(singleSupplementary)
          }
          onSubmit={handleSubmit}
          validationSchema={ADD_EDIT_SUPPLEMENTARY_SCHEMA(
            lang,
            selectedMaterialType
          )}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form>
              <Grid container spacing={3} className="max-width m-0">
                <Grid item xs={6} className="pb-0 ">
                  <Typography
                    variant="h6"
                    component="h6"
                    className="mb-2 hint"
                  >
                    {selectTypeHint}
                  </Typography>
                  <CustomSelect
                    options={MATERIAL_TYPES}
                    placeholder={materialType}
                    name="material_type"
                    bgColor={theme.palette.primary.light}
                    handleSelectChange={(id) =>
                      handleMaterialChange(id, setFieldValue)
                    }
                  />
                  <Input
                    name="title"
                    label={titleLabel}
                    isRequired={true}
                  />
                  {isDicom && (
                    <Input
                      name="diagnosis"
                      label={diagnosisLabel}
                      inputShape="textarea"
                    />
                  )}
                  <Input
                    name="description"
                    label={descriptionLabel}
                    inputShape="textarea"
                  />
                </Grid>
                <Grid item xs={6} className="pb-0 ">
                  <div className="d-flex flex-column align-items-center text-center">
                    <div className="mb-3 d-flex flex-column align-items-center w-100">
                      <MaterialPreview
                        src={filePreview}
                        type={selectedMaterialType}
                        dicomImages={isDicom ? dicomFilesPreview : []}
                      />
                    </div>
                    <Upload
                      label={uploadSupplementary}
                      name="file"
                      accept={
                        isDicom
                          ? "*/dicom,.dcm, image/dcm, */dcm, .dicom "
                          : `${
                              getSupplementaryTypeLabel(
                                selectedMaterialType
                              ).label
                            }/*`
                      }
                      setFieldValue={setFieldValue}
                      handlePreviewSrc={handlePreviewSrc}
                      isRequired={true}
                      isMultiple={isDicom ? true : false}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="d-block mt-3 ">
                <Button
                  text={
                    mode === "add"
                      ? addSupplementary
                      : editSupplementary
                  }
                  className="primary-lg"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SupplementaryForm;

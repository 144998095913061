import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import EmptyState from "../../../components/EmptyState";
import Table from "../../../components/Table";
import { getProgramsRequest } from "../../../store/Programs/actions";
import { useHistory } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

function ProgramsList({ handleDelete }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);
  const histpory = useHistory();

  const {
    cols: { name },
    shared: { deleteLabel },
    programs: { deleteMessage, deleteTitle, editLabel, usersNumber },
    directors: { title }
  } = messages[lang];

  useEffect(() => {
    dispatch(getProgramsRequest());
  }, []);
  const { programs } = useSelector((state) => state.programs);

  const [deleteId, setDeleteId] = useState(0);
  return (
    <>
      <Table
        headlines={[name, usersNumber, title]}
        hasActions={true}
        rows={programs.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.users?.length}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.program_director?.name}
            </TableCell>
            <TableCell className="table__cell" align="right">
              <Tooltip title={editLabel}>
                <span>
                  <IconButton
                    onClick={() =>
                      histpory.push(
                        `${ROUTE_PATHS.programsEdit}/${row.id}`
                      )
                    }
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => setDeleteId(row.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !programs.length && <EmptyState />}
      {/* {meta.pages > 1 && (
          <Pagination
            count={meta.pages}
            page={meta.currentPage || page}
            handleChange={handlePagination}
          />
        )} */}
      {!!deleteId && (
        <DeleteModal
          deleteMessage={deleteMessage}
          deleteTitle={deleteTitle}
          isOpen={!!deleteId}
          lang={lang}
          handleDelete={() => {
            handleDelete(deleteId);
            setDeleteId(0);
          }}
          handleCancel={() => setDeleteId(0)}
        />
      )}
    </>
  );
}

ProgramsList.propTypes = {
  handleDelete: PropTypes.func
};

export default ProgramsList;

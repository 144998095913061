import * as types from "./types";

export const getProgramsRequest = () => ({
  type: types.GET_PROGRAMS_REQUEST
});

export const addProgramsResponse = (data) => ({
  type: types.ADD_PROGRAMS_RESPONSE,
  payload: data
});

export const getProgramRequest = (data) => ({
  type: types.GET_SELECTED_PROGRAMS_REQUEST,
  payload: data
});

export const addProgramResponse = (data) => ({
  type: types.ADD_SELECTED_PROGRAMS_RESPONSE,
  payload: data
});
export const getUsersInsideProgramRequest = (data) => ({
  type: types.GET_USERS_PROGRAMS_REQUEST,
  payload: data
});
export const addUsersInsideProgramResponse = (data) => ({
  type: types.ADD_USERS_PROGRAMS_RESPONSE,
  payload: data
});

export const createProgramRequest = (data) => ({
  type: types.CREATE_PROGRAMS_REQUEST,
  payload: data
});

export const deleteProgramRequest = (data) => ({
  type: types.DELETE_PROGRAMS_REQUEST,
  payload: data
});

export const assignDirectorToProgram = (data) => ({
  type: types.ASSIGN_DIRECTOR_TO_PROGRAM,
  payload: data
});

export const assignUsersToProgram = (data) => ({
  type: types.ASSIGN_USERS_TO_PROGRAM,
  payload: data
});
export const assignUsersFromFileToProgram = (data) => ({
  type: types.ASSIGN_USERS_FROM_FILE_TO_PROGRAM,
  payload: data
});

export const assignBooksToProgram = (data) => ({
  type: types.ASSIGN_BOOKS_TO_PROGRAM,
  payload: data
});

export const removeUserFromProgram = (data) => ({
  type: types.REMOVE_USERS_FROM_PROGRAM,
  payload: data
});

export const setSelectedDirector = (data) => ({
  type: types.SET_SELECTED_DIRECTOR,
  payload: data
});

export const setSelectedUsers = (data) => ({
  type: types.SET_SELECTED_USERS,
  payload: data
});

export const setSelectedBooks = (data) => ({
  type: types.SET_SELECTED_BOOKS,
  payload: data
});

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import {
  deleteQuiz,
  deleteQuizAction
} from "../../../store/Quizzes/actions";

const DeleteQuiz = ({ isOpen, lang }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector(
    (state) => state.quizzes.deleteQuizBtn
  );

  const {
    quizzes: { delete_quiz, delete_confirmation }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteQuiz({ id: deleteId }));
    dispatch(deleteQuizAction(false));
  };
  return (
    <DeleteModal
      deleteMessage={delete_confirmation}
      deleteTitle={delete_quiz}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
      cancelCallback={() => dispatch(deleteQuizAction(false))}
    />
  );
};
DeleteQuiz.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

DeleteQuiz.defaultProps = {
  isOpen: false
};
export default DeleteQuiz;

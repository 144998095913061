export const setAdmin = ({ admin }) => {
  localStorage.removeItem("admin");

  localStorage.setItem("admin", JSON.stringify(admin));
};

export const setTempAdmin = ({ admin }) => {
  localStorage.removeItem("tempAdmin");
  localStorage.setItem("tempAdmin", JSON.stringify(admin));
};

export const clearAdminData = () => {
  localStorage.removeItem("admin");
  localStorage.removeItem("admin");
  localStorage.removeItem("tempAdmin");
};

import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  searchKeyword: null,
  title: null,
  singleTest: null,
  searchInBookKeyword: null,
  CanBeEditTest: true,
  bookTests: {
    list: [],
    meta: {}
  },
  singleTestReport: null
};

const testsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_TESTS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_SINGLE_TEST_RESPONSE:
      return { ...state, singleTest: action.payload };
    case types.SET_TEST_FILTERS:
      return {
        ...state,
        searchKeyword: action.payload.searchKeyword
      };
    case types.GET_BOOK_TEST_RESPONSE:
      return {
        ...state,
        bookTests: {
          list: action.payload.list,
          meta: action.payload.meta
        }
      };
    case types.SET_TEST_BOOK_FILTERS:
      return {
        ...state,
        searchInBookKeyword: action.payload.searchInBookKeyword
      };
    case types.GET_SINGLE_TEST_REPORT_RESPONSE:
      return { ...state, singleTestReport: action.payload };

    case types.CHECK_FOR_CAN_BE_EDIT_TEST_RESPONSE:
      return { ...state, CanBeEditTest: action.payload };

    default:
      return state;
  }
};

export default testsReducer;

import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../components/Button";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import SupplementariesList from "./SupplementariesList";
import SupplementaryDelete from "./SupplementaryDelete";
import AssignSupplementary from "./AssignSupplementary";
import Filters from "./Filters";

const Supplementaries = () => {
  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.supplementaries.list);
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const isAssign = useSelector((state) => state.actionsBtn.add);

  const {
    supplementaries: { title, addSupplementary }
  } = messages[lang];

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Filters />
        </Grid>
        <Grid
          item
          xs={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addSupplementary}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            slug={ROUTE_PATHS["addSupplementary"]}
          />
        </Grid>
      </Grid>
      <SupplementariesList list={list} lang={lang} />
      <SupplementaryDelete isOpen={!!deleteId} lang={lang} />
      <AssignSupplementary isOpen={!!isAssign} lang={lang} />
    </>
  );
};

export default Supplementaries;

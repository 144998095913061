import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as cornerstone from "cornerstone-core";
import CornerstoneViewport from "react-cornerstone-viewport";

const DicomViewer = ({ className, imageIds }) => {
  const [imageLinks, setImageLinks] = useState([]);
  const [, setCornerstoneElement] = useState(undefined);

  useEffect(() => {
    imageIds.length &&
      setImageLinks(imageIds.map((image) => `dicomweb:${image}`));
  }, [imageIds]);

  const tools = [{ name: "StackScrollMouseWheel", mode: "active" }];

  const CustomOverlay = () => {
    return <div></div>;
  };

  return (
    imageLinks.length > 0 && (
      <CornerstoneViewport
        tools={tools}
        imageIds={imageLinks}
        className={className}
        viewportOverlayComponent={CustomOverlay}
        onElementEnabled={(elementEnabledEvt) => {
          const cornerstoneElt = elementEnabledEvt.detail.element;
          setCornerstoneElement(cornerstoneElt);
          cornerstoneElt.addEventListener(
            "cornerstoneimagerendered",
            (imageRenderedEvent) => {
              const viewport = imageRenderedEvent.detail.viewport;

              cornerstone.setViewport(cornerstoneElt, viewport);
            }
          );
        }}
      />
    )
  );
};

DicomViewer.propTypes = {
  className: PropTypes.string,
  imageIds: PropTypes.arrayOf(PropTypes.string)
};

DicomViewer.defaultProps = {
  className: "",
  imageIds: []
};

export default DicomViewer;

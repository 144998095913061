import * as types from "./types";

export const getInstitutionsRequest = (data) => ({
  type: types.GET_INSTITUTIONS_REQUEST,
  payload: data
});

export const getInstitutionsResponse = (data) => ({
  type: types.GET_INSTITUTIONS_RESPONSE,
  payload: data
});

export const deleteInstitutionRequest = (data) => ({
  type: types.DELETE_INSTITUTION_REQUEST,
  payload: data
});

export const setInstitutionsFilters = (data) => ({
  type: types.SET_INSTITUTION_FILTERS,
  payload: data
});

export const addInstitutionsRequest = (data) => ({
  type: types.ADD_INSTITUTIONS_REQUEST,
  payload: data
});

export const assignInstitutionToBookRequest = (data) => ({
  type: types.ASSIGN_BOOK_TO_INSTITUTION_REQUEST,
  payload: data
});

export const unassignInstitutionFromBookRequest = (data) => ({
  type: types.UNASSIGN_BOOK_FROM_INSTITUTION_REQUEST,
  payload: data
});

export const editInstitutionRequest = (data) => ({
  type: types.EDIT_INSTITUTION_REQUEST,
  payload: data
});

export const getSingleInstitutionRequest = (data) => ({
  type: types.GET_SINGLE_INSTITUTION_REQUEST,
  payload: data
});

export const getSingleInstitutionResponse = (data) => ({
  type: types.GET_SINGLE_INSTITUTION_RESPONSE,
  payload: data
});

export const editBookPageForInstitutionRequest = (data) => ({
  type: types.EDIT_BOOK_PAGE_FOR_INSTITUTION_REQUEST,
  payload: data
});

import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import CurrencyInput from "react-currency-input-field";
import { numbersOnly } from "../../utils/Helpers";
import styles from "./Input.module.scss";

const Input = ({
  name,
  label,
  inputIcon,
  onIconClick,
  type,
  showCounter,
  charLimit,
  isRequired,
  inputShape,
  currencyPrefix,
  autoComplete,
  isNumber,
  isDisabled,
  ...props
}) => {
  const renderInput = (field, form) => {
    switch (inputShape) {
      case "currency":
        return (
          <CurrencyInput
            name={name}
            decimalsLimit={2}
            className={styles.input}
            value={field.value}
            allowNegativeValue={false}
            disabled={isDisabled}
            onValueChange={(value) => {
              form.setFieldValue(name, value);
            }}
            prefix={currencyPrefix}
            {...props}
          />
        );
      case "textarea":
        return (
          <textarea
            className={`${styles.input} ${styles.textarea}`}
            rows="6"
            id={name}
            name={name}
            value={field.value}
            disabled={isDisabled}
            {...props}
            {...field}
          />
        );
      default:
        return (
          <input
            className={styles.input}
            id={name}
            name={name}
            type={type}
            value={field.value}
            autoComplete={autoComplete}
            onKeyDown={isNumber ? (e) => numbersOnly(e) : null}
            disabled={isDisabled}
            {...field}
            {...props}
          />
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <Field name={name}>
          {({ field, form }) => (
            <>
              {renderInput(field, form)}
              <label
                className={`${styles.label} ${
                  props?.value || field.value ? styles.focusState : ""
                } ${
                  inputShape === "textarea"
                    ? styles.textareaLabel
                    : ""
                }`}
                htmlFor={name}
              >
                {label}{" "}
                {isRequired && (
                  <span className="error-msg fsize-14">*</span>
                )}
              </label>
              {showCounter && (
                <small className="hint text-end d-block">
                  {field?.value?.length}/{charLimit}
                </small>
              )}
            </>
          )}
        </Field>
        {inputIcon && (
          <small
            onClick={onIconClick}
            className={`${styles.inputIcon} fsize-10`}
          >
            {inputIcon}
          </small>
        )}
      </div>
      <ErrorMessage
        component="div"
        className="error-msg"
        name={name}
      />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputIcon: PropTypes.string || PropTypes.node,
  onIconClick: PropTypes.func,
  type: PropTypes.string,
  showCounter: PropTypes.bool,
  charLimit: PropTypes.number,
  isRequired: PropTypes.bool,
  isNumber: PropTypes.bool,
  inputShape: PropTypes.string,
  currencyPrefix: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool
};

Input.defaultProps = {
  name: "",
  label: "",
  type: "text",
  showCounter: false,
  isRequired: false,
  inputShape: null,
  currencyPrefix: "$",
  isDisabled: false,
  autoComplete: "off",
  isNumber: false,
  onIconClick: () => {}
};

export default Input;

import { axiosInstance } from ".";

export const getDirectors = async (params) => {
  return await axiosInstance.get(`program_directors`, { params });
};

export const deleteDirector = async (id) => {
  return await axiosInstance.delete(`program_directors/${id}`);
};

export const addDirector = async (payload) =>
  await axiosInstance.post(`program_directors`, payload);

// export const editDirector = async ({ name, id, book_ids }) =>
//   await axiosInstance.patch(`program_directors/${id}`, { name, book_ids });

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem } from "@material-ui/core";

const Dropdown = ({ title, menuItems }) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      fontSize: "16px",
      fontFamily: "Helvetica-Bold",
      textTransform: "capitalize"
    },
    paper: {
      boxShadow: "2px 5px 15px 0 rgba(0, 0, 0, 0.1)",
      borderRadius: "12px",
      minWidth: "110px"
    },
    menuItem: {
      color: theme.palette.black.light,
      fontSize: "12px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white
      }
    }
  }));
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.button}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        {menuItems.map(({ id, handleClick, title }) => (
          <MenuItem
            key={id}
            classes={{ root: classes.menuItem }}
            onClick={
              _.isFunction(handleClick)
                ? () => {
                    handleClose();
                    handleClick();
                  }
                : () => {}
            }
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      handleClick: PropTypes.func
    })
  )
};

Dropdown.defaultProps = {
  menuItems: []
};

export default Dropdown;

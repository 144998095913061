import React from "react";
import Input from "../../../../components/Input";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useIntl } from "react-intl";
import "./FinalTestFormDetails.scss";
import PropTypes from "prop-types";

function AnswerDisplay({ values, question, questionIndex }) {
  const { messages } = useIntl();
  console.log("Final Test Form Details", question);

  const {
    finalTest: { answerText }
  } = messages;

  return (
    <div className="">
      <div className=" show active">
        {question?.answer_choices?.map((answer, answerIndex) => {
          return (
            !answer._destroy && (
              <div
                className="mx-5 d-flex align-items-center"
                key={JSON.stringify(answerIndex)}
              >
                <span className="mb-2 mr-1 ">
                  {answerIndex + 1} -
                </span>
                <div className="flex-fill ml-1 d-flex align-items-center">
                  {answer.image_url && (
                    <div item className="pb-0">
                      <img
                        src={answer.image_url}
                        alt="medblend"
                        width="100px"
                        className={`img-thumbnail m-3 img`}
                      />
                    </div>
                  )}
                  {answer.answer && (
                    <div className="flex-fill">
                      <Input
                        name={`details.questions[${questionIndex}].answer_choices[${answerIndex}].answer`}
                        label={answerText}
                        isRequired={false}
                      />
                    </div>
                  )}
                  {values.details.questions[questionIndex]
                    .answer_choices[answerIndex].correct && (
                    <span
                      className={`icon alert alert-success`}
                      role="alert"
                    >
                      <CheckCircle color="success" className="me-2" />
                    </span>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

AnswerDisplay.propTypes = {
  values: PropTypes.shape({
    details: PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          answer_choices: PropTypes.arrayOf(
            PropTypes.shape({
              correct: PropTypes.bool,
              answer: PropTypes.string,
              image_url: PropTypes.string,
              _destroy: PropTypes.bool
            })
          )
        })
      )
    })
  }).isRequired,
  question: PropTypes.shape({
    answer_choices: PropTypes.arrayOf(
      PropTypes.shape({
        correct: PropTypes.bool,
        answer: PropTypes.string,
        image_url: PropTypes.string,
        _destroy: PropTypes.bool
      })
    )
  }).isRequired,
  questionIndex: PropTypes.number.isRequired
};

export default AnswerDisplay;

import * as Yup from "yup";
import messages from "../../../assets/locale/messages";

export const ADD_EDIT_CATEGORY_SCHEMA = (lang) => {
  const {
    categories: {
      validation: { categoryNameRequired }
    }
  } = messages[lang];

  return Yup.object().shape({
    name: Yup.string().required(categoryNameRequired)
  });
};

export const getInitialValues = (category) => {
  return {
    name: category?.name || ""
  };
};

import * as types from "./types";

export const getRegisteredUserRequest = (data) => ({
  type: types.GET_REGISTEREDUSER_REQUEST,
  payload: data
});

export const getRegisteredUserResponse = (data) => ({
  type: types.GET_REGISTEREDUSER_RESPONSE,
  payload: data
});

export const getRegisteredUserInCountryWithJobRequest = (data) => ({
  type: types.GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB_REQUEST,
  payload: data
});

export const getRegisteredUserInCountryWithJobResponse = (data) => ({
  type: types.GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB_RESPONSE,
  payload: data
});

export const setRegisteredUserFilters = (data) => ({
  type: types.SET_REGISTEREDUSER_FILTERS,
  payload: data
});

/* eslint-disable no-unused-vars */
/* eslint-disable require-yield */
import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/institutes";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  getInstitutionsResponse,
  getInstitutionsRequest,
  getSingleInstitutionResponse
} from "./actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";

export function* getInstitutionsSaga({ payload }) {
  try {
    const response = yield call(apis.getInstitutions, payload);
    yield put(
      getInstitutionsResponse({
        list: response.data.institutions,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteInstitutionSaga({ payload }) {
  try {
    const response = yield call(apis.deleteInstitution, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data.message
      })
    );
    yield put(getInstitutionsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addInstitutionSaga({ payload }) {
  try {
    const response = yield call(apis.addInstitution, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].institute.addInstitutionSuccess
      })
    );
    yield history.push(ROUTE_PATHS["institute"]);
  } catch (err) {
    console.error(err);
  }
}

export function* editInstitutionSaga({ payload }) {
  try {
    const response = yield call(apis.editInstitution, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].institute.editInstitutionSuccess
      })
    );
    yield history.push(ROUTE_PATHS["institute"]);
  } catch (err) {
    console.error(err);
  }
}

export function* getSingleInstitutionSaga({ payload }) {
  try {
    const response = yield call(apis.getSingleInstitution, payload);
    yield put(getSingleInstitutionResponse(response.data["survey"]));
  } catch (err) {
    console.error(err);
  }
}

import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { ErrorMessage, Field } from "formik";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import messages from "../../assets/locale/messages";

const CustomSelect = ({
  name,
  options,
  placeholder,
  handleSelectChange,
  bgColor,
  isRequired,
  isMulti,
  isAddNoneOption,
  isMenuRelative,
  disabled,
  style,
  ...props
}) => {
  const theme = useTheme();
  const Styles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: bgColor ? bgColor : theme.palette.white,
      borderRadius: "8px",
      border: 0,
      boxShadow: "none"
    }),
    valueContainer: (styles) => ({ ...styles, padding: "8px 14px" }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: isSelected
          ? theme.palette.white
          : theme.palette.black.light,
        backgroundColor: isSelected
          ? theme.palette.primary.main
          : theme.palette.white,
        ":hover": {
          color: theme.palette.black.light,
          backgroundColor: theme.palette.primary.light
        }
      };
    },
    menu: (styles) => ({
      ...styles,
      boxShadow: "2px 5px 15px 0 rgba(0, 0, 0, 0.1)",
      borderRadius: "12px",
      overflow: "hidden",
      zIndex: 10,
      position: isMenuRelative ? "relative" : "absolute"
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.palette.text.secondary,
      fontFamily: "Helvetica-Regular",
      fontSize: "14px"
    })
  };

  const lang = useSelector((state) => state.locale.lang);

  const {
    shared: { none }
  } = messages[lang];

  const getValue = (field) => {
    if (options) {
      return isMulti
        ? options.id
        : options.find((option) => option.id === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  return (
    <div className="mb-4" style={style}>
      <Field name={name}>
        {({ field, form }) => (
          <Select
            options={
              isAddNoneOption && options.length
                ? [{ id: "", value: "", label: none }, ...options]
                : options
            }
            isDisabled={disabled}
            styles={Styles}
            placeholder={
              <>
                {placeholder}{" "}
                {isRequired && (
                  <span className="error-msg fsize-14">*</span>
                )}
              </>
            }
            name={field.name}
            value={getValue(field)}
            onChange={(option) => {
              form.setFieldValue(field.name, option.id);
              _.isFunction(handleSelectChange) &&
                handleSelectChange(
                  isMulti ? option.map((el) => el.id) : option.id
                );
            }}
            onBlur={field.onBlur}
            isMulti={isMulti}
            {...props}
          />
        )}
      </Field>
      <ErrorMessage
        component="div"
        className="error-msg"
        name={name}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  handleSelectChange: PropTypes.func,
  bgColor: PropTypes.string,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  isAddNoneOption: PropTypes.bool,
  isMenuRelative: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

CustomSelect.defaultProps = {
  name: "",
  placeholder: "",
  options: [],
  isRequired: false,
  isMulti: false,
  isAddNoneOption: false,
  isMenuRelative: false,
  style: {},
  handleSelectChange: () => {}
};

export default CustomSelect;

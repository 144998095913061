import { axiosInstance } from "./";

const getQuizzesList = async (params) => {
  return await axiosInstance.get(`quizzes`, { params });
};

const addQuiz = async (params) => {
  return await axiosInstance.post(`quizzes`, params);
};
const assignQuizToBook = async (params) => {
  return await axiosInstance.post(`book_quizzes`, params);
};
const deleteQuiz = async (params) => {
  return await axiosInstance.delete(`quizzes/${params.id}`);
};
const getBookQuizzes = async (params) => {
  const payload = {
    items: 10,
    ...params
  };
  return await axiosInstance.get(`book_quizzes`, { params: payload });
};
const unassignQuizFromBook = async (id) => {
  return await axiosInstance.delete(`book_quizzes/${id}`);
};
const getQuizDetails = async (id) => {
  return await axiosInstance.get(`quizzes/${id}`);
};
const editQuiz = async (payload) => {
  return await axiosInstance.patch(`quizzes/${payload.id}`, {
    title: payload.title,
    questions_attributes: payload.questions_attributes,
    total_points: payload.total_points
  });
};
const editAssignQuizToBook = async (payload) => {
  return await axiosInstance.patch(`book_quizzes/${payload.id}`, {
    book_page: payload.book_page
  });
};
const importQuizzesToBook = async (payload) => {
  return await axiosInstance.post(`/imports/import_quizzes`, payload);
};

const checkForCanBeEditQuiz = async (id) => {
  return await axiosInstance.get(`quizzes/${id}/can_edit_quiz`);
};
export {
  getQuizzesList,
  addQuiz,
  assignQuizToBook,
  deleteQuiz,
  getBookQuizzes,
  unassignQuizFromBook,
  getQuizDetails,
  editQuiz,
  editAssignQuizToBook,
  checkForCanBeEditQuiz,
  importQuizzesToBook
};

import { Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import DatePicker from "../../../components/DatePicker";
import Input from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import { getBooksRequest } from "../../../store/Books/actions";
import { getCountriesRequest } from "../../../store/Countries/actions";
import { addPromoCodeRequest } from "../../../store/Promocodes/actions";
import "./promocodeStyle.scss";
import { ADD_EDIT_PROMOCODE } from "./validation";

const AddPromoCodes = () => {
  const lang = useSelector((state) => state.locale.lang);
  const booksList = useSelector((state) => state.books.list);
  const countries = useSelector((state) => state.countries.list);
  const [promocodeType, setPromocodeType] = useState("normal");
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const {
    promoCodes: { name, offer, addPromocodes, expDate, books },
    buttons: { submit }
  } = messages[lang];

  const formRef = useRef();
  const dispatch = useDispatch();
  const today = new Date() + 1;

  const selectedCounty = useSelector(
    (state) => state.registeredUsers.selectedCounty
  );
  const selectedJob = useSelector(
    (state) => state.registeredUsers.selectedJob
  );
  const getInitialValues = () => {
    return {
      name: "",
      first_name: "",
      last_name: "",
      percentage: "",
      expiration_date: "",
      individualEmail: ""
    };
  };
  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
    dispatch(getCountriesRequest());
  }, []);
  const handleSubmit = async (values) => {
    let payload = {
      ...values,
      filters: {
        country_ids: selectedCounty
          ? selectedCounty?.split(",").map(Number)
          : null,
        job_ids: selectedJob
          ? selectedJob?.split(",").map(Number)
          : null
      },
      code_type: promocodeType,
      email:
        promocodeType === "individual"
          ? values.individualEmail
          : null,
      book_ids: selectedBooks,
      country_id: countryId
    };

    await dispatch(addPromoCodeRequest(payload));
  };

  const formatOptions = (element, id, label, value) => {
    return {
      id: element[id],
      label: element[label],
      value: element[value || id]
    };
  };

  const handleSelectBooksChange = (ids) => {
    const idsArray = isIndividualPromocode ? [ids] : ids;
    setSelectedBooks(idsArray);
  };

  const isIndividualPromocode = promocodeType === "individual";
  const isCountryPromocode = promocodeType === "country";
  console.log(isCountryPromocode);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {addPromocodes}
        </Typography>
      </div>
      <Formik
        initialValues={getInitialValues()}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={ADD_EDIT_PROMOCODE(lang)}
        enableReinitialize
      >
        {({ setFieldValue, touched, errors }) => {
          return (
            <Form className="form">
              <div className="promocodeForm">
                <div>
                  <CustomSelect
                    options={[
                      {
                        id: "individual",
                        value: "individual",
                        label: "individual"
                      },
                      {
                        id: "country",
                        value: "country",
                        label: "country"
                      },
                      {
                        id: "normal",
                        value: "normal",
                        label: "normal"
                      }
                    ]}
                    placeholder={"Promocode Type"}
                    name="promocodeType"
                    handleSelectChange={(typeId) => {
                      setPromocodeType(typeId);
                    }}
                    isSearchable={true}
                    isMulti={false}
                    isRequired={true}
                    isAddNoneOption={false}
                  />
                  {errors.promocodeType && (
                    <>
                      <p className="error">{errors.promocodeType} </p>
                      <br />
                    </>
                  )}
                </div>
                {isIndividualPromocode && (
                  <Input
                    name="individualEmail"
                    label={"individual email"}
                    isRequired={isIndividualPromocode}
                    isNumber={false}
                  />
                )}
                {isIndividualPromocode && (
                  <>
                    <Input
                      name="first_name"
                      label={"First Name"}
                      isRequired={true}
                    />
                    <Input
                      name="last_name"
                      label={"Last Name"}
                      isRequired={true}
                    />
                  </>
                )}
                {isCountryPromocode && (
                  <div>
                    <CustomSelect
                      options={countries.map((country) =>
                        formatOptions(country, "id", "name")
                      )}
                      placeholder={"country"}
                      name="country"
                      handleSelectChange={(selectedCountryId) => {
                        setCountryId(selectedCountryId);
                      }}
                      isSearchable={true}
                      isMulti={false}
                      isRequired={isCountryPromocode}
                      isAddNoneOption={false}
                    />
                    {errors.country && (
                      <>
                        <p className="error">{errors.country} </p>
                        <br />
                      </>
                    )}
                  </div>
                )}
                <Input name="name" label={name} isRequired={true} />
                <Input
                  name="percentage"
                  type="number"
                  label={offer}
                  isRequired={true}
                  isNumber={true}
                  max="100"
                />
                <div>
                  <DatePicker
                    placeholder={expDate}
                    setFieldValue={setFieldValue}
                    name="expiration_date"
                    minDate={new Date(today).setDate(
                      new Date(today).getDate() + 1
                    )}
                    disablePast
                  />
                  {touched.expiration_date &&
                    errors.expiration_date && (
                      <div className="error-msg mt-2">
                        {errors.expiration_date}
                      </div>
                    )}
                </div>
                <CustomSelect
                  options={booksList.map((book) =>
                    formatOptions(book, "id", "title")
                  )}
                  placeholder={books}
                  name="book"
                  handleSelectChange={handleSelectBooksChange}
                  isSearchable={true}
                  isMulti={!isIndividualPromocode}
                  isRequired={false}
                  isAddNoneOption={false}
                />
              </div>
              <div className="flex-container">
                <Button text={submit} type="submit" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddPromoCodes;

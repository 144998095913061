import * as types from "./types";

export const getSupplementariesRequest = (data) => ({
  type: types.GET_SUPPLEMENTRIES_REQUEST,
  payload: data
});

export const getSupplementariesResponse = (data) => ({
  type: types.GET_SUPPLEMENTRIES_RESPONSE,
  payload: data
});

export const deleteSupplementaryRequest = (data) => ({
  type: types.DELETE_SUPPLEMENTARY_REQUEST,
  payload: data
});

export const setSupplementariesFilters = (data) => ({
  type: types.SET_SUPPLEMENTARY_FILTERS,
  payload: data
});

export const addSupplementariesRequest = (data) => ({
  type: types.ADD_SUPPLEMENTRIES_REQUEST,
  payload: data
});

export const assignSupplementaryToBookRequest = (data) => ({
  type: types.ASSIGN_BOOK_TO_SUPPLEMENTARY_REQUEST,
  payload: data
});

export const unassignSupplementaryFromBookRequest = (data) => ({
  type: types.UNASSIGN_BOOK_FROM_SUPPLEMENTARY_REQUEST,
  payload: data
});

export const editSupplementaryRequest = (data) => ({
  type: types.EDIT_SUPPLEMENTARY_REQUEST,
  payload: data
});

export const getSingleSupplementaryRequest = (data) => ({
  type: types.GET_SINGLE_SUPPLEMENTARY_REQUEST,
  payload: data
});

export const getSingleSupplementaryResponse = (data) => ({
  type: types.GET_SINGLE_SUPPLEMENTARY_RESPONSE,
  payload: data
});

export const editBookPageForSupplementaryRequest = (data) => ({
  type: types.EDIT_BOOK_PAGE_FOR_SUPPLEMENTARY_REQUEST,
  payload: data
});

export const reassignSupplementaryToBookVersionRequest = (data) => ({
  type: types.REASSIGN_SUPPLEMENTARY_TO_BOOK_VERSION_REQUEST,
  payload: data
});

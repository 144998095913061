import { axiosInstance } from "./";

const getTests = async (params) => {
  return await axiosInstance.get(`tests`, { params });
};

const deleteTest = async (id) => {
  return await axiosInstance.delete(`tests/${id}`);
};

const addTest = async (payload) => {
  return await axiosInstance.post(`tests`, payload);
};

const editTest = async ({ data, id }) => {
  return await axiosInstance.patch(`tests/${id}`, data);
};

const getSingleTest = async (id) =>
  await axiosInstance.get(`tests/${id}`);

const getBookTests = async (payload) => {
  return await axiosInstance.get(`book_tests`, {
    params: { ...payload }
  });
};
const getSingleTestReport = async (params) => {
  return await axiosInstance.get(`test_reports`, { params });
};

const exportTestReport = async (paylod) => {
  return await axiosInstance.get(`test_reports/export_test_report`, {
    params: {
      email: paylod.email,
      test_id: paylod.id
    }
  });
};
const checkForCanBeEditTest = async (id) => {
  return await axiosInstance.get(`tests/${id}/can_edit_test`);
};
export {
  getTests,
  addTest,
  editTest,
  deleteTest,
  getSingleTest,
  getBookTests,
  getSingleTestReport,
  checkForCanBeEditTest,
  exportTestReport
};

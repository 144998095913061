import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import AdminForm from "./AdminForm";
import AdminsList from "./AdminsList";
import Button from "../../components/Button";
import AdminDelete from "./AdminDelete";
import { add } from "../../store/ActionsBtn/actions";
import messages from "../../assets/locale/messages";

const Admins = () => {
  const dispatch = useDispatch();
  const editId = useSelector((state) => state.actionsBtn.edit);
  const isAdd = useSelector((state) => state.actionsBtn.add);
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const lang = useSelector((state) => state.locale.lang);
  const list = useSelector((state) => state.admins.list);
  const selectedAdmin = _.find(list, ["id", editId]);

  const {
    admins: { title, addTitle }
  } = messages[lang];

  const handleAddAdmin = () => {
    dispatch(add(true));
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        <Button
          text={addTitle}
          icon={<AddIcon className="me-2" />}
          onClick={handleAddAdmin}
        />
      </div>
      <AdminsList lang={lang} />
      <AdminForm
        mode={editId ? "edit" : "add"}
        selectedAdmin={selectedAdmin}
        isOpen={isAdd || editId ? true : false}
        lang={lang}
      />
      <AdminDelete isOpen={!!deleteId} lang={lang} />
    </>
  );
};

export default Admins;

import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  assignQuizBtn,
  getBookQuizzes,
  unassignBookQuizAction,
  unassignQuizFromBook
} from "../../../store/Quizzes/actions";
import Table from "../../../components/Table";
import {
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  Typography
} from "@material-ui/core";
import messages from "../../../assets/locale/messages";
import Pagination from "../../../components/Pagination";
import { Form, Formik } from "formik";
import Search from "../../../components/Search";
import { IconButton } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import EmptyState from "../../../components/EmptyState";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import VisibilityIcon from "@material-ui/icons/Visibility";
import History from "../../../routes/History";
import EditIcon from "@material-ui/icons/Edit";
import DeleteModal from "../../../components/DeleteModal";

const BookQuizzes = ({ id, setEditPage }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState(null);
  const [unassignedQuiz, setUnassignedQuiz] = useState(null);
  const {
    quizzes: { bookQuiz, unassignBookQuizBtn },
    locale: { lang }
  } = useSelector((state) => state);
  const {
    quizzes: {
      quizTitle,
      pageNumber,
      quizzes,
      book_unassign,
      unassign_book_quiz_confirmation
    },
    shared: { editLabel, view }
  } = messages[lang];
  useEffect(() => {
    if (id) {
      dispatch(getBookQuizzes({ title, page, book_id: id }));
      return () => dispatch(assignQuizBtn(false));
    }
  }, [page, title, id]);
  const handlePagination = (e, page) => {
    setPage(page);
  };
  const handleSearch = (e) => {
    setTitle(e.target.value);
    dispatch(
      getBookQuizzes({ title: e.target.value, page, book_id: id })
    );
  };
  const handleUnassignQuizFromBook = (id) => {
    dispatch(unassignBookQuizAction(id));
  };
  const handleEdit = (id) => {
    dispatch(assignQuizBtn(id));
  };
  const handleUnassignBookQuiz = () => {
    dispatch(unassignQuizFromBook(unassignedQuiz));
    dispatch(unassignBookQuizAction(false));
  };
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h5" component="h4">
          {quizzes}
        </Typography>
      </div>
      <Formik initialValues={{ title: "" }} onSubmit={handleSearch}>
        {() => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <Search
                  name="title"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </Grid>
            </Grid>
            <button className="d-none"></button>
          </Form>
        )}
      </Formik>

      <Table
        headlines={[quizTitle, pageNumber]}
        hasActions={true}
        actionAlignment={"right"}
        rows={bookQuiz?.list?.map((row) => {
          return (
            <TableRow className="table__row" key={row.id}>
              <TableCell className="table__cell" align="left">
                {row?.quiz_title}
              </TableCell>
              <TableCell className="table__cell" align="left">
                {row?.book_page}
              </TableCell>
              <TableCell className="table__cell" align="right">
                <Tooltip title={view}>
                  <IconButton
                    aria-label="view"
                    onClick={() => {
                      History.push({
                        pathname: `${ROUTE_PATHS.viewQuiz}/${row.quiz_id}`,
                        state: { mode: "view" }
                      });
                    }}
                  >
                    <VisibilityIcon
                      className="table__view"
                      fontSize="medium"
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={editLabel}>
                  <IconButton
                    onClick={() => {
                      handleEdit(row.id);
                      setEditPage(row.book_page);
                    }}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"unassign"}>
                  <IconButton
                    onClick={() => {
                      handleUnassignQuizFromBook(row.id);
                      setUnassignedQuiz({
                        book_id: id,
                        id: row.id,
                        page,
                        title
                      });
                    }}
                    aria-label="view"
                  >
                    <RemoveCircleIcon
                      className="table__delete"
                      fontSize="medium"
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      />
      {!bookQuiz?.list?.length && <EmptyState />}
      {bookQuiz?.meta?.pages > 1 && (
        <Pagination
          count={bookQuiz?.meta.pages}
          page={bookQuiz?.meta.currentPage}
          handleChange={handlePagination}
        />
      )}
      <DeleteModal
        deleteMessage={unassign_book_quiz_confirmation}
        deleteTitle={book_unassign}
        isOpen={Boolean(unassignBookQuizBtn)}
        lang={lang}
        cancelCallback={() => dispatch(unassignBookQuizAction(false))}
        handleDelete={handleUnassignBookQuiz}
      />
    </div>
  );
};
BookQuizzes.propTypes = {
  id: PropTypes.boolean,
  list: PropTypes.object,
  setEditPage: PropTypes.func
};
export default BookQuizzes;

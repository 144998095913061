import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteAuthorsRequest } from "../../../store/Authors/actions";

const AuthorsDelete = ({ isOpen, lang, id, setDeleteModal }) => {
  const dispatch = useDispatch();

  const {
    authors: { deleteAdmin, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteAuthorsRequest(id));
    setDeleteModal({ id: 0, open: false });
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteAdmin}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
      handleCancel={() => setDeleteModal({ id: 0, open: false })}
    />
  );
};

AuthorsDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  id: PropTypes.number,
  setDeleteModal: PropTypes.func
};

AuthorsDelete.defaultProps = {
  isOpen: false
};

export default AuthorsDelete;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table as MTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import messages from "../../assets/locale/messages";

const Table = ({ headlines, rows, hasActions, actionAlignment }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: "none",
      borderRadius: "16px"
    },
    cell: {
      padding: hasActions ? "8px 25px" : "20px 25px",
      fontSize: "0.9rem",
      fontFamily: "Helvetica-Medium"
    },
    headlines: {
      fontSize: "1.2rem",
      fontFamily: "Helvetica-Bold",
      textTransform: "capitalize",
      padding: "20px 25px"
    },
    margin: {
      margin: theme.spacing(1)
    },
    actions: {
      textAlign: actionAlignment
    },
    delete: {
      color: theme.palette.secondary.main
    },
    edit: {
      color: theme.palette.primary.main
    },
    row: {
      "&:hover": {
        backgroundColor: theme.palette.grey[100]
      }
    }
  }));
  const classes = useStyles();

  const lang = useSelector((state) => state.locale.lang);

  const {
    cols: { actions }
  } = messages[lang];

  return (
    <TableContainer component={Paper} className={classes.root}>
      <MTable>
        <TableHead>
          <TableRow>
            {headlines.map((head, i) => (
              <TableCell
                className={clsx(classes.cell, classes.headlines)}
                align="left"
                key={i}
              >
                {head}
              </TableCell>
            ))}
            {hasActions && (
              <TableCell
                className={clsx(
                  classes.cell,
                  classes.headlines,
                  classes.actions
                )}
                align={actionAlignment}
                sx={{ textAlign: "end" }}
              >
                {actions}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </MTable>
    </TableContainer>
  );
};

Table.propTypes = {
  headlines: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.node,
  hasActions: PropTypes.bool,
  actionAlignment: PropTypes.string
};

Table.defaultProps = {
  headlines: [],
  hasActions: false,
  actionAlignment: "right"
};

export default Table;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getSingleBookRequest,
  getSingleBookResponse
} from "../../../store/Books/actions";
import AssignQuiz from "../../Quizzes/AssignQuiz";
import AssignSupplementary from "../../Supplementaries/AssignSupplementary";
import BookQuizzes from "../BookQuizzes";
import BookTests from "../BookTests";
import BookSections from "../BookSections";
import BookUnassignSupplementary from "../BookUnassignSupplementary";
import BookVersionAssignSupplementaries from "./BookVersionAssignSupplementaries";
import BookSupplementaries from "./BookSupplementaries";
import MainDetails from "./MainDetails";

const BooksDetails = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const singleBook = useSelector((state) => state.books.singleBook);
  const isReAssign = useSelector(
    (state) => state.actionsBtn.reassign
  );

  const unassignId = useSelector(
    (state) => state.actionsBtn.unassign
  );
  const edit = useSelector((state) => state.actionsBtn.edit);
  const assignQuizBtn = useSelector(
    (state) => state.quizzes.assignQuizBtn
  );
  const bookSupplementariesList = useSelector(
    (state) => state.books.supplementaries.list
  );

  const [page, setPage] = useState(null);

  useEffect(() => {
    if (params?.id) {
      dispatch(getSingleBookRequest(params.id));
    } else {
      return null;
    }
  }, [params]);

  useEffect(() => {
    return () => {
      dispatch(getSingleBookResponse(null));
    };
  }, []);

  const handleBookPage = (page) => {
    setPage(page);
  };

  const [editPage, setEditPage] = useState(null);

  return (
    <>
      {singleBook && (
        <MainDetails lang={lang} singleBook={singleBook} />
      )}
      <BookSupplementaries
        list={bookSupplementariesList}
        lang={lang}
        bookId={params?.id}
        handleBookPage={handleBookPage}
      />
      <BookUnassignSupplementary
        isOpen={!!unassignId}
        lang={lang}
        bookId={params?.id}
      />
      <AssignSupplementary
        isOpen={!!edit}
        lang={lang}
        page={page}
        singleBook={singleBook}
      />
      <BookQuizzes
        id={params?.id}
        singleBook={singleBook}
        setEditPage={setEditPage}
      />
      <AssignQuiz
        page={editPage}
        isOpen={Boolean(assignQuizBtn)}
        singleBook={singleBook}
      />
      <BookVersionAssignSupplementaries
        isOpen={!!isReAssign}
        lang={lang}
        singleBook={singleBook}
      />

      <BookSections />
      <BookTests bookId={params?.id} />
    </>
  );
};

export default BooksDetails;

import { axiosInstance } from "./";

const getAllFinalTests = async (params) => {
  return await axiosInstance.get(`final_tests`, {
    params
  });
};
const setNewFinalTest = async (params) => {
  return await axiosInstance.post(`final_tests`, params);
};
const getSingleFinalTest = async (id) => {
  return await axiosInstance.get(`final_tests/${id}`);
};
const editSingleFinalTest = async (id, final_test) => {
  return await axiosInstance.put(`final_tests/${id}`, { final_test });
};
const deleteSingleFinalTest = async (id) => {
  return await axiosInstance.delete(`final_tests/${id}`);
};

export {
  getAllFinalTests,
  getSingleFinalTest,
  setNewFinalTest,
  editSingleFinalTest,
  deleteSingleFinalTest
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Tooltip
} from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import {
  getSingleBookRequest,
  getSingleBookResponse
} from "../../../store/Books/actions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import Filters from "../../Supplementaries/Filters";
import EditIcon from "@material-ui/icons/Edit";
import { getBookSupplementraiesRequest } from "../../../store/Books/actions";
import {
  edit,
  unassignAction,
  reassignAction
} from "../../../store/ActionsBtn/actions";
import History from "../../../routes/History";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import SortSupplementaries from "./SortSupplementaries";

const BookSupplementaries = ({
  lang,
  list,
  bookId,
  handleBookPage
}) => {
  const dispatch = useDispatch();
  const [openSortModal, setOpenSortModal] = useState(false);
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector(
    (state) => state.books.supplementaries.meta
  );
  const searchKeyword = useSelector(
    (state) => state.supplementaries.searchKeyword
  );
  const selectedType = useSelector(
    (state) => state.supplementaries.selectedType
  );
  const singleBook = useSelector((state) => state.books.singleBook);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getBookSupplementraiesRequest({
        material_type: selectedType,
        title: searchKeyword,
        page: page,
        items: perPage,
        book_id: bookId
      })
    );
  };

  const handleUnassign = (id) => {
    dispatch(unassignAction(id));
  };

  const handleEdit = (id, book_page) => {
    dispatch(edit(id));
    handleBookPage(book_page);
  };

  const handleReAssign = (id) => {
    dispatch(reassignAction(id));
  };

  useEffect(() => {
    if (bookId) {
      dispatch(getSingleBookRequest(bookId));
    }
  }, [bookId]);

  useEffect(() => {
    return () => {
      dispatch(getSingleBookResponse(null));
    };
  }, []);

  const {
    supplementaries: {
      materialType,
      materialTitle,
      title,
      pageNumber,
      reAssign
    },
    shared: { view, unassign, editLabel }
  } = messages[lang];

  return (
    <div className="my-5">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h5" component="h4">
          {title}
        </Typography>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpenSortModal(true);
          }}
        >
          <SortIcon />
          <span className="mx-2">sort</span>
        </div>
      </div>
      <SortSupplementaries
        isOpen={openSortModal}
        setOpenSortModal={setOpenSortModal}
      />

      <Filters bookId={bookId} />
      <Table
        headlines={[materialTitle, materialType, pageNumber]}
        hasActions={true}
        rows={list?.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.material_title}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.material_type}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.book_page}
            </TableCell>

            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              {singleBook?.has_newer_version && (
                <Tooltip title={reAssign}>
                  <IconButton
                    onClick={() => handleReAssign(row.id)}
                    aria-label="view"
                  >
                    <MenuBookIcon
                      className="table__assign"
                      fontSize="medium"
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={view}>
                <IconButton
                  onClick={() => {
                    History.push(
                      `${ROUTE_PATHS.supplementaryDetails}/${row.material_id}`
                    );
                  }}
                  aria-label="view"
                >
                  <VisibilityIcon
                    className="table__view"
                    fontSize="medium"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={editLabel}>
                <IconButton
                  onClick={() => handleEdit(row.id, row.book_page)}
                  aria-label="edit"
                >
                  <EditIcon
                    className="table__edit"
                    fontSize="medium"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={unassign}>
                <IconButton
                  onClick={() => handleUnassign(row.id)}
                  aria-label="view"
                >
                  <RemoveCircleIcon
                    className="table__delete"
                    fontSize="medium"
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </div>
  );
};

BookSupplementaries.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  bookId: PropTypes.string,
  handleBookPage: PropTypes.func
};

export default BookSupplementaries;

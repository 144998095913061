const en = {
  finalTest: {
    FinalTest: "Final Test",
    finalTestTitle: "Final Test Title",
    addFinalTest: "Add Final Test",
    editFinalTest: "edit final test",
    questionTitle: "Question Title",
    answer: "Answer",
    addAQuestion: "Add a question",
    removeQuestion: "Remove question",
    viewFinalTest: "Final Test Details",
    answerText: "Answer",
    uploadImageLabel: "Upload image",
    explanation: "Explanation",
    questionPoints: "Question points",
    addFinalTestSuccess: "Final Test added successfully",
    editFinalTestSuccess: "Final Test edited successfully",
    deleteFinalTestSuccess: "Final Test deleted successfully",
    deleteMessage: "Are you sure you want to delete this Final Test?",
    deleteFinalTest: "Delete Final Test",
    answersOptions: "Answer options : ",
    answerIsRequired: "Answer is required",
    questionTitleRequired: "Question title is required",
    questionPointseRequired: "Points is required",
    questionPointseMin: "Point must be greater than 0",
    pointsInteger: "Point must have 1 decimal number",
    imageRequired: "Image is required",
    finalTestBookIsRequired: "Book is required",
    book: "Book",
    notes: "Notes",
    totalScore: "Total Score",
    noteHint:
      "*Add your note to be displayed to the users on the final test",
    maxNote: "Maximum is 800 characters",
    headerFinalTest: {
      titleFinalTest: "Final Test Title",
      book: "Book",
      reports: "User reports"
    }
  },
  header: {
    title: "Medblend admin dashboard"
  },
  shared: {
    noSearch: "No search results found!",
    emptyStateMsg: "No results found!",
    videoPreview: "Video Preview",
    imagePreview: "Image Preview",
    none: "None",
    dicomPreview: "DICOM Preview",
    view: "View",
    search: "Search",
    unassign: "Unassign",
    deleteLabel: "Delete",
    editLabel: "Edit",
    assign: "Assign",
    upload: "Upload"
  },
  dashboard: { title: "Dashboard" },
  programs: {
    title: "Programs",
    addTitle: "Add Program",
    editTitle: "Edit Program",
    deleteProgramSuccess: "program deleted successfully",
    addProgramSuccess: "program added successfully",
    programNameLabel: "program Name",
    deleteMessage: "Are you sure you want to delete this Program?",
    removeUserMessage: "Are you sure you want to remove this user?",
    deleteTitle: "Delete Program",
    editLabel: "Edit Program",
    usersNumber: "Users Number",
    assignDirector: "Assign Director",
    assignBooks: "Assign Books",
    assignUsers: "Assign Users",
    editUsers: "Edit Users",
    assignDirectorSuccess: "Director Assigned Successfully",
    assignUsersSuccess: "Users Assigned Successfully",
    assignBooksSuccess: "Books Assigned Successfully",
    removeUserSuccess: "User removed successfully"
  },
  directors: {
    title: "Directors",
    addTitle: "Add Director",
    deleteDirectorSuccess: "director deleted successfully",
    addDirectorSuccess: "director added successfully",
    directorNameLabel: "Director Name",
    directorEmailLabel: "Director Email",
    deleteMessage: "Are you sure you want to delete this Director?",
    deleteTitle: "Delete Director"
  },
  books: {
    title: "Books",
    book: "Book",
    addBook: "Add Book",
    editBook: "Edit Book",
    bookTitle: "Book title",
    bookCategory: "Book category",
    bookAuthor: "Book Author",
    bookIsbn: "Book ISBN",
    bookVersion: "Book Version",
    bookPrice: "Book Price",
    bookBrief: "Book Brief",
    bookOlderVersion: "Book Older Version",
    bookEdition: "Book Edition",
    isBookFeatured: "Is Book Featured",
    bookReleaseDate: "Book Release Date",
    submissionDate: "Submission Date",
    bookCover: "Book Cover",
    publishStatus: "Publish status",
    booksCoverDimensions:
      "Best dimensions for book cover image are 128*200",
    uploadBookCover: "Upload Book Cover",
    uploadBookPdf: "Upload Book pdf",
    uploadFontMatterPdf: "Upload Font Matter pdf",
    uploadFacultyPdf: "Upload Faculty pdf",
    bookTitleRequired: "Book title is required.",
    bookAuthorRequired: "Book author is required.",
    bookCategoryRequired: "Book category is required.",
    bookPriceRequired: "Book price is required.",
    bookVersionRequired: "Book version is required.",
    bookIsbnRequired: "Book ISBN is required.",
    bookIsbnPattern:
      "Please enter valid ISBN. Code length must be 10 or 13 number and valid format. ex.: XXX-X-XXX-XXXXX-X or X-XXX-XXXXX-X",
    bookCoverRequired: "Book cover is required.",
    bookBriefRequired: "Book brief is required.",
    bookBriefLength: "Book brief limit is 800 character.",
    credtTypeRequired: "Choose credit type first",
    creditsRequired: "Credits are required for this type.",
    bookPdfRequired: "Book PDF is required.",
    by: "By",
    pages: "Pages",
    toRead: "To read",
    breif: "Brief",
    dollar: "$",
    hour: "h",
    certificate: "Certificate",
    isbn: "ISBN",
    version: "Version",
    previous: "Previous",
    edition: "Edition",
    deleteBook: "Delete Book",
    deleteMessage: "Are you sure you want to delete this Book?",
    deleteNotAllowedMsg: "Can't delete this book",
    editNotAllowedMsg: "Can't edit this book",
    deleteBookSuccess: "Book deleted successfully",
    addBookSuccess: "Book created successfully",
    editBookSuccess: "Book updated successfully",
    bookTitleLength: "Maximum Book title length is 127 characters",
    quickAccess: "Quick access to the",
    website: "website"
  },
  survey: {
    singleSelect: "Single select",
    multiSelect: "Multi-Select"
  },
  certificate: {
    certificateCredits: "Credits",
    certificateType: "Credit type",
    certificateTitle: "Certificate title",
    certificateDescription: "Certificate description",
    certificateSectionTitle:
      "CME Certificate (upon book completion): Optional",
    hintCertificate:
      "To reflect the certificate for users, you have to add a survey on that book",
    hintCertificateCredit:
      "If the Credit Type is MEDBLEND, Credit Hours are not required.",
    certificateTitleRequired: "Certificate title is required.",
    certificateCreditsRequired: "Certificate credits is required.",
    maxCertificateDescription:
      "Description has 142 as max characters",
    maxCertificateTitle: "Title has 18 as max characters"
  },
  admins: {
    title: "Admins",
    addTitle: "Add Admin",
    editTitle: "Edit Admin",
    deleteAdmin: "Delete Admin",
    adminNameLabel: "Admin Name",
    adminEmailLabel: "Admin Email",
    deleteMessage: "Are you sure you want to delete this Admin?",
    adminNamePlaceholder: "Admin Name",
    addAdminSuccess: "Admin created successfully",
    editAdminSuccess: "Admin updated successfully",
    deleteAdminSuccess: "Admin deleted successfully",
    forgotPasswordSuccess: "Reset password link sent to your email",
    deleteNotAllowedMsg: "Can't delete this admin"
  },
  authors: {
    title: "Authors",
    addTitle: "Add Authors",
    authorNameLabel: "Author Name",
    authorEmailLabel: "Author Email",
    editAuthorSuccess: "author has been edited successfully",
    addAuthorSuccess: "author has been added successfully",
    deleteAuthorSuccess: "author has been deleted successfully"
  },
  editors: {
    title: "Editors",
    addTitle: "Add Editors",
    editorNameLabel: "Editor Name",
    editorEmailLabel: "Editor Email",
    editEditorSuccess: "editor has been edited successfully",
    addEditorSuccess: "editor has been added successfully",
    deleteEditorSuccess: "editor has been deleted successfully"
  },
  cols: {
    name: "name",
    email: "email",
    actions: "actions"
  },
  categories: {
    title: "Categories",
    addTitle: "Add Category",
    editTitle: "Edit Category",
    categoryNameLabel: "Category Name",
    validation: {
      categoryNameRequired: "Category Name is Required."
    },
    deleteCategory: "Delete Category",
    deleteMessage: "Are you sure you want to delete this Category?",
    deleteCategorySuccess: "Category deleted successfully",
    addCategorySuccess: "Category created successfully",
    editCategorySuccess: "Category updated successfully"
  },
  inputs: {
    remeberMe: "Remember me",
    emailAddress: "Email address",
    password: "Password",
    oldPassword: "Old password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    emailRequired: "Email is required.",
    passwordRequired: "Password is required.",
    oldPasswordRequired: "Old password is required",
    passwordConfirmationRequired: "Confirm passowrd is required",
    passwordMatch: "Passwords must match"
  },
  buttons: {
    deleteBtn: "Delete",
    signIn: "Sign In",
    submit: "submit",
    resetPassword: "Reset my password",
    updatePassword: "Update my password",
    logout: "Logout",
    changePassword: "Change password",
    createPassword: "Create password"
  },
  auth: {
    show: "Show",
    hide: "Hide",
    copyRights: "© Copyright medblendedu 2023",
    login: {
      title:
        "Please enter your email address and password to access your account"
    },
    forgotPassword: {
      link: "Forgot Password?",
      title: "Forgot your password?",
      subTitle:
        "Enter the email address associated with your account",
      rememberPassword: "Remember your password?",
      forgotPasswordSuccess: "Reset password link sent to your email"
    },
    changePassword: {
      title: "Change your password",
      changePasswordSuccess: "Password updated successfully"
    },
    createPassword: {
      title: "Create your password",
      createPasswordSuccess: "Password created successfully"
    }
  },
  supplementaries: {
    title: "Supplementaries",
    titleLabel: "Supplementary Title",
    descriptionLabel: "Supplementary Description",
    diagnosisLabel: "Supplementary Diagnosis",
    addSupplementary: "Add Supplementary",
    editSupplementary: "Edit Supplementary",
    deleteSupplementary: "Delete Supplementary",
    materialTitle: "Material title",
    materialType: "Material type",
    assignBooks: "Assign to books",
    pageNumber: "Page number",
    selectTypeHint: "Select supplementary type you want to upload.",
    validation: {
      titleRequired: "Supplementary title Name is Required.",
      fileRequired: "Supplementary file is Required.",
      imageFormat: "Accepted formats are (tiff, png, jpg, jpeg)",
      bookRequired: "Book is required",
      pageRequired: "Book page is required",
      validPage:
        "Please enter a valid page. number of pages for selected book is"
    },
    uploadSupplementary: "Upload Supplementary",
    deleteMessage:
      "Are you sure you want to delete this supplementary?",
    deleteSupplementarySuccess: "Supplementary deleted successfully",
    assignToBook: "Assign supplementary to book.",
    assignQuizzesToBook: "Assign Quiz to book.",
    editAssignedPage: "Edit assigned page",
    bookPageLabel: "Book page",
    addSupplementarySuccess: "Supplementary created successfully",
    assignSupplementarySuccess: "Supplementary assigned successfully",
    unassignSupplementarySuccess:
      "Supplementary unassigned successfully",
    unassignMessage:
      "Are you sure you want to unassign this supplementary?",
    unassignFromBook: "Unassign supplementary",
    editSupplementarySuccess: "Supplementary updated successfully",

    reassignSupplementarySuccess:
      "Supplementary reassigned successfully",
    reAssignedPage: "Re-assign to the new book version",
    assignedBooks: "Assigned books",
    reAssign: "Re-assign to the new book version"
  },
  institute: {
    title: "Institute",
    instituteDetails: "Institute Details",
    phoneNumber: "Phone Number",
    titleLabel: "Institution Title",
    descriptionLabel: "Institution Description",
    diagnosisLabel: "Institution Diagnosis",
    addInstitution: "Add Institution",
    editInstitution: "Edit Institution",
    deleteInstitution: "Delete Institution",
    ipFrom: "IP Address from",
    ipTo: "IP Address to",
    startDate: "Start Date",
    name: "Name",
    endDate: "End Date",
    deleteMessage:
      "Are you sure you want to delete this institution?, All subscribers belongs to this institution won’t have access to the website anymore",
    instituteName: "Institution Name",
    pageNumber: "Page number",
    selectTypeHint: "Select institute type you want to upload.",
    validation: {
      invalidPhone: "Please enter valid numbers",
      oneEmailAtLeast: "Please enter at least one email",
      ivalidEmail: "Please enter a valid email",
      ipRequired: "IP is required",
      titleRequired: "Institute name is Required.",
      oneIpAtLeast: "Please insert at least one IP range",
      fileRequired: "Institute file is Required.",
      dateIsRequired: "Date is required",
      invalidIP: "IP is invalid",
      imageFormat: "Accepted formats are (tiff, png, jpg, jpeg)",
      bookRequired: "Book is required",
      pageRequired: "Book page is required",
      validPage:
        "Please enter a valid page. number of pages for selected book is"
    },
    uploadInstitution: "Upload Institution",
    deleteInstitutionSuccess: "Institution deleted successfully",
    assignToBook: "Assign institute to book.",
    assignQuizzesToBook: "Assign Quiz to book.",
    editAssignedPage: "Edit assigned page",
    bookPageLabel: "Book page",
    addInstitutionSuccess: "Institution created successfully",
    assignInstitutionSuccess: "Institution assigned successfully",
    unassignInstitutionSuccess: "Institution unassigned successfully",
    unassignMessage:
      "Are you sure you want to unassign this institute?",
    unassignFromBook: "Unassign institute",
    editInstitutionSuccess: "Institution updated successfully",
    assignedBooks: "Assigned books"
  },
  regiesteredUSer: {
    title: "The registered users : ",
    name: "Name",
    email: "Email",
    country: "Country",
    job: "Job"
  },
  promoCodes: {
    title: "Promocodes",
    addPromocodes: "Add promocode",
    name: "Name",
    offer: "Offer provided %",
    job: "Job",
    expDate: "Expiration date",
    dateRequired: "Expiration date is required",
    promocodeTypeRequired: "Promocode type is required",
    individualEmailRequired: "Individual email is required",
    nameRequired: "Name is required",
    firstNameRequired: "First Name is required",
    lastNameRequired: "Last Name is required",
    offerRequired: "Precentage of the offer is required",
    jobCountryRequired:
      "You must choose country or job to get the user list",
    addMessageSuccess: "PromoCode added successfully",
    addMessageError: "Something went wrong",
    descriptionLabel: "Promocode details",
    deletePromocode: "Delete promocode",
    deleteMessage: "Are you sure you want to delete this Promocode?",
    deletePromocodeSuccess: "Promocode deleted successfully",
    listRegistered: "List of the users",
    books: "Books"
  },
  quizzes: {
    title: "Quizzes",
    quizTitle: "Quiz title",
    pageNumber: "page number",
    addQuiz: "Add Quiz",
    editQuiz: "Edit Quiz",
    quizzes: "Quizzes",
    delete_quiz: "Delete Quiz",
    delete_confirmation: "Are you sure you want to delete this Quiz?",
    book_unassign: "Unassign quiz from book",
    unassign_book_quiz_confirmation:
      "Are you sure you want to unassign this Quiz?",
    titleLabel: "Quiz Title",
    statitics: "Statitics",
    quizDetails: "Quiz Details",
    questionType: "Question Type",
    diagnosisLabel: "Quiz Diagnosis",
    questionTitle: "Question Title",
    answer: "Answer",
    addAQuestion: "Add a question",
    removeQuestion: "Remove question",
    quizTitleIsRequired: "Quiz title is required",
    quizBookIsRequired: "Book is required",
    answerIsRequired: "Answer is required",
    questionTitleRequired: "Question title is required",
    max60Char: "Maximum 60 characters allowed",
    oneQuestionAtleast: "At least one question is required",
    deleteQuiz: "Delete Quiz",
    bookRelated: "Book related",
    assignBooks: "Assign to books",
    selectTypeHint: "Select quiz type you want to upload.",
    quizzeAnswered: "The quiz is already answered",
    validation: {
      titleRequired: "Quiz title Name is Required.",
      fileRequired: "Quiz file is Required.",
      imageFormat: "Accepted formats are (tiff, png, jpg, jpeg)",
      bookRequired: "Book is required",
      pageRequired: "Book page is required",
      validPage:
        "Please enter a valid page. number of pages for selected book is"
    },
    uploadQuiz: "Upload Quiz",
    deleteMessage: "Are you sure you want to delete this quiz?",
    deleteQuizSuccess: "Quiz deleted successfully",
    assignToBook: "Assign quiz to book.",
    editAssignedPage: "Edit assigned page",
    bookPageLabel: "Book page",
    addQuizSuccess: "Quiz created successfully",
    assignQuizSuccess: "Quiz assigned successfully",
    unassignQuizSuccess: "Quiz unassigned successfully",
    unassignMessage: "Are you sure you want to unassign this quiz?",
    unassignFromBook: "Unassign quiz",
    editQuizSuccess: "Quiz updated successfully",
    assignedBooks: "Assigned books",
    uploadImageLabel: "Upload image",
    upload: "upload",
    answersOptions: "Answer options : ",
    imageRequired: "Image is required",
    filterByBook: "Filter By Book"
  },
  surveys: {
    title: "Surveys",
    titleLabel: "Survey Title",
    statitics: "Statitics",
    surveyDetails: "Survey Details",
    questionType: "Question Type",
    diagnosisLabel: "Survey Diagnosis",
    questionTitle: "Question Title",
    surveyAnswered: "The survey is answered by users",
    answer: "Answer",
    addAQuestion: "Add a question",
    removeQuestion: "Remove question",
    surveyTitleIsRequired: "Survey title is required",
    surveyBookIsRequired: "Book is required",
    answerIsRequired: "Answer is required",
    questionTitleRequired: "Question title is required",
    addSurvey: "Add Survey",
    max60Char: "Maximum 60 characters allowed",
    editSurvey: "Edit Survey",
    deleteSurvey: "Delete Survey",
    surveyTitle: "Survey title",
    bookRelated: "Book related",
    assignBooks: "Assign to books",
    pageNumber: "Page number",
    selectTypeHint: "Select survey type you want to upload.",
    validation: {
      titleRequired: "Survey title Name is Required.",
      fileRequired: "Survey file is Required.",
      imageFormat: "Accepted formats are (tiff, png, jpg, jpeg)",
      bookRequired: "Book is required",
      pageRequired: "Book page is required",
      validPage:
        "Please enter a valid page. number of pages for selected book is"
    },
    uploadSurvey: "Upload Survey",
    deleteMessage:
      "All statistics related to that survey will be deleted as well , Are you sure you want to delete this survey?",
    deleteSurveySuccess: "Survey deleted successfully",
    assignToBook: "Assign survey to book.",
    editAssignedPage: "Edit assigned page",
    bookPageLabel: "Book page",
    addSurveySuccess: "Survey created successfully",
    assignSurveySuccess: "Survey assigned successfully",
    unassignSurveySuccess: "Survey unassigned successfully",
    unassignMessage: "Are you sure you want to unassign this survey?",
    unassignFromBook: "Unassign survey",
    editSurveySuccess: "Survey updated successfully",
    assignedBooks: "Assigned books",
    userEmailSuccess:
      "An Email will be sent to you shortly with the survey statics report"
  },

  preTestPostTest: {
    title: "Test",
    addTest: "Add Test",
    editTest: "Edit Test",
    viewTest: "Test Details",
    answerText: "Answer",
    removeQuestion: "Remove question",
    testTitleIsRequired: "Test title is required",
    testBookIsRequired: "Book is required",
    answerIsRequired: "Answer is required",
    questionTitleRequired: "Question title is required",
    prePageRequired: "Pre-page is required",
    postPageRequired: "Post-page is required",
    pointsInteger: "Point must have 1 decimal number",
    questionPointseMin: "Point must be greater than 0",
    questionPointseRequired: "Points is required",
    imageRequired: "Image is required",
    rightAnswerIsRequired: "Right answer is required",
    justificationIsRequired: "Explanation is required",
    isMandatoryIsRequired: "Choose test is mandatory or not",
    testAnswered: "The test is answered by users",
    book: "Book",
    prePage: "Pre-page",
    postPage: "Post-page",
    totalPoints: "Total points",
    questionLabel: "Questions in the test",
    selectTestTypeLabel:
      "Choose if the test is mandatory or optional",
    mandatory: "Mandatory",
    answersOptions: "Answer options : ",
    rightAnswer: "This is the right answer",
    optional: "Optional",
    materialType: "Material type",
    upload: "upload",
    addQuestion: "Add question",
    editQuestion: "Add question",
    titleLabel: "Test title",
    questionTitle: "Question Title",
    addAQuestion: "Add a question",
    uploadImageLabel: "Upload image",
    explanation: "Explanation",
    questionPoints: "Question points",
    addTestSuccess: "Test added successfully",
    editTestSuccess: "Test edited successfully",
    deleteTestSuccess: "Test deleted successfully",
    deletePreTestPostTest: "Delete Test",
    deleteMessage:
      "All user reports related to that test will be deleted by deleting the test, Are you sure you want to delete it ?",
    assignToBook: "Assign to book",
    userAnswer: "The answer of user ",
    userNotAnswer: "User didn't answer yet",
    reports: "User reports",
    bookPageLabel: "Page",
    addSection: "Add Section",
    noSection: "No Section Added",
    noOption: "No Option",
    sectionName: "Section Name",

    notes: "Notes",
    noteHint:
      "*Add your note to be displayed to the users on the pre & post test",
    maxNote: "Maximum is 800 characters",
    none: "None",
    report: {
      title: "User reports",
      id: "ID",
      pretestScore: "Pre-Test score",
      postTestScore: "Post-Test score",
      change: "% Change",
      meanChange: "Mean % Change",
      sectionName: "Section Name",
      averagePreScore: "Average Pre-Test Score",
      averagePostScore: "Average Post-Test Score",
      exportReport: "Export Report",
      toRecieveReport:
        "To recieve the report ,please enter your email",
      email: "Email",
      emailRequired: "User email is required",
      userEmail: "User Email",
      userEmailRequired: "User Email is required",
      userEmailSuccess:
        "An Email will be sent to you shortly with the orders report",
      averagePreTestScore: "average Pre-Test Score",
      averagePostTestScore: "average Post-Test Score",
      remainingStatisticsSection: "Other questions "
    },
    validation: {
      bookRequired: "Book is required",
      pageRequired: "Page is required",
      validPage: "Page number is not valid"
    }
  },
  section: {
    title: "Sections",
    addSection: "Add section",
    prePage: "Pre-Page",
    postPage: "Post-Page",
    from: "Form",
    to: "To",
    name: "Name",
    editSection: "Edit Section",
    fromPageRequired: "From-Page is Required",
    toPageRequired: "To-Page is Required",
    addSectionSuccess: "Section added successfully",
    editSectionSuccess: "Section edited successfully",

    deleteSection: "Delete Section",
    deleteMessage: " Are you sure you want to delete it ?",
    delteSectionSuccess: "Section deleted successfully",
    fieldNumber: "Field must include only numbers",
    maxSectionName: "Maxmimum is 100 characters",
    inRangRequired: "Must be in the range of Book Page",
    lowerThan: "number must be lower than",
    duplicatedName: "Name must be unique",
    nameTaken: "Name is already taken",
    rangeUnique: "Range must be unique",
    rangeoverlaped: "Range overlaped with other section"
  }
};
export default en;

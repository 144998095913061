import { call, put } from "redux-saga/effects";
import * as apis from "../../network/apis/users";
import { addUsersResponse } from "./actions";

export function* getUsersSaga({ payload }) {
  try {
    const response = yield call(apis.getUsers, payload);
    yield put(
      addUsersResponse({
        users: response.data.data.users,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

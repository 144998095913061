import * as Yup from "yup";
import messages from "../../../assets/locale/messages";
import { BOOK_ISBN } from "../../../utils/Patterns";

export const ADD_BOOK_SCHEMA = (lang) => {
  const {
    books: {
      bookTitleRequired,
      bookAuthorRequired,
      bookCategoryRequired,
      bookPriceRequired,
      bookVersionRequired,
      bookIsbnRequired,
      bookIsbnPattern,
      // bookCoverRequired,
      bookBriefRequired,
      bookBriefLength,
      // bookPdfRequired,
      credtTypeRequired,
      creditsRequired,
      bookTitleLength
    },
    certificate: {
      certificateTitleRequired,
      maxCertificateDescription,
      maxCertificateTitle
    }
  } = messages[lang];
  return Yup.object()
    .shape(
      {
        title: Yup.string()
          .max(127, bookTitleLength)
          .required(bookTitleRequired),
        author: Yup.string().required(bookAuthorRequired),
        category_id: Yup.string().required(bookCategoryRequired),
        old_version_id: Yup.string().default(""),
        price: Yup.number().min(1).required(bookPriceRequired),
        book_version: Yup.number()
          .required(bookVersionRequired)
          .min(1),
        edition: Yup.number().min(1),
        isbn: Yup.string()
          .required(bookIsbnRequired)
          .matches(BOOK_ISBN, bookIsbnPattern),
        // cover_photo: Yup.mixed().required(bookCoverRequired),
        brief: Yup.string()
          .required(bookBriefRequired)
          .max(800, bookBriefLength),
        // pdf: Yup.mixed().required(bookPdfRequired),
        certificate_description: Yup.string()
          .optional()
          .max(143, maxCertificateDescription),
        certificate_title: Yup.string()
          .max(18, maxCertificateTitle)
          .when(["credits", "certificate_description"], {
            is: (credits, certificate_description) =>
              credits || certificate_description,
            then: Yup.string().required(certificateTitleRequired)
          }),
        credit_type: Yup.string().required(credtTypeRequired),
        credits: Yup.string()
          .nullable()
          .when("credit_type", {
            is: (value) => value !== "MEDBLEND",
            then: Yup.string().required(creditsRequired),
            otherwise: Yup.string().nullable()
          }),
        submission_date: Yup.string().when("credit_type", {
          is: (value) => value !== "MEDBLEND",
          then: Yup.string().required("Submission Date Is Required"),
          otherwise: Yup.string().nullable()
        }),
        published: Yup.boolean(),
        publish_authors: Yup.boolean(),
        publish_students: Yup.boolean(),
        publish_authors_publish_students: Yup.string()
      },
      [["certificate_title", "credits", "certificate_description"]]
    )
    .test(
      "PublishState",
      "At least one option must be selected.",
      (values, testContext) => {
        const { published, publish_authors, publish_students } =
          values;
        if (!published) {
          return true;
        } else if (
          published &&
          (publish_authors || publish_students)
        ) {
          return true;
        } else
          return testContext.createError({
            message: "At least one option must be selected.",
            path: "publish_authors_publish_students"
          });
      }
    );
};

export const getInitialValues = (book) => {
  return {
    title: book?.title || "",
    author: book?.author || "",
    category_id: book?.category?.id || "",
    price: book?.price || "",
    book_version: book?.book_version || "",
    old_version_id: book?.old_version_book?.id || "",
    edition: book?.edition || "",
    isbn: book?.isbn || "",
    cover_photo: book?.cover_photo || null,
    brief: book?.brief || "",
    senior_editors:
      book?.senior_editors?.length === (0 || undefined)
        ? [
            {
              name: "",
              title: "",
              photo: null
            }
          ]
        : book?.senior_editors,
    front_matter: book?.front_matter || null,
    faculty: book?.faculty || null,
    number_of_authors: book?.number_of_authors || "",
    number_of_speakers: book?.number_of_speakers || "",
    release_date: book?.release_date || null,
    pdf: book?.pdf || null,
    is_featured: book?.is_featured || false,
    published:
      book?.publish_state === undefined
        ? // book?.publish_state !== "not_published" ||
          false
        : book?.publish_state !== null,
    publish_authors: book?.publish_state?.includes("authors"),
    publish_students: book?.publish_state?.includes("students"),
    certificate_title: book?.certificate_title || "",
    credits: book?.credits || "",
    certificate_description: book?.certificate_description || "",
    submission_date: book?.submission_date || "",
    credit_type: book?.credit_type || "MEDBLEND"
  };
};

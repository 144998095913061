import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import { add } from "../../../store/ActionsBtn/actions";
import {
  getBookSectionsRequest,
  getBookSectionsResponse
} from "../../../store/Books/actions";

import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import SectionList from "./SectionList";
import DeleteSection from "./DeleteSection";
import AddSection from "./AddSection";

const BookSections = () => {
  const dispatch = useDispatch();
  let params = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const deleteId = useSelector(
    (state) => state.actionsBtn.deleteSection
  );

  const sectionsInBookOptions = useSelector(
    (state) => state.books.sectionsInBookOptions
  );

  const isAdd = useSelector((state) => state.actionsBtn.add);

  const {
    section: { title, addSection }
  } = messages[lang];

  const handleAddSection = (id) => {
    dispatch(add(id));
  };
  const {
    quizzes: { bookQuiz }
  } = useSelector((state) => state);

  let search = window.location.search;
  let paramSearch = new URLSearchParams(search);
  let test = paramSearch.get("test");

  let sectionView = useRef(null);
  useEffect(() => {
    if (test === "true" && sectionView.current)
      sectionView.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });

    dispatch(
      getBookSectionsRequest({
        page: 1,
        items: 6,
        bookId: params?.id
      })
    );
  }, [bookQuiz]);
  useEffect(() => {
    return () => {
      dispatch(getBookSectionsResponse({ list: [], meta: null }));
    };
  }, []);
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between mt-4 mb-4"
        ref={sectionView}
      >
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3} className="mb-2">
        <Grid
          item
          xs={12}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addSection}
            icon={<AddIcon className="me-2" />}
            onClick={() => handleAddSection(params.id)}
          />
          <AddSection isOpen={!!isAdd} book_id={params.id} />
        </Grid>
      </Grid>
      <div>
        <SectionList list={sectionsInBookOptions} lang={lang} />
      </div>
      <DeleteSection
        isOpen={!!deleteId}
        lang={lang}
        book_id={params?.id}
      />
    </>
  );
};

export default BookSections;

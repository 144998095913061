import { axiosInstance } from "./";

const getBooks = async (params) => {
  return await axiosInstance.get(`books`, { params });
};

const deleteBook = async (id) => {
  return await axiosInstance.delete(`books/${id}`);
};

const addBook = async (payload) => {
  return await axiosInstance.post(`books`, payload);
};

const editBook = async ({ data, id }) => {
  return await axiosInstance.patch(`books/${id}`, data);
};

const getSingleBook = async (id) =>
  await axiosInstance.get(`books/${id}`);

const getBooksInCategory = async (id) =>
  await axiosInstance.get(`categories/${id}/view_books`);

const getBookSupplementries = async (params) => {
  return await axiosInstance.get(`book_supplementary_materials`, {
    params
  });
};

const getBookSections = async (payload) => {
  return await axiosInstance.get(`books/${payload.bookId}/sections`, {
    params: {
      from: payload.prePage,
      to: payload.postPage,
      page: payload.page,
      items: payload.items
    }
  });
};

const addSection = async (payload) => {
  return await axiosInstance.post(
    `books/${payload.bookId}/sections`,
    {
      name: payload.name,
      from: payload.from,
      to: payload.to
    }
  );
};

const editSection = async (payload) => {
  return await axiosInstance.patch(
    `books/${payload.bookId}/sections/${payload.id}`,
    {
      name: payload.name,
      from: payload.from,
      to: payload.to
    }
  );
};

const deleteSection = async ({ id, bookId }) => {
  return await axiosInstance.delete(`books/${bookId}/sections/${id}`);
};
export {
  getBooks,
  addBook,
  editBook,
  deleteBook,
  getSingleBook,
  getBooksInCategory,
  getBookSupplementries,
  getBookSections,
  addSection,
  editSection,
  deleteSection
};

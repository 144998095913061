import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import DeleteModal from "../../components/DeleteModal";
import { deleteInstitutionRequest } from "../../store/Institutions/actions";
import { deleteAction } from "../../store/ActionsBtn/actions";

const InstitutionDelete = ({ isOpen }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const { messages, locale } = useIntl();

  const {
    institute: { deleteInstitution, deleteMessage }
  } = messages;

  const handleDelete = () => {
    dispatch(deleteInstitutionRequest(deleteId));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteInstitution}
      isOpen={isOpen}
      lang={locale}
      handleDelete={handleDelete}
    />
  );
};

InstitutionDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

InstitutionDelete.defaultProps = {
  isOpen: false
};

export default InstitutionDelete;

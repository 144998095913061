import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../components/Button";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import InstitutionsList from "./InstitutionsList";
import Filters from "./Filters";
import InstitutionDelete from "./InstitutionDelete";

const InstitutionList = () => {
  const { messages } = useIntl();
  const list = useSelector((state) => state.institutes.list);

  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    institute: { title, addInstitution }
  } = messages;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Filters />
        </Grid>
        <Grid
          item
          xs={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addInstitution}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            slug={ROUTE_PATHS["addInstitution"]}
          />
        </Grid>
      </Grid>
      <InstitutionsList list={list} />
      <InstitutionDelete isOpen={!!deleteId} />
    </>
  );
};

export default InstitutionList;

import { useIntl } from "react-intl";
import * as Yup from "yup";

export const QUIZ_VALIDATIONSCHEMA = () => {
  const { messages } = useIntl();
  const {
    quizzes: {
      quizTitleIsRequired,
      answerIsRequired,
      questionTitleRequired,
      oneQuestionAtleast,
      max60Char,
      imageRequired
    }
  } = messages;

  return Yup.object().shape({
    title: Yup.string()
      .max(60, max60Char)
      .required(quizTitleIsRequired),
    questions_attributes: Yup.array()
      .of(
        Yup.object().shape({
          _destroy: Yup.boolean(),
          question: Yup.string().when("_destroy", {
            is: (_destroy) => !_destroy,
            then: Yup.string().required(questionTitleRequired)
          }),
          justification: Yup.string().nullable(),
          justification_image_url: Yup.string().nullable(),
          points: Yup.number("Only Numbers allowed")
            .min(0)
            .nullable(),
          answer_choices_attributes: Yup.array().of(
            Yup.object().shape({
              currentTab: Yup.string(),
              answer: Yup.string().when("currentTab", {
                is: (currentTab) => {
                  return (
                    currentTab === "text" ||
                    currentTab === "text_image"
                  );
                },
                then: Yup.string(answerIsRequired)
                  .required(answerIsRequired)
                  .nullable(),
                otherwise: Yup.string(answerIsRequired).nullable()
              }),
              correct: Yup.boolean(),
              image_url: Yup.string().when("currentTab", {
                is: (currentTab) => {
                  return (
                    currentTab === "image" ||
                    currentTab === "text_image"
                  );
                },
                then: Yup.string(imageRequired)
                  .required(imageRequired)
                  .nullable(),
                otherwise: Yup.string(imageRequired).nullable()
              })
            })
          )
        })
      )
      .min(1, oneQuestionAtleast)
      .required("Required")
  });
};

export const getInitialValuesEdit = (quiz = {}) => {
  return {
    id: quiz.id,
    title: quiz.title,
    questions_attributes: quiz?.questions?.map((question) => {
      return {
        id: question.id,
        question: question.question,
        image_url: question.image_url,
        points: question.points,
        justification: question.justification,
        justification_image_url: question.justification_image_url,
        _destroy: false,
        answer_choices_attributes: question?.answer_choices?.length
          ? question?.answer_choices?.map((answer) => ({
              id: answer.id,
              answer: answer.answer,
              correct: answer.correct,
              currentTab:
                answer.image_url && answer.answer
                  ? "text_image"
                  : answer.answer
                  ? "text"
                  : "image",
              image_url: answer.image_url,
              _destroy: false,
              answer_choice: answer.answer_choice || ""
            }))
          : [
              {
                answer: "",
                correct: false,
                image_url: ""
              }
            ]
      };
    })
  };
};

export const newAnswer = {
  answer: "",
  correct: false,
  _destroy: false,
  currentTab: "text",
  image_url: ""
};

export const newEmptyQuestion = {
  question: "",
  image_url: "",
  points: "",
  justification: "",
  _destroy: false,
  answer_choices_attributes: [newAnswer, newAnswer]
};

export const getInitialValuesAdd = () => {
  return {
    title: "",
    questions_attributes: [newEmptyQuestion]
  };
};

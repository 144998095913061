import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { add } from "../../../store/ActionsBtn/actions";

import {
  getBookSectionsRequest,
  setEditableModeSection
} from "../../../store/Books/actions";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";
import { deleteSectionAction } from "../../../store/ActionsBtn/actions";

const SectionList = ({ list, lang }) => {
  const meta = useSelector(
    (state) => state.books.sectionsInBookOptionsMeta
  );
  const isLoading = useSelector((state) => state.loader);
  let params = useParams();

  const {
    section: { title, from, to },
    shared: { deleteLabel, editLabel }
  } = messages[lang];
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const perPage = 6;
  const handleDelete = (id) => {
    dispatch(deleteSectionAction(id));
  };

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getBookSectionsRequest({
        page: page,
        items: perPage,
        bookId: params.id
      })
    );
  };
  const handleEdit = (row) => {
    dispatch(
      setEditableModeSection({ sectionEditable: row, editMode: true })
    );
    dispatch(add(row.id));
  };
  return (
    <>
      <Table
        headlines={[title, from, to]}
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell
              className="table__cell"
              align="left"
              style={{ maxWidth: "250px" }}
            >
              {row.name}
            </TableCell>

            <TableCell className="table__cell" align="left">
              {row.from}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.to}
            </TableCell>

            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={editLabel}>
                <span>
                  <IconButton
                    onClick={() => handleEdit(row)}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta?.pages > 1 && (
        <Pagination
          count={meta?.pages}
          page={meta?.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

SectionList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object)
};

export default SectionList;

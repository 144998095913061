import * as types from "./types";

export const getDirectorsRequest = (data) => ({
  type: types.GET_DIRECTORS_REQUEST,
  payload: data
});

export const getDirectorsResponse = (data) => ({
  type: types.GET_DIRECTORS_RESPONSE,
  payload: data
});

export const deleteDirectorsRequest = (data) => ({
  type: types.DELETE_DIRECTORS_REQUEST,
  payload: data
});

export const addDirectorRequest = (data) => ({
  type: types.ADD_DIRECTOR_REQUEST,
  payload: data
});

export const addDirectorResponse = (data) => ({
  type: types.ADD_DIRECTOR_RESPONSE,
  payload: data
});

export const editDirectorRequest = (data) => ({
  type: types.EDIT_DIRECTOR_REQUEST,
  payload: data
});

export const editDirectorResponse = (data) => ({
  type: types.EDIT_DIRECTOR_RESPONSE,
  payload: data
});

import { axiosInstance } from "./";

const getCategories = async (params) =>
  await axiosInstance.get("categories", { params });

const deleteCategory = async (id) =>
  await axiosInstance.delete(`categories/${id}`);

const addCategory = async (payload) =>
  await axiosInstance.post(`categories`, payload);

const editCategory = async ({ name, id }) =>
  await axiosInstance.patch(`categories/${id}`, { name });

export { getCategories, deleteCategory, addCategory, editCategory };

import { axiosInstance } from "./";

const getAdmins = async (params) => {
  return await axiosInstance.get(`admins`, { params });
};

const deleteAdmin = async (id) => {
  return await axiosInstance.delete(`admins/${id}`);
};

const addAdmin = async (payload) =>
  await axiosInstance.post(`admins`, payload);

const editAdmin = async ({ name, id }) =>
  await axiosInstance.patch(`admins/${id}`, { name });

export { getAdmins, deleteAdmin, addAdmin, editAdmin };

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import UsersList from "../UsersList/UsersList";

function EditUsers({ id }) {
  const lang = useSelector((state) => state.locale.lang);
  const {
    programs: { editUsers }
  } = messages[lang];

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        {editUsers}
      </AccordionSummary>
      <AccordionDetails>
        <UsersList lang={lang} showActions={true} programId={id} />
      </AccordionDetails>
    </Accordion>
  );
}

EditUsers.propTypes = {
  id: PropTypes.string
};

export default EditUsers;

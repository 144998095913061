import { useIntl } from "react-intl";
import * as Yup from "yup";

export const ADD_EDIT_PROMOCODE = () => {
  const { messages } = useIntl();
  const {
    promoCodes: {
      offerRequired,
      nameRequired,
      dateRequired,
      firstNameRequired,
      lastNameRequired,
      promocodeTypeRequired,
      individualEmailRequired
    }
  } = messages;

  return Yup.object().shape({
    name: Yup.string(nameRequired).required(nameRequired),
    first_name: Yup.string().when("promocodeType", {
      is: (val) => {
        return val === "individual";
      },
      then: Yup.string().nullable().required(firstNameRequired),
      otherwise: Yup.string()
    }),
    last_name: Yup.string().when("promocodeType", {
      is: (val) => {
        return val === "individual";
      },
      then: Yup.string().nullable().required(lastNameRequired),
      otherwise: Yup.string()
    }),
    percentage: Yup.number(offerRequired)
      .max(100)
      .min(1)
      .required(offerRequired),

    promocodeType: Yup.string()
      .nullable()
      .required(promocodeTypeRequired),

    individualEmail: Yup.string().when("promocodeType", {
      is: (val) => {
        return val === "individual";
      },
      then: Yup.string().nullable().required(individualEmailRequired),
      otherwise: Yup.string()
    }),
    country: Yup.number().when("promocodeType", {
      is: (val) => {
        return val === "country";
      },
      then: Yup.number().nullable().required("country is required"),
      otherwise: Yup.number().optional()
    }),
    expiration_date: Yup.string().nullable().required(dateRequired)
  });
};

import React from "react";
import PropTypes from "prop-types";
import styles from "./InfoCard.module.scss";
import { Typography } from "@material-ui/core";

const InfoCard = ({ title, subtitle, icon }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.subtitle}>
        {icon} {subtitle}
      </p>
      <Typography variant="h6" component="h3" className="mb-2">
        {title}
      </Typography>
    </div>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node
};

InfoCard.defaultProps = {
  title: ""
};

export default InfoCard;

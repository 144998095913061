import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import { assignDirectorToProgram } from "../../../store/Programs/actions";
import DirectorsList from "../../Directors/DirectorsList/DirectorsList";

function AssignDirector({ id }) {
  const lang = useSelector((state) => state.locale.lang);
  const dispatch = useDispatch();
  const {
    programs: { assignDirector },
    shared: { editLabel }
  } = messages[lang];
  const { selectedDirector } = useSelector((state) => state.programs);
  console.log("selectedDirectorrrrrrrrr", selectedDirector);
  const handleDirectorSubmit = () => {
    dispatch(
      assignDirectorToProgram({
        id,
        data: {
          program: {
            program_director: selectedDirector
          }
        }
      })
    );
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        {assignDirector}
      </AccordionSummary>
      <AccordionDetails>
        <DirectorsList
          lang={lang}
          setDeleteModal={() => {}}
          showActions={false}
          showRadioButtons={true}
        />
      </AccordionDetails>
      <AccordionActions>
        <Button
          onClick={() => {
            handleDirectorSubmit();
          }}
          text={editLabel}
        />
      </AccordionActions>
    </Accordion>
  );
}

AssignDirector.propTypes = {
  id: PropTypes.string
};

export default AssignDirector;

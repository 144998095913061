import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  AppBar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  Toolbar
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MoneyIcon from "@material-ui/icons/Money";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AppsIcon from "@material-ui/icons/Apps";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SurveysIcon from "@material-ui/icons/QuestionAnswerOutlined";
import QuizIcon from "@material-ui/icons/QuestionAnswerTwoTone";
import InstituteIcon from "@material-ui/icons/School";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Dropdown from "../../components/Dropdown";
import messages from "../../assets/locale/messages";
import MedblendLogo from "../../assets/images/logo.png";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import Auth from "../../utils/Auth";
import { logoutRequest } from "../../store/Auth/actions";
import History from "../../routes/History";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    boxShadow: "none"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  paper: {
    border: 0
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  links: {
    color: theme.palette.black.light,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.white
    }
  },
  icons: {
    color: theme.palette.black.light
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(12, 3, 3, 3)
  },
  listItem: {
    padding: "20px 25px",
    fontSize: "1rem",
    fontFamily: "Helvetica-Bold",
    "&:hover, &.Mui-selected": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.black.main,
      borderRight: `3px solid ${theme.palette.secondary.main}`
    }
  }
}));

const Layout = ({ children, lang }) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.auth.login);

  const {
    finalTest: { FinalTest },
    header: { title },
    dashboard: { title: dashboardTitle },
    programs: { title: programsTitle },
    books,
    categories,
    admins,
    authors,
    editors,
    buttons: { logout, changePassword },
    supplementaries,
    quizzes,
    surveys,
    preTestPostTest,
    promoCodes,
    institute,
    directors
  } = messages[lang];

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [path, setPath] = useState("");
  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  const activetRoute = (activeKeys) => {
    return activeKeys.some((key) => path.includes(key));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  const handleChangePassword = () => {
    History.push(ROUTE_PATHS["changePassword"]);
  };

  const sideMenu = [
    {
      id: 1,
      title: dashboardTitle,
      icon: <DashboardIcon />,
      slug: ROUTE_PATHS["dashboard"],
      activeKeys: ["dashboard"]
    },
    {
      id: 2,
      title: admins.title,
      icon: <SupervisorAccountIcon />,
      slug: ROUTE_PATHS["admins"],
      activeKeys: ["admins"]
    },
    {
      id: 11,
      title: authors.title,
      icon: <SupervisorAccountIcon />,
      slug: ROUTE_PATHS["authors"],
      activeKeys: ["authors"]
    },
    {
      id: 12,
      title: editors.title,
      icon: <SupervisorAccountIcon />,
      slug: ROUTE_PATHS["editors"],
      activeKeys: ["editors"]
    },
    {
      id: 14,
      title: directors.title,
      icon: <SupervisorAccountIcon />,
      slug: ROUTE_PATHS.directors,
      activeKeys: ["directors"]
    },
    {
      id: 15,
      title: FinalTest,
      icon: <AssignmentIcon />,
      slug: ROUTE_PATHS.finalTest,
      activeKeys: ["finalTest"]
    },
    {
      id: 3,
      title: categories.title,
      icon: <AppsIcon />,
      slug: ROUTE_PATHS["categories"],
      activeKeys: ["categories"]
    },
    {
      id: 4,
      title: books.title,
      icon: <LibraryBooksIcon />,
      slug: ROUTE_PATHS["books"],
      activeKeys: ["books", "add-book", "edit-book", "book"]
    },
    {
      id: 13,
      title: programsTitle,
      icon: <LibraryBooksIcon />,
      slug: ROUTE_PATHS.programs,
      activeKeys: ["programs"]
    },
    {
      id: 5,
      title: supplementaries.title,
      icon: <AttachmentIcon />,
      slug: ROUTE_PATHS["supplementaries"],
      activeKeys: [
        "supplementaries",
        "add-supplementary",
        "edit-supplementary",
        "supplementary"
      ]
    },
    {
      id: 6,
      title: surveys.title,
      icon: <SurveysIcon />,
      slug: ROUTE_PATHS["surveys"],
      activeKeys: ["surveys", "add-survey", "edit-survey", "survey"]
    },
    {
      id: 7,
      title: quizzes.title,
      icon: <QuizIcon />,
      slug: ROUTE_PATHS["quizzes"],
      activeKeys: ["quizzes"]
    },
    {
      id: 8,
      title: preTestPostTest.title,
      icon: <LibraryBooksIcon />,
      slug: ROUTE_PATHS["preTestPostTest"],
      activeKeys: ["tests", "add-test", "edit-test", "test"]
    },
    {
      id: 9,
      title: promoCodes.title,
      icon: <MoneyIcon />,
      slug: ROUTE_PATHS["promoCodes"],
      activeKeys: ["promoCodes", "add-promocode", "promocode"]
    },
    {
      id: 10,
      title: institute.title,
      icon: <InstituteIcon />,
      slug: ROUTE_PATHS["institute"],
      activeKeys: ["institute"]
    }
  ];

  return Auth.getAuth() ? (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Typography variant="h6" noWrap>
              {title}
            </Typography>
            <Dropdown
              title={
                admin?.name ? admin.name : admins.adminNamePlaceholder
              }
              menuItems={[
                { id: 0, title: admin?.email },
                {
                  id: 1,
                  title: changePassword,
                  handleClick: handleChangePassword
                },
                { id: 2, title: logout, handleClick: handleLogout }
              ]}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            className={classes.icons}
            onClick={handleDrawerClose}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Link
          to={ROUTE_PATHS["dashboard"]}
          className="d-flex align-items-center justify-content-center mb-3"
        >
          <img
            width={`${open ? "70px" : "50px"}`}
            src={MedblendLogo}
            alt="medblend-logo"
          />
        </Link>
        <List>
          {sideMenu.map(({ id, title, icon, slug, activeKeys }) => (
            <Link className={classes.links} to={slug} key={id}>
              <ListItem
                selected={activetRoute(activeKeys)}
                className={classes.listItem}
                button
              >
                <ListItemIcon className={classes.icons}>
                  {icon}
                </ListItemIcon>
                {title}
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  ) : (
    children
  );
};

Layout.propTypes = {
  lang: PropTypes.string,
  children: PropTypes.node
};

export default Layout;

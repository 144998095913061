import * as Yup from "yup";

export const ADD_AUTHOR_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Author name is required."),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Author Email is required.")
});

export const EDIT_AUTHOR_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Author name is required.")
});

export const getInitialValues = (admin) => {
  return {
    name: admin?.name || "",
    email: admin?.email || ""
  };
};

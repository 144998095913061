import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";

import RegisteredUserList from "../../RegisteredUser/RegisteredUserList";
import messages from "../../../assets/locale/messages";
import {
  getSinglePromoCodeRequest,
  getSinglePromoCodeResponse
} from "../../../store/Promocodes/actions";
import {
  getRegisteredUserRequest,
  setRegisteredUserFilters
} from "../../../store/RegisteredUser/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import "./PromoCodeDetails.scss";

const PromoCodeDetails = () => {
  let params = useParams();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const singlePromoCode = useSelector(
    (state) => state.promoCodes.singlePromoCode
  );

  const {
    promoCodes: {
      descriptionLabel,
      name,
      offer,
      listRegistered,
      expDate
    }
  } = messages[lang];
  const registeredUsers = useSelector(
    (state) => state.registeredUsers.list
  );
  useEffect(async () => {
    if (params?.id) {
      await dispatch(getSinglePromoCodeRequest(params?.id));
    } else {
      return null;
    }
  }, [params]);
  useEffect(() => {
    if (singlePromoCode) {
      filterRegisteredUsersList();
    }
  }, [singlePromoCode]);

  function filterRegisteredUsersList() {
    dispatch(
      setRegisteredUserFilters({
        selectedCounty: singlePromoCode?.countries
          ?.map((country) => country.id)
          .join(),
        selectedJob: singlePromoCode?.jobs
          ?.map((job) => job.id)
          .join()
      })
    );
    dispatch(
      getRegisteredUserRequest({
        page: 1,
        items: 10,
        paginate: true,
        job_ids: singlePromoCode?.jobs?.map((job) => job.id).join(),
        country_ids: singlePromoCode?.countries
          ?.map((country) => country.id)
          .join()
      })
    );
  }

  useEffect(() => {
    return () => {
      dispatch(getSinglePromoCodeResponse(null));
    };
  }, []);
  const booksList = useSelector((state) => state.books.list);
  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
  }, []);
  const formatPromcode = (date) => {
    let d = moment(date).format("L");
    return d;
  };
  return (
    <section className="wrapper-bg">
      <>
        {singlePromoCode && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                component="h1"
                className="mb-4 text-blue"
              >
                {descriptionLabel}
              </Typography>
              <Typography
                variant="h5"
                component="h1"
                className="mb-3 text-black"
              >
                {"Promocode Type"}
              </Typography>
              <p> {singlePromoCode.code_type}</p>
              {singlePromoCode.individual_promo_code?.email && (
                <>
                  <Typography
                    variant="h5"
                    component="h1"
                    className="mb-3 text-black"
                  >
                    {"Individual Promocode Email"}
                  </Typography>
                  <p>
                    {singlePromoCode.individual_promo_code?.email}
                  </p>
                </>
              )}
              <Typography
                variant="h5"
                component="h1"
                className="mb-3 text-black"
              >
                {name}
              </Typography>
              <p> {singlePromoCode.name}</p>
              <Typography
                variant="h5"
                component="h1"
                className="mb-3 text-black"
              >
                {offer}
              </Typography>
              <p> {singlePromoCode.percentage}</p>
              {singlePromoCode?.expiration_date && (
                <>
                  <Typography
                    variant="h5"
                    component="h1"
                    className="mb-3 text-black"
                  >
                    {expDate}
                  </Typography>
                  <p className="fsize-14">
                    {formatPromcode(singlePromoCode?.expiration_date)}{" "}
                  </p>
                </>
              )}
              {singlePromoCode?.book_ids?.length > 0 && (
                <div>
                  <Typography
                    variant="h5"
                    component="h1"
                    className="mb-3 text-black"
                  >
                    List of Books
                  </Typography>
                  {singlePromoCode?.book_ids.map((bookid) => (
                    <p key={bookid}>
                      {booksList.find((e) => e.id === bookid)?.title}
                    </p>
                  ))}
                </div>
              )}

              <Typography
                variant="h5"
                component="h1"
                className="mb-3 text-black"
              >
                {listRegistered}
              </Typography>
            </Grid>
            <Grid xs={12}>
              {/* // list of the registered users */}
              {registeredUsers && (
                <RegisteredUserList
                  list={registeredUsers}
                  lang={lang}
                  filter={false}
                />
              )}
            </Grid>
          </Grid>
        )}
      </>
    </section>
  );
};

export default PromoCodeDetails;

import * as types from "./types";

export const getBooksRequest = (data) => ({
  type: types.GET_BOOKS_REQUEST,
  payload: data
});

export const getBooksResponse = (data) => ({
  type: types.GET_BOOKS_RESPONSE,
  payload: data
});

export const deleteBookRequest = (data) => ({
  type: types.DELETE_BOOK_REQUEST,
  payload: data
});

export const addBookRequest = (data) => ({
  type: types.ADD_BOOK_REQUEST,
  payload: data
});

export const addBookResponse = (data) => ({
  type: types.ADD_BOOK_RESPONSE,
  payload: data
});

export const editBookRequest = (data) => ({
  type: types.EDIT_BOOK_REQUEST,
  payload: data
});

export const editBookResponse = (data) => ({
  type: types.EDIT_BOOK_RESPONSE,
  payload: data
});

export const getSingleBookRequest = (data) => ({
  type: types.GET_SINGLE_BOOK_REQUEST,
  payload: data
});

export const getSingleBookResponse = (data) => ({
  type: types.GET_SINGLE_BOOK_RESPONSE,
  payload: data
});

export const getBooksInCategoryRequest = (data) => ({
  type: types.GET_BOOKS_IN_CATEGORY_REQUEST,
  payload: data
});

export const getBooksInCategoryResponse = (data) => ({
  type: types.GET_BOOKS_IN_CATEGORY_RESPONSE,
  payload: data
});

export const setBooksFilters = (data) => ({
  type: types.SET_BOOKS_FILTERS,
  payload: data
});

export const getBookSupplementraiesRequest = (data) => ({
  type: types.GET_BOOK_SUPPLEMENTRAIES_REQUEST,
  payload: data
});

export const getBookSupplementraiesResponse = (data) => ({
  type: types.GET_BOOK_SUPPLEMENTRAIES_RESPONSE,
  payload: data
});

export const getBookSectionsRequest = (data) => ({
  type: types.GET_BOOK_SECTIONS_REQUEST,
  payload: data
});

export const getBookSectionsResponse = (data) => ({
  type: types.GET_BOOK_SECTIONS_RESPONSE,
  payload: data
});
export const setBookSectionsRequest = (data) => ({
  type: types.SET_BOOK_SECTIONS_REQUEST,
  payload: data
});

export const editBookSectionsRequest = (data) => ({
  type: types.EDIT_BOOK_SECTIONS_REQUEST,
  payload: data
});

export const setEditableModeSection = (data) => ({
  type: types.SET_EDITABLE_MODE_SECTION,
  payload: data
});

export const deleteSectionRequest = (data) => ({
  type: types.DELETE_SECTIONS_REQUEST,
  payload: data
});

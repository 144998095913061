import React from "react";
import DeleteModal from "../../../components/DeleteModal";
import PropTypes from "prop-types";
import messages from "../../../assets/locale/messages";
import { useDispatch } from "react-redux";
import { deleteSingleFinalTestRequest } from "../../../store/FinalTests/actions";

function FinalTestDelete({ isOpen, lang, id, setDeleteModal }) {
  console.log("Delete test id: " + id);

  const {
    finalTest: { deleteFinalTest, deleteMessage }
  } = messages[lang];
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteSingleFinalTestRequest(id));
    setDeleteModal({ id: 0, open: false });
  };
  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteFinalTest}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
      handleCancel={() => setDeleteModal({ id: 0, open: false })}
    />
  );
}
FinalTestDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  id: PropTypes.number,
  setDeleteModal: PropTypes.func
};
FinalTestDelete.defaultProps = {
  isOpen: false
};

export default FinalTestDelete;

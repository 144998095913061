export const GET_SUPPLEMENTRIES_REQUEST =
  "GET_SUPPLEMENTRIES_REQUEST";
export const GET_SUPPLEMENTRIES_RESPONSE =
  "GET_SUPPLEMENTRIES_RESPONSE";

export const DELETE_SUPPLEMENTARY_REQUEST =
  "DELETE_SUPPLEMENTARY_REQUEST";

export const SET_SUPPLEMENTARY_FILTERS = "SET_SUPPLEMENTARY_FILTERS";

export const ADD_SUPPLEMENTRIES_REQUEST =
  "ADD_SUPPLEMENTRIES_REQUEST";

export const ASSIGN_BOOK_TO_SUPPLEMENTARY_REQUEST =
  "ASSIGN_BOOK_TO_SUPPLEMENTARY_REQUEST";

export const UNASSIGN_BOOK_FROM_SUPPLEMENTARY_REQUEST =
  "UNASSIGN_BOOK_FROM_SUPPLEMENTARY_REQUEST";

export const EDIT_SUPPLEMENTARY_REQUEST =
  "EDIT_SUPPLEMENTARY_REQUEST";

export const GET_SINGLE_SUPPLEMENTARY_REQUEST =
  "GET_SINGLE_SUPPLEMENTARY_REQUEST";
export const GET_SINGLE_SUPPLEMENTARY_RESPONSE =
  "GET_SINGLE_SUPPLEMENTARY_RESPONSE";
export const EDIT_BOOK_PAGE_FOR_SUPPLEMENTARY_REQUEST =
  "EDIT_BOOK_PAGE_FOR_SUPPLEMENTARY_REQUEST";

export const REASSIGN_SUPPLEMENTARY_TO_BOOK_VERSION_REQUEST =
  "REASSIGN_SUPPLEMENTARY_TO_BOOK_VERSION_REQUEST";

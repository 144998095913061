import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/auth";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { loginResponse } from "./actions";
import { setAdmin, clearAdminData, setTempAdmin } from "./helpers";
import history from "../../routes/History";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";
import { v4 as uuidv4 } from "uuid";
import { browserName } from "react-device-detect";

export function* loginSaga({ payload }) {
  try {
    const response = yield call(apis.login, {
      ...payload.data,
      device_id: browserName + uuidv4(),
      fcm_token: null
    });
    if (response.data.admin.must_change_password) {
      yield setTempAdmin(response.data);
      yield history.push(ROUTE_PATHS["createPassword"]);
    } else {
      yield put(loginResponse(response.data.admin));
      yield setAdmin(response.data);
      yield localStorage.removeItem("tempAdmin");
      yield history.push(ROUTE_PATHS["dashboard"]);
    }
  } catch (err) {
    console.error(err);
  }
}

export function* logoutSaga() {
  try {
    yield call(apis.logout);
    yield put(loginResponse({}));
    yield clearAdminData();
    history.push(ROUTE_PATHS["login"]);
  } catch (err) {
    yield clearAdminData();
    history.push(ROUTE_PATHS["login"]);
    console.error(err);
  }
}

export function* forgotPasswordSaga({ payload }) {
  try {
    const response = yield call(apis.forgotPassword, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].auth.forgotPassword
            .forgotPasswordSuccess
      })
    );
    history.push(ROUTE_PATHS["login"]);
  } catch (err) {
    console.error(err);
  }
}

export function* changePasswordSaga({ payload }) {
  try {
    const response = yield call(apis.changePassword, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].auth.changePassword
            .changePasswordSuccess
      })
    );
    history.push(ROUTE_PATHS["admins"]);
    yield payload.resetForm();
  } catch (err) {
    console.error(err);
  }
}

export function* createPasswordSaga({ payload }) {
  try {
    const response = yield call(apis.createPassword, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].auth.createPassword
            .createPasswordSuccess
      })
    );
    history.push(ROUTE_PATHS["dashboard"]);
  } catch (err) {
    console.error(err);
  }
}

import * as types from "./types";

export const add = (data) => ({
  type: types.ADD,
  payload: data
});

export const edit = (data) => ({
  type: types.EDIT,
  payload: data
});

export const deleteAction = (data) => ({
  type: types.DELETE,
  payload: data
});
export const deleteSectionAction = (data) => ({
  type: types.DELETESECTION,
  payload: data
});
export const unassignAction = (data) => ({
  type: types.UNASSIGN,
  payload: data
});

export const reassignAction = (data) => ({
  type: types.REASSIGN,
  payload: data
});

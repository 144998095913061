import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Grid, Typography } from "@material-ui/core";

import {
  getBookTestsRequest,
  setTestsBookFilters
} from "../../../store/Tests/actions";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/Search";
import messages from "../../../assets/locale/messages";
import PreTestPostTestList from "../../PreTestPostTest/PreTestPostTestList";
import PreTestPostTestDelete from "../../PreTestPostTest/PreTestPostTestDelete";

const BookTests = ({ bookId }) => {
  const dispatch = useDispatch();
  const perPage = 6;

  const [page, setPage] = useState(1);
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.tests.bookTests.meta);
  const lang = useSelector((state) => state.locale.lang);
  const searchInBookKeyword = useSelector(
    (state) => state.tests.searchInBookKeyword
  );
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const bookTestsList = useSelector(
    (state) => state.tests.bookTests.list
  );

  const {
    preTestPostTest: { title }
  } = messages[lang];

  useEffect(() => {
    getBookTests();
  }, []);

  const getBookTests = () => {
    const payload = {
      book_id: bookId,
      page: 1,
      items: 6
    };
    dispatch(getBookTestsRequest(payload));
  };
  useEffect(() => {
    filterBookTestsList();
  }, [searchInBookKeyword]);

  const filterBookTestsList = () => {
    const payload = {
      book_id: bookId,
      page: 1,
      items: perPage,
      title: searchInBookKeyword
    };
    dispatch(getBookTestsRequest(payload));
  };
  useEffect(() => {
    return () => {
      dispatch(
        setTestsBookFilters({
          searchInBookKeyword: ""
        })
      );
    };
  }, []);
  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getBookTestsRequest({
        book_id: bookId,
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      setTestsBookFilters({
        searchInBookKeyword: e.target.value
      })
    );
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3} className="mb-2">
        <Grid item xs={6}>
          <Formik initialValues={{ search: "" }}>
            {() => (
              <Form>
                <Search
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <button className="d-none"></button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <PreTestPostTestList
        list={bookTestsList}
        lang={lang}
        inBookView={true}
      />
      <PreTestPostTestDelete
        isOpen={!!deleteId}
        lang={lang}
        bookId={bookId}
      />

      {!isLoading && !bookTestsList.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </div>
  );
};

BookTests.propTypes = {
  bookId: PropTypes.boolean
};
export default BookTests;

import * as types from "./types";

const INITIAL_STATE = {
  programs: [],
  selectedProgram: {},
  selectedDirector: 0,
  selectedBooks: [],
  selectedUsers: [],
  programUsers: {
    users: [],
    meta: {}
  }
};

const programsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_PROGRAMS_RESPONSE:
      return {
        ...state,
        programs: action.payload
      };
    case types.ADD_SELECTED_PROGRAMS_RESPONSE:
      return {
        ...state,
        selectedProgram: action.payload
      };
    case types.SET_SELECTED_DIRECTOR:
      return {
        ...state,
        selectedDirector: action.payload
      };
    case types.SET_SELECTED_BOOKS:
      return {
        ...state,
        selectedBooks: action.payload
      };
    case types.SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.payload
      };
    case types.ADD_USERS_PROGRAMS_RESPONSE:
      return {
        ...state,
        programUsers: action.payload
      };
    default:
      return state;
  }
};

export default programsReducer;

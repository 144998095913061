import { useIntl } from "react-intl";
import * as Yup from "yup";

export const ADD_EDIT_TEST_SCHEMA = () => {
  const { messages } = useIntl();
  const {
    preTestPostTest: {
      testTitleIsRequired,
      testBookIsRequired,
      answerIsRequired,
      questionTitleRequired,
      prePageRequired,
      postPageRequired,
      questionPointseRequired,
      isMandatoryIsRequired,
      questionPointseMin
    }
  } = messages;

  return Yup.object().shape({
    title: Yup.string().required(testTitleIsRequired),
    book_id: Yup.number().required(testBookIsRequired),
    is_mandatory: Yup.boolean(isMandatoryIsRequired).required(
      isMandatoryIsRequired
    ),
    pre_page: Yup.number().integer().min(0).required(prePageRequired),
    post_page: Yup.number()
      .integer()
      .nullable()
      .moreThan(Yup.ref("pre_page"))
      .required(postPageRequired),
    note: Yup.string(),
    test_questions_attributes: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required(questionTitleRequired),
          points: Yup.number()
            .integer(questionPointseRequired)
            .min(1, questionPointseMin)
            .required(questionPointseRequired),
          section_id: Yup.string(),

          test_answer_choices_attributes: Yup.array().of(
            Yup.object().shape({
              answer:
                Yup.string(answerIsRequired).required(
                  answerIsRequired
                ),
              correct: Yup.boolean(),
              justification: Yup.string(),
              image_url: Yup.string()
            })
          )
        })
      )
      .required("Required")
  });
};

export const getInitialValuesAdd = () => {
  return {
    title: "",
    selectTestType: false,
    pre_page: null,
    post_page: null,
    note: "",
    book_id: 0,
    test_questions_attributes: [
      {
        question: "",
        points: null,
        section_id: null,
        image_url: "",
        test_answer_choices_attributes: [
          {
            answer: "",
            correct: false,
            image_url: "",
            justification: ""
          },
          {
            answer: "",
            correct: false,
            justification: "",
            image_url: ""
          }
        ]
      }
    ]
  };
};

export const getInitialValuesEdit = (test = {}) => {
  return {
    id: test?.id,
    title: test?.title,
    book_id: test?.book_id,
    pre_page: test?.pre_page,
    post_page: test?.post_page,
    note: test?.note,
    is_mandatory: test?.is_mandatory,
    test_questions_attributes: test?.test_questions?.map(
      (question) => {
        return {
          id: question?.id,
          question: question.question || "",
          points: question.points,
          section_id: question.section_id,
          image_url: question.image_url,
          justification: question.justification,
          justification_image_url: question.justification_image_url,
          test_answer_choices_attributes: question.test_answer_choices
            ?.length
            ? question.test_answer_choices.map((answer) => ({
                id: answer.id,
                answer: answer.answer,
                correct: answer.correct,
                image_url: answer.image_url,
                justification: answer.justification,
                answer_choice: answer.answer_choice || ""
              }))
            : [
                {
                  answer: "",
                  correct: false,
                  justification: "",
                  image_url: ""
                }
              ]
        };
      }
    )
  };
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { FieldArray, Form, Formik } from "formik";
import {
  Grid,
  Paper,
  Typography,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Input from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import Button from "../../../components/Button";
import {
  ADD_EDIT_SUPPLEMENTARY_SCHEMA,
  getInitialValuesAdd,
  getInitialValuesEdit
} from "./validation";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { showHideLoader } from "../../../store/Loader/actions";
import {
  addSurveysRequest,
  editSurveyRequest,
  getSingleSurveyRequest,
  getSingleSurveyResponse,
  checkForCanBeEditSurveytRequest,
  checkForCanBeEditSurveyResponse
} from "../../../store/Surveys/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import { questions_types, RatingMapping } from "./questions_types";
import styles from "./SurveyForm.module.scss";

const SurveyForm = () => {
  const dispatch = useDispatch();
  const { locale, messages } = useIntl();
  let params = useParams();
  const theme = useTheme();
  const booksList = useSelector((state) => state.books.list);
  const singleSurvey = useSelector(
    (state) => state.surveys.singleSurvey
  );
  const CanBeEditSurvey = useSelector(
    (state) => state.surveys.CanBeEditSurvey
  );
  const {
    survey: { singleSelect, multiSelect }
  } = messages;

  const [booksOptions, setBooksOptions] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const [destroy, setDestroy] = useState(false);
  const [mode, setMode] = useState(params?.id ? "edit" : "add");
  const [selectedQuestionType, setSelectedQuestionType] = useState(0);

  useEffect(() => {
    dispatch(
      getBooksRequest({
        lookup: true,
        no_survey: params?.id ? null : true
      })
    );
    return () => {
      dispatch(getSingleSurveyResponse({}));
      dispatch(checkForCanBeEditSurveyResponse(true));
    };
  }, []);

  useEffect(() => {
    if (params?.id) {
      dispatch(getSingleSurveyRequest(params.id));
      dispatch(checkForCanBeEditSurveytRequest({ id: params?.id }));
    }
  }, [params]);

  useEffect(() => {
    setBooksOptions(
      booksList.map(({ id, title }) => ({
        id,
        value: title,
        label: title
      }))
    );
  }, [booksList]);

  const handleSelectedBook = (id) => {
    setSelectedBook(_.find(booksList, ["id", id]));
  };

  const handleAnswers = (id, setFieldValue, field) => {
    if (id === 1) {
      setFieldValue(
        field,
        Object.keys(RatingMapping).map((key) => ({
          answer_choice: key
        }))
      );
    } else if (id === 2) {
      setFieldValue(field, [
        {
          answer_choice: "OPEN ENDED QUESTION DOESNOT NEED AN ANSWER"
        }
      ]);
    } else {
      setFieldValue(field, [
        { answer_choice: "" },
        { answer_choice: "" }
      ]);
    }
  };

  useEffect(() => {
    if (mode === "edit" && booksOptions.length) {
      handleSelectedBook(singleSurvey.book_id);
    }
  }, [singleSurvey, booksOptions]);

  const handleSubmit = (values) => {
    const valuesCopy = { ...values };
    const payload = {
      ...valuesCopy,
      survey_questions_attributes:
        valuesCopy.survey_questions_attributes.map((x) =>
          x.question_type === 1
            ? {
                ...x,
                survey_question_answer_choices_attributes:
                  Object.keys(RatingMapping).map((val) => ({
                    answer_choice: val
                  }))
              }
            : x
        )
    };

    dispatch(showHideLoader(true));
    if (mode === "add") {
      dispatch(addSurveysRequest(payload));
    } else {
      dispatch(
        editSurveyRequest({
          data: payload,
          id: singleSurvey.id
        })
      );
    }
  };

  const {
    surveys: {
      addSurvey,
      titleLabel,
      questionType,
      selectTestTypeLabel,
      editSurvey,
      questionTitle,
      surveyAnswered,
      answer: answerText
    },
    books: { book }
  } = messages;

  function AddRemoveNewQuestion(push) {
    return (
      <>
        <div className="mx-2 mb-3">
          <AddCircleIcon
            className="cursor-pointer"
            onClick={() =>
              push({
                question_type: 0,
                question: "",
                is_mcq_single_choice: false,
                survey_question_answer_choices_attributes: [
                  {
                    answer_choice: ""
                  },
                  {
                    answer_choice: ""
                  }
                ]
              })
            }
          />
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {mode === "add" ? addSurvey : editSurvey}
        </Typography>
      </div>

      <Formik
        initialValues={
          mode === "add"
            ? getInitialValuesAdd()
            : getInitialValuesEdit(singleSurvey)
        }
        onSubmit={handleSubmit}
        validationSchema={ADD_EDIT_SUPPLEMENTARY_SCHEMA(
          locale,
          selectedQuestionType
        )}
        enableReinitialize
      >
        {({ setFieldValue, values, errors, ...formik }) => (
          <Form>
            <fieldset
              disabled={!CanBeEditSurvey}
              className={
                !CanBeEditSurvey ? styles.disableFormEdit : null
              }
            >
              <Grid container spacing={5}>
                <Grid item xs={12} className="pb-0">
                  <Input
                    name="title"
                    label={titleLabel}
                    isRequired={true}
                  />
                  <CustomSelect
                    options={booksOptions}
                    placeholder={book}
                    name="book_id"
                    bgColor={theme.palette.primary.light}
                    isRequired={true}
                    isMenuRelative={false}
                    handleSelectChange={handleSelectedBook}
                    disabled={!!params?.id}
                  />
                  <FieldArray name="survey_questions_attributes">
                    {({ push, replace, remove }) => {
                      return !values?.survey_questions_attributes
                        ?.length
                        ? null
                        : values?.survey_questions_attributes?.map(
                            (question, questionIndex, questions) => {
                              const lastActiveItem = [...questions]
                                .map((x) => x._destroy)
                                .map((x) => !!x)
                                .lastIndexOf(false);

                              const showAddButton =
                                lastActiveItem === questionIndex;

                              return (
                                !question._destroy && (
                                  <div className="d-flex flex-row my-5 col-md-10">
                                    <Paper
                                      style={{ padding: "2rem" }}
                                      className="row  flex-fill "
                                    >
                                      <div>
                                        <CustomSelect
                                          options={[
                                            ...questions_types?.values()
                                          ]}
                                          placeholder={questionType}
                                          name={`survey_questions_attributes[${questionIndex}].question_type`}
                                          bgColor={
                                            theme.palette.primary
                                              .light
                                          }
                                          disabled={
                                            mode === "edit" &&
                                            question.id
                                          }
                                          isRequired={true}
                                          isMenuRelative={false}
                                          handleSelectChange={(
                                            id
                                          ) => {
                                            handleAnswers(
                                              id,
                                              setFieldValue,
                                              `survey_questions_attributes[${questionIndex}].survey_question_answer_choices_attributes`
                                            );
                                          }}
                                        />
                                      </div>
                                      {values
                                        .survey_questions_attributes[
                                        questionIndex
                                      ].question_type === 0 && (
                                        <div className="mb-4">
                                          <label
                                            key={questionIndex}
                                            className="cursor-pointer d-flex align-items-start"
                                          >
                                            <RadioGroup
                                              name={`survey_questions_attributes[${questionIndex}].is_mcq_single_choice`}
                                              className="flex-nowrap flex-row"
                                              value={`${values.survey_questions_attributes[questionIndex].is_mcq_single_choice}`}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `survey_questions_attributes[${questionIndex}].is_mcq_single_choice`,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <FormControlLabel
                                                value="true"
                                                control={<Radio />}
                                                label={singleSelect}
                                              />
                                              <FormControlLabel
                                                value="false"
                                                control={<Radio />}
                                                label={multiSelect}
                                              />
                                            </RadioGroup>
                                          </label>
                                        </div>
                                      )}
                                      <Input
                                        name={`survey_questions_attributes[${questionIndex}].question`}
                                        label={questionTitle}
                                        isRequired={true}
                                        inputShape="textarea"
                                        rows="2"
                                      />

                                      <FieldArray
                                        name={`survey_questions_attributes[${questionIndex}].survey_question_answer_choices_attributes`}
                                      >
                                        {({
                                          push,
                                          replace,
                                          remove
                                        }) => {
                                          return (
                                            <Form>
                                              {question.question_type ===
                                                1 && (
                                                <div className="m-4">
                                                  {`(4)Strongly Agree - (3) Agree - (2) Disagree - (1) Strongly Disagree`}
                                                </div>
                                              )}
                                              {question.question_type ===
                                                0 &&
                                                question.survey_question_answer_choices_attributes.map(
                                                  (
                                                    answer,
                                                    answerIndex,
                                                    arr
                                                  ) => {
                                                    const lastActiveItem =
                                                      [...arr]
                                                        .map(
                                                          (x) =>
                                                            x._destroy
                                                        )
                                                        .map(
                                                          (x) => !!x
                                                        )
                                                        .lastIndexOf(
                                                          false
                                                        );

                                                    const showAddButton =
                                                      lastActiveItem ===
                                                      answerIndex;

                                                    return (
                                                      !answer._destroy && (
                                                        <div
                                                          className="mx-5 d-flex"
                                                          key={JSON.stringify(
                                                            answerIndex
                                                          )}
                                                        >
                                                          <div className="flex-fill">
                                                            <Input
                                                              name={`survey_questions_attributes[${questionIndex}].survey_question_answer_choices_attributes[${answerIndex}].answer_choice`}
                                                              label={
                                                                answerText
                                                              }
                                                              isRequired={
                                                                true
                                                              }
                                                              inputShape="textarea"
                                                              rows="2"
                                                            />
                                                          </div>
                                                          <div className="mx-2">
                                                            {showAddButton && (
                                                              <AddIcon
                                                                className="me-2 mt-2 cursor-pointer"
                                                                onClick={() => {
                                                                  push(
                                                                    {
                                                                      answer_choice:
                                                                        ""
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          </div>
                                                          {question.survey_question_answer_choices_attributes.filter(
                                                            (x) =>
                                                              !x._destroy
                                                          ).length >
                                                            2 && (
                                                            <div className="mx-2 mt-2 cursor-pointer">
                                                              <RemoveIcon
                                                                className="me-2"
                                                                onClick={() => {
                                                                  if (
                                                                    !answer.id
                                                                  ) {
                                                                    remove(
                                                                      answerIndex
                                                                    );
                                                                    return;
                                                                  }
                                                                  replace(
                                                                    answerIndex,
                                                                    {
                                                                      ...answer,
                                                                      _destroy: true
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          )}
                                                        </div>
                                                      )
                                                    );
                                                  }
                                                )}
                                            </Form>
                                          );
                                        }}
                                      </FieldArray>
                                    </Paper>
                                    <div className="d-flex flex-row mb-3 align-items-center mx-5">
                                      {values.survey_questions_attributes.filter(
                                        (x) => !x._destroy
                                      ).length > 1 &&
                                        !question?._destroy && (
                                          <div className="mx-2 mb-3">
                                            <CancelIcon
                                              className="cursor-pointer"
                                              onClick={() => {
                                                if (!question.id) {
                                                  remove(
                                                    questionIndex
                                                  );
                                                  return;
                                                }
                                                replace(
                                                  questionIndex,
                                                  {
                                                    ...question,
                                                    _destroy: true
                                                  }
                                                );
                                                if (mode === "edit")
                                                  setDestroy(true);
                                              }}
                                            />
                                          </div>
                                        )}
                                      {showAddButton &&
                                        AddRemoveNewQuestion(push)}
                                    </div>
                                  </div>
                                )
                              );
                            }
                          );
                    }}
                  </FieldArray>
                </Grid>
                <Grid item xs={6} className="pb-0"></Grid>
              </Grid>
            </fieldset>
            <div className="d-block mt-3">
              <Button
                text={mode === "add" ? addSurvey : editSurvey}
                className="primary-lg"
                data-toggle="tooltip"
                data-placement="top"
                disabled={!CanBeEditSurvey}
                title={!CanBeEditSurvey ? surveyAnswered : ""}
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SurveyForm;

import * as Yup from "yup";

export const ADD_EDITOR_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Editor name is required."),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Editor Email is required.")
});

export const EDIT_EDITOR_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Editor name is required.")
});

export const getInitialValues = (editor) => {
  return {
    name: editor?.name || "",
    email: editor?.email || ""
  };
};

export const GET_INSTITUTIONS_REQUEST = "GET_INSTITUTIONS_REQUEST";
export const GET_INSTITUTIONS_RESPONSE = "GET_INSTITUTIONS_RESPONSE";
export const DELETE_INSTITUTION_REQUEST =
  "DELETE_INSTITUTION_REQUEST";
export const SET_INSTITUTION_FILTERS = "SET_INSTITUTION_FILTERS";
export const ADD_INSTITUTIONS_REQUEST = "ADD_INSTITUTIONS_REQUEST";
export const ASSIGN_BOOK_TO_INSTITUTION_REQUEST =
  "ASSIGN_BOOK_TO_INSTITUTION_REQUEST";
export const UNASSIGN_BOOK_FROM_INSTITUTION_REQUEST =
  "UNASSIGN_BOOK_FROM_INSTITUTION_REQUEST";
export const EDIT_INSTITUTION_REQUEST = "EDIT_INSTITUTION_REQUEST";
export const GET_SINGLE_INSTITUTION_REQUEST =
  "GET_SINGLE_INSTITUTION_REQUEST";
export const GET_SINGLE_INSTITUTION_RESPONSE =
  "GET_SINGLE_INSTITUTION_RESPONSE";
export const EDIT_BOOK_PAGE_FOR_INSTITUTION_REQUEST =
  "EDIT_BOOK_PAGE_FOR_INSTITUTION_REQUEST";

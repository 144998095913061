import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import messages from "../../../assets/locale/messages";
import AuthLayout from "../../../components/AuthLayout";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import CustomLink from "../../../components/Link";
import { FORGOT_PASSWORD_SCHEMA } from "./validations";
import { forgotPasswordRequest } from "../../../store/Auth/actions";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);

  const {
    inputs: { emailAddress },
    auth: {
      forgotPassword: { title, subTitle, rememberPassword }
    },
    buttons: { resetPassword, signIn }
  } = messages[lang];

  const handleSubmit = (values) => {
    dispatch(forgotPasswordRequest(values));
  };

  const renderForm = () => {
    return (
      <>
        <p>{subTitle}</p>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={FORGOT_PASSWORD_SCHEMA}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Input name="email" label={emailAddress} />
              <div className="d-flex align-items-center justify-content-between mb-4">
                <Button
                  type={"submit"}
                  text={resetPassword}
                  className="primary-lg"
                />

                <div className="d-flex align-items-center">
                  <p className="me-1 fsize-12 mb-0">
                    {rememberPassword}
                  </p>
                  <CustomLink
                    slug={ROUTE_PATHS["login"]}
                    linkText={signIn}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  return <AuthLayout title={title} body={renderForm()} />;
};

export default ForgotPassword;

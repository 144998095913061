import React from "react";
import Input from "../../../../components/Input";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import RemoveIcon from "@material-ui/icons/Remove";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import MaterialPreview from "../../../../components/MaterialPreview";
import Upload from "../finalTestImageUploading";
import PropTypes from "prop-types";

const setTabValue = (setFieldValue, questionIndex, values, type) => {
  const newArray = [];
  values.details.questions[`${questionIndex}`].answer_choices.forEach(
    (answer) => {
      if (answer?.id) {
        let deletedAns = {
          ...answer,
          correct: false,
          _destroy: true
        };
        newArray.push(deletedAns);
      }
    }
  );

  const withTwoEmptyValues = [
    ...newArray,
    ...[
      {
        answer: "",
        correct: false,
        justification: "",
        image_url: "",
        currentTab: type
      },
      {
        answer: "",
        correct: false,
        justification: "",
        image_url: "",
        currentTab: type
      }
    ]
  ];

  const question = values.details.questions[`${questionIndex}`];

  question.answer_choices = withTwoEmptyValues;

  setFieldValue(`details.questions[${questionIndex}]`, question);
};

const removeAnswer = (replace, answerIndex, answer) => {
  return () => {
    replace(answerIndex, {
      ...answer,
      correct: false,
      _destroy: true
    });
  };
};

const AddAnswer = (push, values, questionIndex) => {
  return () => {
    push({
      answer: "",
      currentTab:
        values.details.questions[questionIndex].answer_choices[0]
          .currentTab,
      image_url: "",
      correct: false,
      justification: ""
    });
  };
};

function AnswerAddOrEdit({
  question,
  questionIndex,
  setFieldValue,
  values,
  push,
  replace,
  remove,
  mode,
  isDicom
}) {
  const { messages } = useIntl();
  const {
    finalTest: { answerText, upload }
  } = messages;

  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item" role="presentation">
          <button
            className={
              (question?.answer_choices[0].id &&
                question?.answer_choices[0].image_url === "" &&
                question.answer_choices[0].answer !== "") ||
              question.answer_choices[0].currentTab === "text"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target={`#text_${questionIndex}`}
            type="button"
            role="tab"
            aria-controls={`text_${questionIndex}`}
            aria-selected="false"
            onClick={() =>
              setTabValue(
                setFieldValue,
                questionIndex,
                values,
                "text"
              )
            }
          >
            Text
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={
              question?.answer_choices[0].id &&
              question?.answer_choices[0].image_url !== "" &&
              question.answer_choices[0].answer === ""
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target={`#image_${questionIndex}`}
            type="button"
            role="tab"
            aria-controls={`image_${questionIndex}`}
            aria-selected="false"
            onClick={() =>
              setTabValue(
                setFieldValue,
                questionIndex,
                values,
                "image"
              )
            }
          >
            Image
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={
              question?.answer_choices[0].id &&
              question?.answer_choices[0].image_url !== "" &&
              question.answer_choices[0].answer !== ""
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target={`#image_text_${questionIndex}`}
            type="button"
            role="tab"
            aria-controls={`image_text_${questionIndex}`}
            aria-selected="false"
            onClick={() =>
              setTabValue(
                setFieldValue,
                questionIndex,
                values,
                "text_image"
              )
            }
          >
            Image with Text
          </button>
        </li>
      </ul>
      <div className="tab-content py-4" id="myTabContent">
        <div
          className={
            (question?.answer_choices[0].id &&
              question?.answer_choices[0].image_url === "" &&
              question.answer_choices[0].answer !== "") ||
            question.answer_choices[0].currentTab === "text"
              ? "tab-pane fade show active"
              : "tab-pane "
          }
          id={`text_${questionIndex}`}
          role="tabpanel"
          aria-labelledby={`text_${questionIndex}`}
        >
          {question.answer_choices.map((answer, answerIndex, arr) => {
            return (
              !answer._destroy && (
                <div
                  className="mx-5 d-flex"
                  key={JSON.stringify(answerIndex)}
                >
                  <div className="flex-fill">
                    <Input
                      name={`details.questions[${questionIndex}].answer_choices[${answerIndex}].answer`}
                      label={answerText}
                      isRequired={true}
                    />
                  </div>
                  <div className="mx-2">
                    {arr?.filter((x) => !x._destroy)[0] && (
                      <div className="d-flex">
                        <AddIcon
                          className="me-2 mt-2 cursor-pointer"
                          onClick={() => {
                            push({
                              answer: "",
                              currentTab:
                                values.details.questions[
                                  questionIndex
                                ].answer_choices[0].currentTab,
                              image_url: "",
                              correct: false,
                              justification: ""
                            });
                          }}
                        />
                        {}
                        <div className="mx-2 mt-2 cursor-pointer">
                          {values.details.questions[
                            questionIndex
                          ].answer_choices.every(
                            (el) => el.correct === false
                          ) === true ? (
                            <CheckIcon
                              color="success"
                              className="me-2"
                              onClick={() => {
                                replace(answerIndex, {
                                  ...answer,
                                  correct: true
                                });
                              }}
                            />
                          ) : (
                            values.details.questions[questionIndex]
                              .answer_choices[answerIndex].correct ===
                              true && (
                              <SettingsBackupRestoreIcon
                                onClick={() => {
                                  replace(answerIndex, {
                                    ...answer,
                                    correct: false
                                  });
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {question.answer_choices.filter((x) => !x._destroy)
                    .length > 2 && (
                    <div className="mx-2 mt-2 cursor-pointer">
                      <RemoveIcon
                        className="me-2"
                        onClick={() => {
                          if (!answer.id) {
                            remove(answerIndex);
                            return;
                          }
                          replace(answerIndex, {
                            ...answer,
                            answer: "deleted",
                            correct: false,

                            _destroy: true
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>

        <div
          className={
            mode === "Edit" &&
            question?.answer_choices[0].id &&
            question?.answer_choices[0].image_url !== "" &&
            question.answer_choices[0].answer === ""
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id={`image_${questionIndex}`}
          role="tabpanel"
          aria-labelledby={`image_${questionIndex}`}
        >
          {question.answer_choices.map((answer, answerIndex, arr) => {
            return (
              !answer._destroy && (
                <div
                  className="mx-5 d-flex"
                  key={JSON.stringify(answerIndex)}
                >
                  <div className="flex-fill">
                    <Grid item xs={6} className="pb-0">
                      <div className="d-flex flex-column align-items-center text-center">
                        <div className="mb-3 d-flex flex-column align-items-center w-100">
                          <MaterialPreview
                            type={1}
                            src={
                              values.details?.questions[questionIndex]
                                .answer_choices[answerIndex].image_url
                            }
                          />
                        </div>
                        <Upload
                          label={upload}
                          name={`details.questions[${questionIndex}].answer_choices[${answerIndex}].image_url`}
                          accept="image/*"
                          isImage={true}
                          setFieldValue={setFieldValue}
                          isRequired={true}
                          isMultiple={isDicom ? true : false}
                        />
                      </div>
                    </Grid>
                  </div>
                  <div className="mx-2">
                    {arr?.filter((x) => !x._destroy)[0] && (
                      <div className="d-flex">
                        <AddIcon
                          className="me-2 mt-2 cursor-pointer"
                          onClick={() => {
                            push({
                              answer: "",
                              currentTab:
                                values.details.questions[
                                  questionIndex
                                ].answer_choices[0].currentTab ||
                                "nothing",
                              image_url: "",
                              correct: false,
                              justification: ""
                            });
                          }}
                        />
                        <div className="mx-2 mt-2 cursor-pointer">
                          {values.details.questions[
                            questionIndex
                          ].answer_choices.every(
                            (el) => el.correct === false
                          ) === true ? (
                            <CheckIcon
                              color="success"
                              className="me-2"
                              onClick={() => {
                                replace(answerIndex, {
                                  ...answer,
                                  correct: true
                                });
                              }}
                            />
                          ) : (
                            values.details.questions[questionIndex]
                              .answer_choices[answerIndex].correct ===
                              true && (
                              <SettingsBackupRestoreIcon
                                onClick={() => {
                                  replace(answerIndex, {
                                    ...answer,
                                    correct: false
                                  });
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {question.answer_choices.filter((x) => !x._destroy)
                    .length > 2 && (
                    <div className="mx-2 mt-2 cursor-pointer">
                      <RemoveIcon
                        className="me-2"
                        onClick={() =>
                          replace(answerIndex, {
                            ...answer,
                            correct: false,

                            _destroy: true
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
        <div
          className={
            mode === "Edit" &&
            question?.answer_choices[0].id &&
            question.answer_choices[0].image_url !== "" &&
            question.answer_choices[0].answer !== ""
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id={`image_text_${questionIndex}`}
          role="tabpanel"
          aria-labelledby={`image_text_${questionIndex}`}
        >
          {question.answer_choices.map((answer, answerIndex, arr) => {
            return (
              !answer._destroy && (
                <div
                  className="mx-5 d-flex"
                  key={JSON.stringify(answerIndex)}
                >
                  <div className="flex-fill">
                    <Input
                      name={`details.questions[${questionIndex}].answer_choices[${answerIndex}].answer`}
                      label={answerText}
                      isRequired={true}
                    />
                    <div className="flex-fill">
                      <Grid item xs={6} className="pb-0">
                        <div className="d-flex flex-column align-items-center text-center">
                          <div className="mb-3 d-flex flex-column align-items-center w-100">
                            <MaterialPreview
                              type={1}
                              src={
                                values.details?.questions[
                                  questionIndex
                                ].answer_choices[answerIndex]
                                  .image_url
                              }
                            />
                          </div>
                          <Upload
                            label={upload}
                            name={`details.questions[${questionIndex}].answer_choices[${answerIndex}].image_url`}
                            accept="image/*"
                            isImage={true}
                            setFieldValue={setFieldValue}
                            isRequired={true}
                            isMultiple={isDicom ? true : false}
                          />
                        </div>
                      </Grid>
                    </div>
                  </div>
                  <div className="mx-2">
                    {arr?.filter((x) => !x._destroy)[0] && (
                      <div className="d-flex">
                        <AddIcon
                          className="me-2 mt-2 cursor-pointer"
                          onClick={AddAnswer(
                            push,
                            values,
                            questionIndex
                          )}
                        />
                        <div className="mx-2 mt-2 cursor-pointer">
                          {values.details.questions[
                            questionIndex
                          ].answer_choices.every(
                            (el) => el.correct == false
                          ) === true ? (
                            <CheckIcon
                              color="success"
                              className="me-2"
                              onClick={() => {
                                replace(answerIndex, {
                                  ...answer,
                                  correct: true
                                });
                              }}
                            />
                          ) : (
                            values.details.questions[questionIndex]
                              .answer_choices[answerIndex].correct ===
                              true && (
                              <SettingsBackupRestoreIcon
                                onClick={() => {
                                  replace(answerIndex, {
                                    ...answer,
                                    correct: false
                                  });
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {question.answer_choices.filter((x) => !x._destroy)
                    .length > 2 && (
                    <div className="mx-2 mt-2 cursor-pointer">
                      <RemoveIcon
                        className="me-2"
                        onClick={removeAnswer(
                          replace,
                          answerIndex,
                          answer
                        )}
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
      </div>
    </>
  );
}
AnswerAddOrEdit.propTypes = {
  values: PropTypes.shape({
    details: PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          answer_choices: PropTypes.arrayOf(
            PropTypes.shape({
              answer: PropTypes.string,
              correct: PropTypes.bool,
              image_url: PropTypes.string,
              _destroy: PropTypes.bool,
              currentTab: PropTypes.string
            })
          )
        })
      )
    })
  }).isRequired,
  question: PropTypes.shape({
    answer_choices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        answer: PropTypes.string,
        correct: PropTypes.bool,
        image_url: PropTypes.string,
        _destroy: PropTypes.bool,
        currentTab: PropTypes.string
      })
    )
  }).isRequired,
  questionIndex: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  isDicom: PropTypes.bool.isRequired
};

export default AnswerAddOrEdit;

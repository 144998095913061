import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import messages from "../../../assets/locale/messages";
import AuthLayout from "../../../components/AuthLayout";
import { CREATE_PASSWORD_SCHEMA } from "./validations";
import { createPasswordRequest } from "../../../store/Auth/actions";

const CreatePassword = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);

  const {
    inputs: { newPassword, confirmNewPassword },
    auth: {
      show,
      hide,
      createPassword: { title }
    },
    buttons: { createPassword }
  } = messages[lang];

  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState(false);

  const handleSubmit = ({ password }) => {
    dispatch(createPasswordRequest({ password }));
  };

  const renderForm = () => {
    return (
      <>
        <Formik
          initialValues={{
            password: "",
            confirmNewPassword: ""
          }}
          validationSchema={CREATE_PASSWORD_SCHEMA(lang)}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Input
                inputIcon={isShowNewPassword ? hide : show}
                type={!isShowNewPassword ? "password" : "text"}
                label={newPassword}
                name="password"
                onIconClick={() =>
                  setIsShowNewPassword(!isShowNewPassword)
                }
              />
              <Input
                inputIcon={isShowConfirmPassword ? hide : show}
                type={!isShowConfirmPassword ? "password" : "text"}
                label={confirmNewPassword}
                name="confirmNewPassword"
                onIconClick={() =>
                  setIsShowConfirmPassword(!isShowConfirmPassword)
                }
              />
              <div className="d-flex align-items-center justify-content-between mb-4">
                <Button
                  type={"submit"}
                  text={createPassword}
                  className="primary-lg"
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  };

  return <AuthLayout title={title} body={renderForm()} />;
};

export default CreatePassword;

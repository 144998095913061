import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";

export const ADD_EDIT_SUPPLEMENTARY_SCHEMA = () => {
  const { messages } = useIntl();
  const {
    surveys: {
      surveyTitleIsRequired,
      surveyBookIsRequired,
      answerIsRequired,
      questionTitleRequired,
      max60Char
    }
  } = messages;

  return Yup.object().shape({
    title: Yup.string()
      .max(60, max60Char)
      .required(surveyTitleIsRequired),
    book_id: Yup.number(surveyBookIsRequired).required(
      surveyBookIsRequired
    ),
    survey_questions_attributes: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required(questionTitleRequired),
          question_type: Yup.number().required(questionTitleRequired),
          is_mcq_single_choice: Yup.boolean(),
          survey_question_answer_choices_attributes: Yup.array().of(
            Yup.object().shape({
              _destroy: Yup.bool(),
              answer_choice: Yup.string(answerIsRequired).when(
                "_destroy",
                {
                  is: (_destroy) => !_destroy,
                  then: Yup.string(answerIsRequired)
                    .min(1, answerIsRequired)
                    .required(answerIsRequired)
                }
              )
            })
          )
        })
      )
      .required("Required")
  });
};

const questionTypes = new Map([
  [
    "MCQ",
    {
      id: 0,
      value: 0,
      label: <FormattedMessage id="MCQ" />,
      name: "MCQ"
    }
  ],
  [
    "Rating",
    {
      id: 1,
      value: 1,
      label: <FormattedMessage id="Rating" />,
      name: "Rating"
    }
  ],
  [
    "OpenEnded",
    {
      id: 2,
      value: 2,
      label: <FormattedMessage id="OpenEnded" />,
      name: "OpenEnded"
    }
  ]
]);

export const getInitialValuesEdit = (survey = {}) => {
  return {
    book_id: survey?.book_id,
    title: survey?.title,
    survey_questions_attributes: survey?.survey_questions?.map(
      (question) => {
        return {
          id: question.id,
          question_type:
            questionTypes.get(question.question_type).value || 0,
          question: question.question || "",
          is_mcq_single_choice:
            question.is_mcq_single_choice || false,
          survey_question_answer_choices_attributes: question
            .survey_question_answer_choices.length
            ? question.survey_question_answer_choices.map(
                (answer) => ({
                  id: answer.id,
                  answer_choice: answer.answer_choice || ""
                })
              )
            : [
                {
                  answer_choice: ""
                }
              ]
        };
      }
    )
  };
};

export const getInitialValuesAdd = () => {
  return {
    book_id: "",
    title: "",
    survey_questions_attributes: [
      {
        question_type: 0,
        is_mcq_single_choice: false,
        question: "",
        survey_question_answer_choices_attributes: [
          { answer_choice: "" },
          { answer_choice: "" }
        ]
      }
    ]
  };
};

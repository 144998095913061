/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _, { countBy } from "lodash";
import Chart from "react-apexcharts";
import { axiosInstance } from "../../../network/apis";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";

import {
  Box,
  TextField,
  Paper,
  useTheme,
  TextareaAutosize
} from "@material-ui/core";
import {
  getSingleSurveyRequest,
  exportSurveyReport
} from "../../../store/Surveys/actions";
import Input from "../../../components/Input";

import messages from "../../../assets/locale/messages";
import Modal from "../../../components/Modal";

const SurveyStatistics = () => {
  const dispatch = useDispatch();
  let params = useParams();
  const theme = useTheme();
  const booksList = useSelector((state) => state.books.list);
  const [allData, setAllData] = useState();
  const [modalExport, setModalExport] = useState(false);

  const [series, setSeries] = useState({});
  const lang = useSelector((state) => state.locale.lang);
  const formRef = useRef();

  const {
    preTestPostTest: {
      report: { exportReport, userEmailRequired }
    },
    cols: { email }
  } = messages[lang];

  const getInitialValues = () => {
    return {
      email: ""
    };
  };

  const REPORT_EXPORT_SCHEMA = () => {
    return Yup.object().shape({
      email: Yup.string().email().required(userEmailRequired)
    });
  };

  const handleSubmit = (values) => {
    dispatch(exportSurveyReport({ ...values, id: params?.id }));
    setModalExport(false);
  };

  const handleModalCancel = () => {
    setModalExport(false);
  };
  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const formBarChart = (question) => {
    return {
      series: [
        {
          name: "",
          data: question.map((q) => q.count)
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 100,
          width: 100
        },
        plotOptions: {
          bar: {
            barHeight: "20%",
            distributed: false,
            horizontal: true,
            dataLabels: {
              position: "bottom"
            }
          },
          dataLabels: {
            position: "center" // top, center, bottom
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: question.map((q) => q.answer_choice),
          min: 0,
          labels: {
            formatter: function (val) {
              let num = val.toFixed(2);
              return num % 1 !== 0 ? "" : Number(num);
            }
          }
        },
        yaxis: {
          trim: false,
          labels: {
            formatter: (value) => {
              return value.length <= 23 ? value : value;
            }
          }
        },
        stacked: true,
        fill: { opacity: 1 }
      }
    };
  };

  const singleSurvey = useSelector(
    (state) => state.surveys.singleSurvey
  );

  useEffect(() => {
    if (params?.id) {
      dispatch(getSingleSurveyRequest(params.id));
    }
  }, [params]);

  useEffect(async () => {
    if (singleSurvey?.survey_questions?.length) {
      const ids = await singleSurvey?.survey_questions?.map(
        ({ id, question_type }) => ({
          id: id,
          type: question_type,
          endpoint: `survey_questions/${id}/statistics`
        })
      );

      const responses = await axios
        .all(
          ids?.map(({ endpoint, id, type }) =>
            axiosInstance.get(endpoint, {
              params: { id: id, type: type }
            })
          )
        )
        .then((data) => {
          setAllData(
            data.map((data) => ({
              type: data.config.params.type,
              id: data.config.params.id,
              data: data.data.statistics
            }))
          );
        });
    }
  }, [singleSurvey]);

  return (
    <div id="chart" className="container">
      {singleSurvey.is_survey_answered_previously && (
        <div className="row">
          <div className="d-flex justify-content-end">
            <Button
              text={exportReport}
              onClick={() => setModalExport(true)}
            />
          </div>
        </div>
      )}

      <div className="row">
        {allData?.map((data) => {
          if (data.type === "MCQ") {
            const series = formBarChart(data.data);
            return (
              <div className="col-12 d-flex flex-column justift-content-center">
                <h3 className="mb5">
                  {
                    singleSurvey.survey_questions.find(
                      (q) => q.id === data.id
                    )?.question
                  }
                </h3>
                <Chart
                  options={series.options}
                  series={series.series}
                  type="bar"
                  width="700"
                />
              </div>
            );
          }
        })}

        <div className="row my-5">
          {allData?.map((data) => {
            if (data.type === "Rating") {
              return (
                <div className="col-12">
                  <div>
                    <h3>
                      {
                        singleSurvey.survey_questions.find(
                          (q) => q.id === data.id
                        )?.question
                      }
                    </h3>
                    <h2 className="fs-5">Average: {data.data}</h2>
                  </div>
                </div>
              );
            }
          })}
        </div>

        <div className="row my-5">
          {allData?.map((data) => {
            if (data.type === "OpenEnded") {
              return (
                <div className="col-12">
                  <h3>
                    {
                      singleSurvey.survey_questions.find(
                        (q) => q.id === data.id
                      )?.question
                    }
                  </h3>
                  {data.data
                    .map((item) => item.open_ended_answer)
                    .map((answer, index) => {
                      return (
                        <div
                          key={JSON.stringify(index)}
                          className="my-5"
                        >
                          <TextareaAutosize
                            style={{ width: "100%" }}
                            minRows={5}
                            maxRows={5}
                            disabled
                            label={""}
                            placeholder={"'Total Quiz Points'"}
                            className="mx-3"
                            value={answer}
                          />
                        </div>
                      );
                    })}
                </div>
              );
            }
          })}
        </div>
      </div>
      <Modal
        title={exportReport}
        hasActions={true}
        isOpen={modalExport}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      >
        <Formik
          initialValues={getInitialValues()}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validationSchema={REPORT_EXPORT_SCHEMA(lang)}
        >
          {() => (
            <Form>
              <Input name="email" label={email} />
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default SurveyStatistics;

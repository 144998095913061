import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteBookRequest } from "../../../store/Books/actions";
import { deleteAction } from "../../../store/ActionsBtn/actions";

const BookDelete = ({ isOpen, lang }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);

  const {
    books: { deleteBook, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteBookRequest(deleteId));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteBook}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
    />
  );
};

BookDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

BookDelete.defaultProps = {
  isOpen: false
};

export default BookDelete;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { Typography, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BooksList from "./BooksList";
import Button from "../../components/Button";
import Search from "../../components/Search";
import CustomSelect from "../../components/Select";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import BookDelete from "./BookDelete";
import {
  getBooksRequest,
  setBooksFilters
} from "../../store/Books/actions";
import AssignQuiz from "../Quizzes/AssignQuiz";

const Books = () => {
  const perPage = 10;
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const categoriesList = useSelector(
    (state) => state.categories.list
  );
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const searchKeyword = useSelector(
    (state) => state.books.searchKeyword
  );
  const selectedCategory = useSelector(
    (state) => state.books.selectedCategory
  );

  const booksList = useSelector((state) => state.books.list);
  console.log("at Books", booksList);

  const assignQuizBtn = useSelector(
    (state) => state.quizzes.assignQuizBtn
  );

  const {
    books: { title, addBook },
    categories
  } = messages[lang];

  const handleSearch = (e) => {
    dispatch(
      setBooksFilters({
        selectedCategory: selectedCategory,
        searchKeyword: e.target.value
      })
    );
  };

  const handleSelectChange = (id) => {
    dispatch(
      setBooksFilters({
        selectedCategory: id,
        searchKeyword
      })
    );
  };

  useEffect(() => {
    function filterBooksList() {
      const params = {
        category_id: selectedCategory,
        search_term: searchKeyword,
        page: 1,
        items: perPage,
        paginate: true
      };
      dispatch(getBooksRequest(params));
    }
    filterBooksList();
  }, [searchKeyword, selectedCategory]);

  useEffect(() => {
    if (categoriesList.length) {
      setCategoriesOptions(
        categoriesList.map(({ id, name }) => ({
          id,
          value: name,
          label: name
        }))
      );
    }
  }, [categoriesList]);

  useEffect(() => {
    return () => {
      dispatch(
        setBooksFilters({
          selectedCategory: "",
          searchKeyword: ""
        })
      );
    };
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Formik initialValues={{ search: "", category: "" }}>
            {() => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={7}>
                    <Search
                      name="search"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <CustomSelect
                      options={categoriesOptions}
                      placeholder={categories.title}
                      name="category"
                      handleSelectChange={handleSelectChange}
                      isAddNoneOption={true}
                    />
                  </Grid>
                </Grid>
                <button className="d-none"></button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          xs={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addBook}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            slug={ROUTE_PATHS["addBook"]}
          />
        </Grid>
      </Grid>
      <BooksList lang={lang} list={booksList} />
      <BookDelete isOpen={!!deleteId} lang={lang} />
      <AssignQuiz isOpen={Boolean(assignQuizBtn)} />
    </>
  );
};

export default Books;

export const questions_types = new Map([
  [
    0,
    {
      id: 0,
      value: 0,
      label: "MCQ",
      name: "MCQ"
    }
  ],
  [
    1,
    {
      id: 1,
      value: 1,
      label: "Rating",
      name: "Rating"
    }
  ],
  [
    2,
    {
      id: 2,
      value: 2,
      label: "Open Ended",
      name: "OpenEnded"
    }
  ]
]);

export const RatingMapping = {
  Strongly_Agree: 4,
  Agree: 3,
  Disagree: 2,
  Strongly_Disagree: 1
};

import { useIntl } from "react-intl";
import * as Yup from "yup";

export const ADD_EDIT_TEST_SCHEMA = () => {
  const { messages } = useIntl();
  const {
    finalTest: {
      finalTestBookIsRequired,
      answerIsRequired,
      questionTitleRequired,
      questionPointseRequired,
      questionPointseMin,
      pointsInteger,
      imageRequired,
      maxNote
    }
  } = messages;

  return Yup.object().shape({
    book_id: Yup.number().required(finalTestBookIsRequired),
    total_score: Yup.number("testTotalScoreIsRequired")
      .nullable()
      .min(0),
    note: Yup.string().nullable().max(800, maxNote),
    details: Yup.object()
      .shape({
        questions: Yup.array().of(
          Yup.object().shape({
            question: Yup.string().required(questionTitleRequired),
            points: Yup.number(questionPointseRequired)
              .required(questionPointseRequired)
              .nullable()
              .min(0.1, questionPointseMin)
              .test(
                "maxDigitsAfterDecimal",
                pointsInteger,
                (number) => /^\d+(\.\d{1})?$/.test(number)
              )
          })
        )
      })
      .test(
        "totalPointsEqual",
        "Total points of questions must equal total exam score",
        function () {
          const totalExamScore = this.parent.total_score;
          const questions = this.parent.details.questions;

          console.log({ totalExamScore, questions });

          if (!Array.isArray(questions) || questions.length === 0) {
            return this.createError({
              path: "details.questions",
              message: "There should be at least one question"
            });
          }

          const totalPoints = questions?.reduce((sum, question) => {
            return sum + (question.points || 0);
          }, 0);

          console.log("totalPoints", totalPoints);

          if (totalPoints !== totalExamScore) {
            return this.createError({
              path: "total_score",
              message:
                "Total points of questions must equal total exam scores"
            });
          }
          return true;
        }
      ),
    answer_choices: Yup.array().of(
      Yup.object().shape({
        currentTab: Yup.string(),
        answer: Yup.string().when("currentTab", {
          is: (currentTab) =>
            currentTab === "text" || currentTab === "text_image",
          then: Yup.string(answerIsRequired)
            .required(answerIsRequired)
            .nullable(),
          otherwise: Yup.string(answerIsRequired).nullable()
        }),
        correct: Yup.boolean(),
        justification: Yup.string(),
        image_url: Yup.string().when("currentTab", {
          is: (currentTab) =>
            currentTab === "image" || currentTab === "text_image",
          then: Yup.string(imageRequired)
            .required(imageRequired)
            .nullable(),
          otherwise: Yup.string(imageRequired).nullable()
        })
      })
    )
  });
};

export const getInitialValuesAdd = () => {
  return {
    book_id: 0,
    total_score: null,
    note: "",
    details: {
      questions: [
        {
          points: null,
          question: "",
          image_url: "",
          answer_choices: [
            {
              currentTab: "text",
              answer: "",
              correct: false,
              image_url: "",
              justification: ""
            },
            {
              currentTab: "text",
              answer: "",
              correct: false,
              justification: "",
              image_url: ""
            }
          ]
        }
      ]
    }
  };
};

export const getInitialValuesEdit = (finalTest = {}) => {
  return {
    book_id: finalTest?.book_id || "",
    total_score: finalTest?.total_score || 0,
    note: finalTest?.note || "",
    details: {
      questions:
        finalTest?.details?.questions?.map((question) => {
          return {
            id: question?.question_id,
            question: question.question || "",
            points: question.points || 0,
            image_url: question.image_url || "",
            justification: question.justification,
            justification_image_url: question.justification_image_url,
            answer_choices: question.answer_choices?.length
              ? question.answer_choices.map((answer) => ({
                  id: answer.answer_choice_id,
                  answer: answer.answer || "",
                  correct: answer.correct || false,
                  currentTab:
                    answer.image_url && answer.answer
                      ? "text_image"
                      : answer.answer
                      ? "text"
                      : "image",
                  image_url: answer.image_url || "",
                  justification: answer.justification || "",
                  answer_choice: answer.answer_choice || ""
                }))
              : [
                  {
                    answer: "",
                    correct: false,
                    justification: "",
                    image_url: ""
                  }
                ]
          };
        }) || []
    }
  };
};

import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/promoCodes";
import {
  getPromoCodesListResponse,
  addPromoCodeResponse,
  getPromoCodesListRequest,
  getSinglePromoCodeResponse
} from "./actions";
import messages from "../../assets/locale/messages";
import { showHideSnackbar } from "../Snackbar/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";

export function* getPromoCodesListSaga({ payload }) {
  try {
    const response = yield call(apis.getPromoCodesList, payload);
    yield put(
      getPromoCodesListResponse({
        list: response.data["promo codes"],
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* addPromoCodesSaga({ payload }) {
  try {
    const response = yield call(apis.addPromoCode, payload);
    yield put(addPromoCodeResponse(response.data));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].promoCodes.addMessageSuccess
      })
    );
    yield history.push(ROUTE_PATHS.promoCodes);
  } catch (err) {
    console.error(err);
  }
}

export function* deletePromoCodeSaga({ payload }) {
  try {
    const response = yield call(apis.deletePromoCode, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].promoCodes.deletePromocodeSuccess
      })
    );
    yield put(getPromoCodesListRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* getSinglePromocodeSaga({ payload }) {
  try {
    const response = yield call(apis.getSinglePromocode, payload);
    yield put(getSinglePromoCodeResponse(response.data.promocode));
  } catch (err) {
    console.error(err);
  }
}

import * as Yup from "yup";
import messages from "../../../assets/locale/messages";

export const LOGIN_SCHEMA = (lang) => {
  const {
    inputs: { emailRequired, passwordRequired }
  } = messages[lang];

  return Yup.object().shape({
    password: Yup.string().required(passwordRequired),
    email: Yup.string().required(emailRequired)
  });
};

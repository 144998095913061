import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import { add, deleteAction } from "../../../store/ActionsBtn/actions";
import { getSupplementariesRequest } from "../../../store/Supplementaries/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

const SupplementariesList = ({ list, lang }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.supplementaries.meta);
  const searchKeyword = useSelector(
    (state) => state.supplementaries.searchKeyword
  );
  const selectedType = useSelector(
    (state) => state.supplementaries.selectedType
  );

  const [page, setPage] = useState(1);
  const perPage = 10;

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getSupplementariesRequest({
        material_type: selectedType,
        title: searchKeyword,
        page: page,
        items: perPage
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(deleteAction(id));
  };

  const handleAssignToBook = (id) => {
    dispatch(add(id));
  };

  const handleView = (id) => {
    history.push(`${ROUTE_PATHS["supplementaryDetails"]}/${id}`);
  };

  const handleEdit = (id) => {
    history.push(`${ROUTE_PATHS["editSupplementary"]}/${id}`);
  };

  const {
    supplementaries: { materialType, materialTitle, assignBooks },
    shared: { view, editLabel, deleteLabel }
  } = messages[lang];
  return (
    <>
      <Table
        headlines={[materialTitle, materialType]}
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.title}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.material_type}
            </TableCell>

            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <Tooltip title={assignBooks}>
                <span>
                  <IconButton
                    onClick={() => handleAssignToBook(row.id)}
                    aria-label="assign"
                  >
                    <MenuBookIcon
                      className="table__assign"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={view}>
                <span>
                  <IconButton
                    onClick={() => handleView(row.id)}
                    aria-label="view"
                  >
                    <VisibilityIcon
                      className="table__view"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={editLabel}>
                <span>
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

SupplementariesList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object)
};

export default SupplementariesList;

import * as apis from "../../network/apis/finalTests";
import { put, call } from "redux-saga/effects";
import {
  deleteSingleFinalTestResponse,
  editSingleFinalTestResponse,
  getAllFinalTestsRequest,
  getAllFinalTestsResponse,
  getSingleFinalTestsResponse,
  setNewFinalTestResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";
import { edit } from "../ActionsBtn/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";
export function* getAllFinalTestsSaga({ payload }) {
  try {
    const response = yield call(apis.getAllFinalTests, payload);

    yield put(
      getAllFinalTestsResponse({
        list: response.data.final_tests,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getSingleFinalTestsSaga({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(apis.getSingleFinalTest, id);

    yield put(
      getSingleFinalTestsResponse({
        singleFinalTest: response.data.final_tests
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* editSingleFinalTestSaga({ payload }) {
  try {
    const { id, final_test } = payload;
    const response = yield call(
      apis.editSingleFinalTest,
      id,
      final_test
    );

    yield put(
      editSingleFinalTestResponse({
        singleFinalTest: response.data.final_tests
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].finalTest.editFinalTestSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* setNewSingleFinalTestsSaga({ payload }) {
  try {
    const response = yield call(apis.setNewFinalTest, payload);

    yield put(
      setNewFinalTestResponse({
        newFinalTest: response.data.final_tests
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].finalTest.addFinalTestSuccess
      })
    );
    yield history.push(ROUTE_PATHS.finalTest);
    yield put(getAllFinalTestsRequest({ items: 6, page: 1 }));
  } catch (err) {
    console.error(err);
  }
}

export function* deleteSingleFinalTestSaga({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(apis.deleteSingleFinalTest, id);
    yield put(edit(false));

    yield put(
      deleteSingleFinalTestResponse({
        singleFinalTest: response.data.final_tests
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].finalTest.deleteFinalTestSuccess
      })
    );

    yield put(getAllFinalTestsRequest());
  } catch (err) {
    console.error(err);
  }
}

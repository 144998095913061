import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import {
  FormControl,
  OutlinedInput,
  InputAdornment
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import styles from "./Search.module.scss";

const Search = ({ name, placeholder, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <Field name={name}>
        {() => (
          <FormControl
            className={styles.textField}
            variant="outlined"
          >
            <OutlinedInput
              id="search-input"
              placeholder={placeholder}
              type="string"
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                  {/* {field.value.length == 0 && <SearchIcon />} */}
                  {/* {field.value.length > 0 && (
                    <HighlightOffIcon
                      className={styles.icon}
                      onClick={() => handleResetSearch(field)}
                    />
                  )} */}
                </InputAdornment>
              }
              classes={{
                input: styles.input,
                notchedOutline: styles.fieldset
              }}
              {...props}
            />
          </FormControl>
        )}
      </Field>
    </div>
  );
};

Search.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string
};

Search.defaultProps = {
  name: "",
  placeholder: ""
};

export default Search;

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";

import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { reassignAction } from "../../../store/ActionsBtn/actions";
import messages from "../../../assets/locale/messages";
import { getBooksRequest } from "../../../store/Books/actions";
import { reassignSupplementaryToBookVersionRequest } from "../../../store/Supplementaries/actions";

const BookVersionAssignSupplementaries = ({
  isOpen,
  lang,
  singleBook
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const supplementary_book_id = useSelector(
    (state) => state.actionsBtn.reassign
  );

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
  }, []);

  const handleModalCancel = () => {
    dispatch(reassignAction(false));
  };

  const handleSubmit = (values) => {
    const addData = {
      data: values,
      id: supplementary_book_id,
      book_id: singleBook.id
    };
    dispatch(reassignSupplementaryToBookVersionRequest(addData));
    dispatch(reassignAction(false));
  };

  const {
    supplementaries: {
      bookPageLabel,
      reAssignedPage,

      validation: { pageRequired, validPage }
    }
  } = messages[lang];

  const ASSIGN_SUPPLEMENTARY_SCHEMA = (book_page) => {
    return Yup.object().shape({
      book_page: Yup.string()
        .required(pageRequired)
        .test(
          "in book page rang",
          `${validPage} ${book_page}`,
          (value) => value > 0 && value <= book_page
        )
    });
  };

  const getInitialValues = () => {
    return {
      book_page: ""
    };
  };
  return (
    <Modal
      title={reAssignedPage}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={getInitialValues()}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={ASSIGN_SUPPLEMENTARY_SCHEMA(lang)}
      >
        {() => (
          <Form>
            <Input
              name="book_page"
              label={bookPageLabel}
              isRequired={true}
              isNumber={true}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

BookVersionAssignSupplementaries.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  singleBook: PropTypes.object,
  page: PropTypes.number
};

BookVersionAssignSupplementaries.defaultProps = {
  isOpen: false
};

export default BookVersionAssignSupplementaries;

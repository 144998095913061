import * as types from "./types";

export const getInsightsRequest = () => ({
  type: types.GET_INSIGHTS_REQUEST
});
export const setInsightsRequest = (data) => ({
  type: types.SET_INSIGHTS_REQUEST,
  payload: data
});

export const getBooksDashboardRequest = (data) => ({
  type: types.SET_BOOKS_REQUEST,
  payload: data
});

export const getBooksDashboardResponse = () => ({
  type: types.GET_BOOKS_REQUEST
});

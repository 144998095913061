import * as types from "./types";

export const getPromoCodesListRequest = (payload) => ({
  type: types.GET_PROMOCODES_LIST_REQUEST,
  payload
});
export const getPromoCodesListResponse = (payload) => ({
  type: types.GET_PROMOCODES_LIST_RESPONSE,
  payload
});

export const addPromoCodeRequest = (data) => ({
  type: types.ADD_PROMOCODES_REQUEST,
  payload: data
});

export const addPromoCodeResponse = (data) => ({
  type: types.ADD_PROMOCODES_RESPONSE,
  payload: data
});

export const setPromoCodesFilters = (data) => ({
  type: types.SET_PROMOCODES_FILTERS,
  payload: data
});

export const getSinglePromoCodeRequest = (data) => ({
  type: types.GET_SINGLE_PROMOCODE_REQUEST,
  payload: data
});

export const getSinglePromoCodeResponse = (data) => ({
  type: types.GET_SINGLE_PROMOCODE_RESPONSE,
  payload: data
});

export const deletePromoCodeRequest = (data) => ({
  type: types.DELETE_PROMOCODE_REQUEST,
  payload: data
});

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import Button from "../../../components/Button";
import { getBooksRequest } from "../../../store/Books/actions";
import {
  assignBooksToProgram,
  setSelectedBooks
} from "../../../store/Programs/actions";

function AssignBooks({ id }) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const booksList = useSelector((state) => state.books.list);
  const { selectedBooks } = useSelector((state) => state.programs);
  const {
    programs: { assignBooks },
    shared: { editLabel }
  } = messages[lang];
  useEffect(() => {
    function filterBooksList() {
      const params = {
        category_id: "",
        search_term: "",
        page: 1,
        items: 1000,
        paginate: true
      };
      dispatch(getBooksRequest(params));
    }
    filterBooksList();
  }, []);

  const handleBooksSubmit = () => {
    dispatch(
      assignBooksToProgram({
        id,
        data: {
          program: {
            book_ids: selectedBooks
          }
        }
      })
    );
  };

  const handleEditSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3-content"
        id="panel3-header"
      >
        {assignBooks}
      </AccordionSummary>

      <AccordionDetails>
        <Formik
          innerRef={formRef}
          onSubmit={handleBooksSubmit}
          initialValues={{}}
        >
          {() => (
            <Form>
              <Autocomplete
                multiple
                limitTags={4}
                id="multiple-assigned-books"
                options={booksList}
                getOptionLabel={(option) => option?.title}
                value={selectedBooks
                  .map((selectedId) =>
                    booksList.find((b) => b.id === selectedId)
                  )
                  .filter(Boolean)}
                defaultValue={selectedBooks
                  .map((selectedId) =>
                    booksList.find((b) => b.id === selectedId)
                  )
                  .filter(Boolean)}
                onChange={(_, elements) => {
                  if (elements.length) {
                    const selectedIds = elements.map((b) => b.id);
                    dispatch(setSelectedBooks(selectedIds));
                  } else dispatch(setSelectedBooks([]));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assigned Books"
                    placeholder="Books"
                  />
                )}
              />
            </Form>
          )}
        </Formik>
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={handleEditSubmit} text={editLabel} />
      </AccordionActions>
    </Accordion>
  );
}

AssignBooks.propTypes = {
  id: PropTypes.string
};

export default AssignBooks;

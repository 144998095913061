import { axiosInstance } from "./";

const getInstitutions = async (params) => {
  return await axiosInstance.get(`/institutions`, {
    params
  });
};

const deleteInstitution = async (id) => {
  return await axiosInstance.delete(`/institutions/${id}`);
};

const addInstitution = async (payload) => {
  return await axiosInstance.post(`/institutions`, payload);
};

const editInstitution = async ({ data, id }) => {
  return await axiosInstance.patch(`/institutions/${id}`, data);
};

const getSingleInstitution = async (id) => {
  return await axiosInstance.get(`/institutions/${id}`);
};

export {
  getInstitutions,
  deleteInstitution,
  addInstitution,
  editInstitution,
  getSingleInstitution
};

import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

const PreTestPostTestList = ({ list, lang, inBookView }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let test = params.get("test");
  useEffect(() => {
    if (!test) sessionStorage.removeItem("bookSelected");
  }, []);
  const handleDelete = (id) => {
    dispatch(deleteAction(id));
  };

  const handleView = (id) => {
    history.push(`${ROUTE_PATHS["preTestPostTestDetails"]}/${id}`);
  };

  const handleEdit = (id) => {
    history.push(`${ROUTE_PATHS["editPreTestPostTest"]}/${id}`);
  };

  const handleViewReport = (id) => {
    history.push(`${ROUTE_PATHS["userTestReports"]}/${id}`);
  };

  const {
    preTestPostTest: { titleLabel, book, prePage, postPage, reports },
    shared: { view, editLabel, deleteLabel }
  } = messages[lang];
  return (
    <>
      <Table
        headlines={
          inBookView
            ? [titleLabel, prePage, postPage]
            : [titleLabel, book]
        }
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell
              className="table__cell"
              align="left"
              style={{ maxWidth: "250px" }}
            >
              {row.title}
            </TableCell>
            {inBookView && (
              <>
                <TableCell className="table__cell" align="left">
                  {row.pre_page}
                </TableCell>
                <TableCell className="table__cell" align="left">
                  {row.post_page}
                </TableCell>
              </>
            )}
            {!inBookView && (
              <TableCell className="table__cell" align="left">
                {row.book_title}
              </TableCell>
            )}

            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <Tooltip title={view}>
                <span>
                  <IconButton
                    onClick={() => handleView(row.id)}
                    aria-label="view"
                  >
                    <VisibilityIcon
                      className="table__view"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={reports}>
                <span>
                  <IconButton
                    onClick={() => handleViewReport(row.id)}
                    aria-label="statitics"
                  >
                    <BarChartIcon
                      className="table__statitics"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={editLabel}>
                <span>
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={deleteLabel}>
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
    </>
  );
};

PreTestPostTestList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  inBookView: PropTypes.boolean
};

export default PreTestPostTestList;

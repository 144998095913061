import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import messages from "../../../assets/locale/messages";
import Table from "../../../components/Table";
import { getCategoriesRequest } from "../../../store/Categories/actions";
import {
  edit,
  deleteAction
} from "../../../store/ActionsBtn/actions";
import Pagination from "../../../components/Pagination";
import EmptyState from "../../../components/EmptyState";

const CategoriesList = ({ lang }) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.categories.list);
  const meta = useSelector((state) => state.categories.meta);
  const searchKeyword = useSelector(
    (state) => state.categories.searchKeyword
  );
  const isLoading = useSelector((state) => state.loader);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const {
    cols: { name }
  } = messages[lang];

  useEffect(() => {
    function getCategories(params) {
      dispatch(getCategoriesRequest(params));
    }
    getCategories({
      page: page,
      items: perPage,
      paginate: true
    });
  }, []);

  const handleEdit = (id) => {
    dispatch(edit(id));
  };

  const handleDelete = (id) => {
    dispatch(deleteAction(id));
  };

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getCategoriesRequest({
        page: page,
        items: perPage,
        name: searchKeyword ? searchKeyword : "",
        paginate: true
      })
    );
  };

  return (
    <>
      <Table
        headlines={[name]}
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.name}
            </TableCell>
            <TableCell className="table__cell" align="right">
              <IconButton
                onClick={() => handleEdit(row.id)}
                aria-label="edit"
              >
                <EditIcon className="table__edit" fontSize="medium" />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(row.id)}
              >
                <DeleteIcon
                  className="table__delete"
                  fontSize="medium"
                />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

CategoriesList.propTypes = {
  lang: PropTypes.string
};

export default CategoriesList;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import "react-phone-number-input/style.css";
import "./institution.scss";
import PhoneInput, {
  isPossiblePhoneNumber
} from "react-phone-number-input";
import { FieldArray, Form, Formik } from "formik";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme
} from "@material-ui/core";
import Input from "../../components/Input";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import { axiosInstance } from "../../network/apis";

const InstitutionDetails = () => {
  const { messages } = useIntl();
  const msgs = messages.institute;
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone_numbers: [
      {
        number: "",
        isValid: false,
        _destroy: false
      }
    ],
    emails: [
      {
        email: "",
        isValid: false,
        _destroy: false
      }
    ],
    subscription_start_date: "",
    subscription_end_date: "",
    ip_ranges_attributes: [
      {
        from: "",
        to: "",
        _destroy: false
      }
    ]
  });
  const { id } = useParams();

  const getInstituteData = async () => {
    const response = await axiosInstance.get(`/institutions/${id}`);
    const data = response.data.institution;
    const instituteData = {
      ...initialValues,
      name: data.name,
      phone_numbers: data.phone_numbers.map((num) => ({
        number: "+" + num.toString(),
        isValid: true,
        _destroy: false
      })),
      emails: data.emails.map((email) => ({
        email,
        isValid: true,
        _destroy: false
      })),
      ip_ranges_attributes: data.IP_ranges.map((range) => ({
        to: range.to,
        from: range.from,
        isValid: true,
        _destroy: false
      })),
      subscription_start_date: data.subscription_start_date,
      subscription_end_date: data.subscription_end_date
    };
    setInitialValues(instituteData);
  };

  useEffect(() => {
    if (id) {
      getInstituteData();
    }
  }, [id]);

  const {
    inputs: { emailAddress },
    institute: { instituteName, ipFrom, ipTo, startDate, endDate }
  } = messages;

  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {msgs.instituteDetails}
        </Typography>
      </div>

      <Formik initialValues={initialValues} enableReinitialize>
        {({ setFieldValue, values, setTouched, touched }) => (
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={12} className="pb-0 ">
                <Input
                  isDisabled={true}
                  name="name"
                  label={instituteName}
                  isRequired={true}
                />
              </Grid>
              <FieldArray name="phone_numbers">
                {({ push }) => (
                  <div className="phoneInputWrapper my-2 mx-5">
                    {values.phone_numbers.map(
                      (phone, index, arr) =>
                        !phone._destroy && (
                          <Grid
                            key={JSON.stringify(index)}
                            className="d-flex align-items-center my-2"
                          >
                            <PhoneInput
                              className="input"
                              inpup
                              disabled
                              placeholder={
                                messages.institute.phoneNumber
                              }
                              value={phone.number}
                              onChange={(value) => {
                                setTouched({
                                  ...touched,
                                  phone_numbers: true
                                });
                                const phone = value || "";
                                const phone_numbers = [
                                  ...values.phone_numbers
                                ];
                                phone_numbers[index] = {
                                  number: phone,
                                  isValid:
                                    isPossiblePhoneNumber(phone),
                                  _destroy: false
                                };
                                setFieldValue(
                                  "phone_numbers",
                                  phone_numbers
                                );
                              }}
                            />
                          </Grid>
                        )
                    )}

                    {touched.phone_numbers &&
                      !values.phone_numbers
                        .map((item) => item.isValid)
                        .every((isValid) => isValid) && (
                        <div className="text-secondary mt-2">
                          {
                            messages.institute.validations
                              .invalidPhone
                          }
                        </div>
                      )}
                  </div>
                )}
              </FieldArray>

              <Grid item xs={12} className="pb-0">
                <Grid container xs={12}>
                  <FieldArray name="emails">
                    {({ push, remove }) => (
                      <div>
                        {values.emails.map(
                          (email, index, arr) =>
                            !email._destroy && (
                              <div
                                key={JSON.stringify(index)}
                                className="d-flex align-items-center my-2"
                              >
                                <Input
                                  isDisabled={true}
                                  placeholder={
                                    messages.institute.email
                                  }
                                  name={`emails[${index}]['email']`}
                                  label={emailAddress}
                                />
                              </div>
                            )
                        )}
                        {touched.emails &&
                          !values.emails
                            .map((item) => item.isValid)
                            .every((isValid) => isValid) && (
                            <div className="text-secondary mt-2">
                              Please enter valid numbers
                            </div>
                          )}
                      </div>
                    )}
                  </FieldArray>
                </Grid>
                <div className="d-flex mb-5 w-50">
                  <Grid item xs={8}>
                    <DatePicker
                      isDisabled
                      placeholder={startDate}
                      setFieldValue={setFieldValue}
                      name="subscription_start_date"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <DatePicker
                      isDisabled
                      placeholder={endDate}
                      setFieldValue={setFieldValue}
                      name="subscription_end_date"
                    />
                  </Grid>
                </div>
                <FieldArray name="ip_ranges_attributes">
                  {({ push }) => (
                    <div>
                      {values?.ip_ranges_attributes?.map(
                        (ipRange, index, arr) =>
                          !ipRange._destroy && (
                            <div
                              className="d-flex gap-3"
                              key={JSON.stringify(index)}
                            >
                              <Input
                                isDisabled={true}
                                name={`ip_ranges_attributes[${index}].from`}
                                label={ipFrom}
                                isRequired={true}
                              />
                              <Input
                                name={`ip_ranges_attributes[${index}].to`}
                                label={ipTo}
                                isRequired={true}
                                isDisabled={true}
                              />
                            </div>
                          )
                      )}
                    </div>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InstitutionDetails;

import store from "../../store";
import { showHideLoader } from "../../store/Loader/actions";
import history from "../../routes/History";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import { loginResponse } from "../../store/Auth/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";

export const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(
    config,
    "handlerEnabled"
  ) && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    store.dispatch(showHideLoader(true));
    const adminData =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(sessionStorage.getItem("admin")) ||
      JSON.parse(localStorage.getItem("tempAdmin"));
    const token = adminData?.auth_token;
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return request;
};

export const successHandler = (response) => {
  const state = store?.getState();

  if (isHandlerEnabled(response)) {
    store.dispatch(showHideLoader(false));
    response.lang = state.locale.lang;
  }
  return response;
};

export const errorHandler = (error) => {
  if (error.response.status === 401) {
    store.dispatch(loginResponse({}));

    history.push(ROUTE_PATHS["login"]);
  }
  console.log("interceptor error.config", error.config);

  if (isHandlerEnabled(error.config)) {
    store.dispatch(showHideLoader(false));
    store.dispatch(
      showHideSnackbar({
        isOpen: true,
        type: "error",
        message: error.response.data.message
      })
    );
  }
  return Promise.reject({ ...error });
};

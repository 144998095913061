export const CERTIFICATE_TYPES = [
  { id: "CME", value: "CME", label: "CME" },
  { id: "CE", value: "CE", label: "CE" },
  { id: "MEDBLEND", value: "MEDBLEND", label: "MEDBLEND" }
];

export const MATERIAL_TYPES = [
  { id: 0, value: "Video", label: "Video" },
  { id: 1, value: "Image", label: "Image" },
  { id: 2, value: "DICOM", label: "DICOM" }
];

export const SUPPORTED_IMAGES_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/tiff"
];

export const ASC = "ASC";
export const DESC = "DESC";

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import { deleteTestRequest } from "../../../store/Tests/actions";

const PreTestPostTestDelete = ({
  isOpen,
  lang,
  bookId = null,
  handleCancelDelete
}) => {
  const dispatch = useDispatch();
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const {
    preTestPostTest: { deletePreTestPostTest, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteTestRequest({ deleteId, bookId }));
    dispatch(deleteAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deletePreTestPostTest}
      isOpen={isOpen}
      lang={lang}
      handleCancel={handleCancelDelete}
      handleDelete={handleDelete}
    />
  );
};

PreTestPostTestDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  bookId: PropTypes.number,
  handleCancelDelete: PropTypes.func
};

PreTestPostTestDelete.defaultProps = {
  isOpen: false
};

export default PreTestPostTestDelete;

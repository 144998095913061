import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  singleFinalTest: null,
  newFinalTest: null,
  searchKeyword: ""
};

const finalTestsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_FINAL_TEST_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_SINGLE_FINAL_TEST_RESPONSE:
      return {
        ...state,
        singleFinalTest: action.payload.singleFinalTest
      };
    case types.SET_FINAL_TEST_SEARCH:
      return {
        ...state,
        searchKeyword: action.payload.searchKeyword
      };
    case types.SET_FINAL_TEST_RESPONSE:
      return {
        ...state,
        newFinalTest: action.payload.newFinalTest
      };
    case types.EDIT_FINAL_TEST_RESPONSE:
      return {
        ...state,
        singleFinalTest: action.payload.singleFinalTest
      };
    case types.DELETE_FINAL_TEST_RESPONSE:
      return {
        ...state,
        singleFinalTest: action.payload.singleFinalTest
      };
    default:
      return state;
  }
};

export default finalTestsReducer;

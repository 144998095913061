import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import {
  ADD_ADMIN_SCHEMA,
  EDIT_ADMIN_SCHEMA,
  getInitialValues
} from "./validations";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { edit, add } from "../../../store/ActionsBtn/actions";
import messages from "../../../assets/locale/messages";
import {
  addAdminRequest,
  editAdminRequest
} from "../../../store/Admins/actions";

const AdminForm = ({ mode, selectedAdmin, isOpen, lang }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.actionsBtn.edit);
  const currentAdmin = useSelector((state) => state.auth.login);

  const {
    admins: { addTitle, editTitle, adminNameLabel, adminEmailLabel }
  } = messages[lang];

  const handleSubmit = ({ name, email }) => {
    const addData = { name, email };
    const editData = {
      name,
      id,
      currentAdmin: currentAdmin
    };
    mode === "add"
      ? dispatch(addAdminRequest(addData))
      : dispatch(editAdminRequest(editData));
  };

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleModalCancel = () => {
    mode === "add" ? dispatch(add(false)) : dispatch(edit(false));
  };

  return (
    <Modal
      title={mode === "add" ? addTitle : editTitle}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={getInitialValues(selectedAdmin)}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={
          mode === "add" ? ADD_ADMIN_SCHEMA : EDIT_ADMIN_SCHEMA
        }
      >
        {() => (
          <Form>
            <Input name="name" label={adminNameLabel} />
            {mode === "add" && (
              <Input name="email" label={adminEmailLabel} />
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

AdminForm.propTypes = {
  mode: PropTypes.string,
  selectedAdmin: PropTypes.object,
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

AdminForm.defaultProps = {
  mode: "add",
  isOpen: false
};

export default AdminForm;

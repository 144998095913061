import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../assets/locale/messages";
import Button from "../../components/Button";
import ProgramsList from "./ProgramsList/ProgramsList";
import AddIcon from "@material-ui/icons/Add";
import ProgramForm from "./ProgramForm/ProgramForm";
import { deleteProgramRequest } from "../../store/Programs/actions";

export default function Programs() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const {
    programs: { title, addTitle }
  } = messages[lang];
  const [addModalOpen, setFormModalOpen] = useState(false);
  const [formMode, setFormMode] = useState({
    mode: "add",
    editId: 0
  });
  const handleDelete = (id) => {
    dispatch(deleteProgramRequest(id));
  };
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        <Button
          text={addTitle}
          icon={<AddIcon className="me-2" />}
          onClick={() => {
            setFormModalOpen(true);
            setFormMode({ mode: "add", editId: 0 });
          }}
        />
      </div>
      <ProgramsList handleDelete={handleDelete} />
      <ProgramForm
        mode={formMode}
        setFormModalOpen={setFormModalOpen}
        isOpen={addModalOpen}
      />
    </div>
  );
}

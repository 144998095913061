import { useIntl } from "react-intl";
import * as Yup from "yup";

export const ADD_EDIT_TEST_SCHEMA = () => {
  const { messages } = useIntl();
  const {
    preTestPostTest: {
      testTitleIsRequired,
      testBookIsRequired,
      answerIsRequired,
      questionTitleRequired,
      prePageRequired,
      postPageRequired,
      questionPointseRequired,
      isMandatoryIsRequired,
      questionPointseMin,
      pointsInteger,
      imageRequired,
      maxNote
    }
  } = messages;

  return Yup.object().shape({
    title: Yup.string().required(testTitleIsRequired),
    book_id: Yup.number().required(testBookIsRequired),
    is_mandatory: Yup.boolean(isMandatoryIsRequired).required(
      isMandatoryIsRequired
    ),
    pre_page: Yup.number(prePageRequired)
      .integer()
      .nullable()
      .min(0)
      .required(prePageRequired),
    post_page: Yup.number(postPageRequired)
      .integer()
      .nullable()
      .moreThan(Yup.ref("pre_page"))
      .required(postPageRequired),
    note: Yup.string().nullable().max(800, maxNote),
    test_questions_attributes: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required(questionTitleRequired),
          section_id: Yup.string().nullable(),

          points: Yup.number(questionPointseRequired)
            .required(questionPointseRequired)
            .nullable()
            .min(0.1, questionPointseMin)
            .test("maxDigitsAfterDecimal", pointsInteger, (number) =>
              /^\d+(\.\d{1})?$/.test(number)
            ),
          test_answer_choices_attributes: Yup.array().of(
            Yup.object().shape({
              currentTab: Yup.string(),
              answer: Yup.string().when("currentTab", {
                is: (currentTab) => {
                  return (
                    currentTab === "text" ||
                    currentTab === "text_image"
                  );
                },
                then: Yup.string(answerIsRequired)
                  .required(answerIsRequired)
                  .nullable(),
                otherwise: Yup.string(answerIsRequired).nullable()
              }),
              correct: Yup.boolean(),
              justification: Yup.string(),
              image_url: Yup.string().when("currentTab", {
                is: (currentTab) => {
                  return (
                    currentTab === "image" ||
                    currentTab === "text_image"
                  );
                },
                then: Yup.string(imageRequired)
                  .required(imageRequired)
                  .nullable(),
                otherwise: Yup.string(imageRequired).nullable()
              })
            })
          )
        })
      )
      .required("Required")
  });
};

export const getInitialValuesAdd = () => {
  return {
    title: "",
    is_mandatory: false,
    pre_page: null,
    post_page: null,
    book_id: 0,
    note: "",
    test_questions_attributes: [
      {
        question: "",
        points: null,
        section_id: "",
        image_url: "",
        justification: "",
        justification_image_url: "",
        test_answer_choices_attributes: [
          {
            currentTab: "text",
            answer: "",
            correct: false,
            image_url: "",
            justification: ""
          },
          {
            currentTab: "text",
            answer: "",
            correct: false,
            justification: "",
            image_url: ""
          }
        ]
      }
    ]
  };
};

export const getInitialValuesEdit = (test = {}) => {
  return {
    id: test?.id,
    title: test?.title,
    book_id: test?.book_id,
    pre_page: test?.pre_page,
    post_page: test?.post_page,
    is_mandatory: test?.is_mandatory,
    note: test?.note,
    test_questions_attributes: test?.test_questions?.map(
      (question) => {
        return {
          id: question?.id,
          question: question.question || "",
          points: question.points,
          section_id: question.section_id,
          image_url: question.image_url,
          justification: question.justification,
          justification_image_url: question.justification_image_url,
          test_answer_choices_attributes: question.test_answer_choices
            ?.length
            ? question.test_answer_choices.map((answer) => ({
                id: answer.id,
                answer: answer.answer,
                correct: answer.correct,
                currentTab:
                  answer.image_url && answer.answer
                    ? "text_image"
                    : answer.answer
                    ? "text"
                    : "image",
                image_url: answer.image_url,
                justification: answer.justification,
                answer_choice: answer.answer_choice || ""
              }))
            : [
                {
                  answer: "",
                  correct: false,
                  justification: "",
                  image_url: ""
                }
              ]
        };
      }
    )
  };
};

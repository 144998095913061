import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/tests";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  getTestsResponse,
  getTestsRequest,
  getSingleTestResponse,
  getBookTestsResponse,
  getSingleTestReportResponse,
  getBookTestsRequest,
  checkForCanBeEditTestResponse
} from "./actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";

export function* getTestsSaga({ payload }) {
  try {
    const response = yield call(apis.getTests, payload);
    yield put(
      getTestsResponse({
        list: response.data.tests,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteTestSaga({ payload }) {
  try {
    const response = yield call(apis.deleteTest, payload.deleteId);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].preTestPostTest.deleteTestSuccess
      })
    );
    yield put(getTestsRequest({ page: 1, items: 6 }));
    payload.bookId
      ? yield put(
          getBookTestsRequest({
            book_id: payload.bookId,
            page: 1,
            items: 6
          })
        )
      : yield put(getTestsRequest({ page: 1, items: 6 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addTestSaga({ payload }) {
  try {
    const response = yield call(apis.addTest, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].preTestPostTest.addTestSuccess
      })
    );
    yield history.push(ROUTE_PATHS["preTestPostTest"]);
  } catch (err) {
    console.error(err);
  }
}

export function* editTestSaga({ payload }) {
  try {
    const response = yield call(apis.editTest, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].preTestPostTest.editTestSuccess
      })
    );
    yield history.push(ROUTE_PATHS["preTestPostTest"]);
  } catch (err) {
    console.error(err);
  }
}

export function* getSingleTestSaga({ payload }) {
  try {
    const response = yield call(apis.getSingleTest, payload);
    yield put(getSingleTestResponse(response.data["test"]));
  } catch (err) {
    console.error(err);
  }
}

export function* getBookTestsSaga({ payload }) {
  try {
    const response = yield call(apis.getBookTests, payload);
    yield put(
      getBookTestsResponse({
        list: response.data.tests,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* getSingleTestReportSaga({ payload }) {
  try {
    const response = yield call(apis.getSingleTestReport, payload);
    yield put(getSingleTestReportResponse(response.data));
  } catch (err) {
    console.error(err);
  }
}

export function* exportTestReportSaga({ payload }) {
  try {
    const response = yield call(apis.exportTestReport, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].preTestPostTest.report
            .userEmailSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* checkForCanBeEditTestSaga({ payload }) {
  try {
    const response = yield call(
      apis.checkForCanBeEditTest,
      payload.id
    );
    yield put(
      checkForCanBeEditTestResponse(response.data.can_edit_test)
    );
  } catch (err) {
    console.error(err);
  }
}

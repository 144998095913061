import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "./History";
import { ROUTE_PATHS } from "../utils/PathsNames";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
// ========= Components =========
import Login from "../containers/Auth/Login";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import ChangePassword from "./../containers/Auth/ChangePassword";
import Dashboard from "../containers/Dashboard";
import Admins from "../containers/Admins";
import Authors from "../containers/Authors";
import Directors from "../containers/Directors";
import Editors from "../containers/Editors";
import Categories from "../containers/Categories";
import Books from "../containers/Books";
import BookForm from "../containers/Books/BookForm";
import BooksDetails from "../containers/Books/BookDetails";
import Supplementaries from "../containers/Supplementaries";
import SupplementaryForm from "../containers/Supplementaries/SupplementaryForm";
import Surveys from "../containers/Surveys";
import SurveysForm from "../containers/Surveys/SurveyForm";
import CreatePassword from "../containers/Auth/CreatePassword";
import SupplementaryDetails from "../containers/Supplementaries/SupplementaryDetails";
import QuizzesList from "../containers/Quizzes";
import SurveyDetails from "../containers/Surveys/SurveyDetails";
import PreTestPostTest from "../containers/PreTestPostTest";
import PreTestPostTestForm from "../containers/PreTestPostTest/PreTestPostTestForm";
import PreTestPostTestDetails from "../containers/PreTestPostTest/PreTestPostTestDetails";
import UserTestReport from "../containers/PreTestPostTest/UserTestReport";
import PromoCodes from "../containers/PromoCodes";
import PromoCodeDetails from "../containers/PromoCodes/PromoCodeDetails";
import AddPromoCodes from "../containers/PromoCodes/AddPromoCodes";
import InstitutionList from "../containers/Institution";
import InstitutionAddEdit from "../containers/Institution/InstitutionAddEdit";
import InstitutionDetails from "../containers/Institution/InstituteDetails";
import SurveyStatistics from "../containers/Surveys/SurveyStatistics";
import AddEditQuiz from "../containers/Quizzes/AddEditQuiz";
import Programs from "../containers/Programs";
import ProgramsEdit from "../containers/Programs/ProgramsEdit/ProgramsEdit";
import FinalTests from "../containers/FinalTests";
import FinalTestFormAEV from "../containers/FinalTests/FinalTestForm";
const Routes = (
  <Router history={history}>
    <Switch>
      <PrivateRoute
        component={FinalTests}
        path={ROUTE_PATHS.finalTest}
      />
      <PrivateRoute
        component={FinalTestFormAEV}
        path={`${ROUTE_PATHS.finalTestForm}/:mode/:id?`}
      />
      <PrivateRoute
        component={Dashboard}
        path={ROUTE_PATHS.dashboard}
      />
      <PrivateRoute component={Admins} path={ROUTE_PATHS.admins} />
      <PrivateRoute component={Authors} path={ROUTE_PATHS.authors} />
      <PrivateRoute component={Editors} path={ROUTE_PATHS.editors} />
      <PrivateRoute component={Books} path={ROUTE_PATHS.books} />
      <PrivateRoute
        component={BookForm}
        path={[ROUTE_PATHS.addBook, `${ROUTE_PATHS.editBook}/:id`]}
      />
      <PrivateRoute
        component={BooksDetails}
        path={`${ROUTE_PATHS.bookDetails}/:id`}
      />
      <PrivateRoute
        component={SupplementaryDetails}
        path={`${ROUTE_PATHS.supplementaryDetails}/:id`}
      />
      <PrivateRoute
        component={SurveyDetails}
        path={`${ROUTE_PATHS.surveysDetails}/:id`}
      />
      <PrivateRoute
        component={PreTestPostTest}
        path={ROUTE_PATHS.preTestPostTest}
      />
      <PrivateRoute
        component={PreTestPostTestForm}
        path={ROUTE_PATHS.addPreTestPostTest}
      />
      <PrivateRoute
        component={PreTestPostTestForm}
        path={`${ROUTE_PATHS.editPreTestPostTest}/:id`}
      />

      <PrivateRoute
        component={PreTestPostTestDetails}
        path={`${ROUTE_PATHS.preTestPostTestDetails}/:id`}
      />
      <PrivateRoute
        component={UserTestReport}
        path={`${ROUTE_PATHS.userTestReports}/:id`}
      />

      <PrivateRoute
        component={SurveyStatistics}
        path={`${ROUTE_PATHS.surveysStatistics}/:id`}
      />
      <PrivateRoute
        component={Categories}
        path={ROUTE_PATHS.categories}
      />
      <PrivateRoute
        component={Supplementaries}
        path={ROUTE_PATHS.supplementaries}
      />
      <PrivateRoute
        component={SupplementaryForm}
        path={[
          ROUTE_PATHS.addSupplementary,
          `${ROUTE_PATHS.editSupplementary}/:id`
        ]}
      />
      <PrivateRoute
        component={InstitutionDetails}
        path={[`${ROUTE_PATHS.institutionDetails}/:id`]}
      />
      <PrivateRoute component={Surveys} path={ROUTE_PATHS.surveys} />
      <PrivateRoute
        component={SurveysForm}
        path={[
          ROUTE_PATHS.addSurvey,
          `${ROUTE_PATHS.editSurvey}/:id`
        ]}
      />
      <PrivateRoute
        component={InstitutionList}
        path={ROUTE_PATHS.institute}
      />
      <PrivateRoute
        component={InstitutionAddEdit}
        path={[
          ROUTE_PATHS.addInstitution,
          `${ROUTE_PATHS.editInstitute}/:id`
        ]}
      />
      <PrivateRoute
        component={ChangePassword}
        path={ROUTE_PATHS.changePassword}
      />
      <PrivateRoute
        component={QuizzesList}
        path={ROUTE_PATHS.quizzes}
      />
      <PrivateRoute
        component={AddEditQuiz}
        path={ROUTE_PATHS.addQuiz}
      />
      <PrivateRoute
        component={AddEditQuiz}
        path={`${ROUTE_PATHS.editQuiz}/:id`}
      />
      <PrivateRoute
        component={AddEditQuiz}
        path={`${ROUTE_PATHS.viewQuiz}/:id`}
      />
      <PrivateRoute
        component={PromoCodes}
        path={ROUTE_PATHS.promoCodes}
      />
      <PrivateRoute
        component={AddPromoCodes}
        path={ROUTE_PATHS.addPromoCodes}
      />
      <PrivateRoute
        component={PromoCodeDetails}
        path={`${ROUTE_PATHS.promoCodeDetails}/:id`}
      />
      <PrivateRoute
        component={Programs}
        path={`${ROUTE_PATHS.programs}`}
      />
      <PrivateRoute
        component={ProgramsEdit}
        path={`${ROUTE_PATHS.programsEdit}/:id`}
      />
      <PrivateRoute
        component={Directors}
        path={`${ROUTE_PATHS.directors}`}
      />
      <PublicRoute
        component={Login}
        path={[ROUTE_PATHS.login, ROUTE_PATHS.root]}
        exact
      />
      <PublicRoute
        component={ForgotPassword}
        path={ROUTE_PATHS.forgotPassword}
      />

      <PublicRoute
        component={CreatePassword}
        path={ROUTE_PATHS.createPassword}
      />
      <Redirect from="/**" to={ROUTE_PATHS.root} />
    </Switch>
  </Router>
);

export default Routes;

import { axiosInstance } from ".";

const getAuthors = async (params) => {
  return await axiosInstance.get(`authors`, { params });
};

const deleteAuthor = async (id) => {
  return await axiosInstance.delete(`authors/${id}`);
};

const addAuthor = async (payload) =>
  await axiosInstance.post(`authors`, payload);

const assigneBookToAuthor = async (payload) =>
  await axiosInstance.post(
    `authors/assign_books/${payload.authorId}`,
    payload
  );

const editAuthor = async ({ name, id, book_ids }) =>
  await axiosInstance.patch(`authors/${id}`, { name, book_ids });

export {
  getAuthors,
  deleteAuthor,
  addAuthor,
  editAuthor,
  assigneBookToAuthor
};

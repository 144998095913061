import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  registeredUserInCountryWithJob: [],
  selectedCounty: null,
  selectedJob: null
};
const registeredUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_REGISTEREDUSER_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.GET_REGISTEREDUSER_IN_COUNTRY_WITH_JOB_RESPONSE:
      return {
        ...state,
        registeredUserInCountryWithJob: [
          action.payload.selectedCounty,
          action.payload.selectedJob
        ]
      };
    case types.SET_REGISTEREDUSER_FILTERS:
      return {
        ...state,
        selectedCounty: action.payload.selectedCounty,
        selectedJob: action.payload.selectedJob
      };

    default:
      return state;
  }
};

export default registeredUserReducer;

export const GET_BOOKS_REQUEST = "GET_BOOKS_REQUEST";
export const GET_BOOKS_RESPONSE = "GET_BOOKS_RESPONSE";

export const DELETE_BOOK_REQUEST = "DELETE_BOOK_REQUEST";

export const ADD_BOOK_REQUEST = "ADD_BOOK_REQUEST";
export const ADD_BOOK_RESPONSE = "ADD_BOOK_RESPONSE";

export const EDIT_BOOK_REQUEST = "EDIT_BOOK_REQUEST";
export const EDIT_BOOK_RESPONSE = "EDIT_BOOK_RESPONSE";

export const GET_SINGLE_BOOK_REQUEST = "GET_SINGLE_BOOK_REQUEST";
export const GET_SINGLE_BOOK_RESPONSE = "GET_SINGLE_BOOK_RESPONSE";

export const GET_BOOKS_IN_CATEGORY_REQUEST =
  "GET_BOOKS_IN_CATEGORY_REQUEST";
export const GET_BOOKS_IN_CATEGORY_RESPONSE =
  "GET_BOOKS_IN_CATEGORY_RESPONSE";

export const SET_BOOKS_FILTERS = "SET_BOOKS_FILTERS";

export const GET_BOOK_SUPPLEMENTRAIES_REQUEST =
  "GET_BOOKS_SUPPLEMENTRAIES_REQUEST";
export const GET_BOOK_SUPPLEMENTRAIES_RESPONSE =
  "GET_BOOKS_SUPPLEMENTRAIES_RESPONSE";

export const GET_BOOK_SECTIONS_REQUEST = "GET_BOOK_SECTIONS_REQUEST";

export const GET_BOOK_SECTIONS_RESPONSE =
  "GET_BOOK_SECTIONS_RESPONSE";

export const SET_BOOK_SECTIONS_REQUEST = "SET_BOOK_SECTIONS_REQUEST";
export const DELETE_SECTIONS_REQUEST = "DELETE_SECTIONS_REQUEST";

export const SET_EDITABLE_MODE_SECTION = "SET_EDITABLE_MODE_SECTION";
export const EDIT_BOOK_SECTIONS_REQUEST =
  "EDIT_BOOK_SECTIONS_REQUEST";

import React from "react";
import { Typography } from "@material-ui/core";
import messages from "../../assets/locale/messages";
import { useSelector } from "react-redux";
import Insights from "./Insights";
import BookDetails from "./BookDetails";

const Dashboard = () => {
  const lang = useSelector((state) => state.locale.lang);
  const {
    dashboard: { title }
  } = messages[lang];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Insights />
      <BookDetails />
    </>
  );
};

export default Dashboard;

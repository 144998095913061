import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import {
  getBooksRequest,
  getBooksResponse
} from "../../../store/Books/actions";
import messages from "../../../assets/locale/messages";
import { deleteAction } from "../../../store/ActionsBtn/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { getCategoriesRequest } from "../../../store/Categories/actions";
import EmptyState from "../../../components/EmptyState";

const BooksList = ({ lang, list }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const meta = useSelector((state) => state.books.meta);
  const isLoading = useSelector((state) => state.loader);
  const searchKeyword = useSelector(
    (state) => state.books.searchKeyword
  );
  const selectedCategory = useSelector(
    (state) => state.books.selectedCategory
  );

  const [page, setPage] = useState(1);

  const perPage = 10;

  const {
    books: {
      bookTitle,
      bookCategory,
      bookAuthor,
      bookIsbn,
      bookVersion,
      deleteNotAllowedMsg,
      editNotAllowedMsg
    }
  } = messages[lang];

  useEffect(() => {
    function getBooksAndCategories() {
      dispatch(getCategoriesRequest({ paginate: false }));
    }
    getBooksAndCategories();
    return () => {
      dispatch(getBooksResponse({ list: [], meta: {} }));
    };
  }, []);

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getBooksRequest({
        page: page,
        items: perPage,
        paginate: true,
        category_id: selectedCategory,
        search_term: searchKeyword
      })
    );
  };

  const handleViewBook = (id) => {
    history.push(`${ROUTE_PATHS["bookDetails"]}/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteAction(id));
  };

  const handleEdit = (id) => {
    history.push(`${ROUTE_PATHS["editBook"]}/${id}`);
  };

  return (
    <>
      <Table
        headlines={[
          bookTitle,
          bookCategory,
          bookAuthor,
          bookIsbn,
          bookVersion
        ]}
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row?.title}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row?.category?.name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row?.author}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row?.isbn}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row?.book_version}
            </TableCell>
            <TableCell
              className="table__cell table__actions"
              align="right"
            >
              <IconButton
                onClick={() => handleViewBook(row.id)}
                aria-label="view"
              >
                <VisibilityIcon
                  className="table__view"
                  fontSize="medium"
                />
              </IconButton>
              <Tooltip
                title={
                  row?.deprecation === "archived"
                    ? editNotAllowedMsg
                    : ""
                }
              >
                <span>
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    disabled={row?.deprecation === "archived"}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  row?.has_subscribers ||
                  row?.deprecation === "pending"
                    ? deleteNotAllowedMsg
                    : ""
                }
              >
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    disabled={
                      row?.has_subscribers ||
                      row?.deprecation === "pending"
                    }
                    className="text-gray"
                  >
                    <DeleteIcon
                      className={`table__delete ${
                        row?.has_subscribers ? "table__disabled" : ""
                      }`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

BooksList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object)
};

export default BooksList;

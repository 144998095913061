import React from "react";
import PropTypes from "prop-types";
import Table from "../../../components/Table";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BarChartIcon from "@material-ui/icons/BarChart";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import messages from "../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { useHistory } from "react-router-dom";

function FinalTestList({ list, lang, inBookView, setDeleteModal }) {
  const history = useHistory();

  const {
    finalTest: {
      headerFinalTest: { titleFinalTest, book, reports }
    },
    shared: { view, editLabel, deleteLabel }
  } = messages[lang];

  const handleDelete = (id) => {
    console.log("handleDelete id: " + id);

    setDeleteModal({ id, open: true });
  };
  const handleView = (id) => {
    history.push(`${ROUTE_PATHS.finalTestForm}/View/${id}`);
  };

  const handleEdit = (id) => {
    history.push(`${ROUTE_PATHS.finalTestForm}/Edit/${id}`);
  };
  return (
    <>
      <Table
        headlines={inBookView ? [titleFinalTest] : [book]}
        hasActions={true}
        rows={
          Array.isArray(list) && list.length > 0
            ? list?.map((row) => (
                <TableRow className="table__row" key={row.id}>
                  {inBookView && (
                    <>
                      <TableCell className="table__cell" align="left">
                        {row.pre_page}
                      </TableCell>
                      <TableCell className="table__cell" align="left">
                        {row.post_page}
                      </TableCell>
                    </>
                  )}
                  {!inBookView && (
                    <TableCell className="table__cell" align="left">
                      {row.book_title}
                    </TableCell>
                  )}

                  <TableCell
                    className="table__cell table__actions"
                    align="right"
                  >
                    <Tooltip title={view}>
                      <span>
                        <IconButton
                          onClick={() => handleView(row.id)}
                          aria-label="view"
                        >
                          <VisibilityIcon
                            className="table__view"
                            fontSize="medium"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={reports}>
                      <span>
                        <IconButton
                          // onClick={() => handleViewReport(row.id)}
                          aria-label="statitics"
                        >
                          <BarChartIcon
                            className="table__statitics"
                            fontSize="medium"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={editLabel}>
                      <span>
                        <IconButton
                          onClick={() => handleEdit(row.id)}
                          aria-label="edit"
                        >
                          <EditIcon
                            className="table__edit"
                            fontSize="medium"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title={deleteLabel}>
                      <span>
                        <IconButton
                          onClick={() => handleDelete(row.id)}
                          aria-label="delete"
                          className="text-gray"
                        >
                          <DeleteIcon
                            className={`table__delete`}
                            fontSize="medium"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            : []
        }
      />
    </>
  );
}
FinalTestList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  inBookView: PropTypes.bool,
  setDeleteModal: PropTypes.func
};
export default FinalTestList;

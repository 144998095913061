import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import messages from "../../assets/locale/messages";
import Button from "../../components/Button";
import DirectorsDelete from "./DirectorsDelete";
import DirectorsForm from "./DirectorsForm/DirectorsForm";
import DirectorsList from "./DirectorsList/DirectorsList";

export default function index() {
  const lang = useSelector((state) => state.locale.lang);
  const {
    directors: { title, addTitle }
  } = messages[lang];
  const list = useSelector((state) => state.directors.list);
  const [formMode, setFormMode] = useState({
    mode: "add",
    editId: 0
  });
  const selectedDirector = _.find(list, ["id", formMode.editId]);
  const [addModalOpen, setFormModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: 0
  });
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
        <Button
          text={addTitle}
          icon={<AddIcon className="me-2" />}
          onClick={() => {
            setFormModalOpen(true);
            setFormMode({ mode: "add", editId: 0 });
          }}
        />
      </div>
      <DirectorsList
        setFormModalOpen={setFormModalOpen}
        setFormMode={setFormMode}
        lang={lang}
        setDeleteModal={setDeleteModal}
      />
      <DirectorsForm
        setFormModalOpen={setFormModalOpen}
        isOpen={addModalOpen}
        lang={lang}
        mode={formMode.mode}
        selectedAuthor={selectedDirector}
      />
      <DirectorsDelete
        setDeleteModal={setDeleteModal}
        isOpen={deleteModal.open}
        id={deleteModal.id}
        lang={lang}
      />
    </>
  );
}

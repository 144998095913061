import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteSectionAction } from "../../../store/ActionsBtn/actions";
import { deleteSectionRequest } from "../../../store/Books/actions";

const DeleteSection = ({ isOpen, lang, book_id = null }) => {
  const dispatch = useDispatch();
  const deleteId = useSelector(
    (state) => state.actionsBtn.deleteSection
  );
  const {
    section: { deleteSection, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteSectionRequest({ id: deleteId, bookId: book_id }));
    dispatch(deleteSectionAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteSection}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
    />
  );
};

DeleteSection.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  book_id: PropTypes.number
};

DeleteSection.defaultProps = {
  isOpen: false
};

export default DeleteSection;

import * as types from "./types";

export const getTestsRequest = (data) => ({
  type: types.GET_TESTS_REQUEST,
  payload: data
});

export const getTestsResponse = (data) => ({
  type: types.GET_TESTS_RESPONSE,
  payload: data
});

export const deleteTestRequest = (data) => ({
  type: types.DELETE_TEST_REQUEST,
  payload: data
});

export const addTestsRequest = (data) => ({
  type: types.ADD_TESTS_REQUEST,
  payload: data
});

export const setTestsFilters = (data) => ({
  type: types.SET_TEST_FILTERS,
  payload: data
});

export const setTestsBookFilters = (data) => ({
  type: types.SET_TEST_BOOK_FILTERS,
  payload: data
});

export const editTestRequest = (data) => ({
  type: types.EDIT_TEST_REQUEST,
  payload: data
});

export const getSingleTestRequest = (data) => ({
  type: types.GET_SINGLE_TEST_REQUEST,
  payload: data
});

export const getSingleTestResponse = (data) => ({
  type: types.GET_SINGLE_TEST_RESPONSE,
  payload: data
});
export const getBookTestsRequest = (payload) => ({
  type: types.GET_BOOK_TEST_REQUEST,
  payload
});

export const getBookTestsResponse = (payload) => ({
  type: types.GET_BOOK_TEST_RESPONSE,
  payload
});
export const getSingleTestReportRequest = (data) => ({
  type: types.GET_SINGLE_TEST_REPORT_REQUEST,
  payload: data
});

export const getSingleTestReportResponse = (data) => ({
  type: types.GET_SINGLE_TEST_REPORT_RESPONSE,
  payload: data
});

export const exportTestReport = (data) => ({
  type: types.EXPORT_TEST_REPORT,
  payload: data
});

export const checkForCanBeEditTestRequest = (data) => ({
  type: types.CHECK_FOR_CAN_BE_EDIT_TEST_REQUEST,
  payload: data
});

export const checkForCanBeEditTestResponse = (data) => ({
  type: types.CHECK_FOR_CAN_BE_EDIT_TEST_RESPONSE,
  payload: data
});

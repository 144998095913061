import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@material-ui/core";
import EmptyState from "../../../components/EmptyState";
import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";
import Pagination from "../../../components/Pagination";
import {
  getRegisteredUserRequest,
  getRegisteredUserResponse
} from "../../../store/RegisteredUser/actions";
import "./RegisteredUser.scss";

const RegisteredUserList = ({ list, lang }) => {
  const perPage = 10;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.registeredUsers.meta);

  const searchKeyword = useSelector(
    (state) => state.registeredUsers.searchKeyword
  );
  const selectedCounty = useSelector(
    (state) => state.registeredUsers.selectedCounty
  );
  const selectedJob = useSelector(
    (state) => state.registeredUsers.selectedJob
  );
  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getRegisteredUserRequest({
        page: page,
        items: perPage,
        paginate: true,
        job_ids: selectedJob,
        country_ids: selectedCounty,
        search_term: searchKeyword
      })
    );
  };

  const {
    regiesteredUSer: { name, email, country, job }
  } = messages[lang];
  useEffect(() => {
    return () => {
      dispatch(getRegisteredUserResponse({ list: [], meta: {} }));
    };
  }, []);

  return (
    <>
      <Table
        headlines={[name, email, country, job]}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.first_name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.email}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.country_name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.job.job_type}
            </TableCell>
            <TableCell
              className="table__cell table__actions"
              align="right"
            ></TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

RegisteredUserList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object)
};

export default RegisteredUserList;

import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { unassignAction } from "../../../store/ActionsBtn/actions";
import { unassignSupplementaryFromBookRequest } from "../../../store/Supplementaries/actions";

const BookUnassignSupplementary = ({ isOpen, lang, bookId }) => {
  const dispatch = useDispatch();
  const unassignId = useSelector(
    (state) => state.actionsBtn.unassign
  );

  const {
    supplementaries: { unassignMessage, unassignFromBook }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(
      unassignSupplementaryFromBookRequest({
        id: unassignId,
        book_id: bookId
      })
    );
    dispatch(unassignAction(false));
  };

  return (
    <DeleteModal
      deleteMessage={unassignMessage}
      deleteTitle={unassignFromBook}
      isOpen={isOpen}
      lang={lang}
      cancelCallback={() => dispatch(unassignAction(false))}
      handleDelete={handleDelete}
    />
  );
};

BookUnassignSupplementary.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  bookId: PropTypes.string
};

BookUnassignSupplementary.defaultProps = {
  isOpen: false
};

export default BookUnassignSupplementary;

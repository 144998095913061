import * as types from "./types";

export const getSurveysRequest = (data) => ({
  type: types.GET_SURVEYS_REQUEST,
  payload: data
});

export const getSurveysResponse = (data) => ({
  type: types.GET_SURVEYS_RESPONSE,
  payload: data
});

export const deleteSurveyRequest = (data) => ({
  type: types.DELETE_SURVEY_REQUEST,
  payload: data
});

export const setSurveysFilters = (data) => ({
  type: types.SET_SURVEY_FILTERS,
  payload: data
});

export const addSurveysRequest = (data) => ({
  type: types.ADD_SURVEYS_REQUEST,
  payload: data
});

export const assignSurveyToBookRequest = (data) => ({
  type: types.ASSIGN_BOOK_TO_SURVEY_REQUEST,
  payload: data
});

export const unassignSurveyFromBookRequest = (data) => ({
  type: types.UNASSIGN_BOOK_FROM_SURVEY_REQUEST,
  payload: data
});

export const editSurveyRequest = (data) => ({
  type: types.EDIT_SURVEY_REQUEST,
  payload: data
});

export const getSingleSurveyRequest = (data) => ({
  type: types.GET_SINGLE_SURVEY_REQUEST,
  payload: data
});

export const getSingleSurveyResponse = (data) => ({
  type: types.GET_SINGLE_SURVEY_RESPONSE,
  payload: data
});

export const getSingleSurveyStaticsRequest = (data) => ({
  type: types.GET_SINGLE_SURVEY_STATISTCS_REQUEST,
  payload: data
});

export const getSingleSurveyStaticsResponse = (data) => ({
  type: types.GET_SINGLE_SURVEY_STATISTCS_RESPONSE,
  payload: data
});

export const editBookPageForSurveyRequest = (data) => ({
  type: types.EDIT_BOOK_PAGE_FOR_SURVEY_REQUEST,
  payload: data
});

export const checkForCanBeEditSurveytRequest = (data) => ({
  type: types.CHECK_FOR_CAN_BE_EDIT_SURVEY_REQUEST,
  payload: data
});
export const checkForCanBeEditSurveyResponse = (data) => ({
  type: types.CHECK_FOR_CAN_BE_EDIT_SURVEY_RESPONSE,
  payload: data
});

export const exportSurveyReport = (data) => ({
  type: types.EXPORT_SURVEY_REPORT,
  payload: data
});

import { call, put } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/authors";
import { edit } from "../ActionsBtn/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  addAuthorResponse,
  editAuthorResponse,
  getAuthorsRequest,
  getAuthorsResponse
} from "./actions";

export function* getAuthorsSaga({ payload }) {
  try {
    const response = yield call(apis.getAuthors, payload);
    yield put(
      getAuthorsResponse({
        list: response.data.authors,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteAuthorsSaga({ payload }) {
  try {
    const response = yield call(apis.deleteAuthor, payload);
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].authors.deleteAuthorSuccess
      })
    );
    yield put(getAuthorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addAuthorSaga({ payload }) {
  try {
    const response = yield call(apis.addAuthor, payload);
    yield put(addAuthorResponse(response.data));
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].authors.addAuthorSuccess
      })
    );
    yield put(getAuthorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* editAuthorSaga({ payload }) {
  try {
    const response = yield call(apis.editAuthor, payload);
    yield put(editAuthorResponse(response.data));
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].authors.editAuthorSuccess
      })
    );
    yield put(getAuthorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

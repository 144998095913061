import { TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { edit } from "../../../store/ActionsBtn/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import {
  addEditorRequest,
  editEditorRequest
} from "../../../store/Editors/actions";
import { ADD_EDITOR_SCHEMA, EDIT_EDITOR_SCHEMA } from "./validations";

const EditorsForm = ({
  mode,
  selectedEditor,
  isOpen,
  setIsOpen,
  lang,
  setFormModalOpen
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const {
    editors: {
      addTitle,
      editTitle,
      editorNameLabel,
      editorEmailLabel
    }
  } = messages[lang];
  const [assignedBooksIds, setAssignedBooksIds] = useState([]);
  // attach books ids
  useEffect(() => {
    setAssignedBooksIds(selectedEditor?.book_ids ?? []);
    return () => {
      setAssignedBooksIds([]);
    };
  }, [selectedEditor?.id]);

  const handleSubmit = ({ name, email }) => {
    const addData = { name, email, book_ids: assignedBooksIds };
    const editData = {
      name,
      id: selectedEditor?.id,
      book_ids: assignedBooksIds
    };
    mode === "add"
      ? dispatch(addEditorRequest({ editor: addData }))
      : dispatch(editEditorRequest(editData));
  };
  const booksList = useSelector((state) => state.books.list);
  // fetch all books
  useEffect(() => {
    function filterBooksList() {
      const params = {
        category_id: "",
        search_term: "",
        page: 1,
        items: 1000,
        paginate: true
      };
      dispatch(getBooksRequest(params));
    }
    filterBooksList();
  }, []);
  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const openForm = useSelector((state) => state.actionsBtn.edit);
  useEffect(() => {
    dispatch(edit(isOpen));
  }, [isOpen]);
  useEffect(() => {
    if (!openForm) {
      setIsOpen(openForm);
    }
  }, [openForm]);

  return (
    <Modal
      title={mode === "add" ? addTitle : editTitle}
      hasActions={true}
      isOpen={openForm}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={() => setFormModalOpen(false)}
    >
      <Formik
        initialValues={{
          name: selectedEditor?.name,
          email: selectedEditor?.email
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={
          mode === "add" ? ADD_EDITOR_SCHEMA : EDIT_EDITOR_SCHEMA
        }
      >
        {() => {
          return (
            <Form>
              <Input name="name" label={editorNameLabel} />
              {mode === "add" && (
                <Input name="email" label={editorEmailLabel} />
              )}
              <Autocomplete
                multiple
                limitTags={4}
                id="multiple-assigned-books"
                size="small"
                options={booksList}
                getOptionLabel={(option) => option?.title}
                defaultValue={assignedBooksIds.map((selectedId) =>
                  booksList.find((b) => b.id === selectedId)
                )}
                onChange={(_, elements) => {
                  if (elements.length) {
                    const selectedIds = elements.map((b) => b.id);
                    setAssignedBooksIds(selectedIds);
                  } else setAssignedBooksIds([]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assigned Books"
                    placeholder="Books"
                  />
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

EditorsForm.propTypes = {
  mode: PropTypes.string,
  selectedEditor: PropTypes.object,
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  setFormModalOpen: PropTypes.func,
  setIsOpen: PropTypes.func
};

EditorsForm.defaultProps = {
  mode: "add",
  isOpen: false
};

export default EditorsForm;

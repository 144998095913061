import { Grid, Typography, useTheme } from "@material-ui/core";
import { Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import Search from "../../components/Search";
import {
  getQuizzesList,
  importQuizzesToBook,
  setQuizzesBookFilter,
  setQuizzesPage
} from "../../store/Quizzes/actions";
import QuizzesList from "./QuizzesList";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@material-ui/icons/Add";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import AssignQuiz from "./AssignQuiz";
import messages from "../../assets/locale/messages";
import DeleteQuiz from "./DeleteQuiz";
import EmptyState from "../../components/EmptyState";
import CustomSelect from "../../components/Select";

import { ASC } from "../../utils/Constants";
import { getBooksRequest } from "../../store/Books/actions";

const Quizzes = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loader);

  const {
    locale: { lang },
    quizzes: {
      list,
      meta,
      assignQuizBtn,
      deleteQuizBtn,
      quizzesPage,
      quizzesBookFilter,
      quizzesBookSort
    }
  } = useSelector((state) => state);

  const { quizzes } = messages[lang];

  const theme = useTheme();
  useEffect(() => {
    const params = {
      page: quizzesPage,
      title: search,
      items: 10,
      book_id: quizzesBookFilter,
      sort_by_book_page: !!quizzesBookSort,
      sort_method: quizzesBookSort
    };
    if (!quizzesBookFilter) {
      delete params.book_id;
      delete params.sort_by_book_page;
      delete params.sort_method;
    }
    if (!quizzesBookSort) {
      delete params.sort_by_book_page;
      delete params.sort_method;
    }
    dispatch(getQuizzesList(params));
  }, [search, quizzesPage, quizzesBookSort, quizzesBookFilter]);

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(setQuizzesPage({ page }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleAddQuiz = () => {
    history.push(`${ROUTE_PATHS["addQuiz"]}`);
  };

  const uploadRef = useRef();
  const handleChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("book_id", 232);
    dispatch(importQuizzesToBook(formData));
  };

  const handleImportQuizzes = () => {
    uploadRef.current.click();
  };

  // filter
  const booksList = useSelector((state) => state.books.list);
  const [booksOptions, setBooksOptions] = useState([]);
  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
  }, []);
  useEffect(() => {
    if (booksList.length) {
      setBooksOptions([
        { id: 0, value: 0, label: "No Filter" },
        ...booksList.map(({ id, title }) => ({
          id,
          value: id,
          label: title
        }))
      ]);
    }
  }, [booksList]);
  const handleSelectedBookFilter = (id) => {
    if (id) {
      dispatch(
        getQuizzesList({
          page: quizzesPage,
          title: search,
          items: 10,
          book_id: id,
          sort_by_book_page: true,
          sort_method: ASC
        })
      );
      dispatch(setQuizzesBookFilter({ id }));
    } else {
      dispatch(
        getQuizzesList({
          page: quizzesPage,
          title: search,
          items: 10
        })
      );
      dispatch(setQuizzesBookFilter({ id: 0 }));
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {quizzes.quizzes}
        </Typography>
      </div>
      <Grid container>
        <Grid item md={8}>
          <Formik
            initialValues={{ search: "" }}
            onSubmit={handleSearch}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  gap: "10px"
                }}
              >
                <Search
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <CustomSelect
                  options={booksOptions}
                  placeholder={quizzes.filterByBook}
                  name="book_id"
                  style={{ width: "50%" }}
                  bgColor={theme.palette.primary.light}
                  isRequired={false}
                  isMenuRelative={false}
                  handleSelectChange={(id) => {
                    handleSelectedBookFilter(id);
                  }}
                />
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          md={4}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={"add Quiz"}
            icon={<AddIcon className="mx-2" />}
            onClick={handleAddQuiz}
          />
          {/* wrapper div to hide for now */}
          <div style={{ display: "none" }}>
            <Button
              text={"Import Quizzes"}
              icon={<AddIcon className="mx-2" />}
              onClick={handleImportQuizzes}
            />
          </div>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: "none" }}
            ref={uploadRef}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <QuizzesList lang={lang} list={list} />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", height: "27px" }}>
            <label htmlFor="pageInput">Page Number&nbsp;</label>
            <input
              id="pageInput"
              value={page}
              style={{
                width: "4vw",
                border: "1px solid black",
                borderRadius: "5px 0px 0px 5px"
              }}
              onChange={(e) => setPage(e.target.value)}
            />
            <button
              style={{
                color: "white",
                backgroundColor: "darkblue",
                padding: "2px 2px",
                borderRadius: "0px 5px 5px 0px",
                border: "0",
                boxShadow: "0px 2px 2px lightgray",
                width: "4vw"
              }}
              onClick={() => dispatch(setQuizzesPage({ page }))}
              title="Go To Page"
            >
              <ArrowForwardIcon className="mx-2" />
            </button>
          </div>
          <Pagination
            count={meta?.pages}
            page={quizzesPage}
            defaultPage={quizzesPage}
            handleChange={handlePagination}
          />
        </div>
      )}
      <AssignQuiz isOpen={Boolean(assignQuizBtn)} />
      <DeleteQuiz isOpen={Boolean(deleteQuizBtn)} lang={lang} />
    </div>
  );
};

export default Quizzes;

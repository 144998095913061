import React from "react";
import { TableRow, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableUI from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import messages from "../../../assets/locale/messages";
import { useSelector } from "react-redux";
function BookSingleRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const lang = useSelector((state) => state.locale.lang);

  const {
    books: { bookCover }
  } = messages[lang];
  return (
    <React.Fragment>
      <TableRow className="table__row">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className="table__cell" component="th" scope="row">
          <img
            src={row.book.cover_photo}
            width={30}
            alt={bookCover}
          />
          <span className="p-2">{row.book.title}</span>
        </TableCell>
        <TableCell className="table__cell">
          {row.book.release_date}
        </TableCell>
        <TableCell className="table__cell">{row.book.isbn}</TableCell>
      </TableRow>
      <TableRow className="table__row">
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Detials
              </Typography>
              <TableContainer component={Paper}>
                <TableUI size="small">
                  <TableBody>
                    <TableRow className="table__row">
                      <TableCell className="table__cell">
                        {" "}
                        Total subscribers
                      </TableCell>
                      <TableCell className="table__cell">
                        {" "}
                        {row.total_subscribers}
                      </TableCell>
                      {/* <TableCell>{row.paid_subscribers}</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {row.completed_subscribers}
                      </TableCell> */}
                    </TableRow>
                    <TableRow className="table__row">
                      <TableCell className="table__cell">
                        completed subscribers
                      </TableCell>
                      <TableCell className="table__cell">
                        {row.completed_subscribers}
                      </TableCell>
                    </TableRow>
                    <TableRow className="table__row">
                      <TableCell className="table__cell">
                        paid subscribers
                      </TableCell>
                      <TableCell className="table__cell">
                        {row.paid_subscribers}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </TableUI>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
BookSingleRow.propTypes = {
  row: PropTypes.shape({
    total_subscribers: PropTypes.number.isRequired,
    paid_subscribers: PropTypes.number.isRequired,
    completed_subscribers: PropTypes.number.isRequired,
    book: PropTypes.object.isRequired
  }).isRequired
};

export default BookSingleRow;

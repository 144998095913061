import React, { useEffect, useState } from "react";
import messages from "../../assets/locale/messages";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import Button from "../../components/Button";
import { Form, Formik } from "formik";
import Search from "../../components/Search";
import AddIcon from "@material-ui/icons/Add";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import FinalTestList from "./FinalTestList";
import EmptyState from "../../components/EmptyState";
import Pagination from "../../components/Pagination";
import {
  getAllFinalTestsRequest,
  setFinalTestSearch
} from "../../store/FinalTests/actions";
import FinalTestDelete from "./FinalTestDelete";

function FinalTests() {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: 0
  });
  const meta = useSelector((state) => state.finalTestsReducer.meta);
  const finalTestList = useSelector(
    (state) => state.finalTestsReducer.list
  );
  // console.log("finalTestList", finalTestList);

  const lang = useSelector((state) => state.locale.lang);
  // console.log("meta", meta);
  const isLoading = useSelector((state) => state.loader);
  const perPage = 6;

  const [page, setPage] = useState(1);

  const handleSearch = (e) => {
    const search = e.target.value;
    dispatch(setFinalTestSearch(search ? search : ""));
    dispatch(
      getAllFinalTestsRequest({
        book_name: search ? search : "",
        page: 1,
        items: perPage
        // paginate: true
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAllFinalTestsRequest({
        page: page,
        items: perPage
      })
    );
  }, []);

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getAllFinalTestsRequest({
        book_name: "",
        page: page,
        items: perPage
      })
    );
  };
  const {
    finalTest: { titleFinalTest, addFinalTest }
  } = messages[lang];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {titleFinalTest}
        </Typography>
      </div>
      <Grid container spacing={3} className="mb-2">
        <Grid item xs={6}>
          <Formik initialValues={{ search: "" }}>
            {() => (
              <Form>
                <Search
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <button className="d-none"></button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          xs={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addFinalTest}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            slug={`${ROUTE_PATHS.finalTestForm}/Add`}
          />
        </Grid>
      </Grid>
      <FinalTestList
        list={finalTestList}
        lang={lang}
        setDeleteModal={setDeleteModal}
        inBookView={false}
      />
      {!isLoading && !finalTestList?.length && <EmptyState />}
      <FinalTestDelete
        setDeleteModal={setDeleteModal}
        isOpen={deleteModal.open}
        id={deleteModal.id}
        lang={lang}
      />
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
}

export default FinalTests;

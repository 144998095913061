import * as types from "./types";

export const setFinalTestSearch = (data) => ({
  type: types.SET_FINAL_TEST_SEARCH,
  payload: data
});
export const getAllFinalTestsRequest = (data) => {
  return { type: types.GET_ALL_FINAL_TEST_REQUEST, payload: data };
};

export const getAllFinalTestsResponse = (data) => ({
  type: types.GET_ALL_FINAL_TEST_RESPONSE,
  payload: data
});

export const getSingleFinalTestsRequest = (id) => ({
  type: types.GET_SINGLE_FINAL_TEST_REQUEST,
  payload: { id }
});

export const getSingleFinalTestsResponse = (data) => ({
  type: types.GET_SINGLE_FINAL_TEST_RESPONSE,
  payload: data
});

export const setNewFinalTestRequest = (data) => ({
  type: types.SET_FINAL_TEST_REQUEST,
  payload: data
});

export const setNewFinalTestResponse = (data) => ({
  type: types.SET_FINAL_TEST_RESPONSE,
  payload: data
});

export const editSingleFinalTestRequest = ({ final_test, id }) => ({
  type: types.EDIT_FINAL_TEST_REQUEST,
  payload: { final_test, id }
});

export const editSingleFinalTestResponse = (data) => ({
  type: types.EDIT_FINAL_TEST_RESPONSE,
  payload: data
});

export const deleteSingleFinalTestRequest = (id) => ({
  type: types.DELETE_FINAL_TEST_REQUEST,
  payload: { id }
});

export const deleteSingleFinalTestResponse = (data) => ({
  type: types.DELETE_FINAL_TEST_RESPONSE,
  payload: data
});

import { useIntl } from "react-intl";
import * as Yup from "yup";
import { IPPattern } from "../../utils/Patterns";
// import messages from "../../assets/locale/messages";

export const ADD_EDIT_INSTITUTE_SCHEMA = () => {
  const { messages } = useIntl();
  const {
    institute: {
      validation: {
        titleRequired,
        ivalidEmail,
        oneEmailAtLeast,
        oneIpAtLeast,
        ipRequired,
        invalidIP,
        dateIsRequired
      }
    },
    inputs: { emailRequired }
  } = messages;

  return Yup.object().shape({
    name: Yup.string().required(titleRequired),
    subscription_start_date: Yup.string()
      .required(dateIsRequired)
      .nullable(),
    subscription_end_date: Yup.string()
      .required(dateIsRequired)
      .nullable(),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email(ivalidEmail)
            .required(emailRequired)
        })
      )
      .min(1, oneEmailAtLeast),
    ip_ranges_attributes: Yup.array()
      .of(
        Yup.object().shape({
          _destroy: Yup.bool().nullable(),
          from: Yup.string().when("_destroy", {
            is: (_destroy) => !_destroy,
            then: Yup.string()
              .matches(IPPattern, invalidIP)
              .required(ipRequired)
          }),

          to: Yup.string().when("_destroy", {
            is: (_destroy) => !_destroy,
            then: Yup.string()
              .matches(IPPattern, invalidIP)
              .required(ipRequired)
          })
        })
      )
      .min(1, oneIpAtLeast)
  });
};

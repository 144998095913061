import * as types from "./types";

export const getCategoriesRequest = (data) => ({
  type: types.GET_CATEGORIES_REQUEST,
  payload: data
});

export const getCategoriesResponse = (data) => ({
  type: types.GET_CATEGORIES_RESPONSE,
  payload: data
});

export const deleteCategoryRequest = (data) => ({
  type: types.DELETE_CATEGORY_REQUEST,
  payload: data
});

export const addCategoryRequest = (data) => ({
  type: types.ADD_CATEGORY_REQUEST,
  payload: data
});

export const addCategoryResponse = (data) => ({
  type: types.ADD_CATEGORY_RESPONSE,
  payload: data
});

export const editCategoryRequest = (data) => ({
  type: types.EDIT_CATEGORY_REQUEST,
  payload: data
});

export const editCategoryResponse = (data) => ({
  type: types.EDIT_CATEGORY_RESPONSE,
  payload: data
});

export const setCategorySearch = (data) => ({
  type: types.SET_CATEGORY_SEARCH,
  payload: data
});

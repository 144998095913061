import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import messages from "../../../assets/locale/messages";
import DeleteModal from "../../../components/DeleteModal";
import { deleteDirectorsRequest } from "../../../store/Directors/actions";

const DirectorsDelete = ({ isOpen, lang, id, setDeleteModal }) => {
  const dispatch = useDispatch();

  const {
    directors: { deleteTitle, deleteMessage }
  } = messages[lang];

  const handleDelete = () => {
    dispatch(deleteDirectorsRequest(id));
    setDeleteModal({ id: 0, open: false });
  };

  return (
    <DeleteModal
      deleteMessage={deleteMessage}
      deleteTitle={deleteTitle}
      isOpen={isOpen}
      lang={lang}
      handleDelete={handleDelete}
      handleCancel={() => setDeleteModal({ id: 0, open: false })}
    />
  );
};

DirectorsDelete.propTypes = {
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  id: PropTypes.number,
  setDeleteModal: PropTypes.func
};

DirectorsDelete.defaultProps = {
  isOpen: false
};

export default DirectorsDelete;

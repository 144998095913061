import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/categories";
import {
  getCategoriesResponse,
  addCategoryResponse,
  editCategoryResponse
} from "./actions";
import { showHideSnackbar } from "./../Snackbar/actions";
import { add, edit } from "./../ActionsBtn/actions";
import messages from "../../assets/locale/messages";

export function* getCategoriesSaga({ payload }) {
  try {
    const response = yield call(apis.getCategories, payload);
    yield put(
      getCategoriesResponse({
        list: response.data.categories,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteCategorySaga({ payload }) {
  try {
    const response = yield call(apis.deleteCategory, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].categories.deleteCategorySuccess
      })
    );
    yield getCategoriesSaga({ payload: { page: 1, items: 10 } });
  } catch (err) {
    console.error(err);
  }
}

export function* addCategorySaga({ payload }) {
  try {
    const response = yield call(apis.addCategory, payload);
    yield put(addCategoryResponse(response.data));
    yield put(add(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].categories.addCategorySuccess
      })
    );
    yield getCategoriesSaga({
      payload: { page: 1, items: 10, paginate: true }
    });
  } catch (err) {
    console.error(err);
  }
}

export function* editCategorySaga({ payload }) {
  try {
    const response = yield call(apis.editCategory, payload);
    yield put(editCategoryResponse(response.data));
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].categories.editCategorySuccess
      })
    );
    yield getCategoriesSaga({
      payload: { page: 1, items: 10, paginate: true }
    });
  } catch (err) {
    console.error(err);
  }
}

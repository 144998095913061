import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import EmptyState from "../../../components/EmptyState";
import Pagination from "../../../components/Pagination";
import Table from "../../../components/Table";
import { getAuthorsRequest } from "../../../store/Authors/actions";

const AuthorsList = ({
  lang,
  setFormMode,
  setFormModalOpen,
  setDeleteModal
}) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.authors.list);
  const meta = useSelector((state) => state.authors.meta);
  const isLoading = useSelector((state) => state.loader);
  const currentAdmin = useSelector((state) => state.auth.login);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const {
    cols: { name, email },
    admins: { deleteNotAllowedMsg },
    shared: { deleteLabel, editLabel }
  } = messages[lang];

  const handleEdit = (id) => {
    // dispatch(edit(id));
    setFormModalOpen(true);
    setFormMode({ mode: "edit", editId: id });
  };

  const handleDelete = (id) => {
    setDeleteModal({ id, open: true });
  };

  useEffect(() => {
    function getAdmins() {
      dispatch(
        getAuthorsRequest({ page: 1, items: perPage, paginate: true })
      );
    }
    getAdmins();
  }, []);

  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getAuthorsRequest({
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };

  return (
    <>
      <Table
        headlines={[name, email]}
        hasActions={true}
        rows={list.map((row) => (
          <TableRow className="table__row" key={row.id}>
            <TableCell className="table__cell" align="left">
              {row.name}
            </TableCell>
            <TableCell className="table__cell" align="left">
              {row.email}
            </TableCell>
            <TableCell className="table__cell" align="right">
              <Tooltip title={editLabel}>
                <span>
                  <IconButton
                    onClick={() => handleEdit(row.id)}
                    aria-label="edit"
                  >
                    <EditIcon
                      className="table__edit"
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  currentAdmin.id === row.id
                    ? deleteNotAllowedMsg
                    : deleteLabel
                }
              >
                <span>
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    aria-label="delete"
                    disabled={currentAdmin.id === row.id}
                  >
                    <DeleteIcon
                      className={`table__delete ${
                        currentAdmin.id === row.id
                          ? "table__disabled"
                          : ""
                      }`}
                      fontSize="medium"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      />
      {!isLoading && !list.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

AuthorsList.propTypes = {
  lang: PropTypes.string,
  setFormMode: PropTypes.func,
  setDeleteModal: PropTypes.func,
  setFormModalOpen: PropTypes.func
};

export default AuthorsList;

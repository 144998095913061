import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";

window?.Tiff?.initialize({ TOTAL_MEMORY: 19777216 * 10 });

export const ImgDisplay = ({ src, className, hasClear, onClear }) => {
  console.log("src", src);

  const imgRef = useRef();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [percentLoaded, setPercentLoaded] = useState(0);

  function isTiffFile() {
    return src && src?.endsWith("tiff");
  }

  function _arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  function displayImage() {
    axios
      .get(src, {
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          setIsFileLoading(true);
          if (progressEvent.lengthComputable) {
            setPercentLoaded(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }
        }
      })
      .then((response) => {
        if (response.headers["content-type"] === "image/tiff") {
          try {
            var tiff = new window.Tiff({ buffer: response.data });
            var canvas = tiff.toDataURL();
            imgRef.current.src = canvas;
          } catch (err) {
            console.error(err);
          }
        } else {
          imgRef.current.src =
            "data:image/png;base64," +
            _arrayBufferToBase64(response.data);
        }

        setIsFileLoading(false);
      });
  }

  useEffect(() => {
    if (isTiffFile()) {
      displayImage();
    }
  }, [src]);
  return (
    <>
      {/* .tif file only */}

      {src?.includes(" ") && src?.endsWith(".tif") && (
        <div className={className} style={{ textAlign: "center" }}>
          <img
            src={src}
            className={className}
            style={{
              width: "auto",
              height: "100%",
              display: "inline-block",
              position: "initial"
            }}
          />
          {hasClear && !!src && (
            <CancelIcon
              style={{
                position: "absolute",
                zIndex: 9,
                right: 0,
                cursor: "pointer"
              }}
              onClick={onClear}
            />
          )}
        </div>
      )}

      {/* Normal Image or .tif file witout spaces in url */}

      {!isTiffFile() &&
        !(src?.includes(" ") && src?.endsWith(".tif")) && (
          <div
            className={className}
            style={{ backgroundImage: `url(${encodeURI(src)})` }}
          >
            {hasClear && !!src && (
              <CancelIcon
                style={{
                  position: "absolute",
                  zIndex: 9,
                  right: 0,
                  cursor: "pointer"
                }}
                onClick={onClear}
              />
            )}
          </div>
        )}

      {/* TIFF image only */}
      {isFileLoading ? (
        <div
          style={{
            textAlign: "center"
          }}
        >
          <div>
            <strong>{percentLoaded.toFixed(1)} %</strong>
          </div>
          <br />
          <progress
            max="100"
            value={percentLoaded.toFixed(1)}
          ></progress>
        </div>
      ) : (
        ""
      )}
      <img
        className={className}
        style={{
          visibility:
            isFileLoading || !isTiffFile() ? "hidden" : "visible"
        }}
        ref={imgRef}
      />
      {hasClear && !!src && isTiffFile() && (
        <CancelIcon
          style={{
            position: "absolute",
            zIndex: 9,
            right: 0,
            cursor: "pointer"
          }}
          onClick={onClear}
        />
      )}
    </>
  );
};

ImgDisplay.propTypes = {
  src: PropTypes.string,
  className: PropTypes.any,
  hasClear: PropTypes.bool,
  onClear: PropTypes.func
};

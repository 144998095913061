import * as types from "./types";

export const loginRequest = (data) => ({
  type: types.LOGIN_REQUEST,
  payload: data
});

export const loginResponse = (data) => ({
  type: types.LOGIN_RESPONSE,
  payload: data
});

export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST
});

export const forgotPasswordRequest = (data) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload: data
});

export const changePasswordRequest = (data) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload: data
});

export const createPasswordRequest = (data) => ({
  type: types.CREATE_PASSWORD_REQUEST,
  payload: data
});

export const GET_SURVEYS_REQUEST = "GET_SURVEYS_REQUEST";
export const GET_SURVEYS_RESPONSE = "GET_SURVEYS_RESPONSE";
export const DELETE_SURVEY_REQUEST = "DELETE_SURVEY_REQUEST";
export const SET_SURVEY_FILTERS = "SET_SURVEY_FILTERS";
export const ADD_SURVEYS_REQUEST = "ADD_SURVEYS_REQUEST";
export const ASSIGN_BOOK_TO_SURVEY_REQUEST =
  "ASSIGN_BOOK_TO_SURVEY_REQUEST";
export const UNASSIGN_BOOK_FROM_SURVEY_REQUEST =
  "UNASSIGN_BOOK_FROM_SURVEY_REQUEST";
export const EDIT_SURVEY_REQUEST = "EDIT_SURVEY_REQUEST";
export const GET_SINGLE_SURVEY_REQUEST = "GET_SINGLE_SURVEY_REQUEST";
export const GET_SINGLE_SURVEY_RESPONSE =
  "GET_SINGLE_SURVEY_RESPONSE";
export const EDIT_BOOK_PAGE_FOR_SURVEY_REQUEST =
  "EDIT_BOOK_PAGE_FOR_SURVEY_REQUEST";
export const GET_SINGLE_SURVEY_STATISTCS_RESPONSE =
  "GET_SINGLE_SURVEY_STATISTCS_RESPONSE";
export const GET_SINGLE_SURVEY_STATISTCS_REQUEST =
  "GET_SINGLE_SURVEY_STATISTCS_REQUEST";

export const CHECK_FOR_CAN_BE_EDIT_SURVEY_REQUEST =
  "CHECK_FOR_CAN_BE_EDIT_SURVEY_REQUEST";

export const CHECK_FOR_CAN_BE_EDIT_SURVEY_RESPONSE =
  "CHECK_FOR_CAN_BE_EDIT_SURVEY_RESPONSE";

export const EXPORT_SURVEY_REPORT = "EXPORT_SURVEY_REPORT";

import * as types from "./types";

const INITIAL_STATE = {
  edit: false,
  add: false,
  delete: false,
  deleteSection: false,
  unassign: false,
  reassign: false
};

const actionsBtnReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.EDIT:
      return { ...state, edit: action.payload };
    case types.ADD:
      return { ...state, add: action.payload };
    case types.DELETE:
      return { ...state, delete: action.payload };
    case types.DELETESECTION:
      return { ...state, deleteSection: action.payload };

    case types.UNASSIGN:
      return { ...state, unassign: action.payload };
    case types.REASSIGN:
      return { ...state, reassign: action.payload };
    default:
      return state;
  }
};

export default actionsBtnReducer;

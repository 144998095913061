import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import Search from "../../components/Search";
import CustomSelect from "../../components/Select";
import { MATERIAL_TYPES } from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupplementariesRequest,
  setSupplementariesFilters
} from "../../store/Supplementaries/actions";
import { getBookSupplementraiesRequest } from "../../store/Books/actions";

const Filters = ({ bookId }) => {
  const dispatch = useDispatch();
  const perPage = 10;
  const lang = useSelector((state) => state.locale.lang);
  const searchKeyword = useSelector(
    (state) => state.supplementaries.searchKeyword
  );
  const selectedType = useSelector(
    (state) => state.supplementaries.selectedType
  );

  const {
    supplementaries: { materialType }
  } = messages[lang];

  const handleSelectChange = (id) => {
    dispatch(
      setSupplementariesFilters({
        selectedType: id,
        searchKeyword
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      setSupplementariesFilters({
        selectedType: selectedType,
        searchKeyword: e.target.value
      })
    );
  };

  useEffect(() => {
    function filterSupplementariesList() {
      if (bookId) {
        dispatch(
          getBookSupplementraiesRequest({
            material_type: selectedType,
            title: searchKeyword,
            page: 1,
            items: perPage,
            book_id: bookId
          })
        );
      } else {
        dispatch(
          getSupplementariesRequest({
            material_type: selectedType,
            title: searchKeyword,
            page: 1,
            items: perPage
          })
        );
      }
    }
    filterSupplementariesList();
  }, [searchKeyword, selectedType]);

  useEffect(() => {
    return () => {
      dispatch(
        setSupplementariesFilters({
          searchKeyword: null,
          selectedType: null
        })
      );
    };
  }, []);

  return (
    <Formik initialValues={{ title: "", material_type: "" }}>
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Search
                name="title"
                placeholder="Search"
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomSelect
                isAddNoneOption={true}
                options={MATERIAL_TYPES}
                placeholder={materialType}
                name="material_type"
                handleSelectChange={handleSelectChange}
              />
            </Grid>
          </Grid>
          <button className="d-none"></button>
        </Form>
      )}
    </Formik>
  );
};

Filters.propTypes = {
  bookId: PropTypes.string
};

Filters.defaultProps = {
  bookId: null
};

export default Filters;

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import {
  ADD_EDIT_CATEGORY_SCHEMA,
  getInitialValues
} from "./validations";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { add, edit } from "../../../store/ActionsBtn/actions";
import { addCategoryRequest } from "../../../store/Categories/actions";
import { editCategoryRequest } from "../../../store/Categories/actions";
import messages from "../../../assets/locale/messages";

const CategoryForm = ({ mode, selectedCategory, isOpen, lang }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.actionsBtn.edit);

  const {
    categories: { addTitle, editTitle, categoryNameLabel }
  } = messages[lang];

  const handleSubmit = ({ name }) => {
    const addData = { name },
      editData = { name, id };
    mode === "add"
      ? dispatch(addCategoryRequest(addData))
      : dispatch(editCategoryRequest(editData));
  };

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleModalCancel = () => {
    mode === "add" ? dispatch(add(false)) : dispatch(edit(false));
  };

  return (
    <Modal
      title={mode === "add" ? addTitle : editTitle}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={getInitialValues(selectedCategory)}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={ADD_EDIT_CATEGORY_SCHEMA(lang)}
      >
        {() => (
          <Form>
            <Input name="name" label={categoryNameLabel} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

CategoryForm.propTypes = {
  mode: PropTypes.string,
  selectedCategory: PropTypes.object,
  isOpen: PropTypes.bool,
  lang: PropTypes.string
};

CategoryForm.defaultProps = {
  mode: "add",
  isOpen: false
};

export default CategoryForm;

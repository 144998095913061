import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { edit } from "../../../store/ActionsBtn/actions";
import {
  addAuthorRequest,
  editAuthorRequest
} from "../../../store/Authors/actions";
import { getBooksRequest } from "../../../store/Books/actions";
import { ADD_AUTHOR_SCHEMA, EDIT_AUTHOR_SCHEMA } from "./validations";

const AuthorsForm = ({
  mode,
  selectedAuthor,
  isOpen,
  setIsOpen,
  lang,
  setFormModalOpen
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const {
    authors: {
      addTitle,
      editTitle,
      authorNameLabel,
      authorEmailLabel
    }
  } = messages[lang];
  const [assignedBooksIds, setAssignedBooksIds] = useState([]);
  // attach books ids
  useEffect(() => {
    setAssignedBooksIds(selectedAuthor?.book_ids ?? []);
    return () => {
      setAssignedBooksIds([]);
    };
  }, [selectedAuthor?.id]);

  const handleSubmit = ({ name, email }) => {
    const addData = { name, email, book_ids: assignedBooksIds };
    const editData = {
      name,
      id: selectedAuthor?.id,
      book_ids: assignedBooksIds
    };
    mode === "add"
      ? dispatch(addAuthorRequest({ author: addData }))
      : dispatch(editAuthorRequest(editData));
  };
  const booksList = useSelector((state) => state.books.list);
  // fetch all books
  useEffect(() => {
    function filterBooksList() {
      const params = {
        category_id: "",
        search_term: "",
        page: 1,
        items: 1000,
        paginate: true
      };
      dispatch(getBooksRequest(params));
    }
    filterBooksList();
  }, []);
  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const openForm = useSelector((state) => state.actionsBtn.edit);
  useEffect(() => {
    dispatch(edit(isOpen));
  }, [isOpen]);
  useEffect(() => {
    if (!openForm) {
      setIsOpen(openForm);
    }
  }, [openForm]);

  return (
    <Modal
      title={mode === "add" ? addTitle : editTitle}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={() => setFormModalOpen(false)}
    >
      <Formik
        initialValues={{
          name: selectedAuthor?.name,
          email: selectedAuthor?.email
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={
          mode === "add" ? ADD_AUTHOR_SCHEMA : EDIT_AUTHOR_SCHEMA
        }
      >
        {() => (
          <Form>
            <Input name="name" label={authorNameLabel} />
            {mode === "add" && (
              <Input name="email" label={authorEmailLabel} />
            )}
            <Autocomplete
              multiple
              limitTags={4}
              id="multiple-assigned-books"
              options={booksList}
              getOptionLabel={(option) => option?.title}
              defaultValue={assignedBooksIds.map((selectedId) =>
                booksList.find((b) => b.id === selectedId)
              )}
              onChange={(_, elements) => {
                if (elements.length) {
                  const selectedIds = elements.map((b) => b.id);
                  setAssignedBooksIds(selectedIds);
                } else setAssignedBooksIds([]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assigned Books"
                  placeholder="Books"
                />
              )}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

AuthorsForm.propTypes = {
  mode: PropTypes.string,
  selectedAuthor: PropTypes.object,
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  setFormModalOpen: PropTypes.func,
  setIsOpen: PropTypes.func
};

AuthorsForm.defaultProps = {
  mode: "add",
  isOpen: false
};

export default AuthorsForm;

import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import * as Yup from "yup";
import CustomSelect from "../../../components/Select";
import Input from "../../../components/Input";
import { getBooksRequest } from "../../../store/Books/actions";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core";
import {
  assignQuizBtn,
  assignQuizToBook,
  editAssignQuizToBook
} from "../../../store/Quizzes/actions";
import _ from "lodash";
import messages from "../../../assets/locale/messages";
import { useParams } from "react-router-dom";

const AssignQuiz = ({ isOpen, singleBook, page }) => {
  let { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const formRef = useRef();
  const booksList = useSelector((state) => state.books.list);
  const quizID = useSelector((state) => state.quizzes.assignQuizBtn);
  const lang = useSelector((state) => state.locale.lang);
  const [booksOptions, setBooksOptions] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const [mode, setMode] = useState("add");

  const {
    supplementaries: {
      assignQuizzesToBook,
      bookPageLabel,
      editAssignedPage
    },
    books: { book },
    supplementaries: {
      validation: { bookRequired, pageRequired, validPage }
    }
  } = messages[lang];

  const validationSchema = Yup.object().shape({
    book_id:
      mode === "edit"
        ? Yup.number().optional()
        : Yup.number().required(bookRequired),
    book_page: Yup.string()
      .test(
        "in book page range",
        `${validPage} ${selectedBook?.number_of_pages}`,
        (value) =>
          selectedBook?.number_of_pages
            ? value > 0 && value <= selectedBook?.number_of_pages
            : true
      )
      .required(pageRequired)
  });
  useEffect(() => {
    id ? setMode("edit") : null;
  }, [id]);

  useEffect(() => {
    dispatch(getBooksRequest({ lookup: true }));
  }, []);

  useEffect(() => {
    if (booksList.length) {
      setBooksOptions(
        booksList.map(({ id, title }) => ({
          id,
          value: id,
          label: title
        }))
      );
    }
  }, [booksList]);

  useEffect(() => {
    mode === "edit" ? setSelectedBook(singleBook) : null;
  }, [singleBook]);

  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleModalCancel = () => {
    setSelectedBook({});
    dispatch(assignQuizBtn(false));
  };

  const handleSubmit = (values) => {
    mode === "edit"
      ? dispatch(
          editAssignQuizToBook({
            id: quizID,
            book_page: Number(values.book_page),
            book_id: id
          })
        )
      : dispatch(
          assignQuizToBook({
            quiz_id: quizID,
            book_id: Number(values.book_id),
            book_page: Number(values.book_page)
          })
        );
    handleModalCancel();
  };

  const handleSelectedBook = (id) => {
    setSelectedBook(_.find(booksList, ["id", id]));
  };

  return (
    <Modal
      title={mode == "edit" ? editAssignedPage : assignQuizzesToBook}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={{
          book_id: "",
          book_page: page || ""
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ ...formik }) => (
          <Form>
            <Input
              name="book_page"
              label={bookPageLabel}
              isRequired={true}
              isNumber={true}
            />
            {mode === "add" && (
              <CustomSelect
                options={booksOptions}
                placeholder={book}
                name="book_id"
                bgColor={theme.palette.primary.light}
                isRequired={true}
                isMenuRelative={true}
                handleSelectChange={(id) => {
                  handleSelectedBook(id);
                  setTimeout(() => {
                    formik.validateForm();
                  }, 10);
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
AssignQuiz.propTypes = {
  isOpen: PropTypes.bool,
  singleBook: PropTypes.object,
  page: PropTypes.number
};
export default AssignQuiz;

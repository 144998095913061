import { axiosInstance } from ".";

const getInsights = async (params) => {
  return await axiosInstance.get(`dashboard?reset=true`, { params });
};
const getBooksDashbosrd = async (params) => {
  return await axiosInstance.get(`dashboard/books_insights`, {
    params
  });
};
export { getInsights, getBooksDashbosrd };

import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Chip, Grid, Typography } from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MaterialPreview from "../../../components/MaterialPreview";
import InfoCard from "../../../components/InfoCard";
import {
  getSingleSupplementaryRequest,
  getSingleSupplementaryResponse
} from "../../../store/Supplementaries/actions";
import { getSupplementaryTypeLabel } from "../../../utils/Helpers";
import { KEEP_LINE_SPACES } from "../../../utils/Patterns";

const SupplementaryDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const singleSupplementary = useSelector(
    (state) => state.supplementaries.singleSupplementary
  );

  const {
    supplementaries: {
      diagnosisLabel,
      descriptionLabel,
      assignedBooks
    }
  } = messages;

  useEffect(() => {
    params?.id
      ? dispatch(getSingleSupplementaryRequest(params.id))
      : null;
  }, [params]);

  useEffect(() => {
    return () => {
      dispatch(getSingleSupplementaryResponse({}));
    };
  }, []);

  return (
    <section className="wrapper-bg">
      {!_.isEmpty(singleSupplementary) && (
        <div className="max-width">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                component="h1"
                className="mb-3 text-black"
              >
                {singleSupplementary.title}
              </Typography>
              <Chip
                color="primary"
                className="mb-4"
                label={
                  <span className="fsize-12">
                    <span className="me-2">
                      {
                        getSupplementaryTypeLabel(
                          singleSupplementary.material_type
                        )?.icon
                      }
                    </span>
                    {singleSupplementary.material_type}
                  </span>
                }
              />
              {singleSupplementary.diagnosis && (
                <>
                  <Typography
                    variant="h5"
                    component="h1"
                    className="mb-3 text-black"
                  >
                    {diagnosisLabel}
                  </Typography>
                  <p
                    className="lh-lg w-80"
                    dangerouslySetInnerHTML={{
                      __html: singleSupplementary.diagnosis?.replace(
                        KEEP_LINE_SPACES,
                        "<br />"
                      )
                    }}
                  ></p>
                </>
              )}
              {singleSupplementary.description && (
                <>
                  <Typography
                    variant="h5"
                    component="h1"
                    className="mb-3 text-black"
                  >
                    {descriptionLabel}
                  </Typography>
                  <p
                    className="lh-lg w-80 mb-5"
                    dangerouslySetInnerHTML={{
                      __html:
                        singleSupplementary.description?.replace(
                          KEEP_LINE_SPACES,
                          "<br />"
                        )
                    }}
                  ></p>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <div className="mb-3 d-flex flex-column align-items-center w-100">
                <MaterialPreview
                  src={singleSupplementary.url?.[0]}
                  type={
                    getSupplementaryTypeLabel(
                      singleSupplementary.material_type
                    )?.id
                  }
                  dicomImages={
                    singleSupplementary.material_type === "DICOM"
                      ? singleSupplementary.url
                      : []
                  }
                />
              </div>
            </Grid>
          </Grid>
          {singleSupplementary.books_details.length !== 0 && (
            <>
              <Typography
                variant="h5"
                component="h1"
                className="mb-3 text-black"
              >
                {assignedBooks}
              </Typography>
              <Grid container spacing={2}>
                {singleSupplementary.books_details.map((book) => (
                  <Grid item xs={3} key={book.id}>
                    <InfoCard
                      title={book.book_title}
                      subtitle={book.book_page}
                      icon={
                        <MenuBookIcon
                          fontSize="large"
                          className="me-1"
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default SupplementaryDetails;

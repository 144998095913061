import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomLink from "../../../components/Link";
import messages from "../../../assets/locale/messages";
import AuthLayout from "../../../components/AuthLayout";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { LOGIN_SCHEMA } from "./validations";
import { loginRequest } from "../../../store/Auth/actions";

const Login = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);

  const {
    inputs: { emailAddress, password },
    auth: {
      show,
      hide,
      login: { title },
      forgotPassword: { link }
    },
    buttons: { signIn }
  } = messages[lang];

  const [isShowPassword, setIsShowPassword] = useState(false);

  const setPasswordStatus = () => setIsShowPassword(!isShowPassword);

  const handleSubmit = ({ email, password, rememberMe }) => {
    dispatch(loginRequest({ data: { email, password }, rememberMe }));
  };

  const renderLoginForm = () => {
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
          rememberMe: false
        }}
        onSubmit={handleSubmit}
        validationSchema={LOGIN_SCHEMA(lang)}
      >
        {() => (
          <Form>
            <Input name="email" label={emailAddress} />
            <Input
              inputIcon={isShowPassword ? hide : show}
              type={!isShowPassword ? "password" : "text"}
              label={password}
              name="password"
              onIconClick={setPasswordStatus}
              autoComplete="new-password"
            />
            <CustomLink
              className="mb-4"
              slug={ROUTE_PATHS["forgotPassword"]}
              linkText={link}
            />
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Button
                type={"submit"}
                text={signIn}
                className="primary-lg"
              />
              {/* <Checkbox name="rememberMe" label={remeberMe} /> */}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return <AuthLayout title={title} body={renderLoginForm()} />;
};

export default Login;

import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/books";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { edit } from "../ActionsBtn/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  addBookResponse,
  getBooksInCategoryResponse,
  getBooksRequest,
  getBooksResponse,
  getBookSupplementraiesResponse,
  getSingleBookResponse,
  getBookSectionsResponse,
  getBookSectionsRequest
} from "./actions";

export function* getBooksSaga({ payload }) {
  try {
    const response = yield call(apis.getBooks, payload);

    yield put(
      getBooksResponse({
        list: response.data.books,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteBookSaga({ payload }) {
  try {
    const response = yield call(apis.deleteBook, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].books.deleteBookSuccess
      })
    );
    yield put(getBooksRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addBookSaga({ payload }) {
  try {
    const response = yield call(apis.addBook, payload);
    yield put(addBookResponse(response.data));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].books.addBookSuccess
      })
    );
    yield history.push(ROUTE_PATHS["books"]);
  } catch (err) {
    console.error(err);
  }
}

export function* editBookSaga({ payload }) {
  try {
    const response = yield call(apis.editBook, payload);
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].books.editBookSuccess
      })
    );
    yield history.push(ROUTE_PATHS["books"]);
  } catch (err) {
    console.error(err);
  }
}

export function* getSingeBookSaga({ payload }) {
  try {
    const response = yield call(apis.getSingleBook, payload);
    yield put(getSingleBookResponse(response.data.book));
  } catch (err) {
    console.error(err);
  }
}

export function* getBooksInCategorySaga({ payload }) {
  try {
    const response = yield call(apis.getBooksInCategory, payload);
    yield put(getBooksInCategoryResponse(response.data.books));
  } catch (err) {
    console.error(err);
  }
}

export function* getBookSupplementariesSaga({ payload }) {
  try {
    const response = yield call(apis.getBookSupplementries, payload);
    yield put(
      getBookSupplementraiesResponse({
        list: response.data.book_supplementary_materials,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getBookSectionsSaga({ payload }) {
  try {
    const response = yield call(apis.getBookSections, payload);
    yield put(
      getBookSectionsResponse({
        list: response.data.sections,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* setBookSectionsSaga({ payload }) {
  try {
    const response = yield call(apis.addSection, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].section.addSectionSuccess
      })
    );
    yield put(
      getBookSectionsRequest({
        page: 1,
        items: 6,
        bookId: payload.bookId
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* editBookSectionsSaga({ payload }) {
  try {
    const response = yield call(apis.editSection, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].section.editSectionSuccess
      })
    );
    yield put(
      getBookSectionsRequest({
        page: 1,
        items: 6,
        bookId: payload.bookId
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteTestSaga({ payload }) {
  try {
    const response = yield call(apis.deleteSection, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].section.delteSectionSuccess
      })
    );
    yield put(
      getBookSectionsRequest({
        page: 1,
        items: 6,
        bookId: payload.bookId
      })
    );
  } catch (err) {
    console.error(err);
  }
}

import { createTheme } from "@material-ui/core/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#0e4dce",
      light: "#ecf1fb"
    },
    secondary: {
      main: "#d24ec6"
    },
    text: {
      primary: "#5e5e5e",
      secondary: "#807f7f"
    },
    background: {
      default: "#f4f7fd"
    },
    white: "#ffffff",
    black: {
      main: "#000000",
      light: "#3d3d3d"
    },
    divider: "#807f7f"
  },
  typography: {
    fontFamily: "Helvetica-Regular",
    h1: {
      fontFamily: "Helvetica-Bold"
    },
    h2: {
      fontFamily: "Helvetica-Bold"
    },
    h3: {
      fontFamily: "Helvetica-Bold"
    },
    h4: {
      fontFamily: "Helvetica-Bold"
    },
    h5: {
      fontFamily: "Helvetica-Bold"
    },
    h6: {
      fontFamily: "Helvetica-Bold"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.7rem",
        color: "#f8f8f8",
        backgroundColor: "#807f7f"
      }
    }
  }
});

export default Theme;

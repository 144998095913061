import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";
import moment from "moment";
import BookCover from "../../../components/BookCover";
import messages from "../../../assets/locale/messages";
import { KEEP_LINE_SPACES } from "../../../utils/Patterns";
import styles from "./MainDetails.module.scss";

const MainDetails = ({ singleBook, lang }) => {
  const releaseFormat = "Do MMMM YYYY";
  const {
    books: {
      by,
      pages,
      toRead,
      breif,
      dollar,
      hour,
      certificate,
      isbn,
      version,
      edition,
      previous,
      quickAccess,
      website
    }
  } = messages[lang];

  return (
    <section className={`wrapper-bg ${styles.MainDetails} `}>
      <Grid container spacing={2} className="mb-5">
        <Grid item xs={2} className="d-flex justify-content-center">
          <BookCover src={singleBook?.cover_photo} size="sm" />
        </Grid>
        <Grid item xs={6} className="pb-0">
          <Typography
            variant="h5"
            component="h1"
            className="mb-3 text-black"
          >
            {singleBook?.title}
          </Typography>
          <p>
            {quickAccess}
            {
              <a
                href={process.env.REACT_APP_WEBSITE_URL}
                target="_blank"
                rel="noreferrer"
                className="ms-1 text-primary"
              >
                {website}
              </a>
            }
          </p>
          <p>
            {`${by} ${singleBook?.author}`}
            {singleBook?.release_date && (
              <span>{` | ${
                singleBook?.release_date
                  ? moment(singleBook.release_date).format(
                      releaseFormat
                    )
                  : ""
              }`}</span>
            )}
          </p>
          <p className="fsize-14 text-primary fw-bold">{`${dollar} ${singleBook?.price}`}</p>
          <p>{`${isbn}: ${singleBook?.isbn}`}</p>
          <p>
            {singleBook?.edition && (
              <span>{`${edition}: ${singleBook?.edition} | `}</span>
            )}

            {`${version}: ${singleBook?.book_version} `}
          </p>
          <p className="bg-gray-light px-4 py-2 rounded-pill text-gray w-max-content">
            {singleBook?.category?.name}
          </p>
          {singleBook?.old_version_book?.id && (
            <p>
              <span className="bold">{previous}:</span>{" "}
              <span> {singleBook?.old_version_book?.title}</span>
            </p>
          )}
        </Grid>
        {singleBook?.certificate && (
          <Grid
            item
            xs={4}
            className="p-1 border border-3 border-secondary h-max-content"
          >
            <div className="border border-secondary h-100 p-3">
              <Typography
                variant="h5"
                component="h1"
                className="text-center mb-3"
              >
                {certificate}
              </Typography>
              <Typography
                variant="h6"
                component="h1"
                className="text-center text-primary mb-3"
              >
                {singleBook?.certificate?.certificate_title}
              </Typography>
              <small className="text-center d-block">
                {singleBook?.certificate?.credit_type} |{" "}
                {singleBook?.certificate?.credits}
              </small>
              <p className="text-center">
                {singleBook?.certificate?.certificate_description}
              </p>
              <div className="text-center">
                <StarsIcon
                  className="text-secondary"
                  fontSize="large"
                />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2} className="pb-0">
          <div className="mb-5">
            <Typography
              variant="h5"
              component="h1"
              className=" text-primary text-center"
            >
              {singleBook?.number_of_pages}
            </Typography>
            <p className="text-center">{pages}</p>
          </div>

          {singleBook?.reading_hours && (
            <div className="mb-5">
              <Typography
                variant="h5"
                component="h1"
                className=" text-primary text-center"
              >
                {`${singleBook?.reading_hours}${hour}`}
              </Typography>
              <p className="text-center">{toRead}</p>
            </div>
          )}
        </Grid>
        <Grid item xs={10} className="pb-0">
          <Typography variant="h5" component="h1" className="mb-3">
            {breif}
          </Typography>
          <p
            className="lh-lg w-80"
            dangerouslySetInnerHTML={{
              __html: singleBook?.brief?.replace(
                KEEP_LINE_SPACES,
                "<br />"
              )
            }}
          ></p>
        </Grid>
      </Grid>
    </section>
  );
};

MainDetails.propTypes = {
  singleBook: PropTypes.object,
  lang: PropTypes.string
};

MainDetails.defaultProps = {
  singleBook: {}
};

export default MainDetails;

/* eslint-disable no-unused-vars */
import {
  IconButton,
  Paper,
  Typography,
  Box,
  Grid
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import RemoveIcon from "@material-ui/icons/Remove";
// import { showHideSnackbar } from "../../../store/Snackbar/actions";
import { useIntl } from "react-intl";
import CheckCircle from "@material-ui/icons/CheckCircle";

import Button from "../../../components/Button";
import {
  addQuiz,
  editQuiz,
  getQuizDetails,
  checkForCanBeEditQuizRequest,
  checkForCanBeEditQuizResponse
} from "../../../store/Quizzes/actions";
import { useParams, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { FieldArray, Form, Formik } from "formik";
import {
  getInitialValuesAdd,
  getInitialValuesEdit,
  QUIZ_VALIDATIONSCHEMA,
  newEmptyQuestion,
  newAnswer
} from "./QuizValidations";
import Input from "../../../components/Input";
import "./AddEditQuiz.scss";
import MaterialPreview from "../../../components/MaterialPreview";
import Upload from "../../PreTestPostTest/PreTestPostTestForm/testImageUploading";

const AddEditQuiz = ({ location }) => {
  const { messages, locale } = useIntl();
  const {
    quizzes: { quizDetails }
  } = useSelector((state) => state);
  const CanBeEditQuiz = true;

  const { quizzes, buttons } = messages;
  const { id } = useParams();

  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(false);

  /* ------------------------ GETTING QUIZ IN EDIT MODE ----------------------- */
  useEffect(() => {
    if (id) {
      dispatch(getQuizDetails({ id }));
      dispatch(checkForCanBeEditQuizRequest({ id: id }));
    }
  }, [id]);
  useEffect(() => {
    return () => {
      dispatch(checkForCanBeEditQuizResponse(true));
    };
  }, []);

  /* VIEW 
  Depending on Alaa's code */
  useEffect(() => {
    if (location?.state?.mode == "view") setReadOnly(true);
  }, [location?.state?.mode]);

  // Forming Questions in edit mode

  const handleSubmit = (values) => {
    id ? dispatch(editQuiz(values)) : dispatch(addQuiz(values));
  };

  const AddAnswer = (push, values, questionIndex) => {
    return () => {
      push({
        answer: "",
        currentTab:
          values.questions_attributes[questionIndex]
            .answer_choices_attributes[0].currentTab,
        image_url: "",
        correct: false
      });
    };
  };

  const removeAnswer = (replace, answerIndex, answer) => {
    return () => {
      replace(answerIndex, {
        ...answer,
        correct: false,
        _destroy: true
      });
    };
  };

  const setTabValue = (
    setFieldValue,
    questionIndex,
    values,
    type
  ) => {
    const newArray = [];
    values.questions_attributes[
      `${questionIndex}`
    ].answer_choices_attributes.forEach((answer) => {
      if (answer?.id) {
        let deletedAns = {
          ...answer,
          correct: false,
          _destroy: true
        };
        newArray.push(deletedAns);
      }
    });

    const withTwoEmptyValues = [
      ...newArray,
      ...[
        {
          answer: "",
          correct: false,
          image_url: "",
          currentTab: type
        },
        {
          answer: "",
          correct: false,
          image_url: "",
          currentTab: type
        }
      ]
    ];

    setFieldValue(
      `questions_attributes[${questionIndex}].answer_choices_attributes`,
      withTwoEmptyValues
    );
  };

  return (
    <Formik
      initialValues={
        id ? getInitialValuesEdit(quizDetails) : getInitialValuesAdd()
      }
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={QUIZ_VALIDATIONSCHEMA(locale)}
      enableReinitialize
    >
      {({ ...formik }) => {
        console.log("FFFFFF", formik);

        const totalPoints = formik?.values?.questions_attributes
          ?.filter((x) => !x._destroy)
          ?.reduce((acc, cur) => acc + Number(cur.points), 0);

        return (
          <div>
            <div className="mb-4">
              <Typography variant="h4" component="h1">
                {location?.state?.mode === "view"
                  ? formik.values.title
                  : id
                  ? quizzes.editQuiz
                  : quizzes.addQuiz}
              </Typography>
            </div>
            <fieldset
              disabled={readOnly || !CanBeEditQuiz}
              className={
                readOnly || !CanBeEditQuiz ? "disableFormEdit" : null
              }
            >
              <Box sx={{ display: "flex", gap: "5" }}>
                <div className="w-40">
                  <Input
                    name="title"
                    label={"Quiz Title"}
                    // remove the * from field in the view mode
                    isRequired={!readOnly}
                  />
                </div>
                <div className="mx-4">
                  <Input
                    name="total_points"
                    label={"Total Quiz Points"}
                    disabled={true}
                    value={totalPoints || ""}
                  />
                </div>
              </Box>

              <FieldArray name="questions_attributes">
                {({ push, replace, remove }) => {
                  return !formik?.values?.questions_attributes?.length
                    ? null
                    : formik?.values?.questions_attributes?.map(
                        (question, questionIndex, arr) => {
                          const lastActiveItem = [...arr]
                            .map((x) => x._destroy)
                            .map((x) => !!x)
                            .lastIndexOf(false);

                          const showAddButton =
                            lastActiveItem === questionIndex;

                          const error =
                            formik.errors.questions_attributes?.[
                              questionIndex
                            ];

                          return (
                            !question._destroy && (
                              <div
                                className="row align-items-center justify-content-center my-3"
                                key={question.id}
                              >
                                <div className="col-md-10 my-4">
                                  <Paper
                                    key={question.id}
                                    style={{ padding: "2rem" }}
                                    className="row align-items-center justify-content-center"
                                  >
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div style={{ width: "100%" }}>
                                        <Input
                                          name={`questions_attributes[${questionIndex}].question`}
                                          label={
                                            readOnly
                                              ? quizzes?.questionTitle
                                              : "Add Question"
                                          }
                                          inputShape="textarea"
                                          rows="2"
                                        />
                                      </div>
                                      {!readOnly && (
                                        <a
                                          data-bs-toggle="collapse"
                                          href={`#collapseExample${questionIndex}`}
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="collapseExample"
                                          style={{ width: "100px" }}
                                          className="ml-2 mb-4"
                                        >
                                          {quizzes?.uploadImageLabel}
                                        </a>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        !formik.values
                                          ?.questions_attributes[
                                          questionIndex
                                        ].image_url
                                          ? " collapse p-2 mb-2 "
                                          : "collapse show p-2 mb-2"
                                      }
                                      id={`collapseExample${questionIndex}`}
                                    >
                                      <div className="d-flex align-items-center justify-content-center">
                                        <Grid
                                          item
                                          className="pb-0"
                                          xs={6}
                                        >
                                          <div className="text-center">
                                            <div className="mb-3 d-flex flex-column align-items-center">
                                              <MaterialPreview
                                                type={1}
                                                src={
                                                  formik.values
                                                    ?.questions_attributes[
                                                    questionIndex
                                                  ].image_url
                                                }
                                                hasClear={
                                                  !readOnly &&
                                                  CanBeEditQuiz
                                                }
                                                onClear={() => {
                                                  formik.setFieldValue(
                                                    `questions_attributes[${questionIndex}].image_url`,
                                                    ""
                                                  );
                                                }}
                                              />
                                            </div>
                                            {!readOnly && (
                                              <Upload
                                                label={
                                                  quizzes?.upload
                                                }
                                                name={`questions_attributes[${questionIndex}].image_url`}
                                                accept="image/*"
                                                isImage={true}
                                                setFieldValue={
                                                  formik.setFieldValue
                                                }
                                                isRequired={true}
                                                isMultiple={false}
                                              />
                                            )}
                                          </div>
                                        </Grid>
                                      </div>
                                    </div>
                                    <Input
                                      name={`questions_attributes[${questionIndex}].points`}
                                      label={"Points"}
                                      type="number"
                                      value={
                                        formik.values
                                          .questions_attributes[
                                          questionIndex
                                        ].points === 0
                                          ? "0"
                                          : formik.values
                                              .questions_attributes[
                                              questionIndex
                                            ].points
                                      }
                                    />

                                    {typeof error?.answer_choices_attributes ===
                                      "string" && (
                                      <p className="error-msg mb-3">
                                        {
                                          error?.answer_choices_attributes
                                        }
                                      </p>
                                    )}

                                    <Typography
                                      variant="h6"
                                      component="h6"
                                      className="text-secondary my-4"
                                    >
                                      {quizzes?.answersOptions}
                                    </Typography>
                                    {readOnly ? (
                                      <div className=" py-4">
                                        <div className=" show active">
                                          {question?.answer_choices_attributes.map(
                                            (answer, answerIndex) => {
                                              return (
                                                !answer._destroy && (
                                                  <div
                                                    className="mx-5 d-flex align-items-center"
                                                    key={JSON.stringify(
                                                      answerIndex
                                                    )}
                                                  >
                                                    <span className="mb-2 mr-1 ">
                                                      {answerIndex +
                                                        1}{" "}
                                                      -
                                                    </span>
                                                    <div className="flex-fill ml-1 d-flex align-items-center">
                                                      {answer.image_url && (
                                                        <div
                                                          item
                                                          className="pb-0"
                                                        >
                                                          <img
                                                            src={
                                                              answer.image_url
                                                            }
                                                            alt="medblend"
                                                            width="100px"
                                                            className={`img-thumbnail m-3 img`}
                                                          />
                                                        </div>
                                                      )}
                                                      {answer.answer && (
                                                        <div className="flex-fill">
                                                          <Input
                                                            name={`questions_attributes[${questionIndex}].answer_choices_attributes[${answerIndex}].answer`}
                                                            label={
                                                              quizzes?.answer
                                                            }
                                                            isRequired={
                                                              false
                                                            }
                                                          />
                                                        </div>
                                                      )}
                                                      {formik.values
                                                        .questions_attributes[
                                                        questionIndex
                                                      ]
                                                        .answer_choices_attributes[
                                                        answerIndex
                                                      ].correct && (
                                                        <span
                                                          className={`icon alert alert-success`}
                                                          role="alert"
                                                        >
                                                          <CheckCircle
                                                            color="success"
                                                            className="me-2"
                                                          />
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <FieldArray
                                        name={`questions_attributes[${questionIndex}].answer_choices_attributes`}
                                      >
                                        {({
                                          push,
                                          replace,
                                          remove
                                        }) => {
                                          return (
                                            <Form>
                                              <ul className="nav nav-tabs">
                                                <li
                                                  className="nav-item"
                                                  role="presentation"
                                                >
                                                  <button
                                                    className={
                                                      (question
                                                        ?.answer_choices_attributes[0]
                                                        .id &&
                                                        question
                                                          ?.answer_choices_attributes[0]
                                                          .image_url ===
                                                          "" &&
                                                        question
                                                          .answer_choices_attributes[0]
                                                          .answer !==
                                                          "") ||
                                                      question
                                                        .answer_choices_attributes[0]
                                                        .currentTab ===
                                                        "text"
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                    }
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#text_${questionIndex}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`text_${questionIndex}`}
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      setTabValue(
                                                        formik.setFieldValue,
                                                        questionIndex,
                                                        formik.values,
                                                        "text"
                                                      )
                                                    }
                                                  >
                                                    Text
                                                  </button>
                                                </li>
                                                <li
                                                  className="nav-item"
                                                  role="presentation"
                                                >
                                                  <button
                                                    className={
                                                      question
                                                        ?.answer_choices_attributes[0]
                                                        .id &&
                                                      question
                                                        ?.answer_choices_attributes[0]
                                                        .image_url !==
                                                        "" &&
                                                      question
                                                        .answer_choices_attributes[0]
                                                        .answer === ""
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                    }
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#image_${questionIndex}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`image_${questionIndex}`}
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      setTabValue(
                                                        formik.setFieldValue,
                                                        questionIndex,
                                                        formik.values,
                                                        "image"
                                                      )
                                                    }
                                                  >
                                                    Image
                                                  </button>
                                                </li>
                                                <li
                                                  className="nav-item"
                                                  role="presentation"
                                                >
                                                  <button
                                                    className={
                                                      question
                                                        ?.answer_choices_attributes[0]
                                                        .id &&
                                                      question
                                                        ?.answer_choices_attributes[0]
                                                        .image_url !==
                                                        "" &&
                                                      question
                                                        .answer_choices_attributes[0]
                                                        .answer !== ""
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                    }
                                                    data-bs-toggle="tab"
                                                    data-bs-target={`#image_text_${questionIndex}`}
                                                    type="button"
                                                    role="tab"
                                                    aria-controls={`image_text_${questionIndex}`}
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      setTabValue(
                                                        formik.setFieldValue,
                                                        questionIndex,
                                                        formik.values,
                                                        "text_image"
                                                      )
                                                    }
                                                  >
                                                    Image with Text
                                                  </button>
                                                </li>
                                              </ul>
                                              <div
                                                className="tab-content py-4"
                                                id="myTabContent"
                                              >
                                                <div
                                                  className={
                                                    (question
                                                      ?.answer_choices_attributes[0]
                                                      .id &&
                                                      question
                                                        ?.answer_choices_attributes[0]
                                                        .image_url ===
                                                        "" &&
                                                      question
                                                        .answer_choices_attributes[0]
                                                        .answer !==
                                                        "") ||
                                                    question
                                                      .answer_choices_attributes[0]
                                                      .currentTab ===
                                                      "text"
                                                      ? "tab-pane fade show active"
                                                      : "tab-pane "
                                                  }
                                                  id={`text_${questionIndex}`}
                                                  role="tabpanel"
                                                  aria-labelledby={`text_${questionIndex}`}
                                                >
                                                  {question.answer_choices_attributes.map(
                                                    (
                                                      answer,
                                                      answerIndex,
                                                      arr
                                                    ) => {
                                                      return (
                                                        !answer._destroy && (
                                                          <div
                                                            className="mx-5 d-flex"
                                                            key={JSON.stringify(
                                                              answerIndex
                                                            )}
                                                          >
                                                            <div className="flex-fill">
                                                              <Input
                                                                name={`questions_attributes[${questionIndex}].answer_choices_attributes[${answerIndex}].answer`}
                                                                label={
                                                                  quizzes?.answer
                                                                }
                                                                isRequired={
                                                                  true
                                                                }
                                                              />
                                                            </div>
                                                            <div className="mx-2">
                                                              {arr?.filter(
                                                                (x) =>
                                                                  !x._destroy
                                                              )[0] && (
                                                                <div className="d-flex">
                                                                  <AddIcon
                                                                    className="me-2 mt-2 cursor-pointer"
                                                                    onClick={() => {
                                                                      push(
                                                                        {
                                                                          answer:
                                                                            "",
                                                                          currentTab:
                                                                            formik
                                                                              .values
                                                                              .questions_attributes[
                                                                              questionIndex
                                                                            ]
                                                                              .answer_choices_attributes[0]
                                                                              .currentTab,
                                                                          image_url:
                                                                            "",
                                                                          correct: false
                                                                        }
                                                                      );
                                                                    }}
                                                                  />
                                                                  {}
                                                                  <div className="mx-2 mt-2 cursor-pointer">
                                                                    {formik.values.questions_attributes[
                                                                      questionIndex
                                                                    ].answer_choices_attributes.every(
                                                                      (
                                                                        el
                                                                      ) =>
                                                                        el.correct ===
                                                                        false
                                                                    ) ===
                                                                    true ? (
                                                                      <CheckIcon
                                                                        color="success"
                                                                        className="me-2"
                                                                        onClick={() => {
                                                                          replace(
                                                                            answerIndex,
                                                                            {
                                                                              ...answer,
                                                                              correct: true
                                                                            }
                                                                          );
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      formik
                                                                        .values
                                                                        .questions_attributes[
                                                                        questionIndex
                                                                      ]
                                                                        .answer_choices_attributes[
                                                                        answerIndex
                                                                      ]
                                                                        .correct ===
                                                                        true && (
                                                                        <SettingsBackupRestoreIcon
                                                                          onClick={() => {
                                                                            replace(
                                                                              answerIndex,
                                                                              {
                                                                                ...answer,
                                                                                correct: false
                                                                              }
                                                                            );
                                                                          }}
                                                                        />
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>

                                                            {question.answer_choices_attributes.filter(
                                                              (x) =>
                                                                !x._destroy
                                                            ).length >
                                                              2 && (
                                                              <div className="mx-2 mt-2 cursor-pointer">
                                                                <RemoveIcon
                                                                  className="me-2"
                                                                  onClick={() => {
                                                                    if (
                                                                      !answer.id
                                                                    ) {
                                                                      remove(
                                                                        answerIndex
                                                                      );
                                                                      return;
                                                                    }
                                                                    replace(
                                                                      answerIndex,
                                                                      {
                                                                        ...answer,
                                                                        answer:
                                                                          "deleted",
                                                                        correct: false,

                                                                        _destroy: true
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            )}
                                                          </div>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                </div>

                                                <div
                                                  className={
                                                    question
                                                      ?.answer_choices_attributes[0]
                                                      .id &&
                                                    question
                                                      ?.answer_choices_attributes[0]
                                                      .image_url !==
                                                      "" &&
                                                    question
                                                      .answer_choices_attributes[0]
                                                      .answer === ""
                                                      ? "tab-pane fade show active"
                                                      : "tab-pane fade"
                                                  }
                                                  id={`image_${questionIndex}`}
                                                  role="tabpanel"
                                                  aria-labelledby={`image_${questionIndex}`}
                                                >
                                                  {question.answer_choices_attributes.map(
                                                    (
                                                      answer,
                                                      answerIndex,
                                                      arr
                                                    ) => {
                                                      return (
                                                        !answer._destroy && (
                                                          <div
                                                            className="mx-5 d-flex"
                                                            key={JSON.stringify(
                                                              answerIndex
                                                            )}
                                                          >
                                                            <div className="flex-fill">
                                                              <Grid
                                                                item
                                                                xs={6}
                                                                className="pb-0"
                                                              >
                                                                <div className="d-flex flex-column align-items-center text-center">
                                                                  <div className="mb-3 d-flex flex-column align-items-center w-100">
                                                                    <MaterialPreview
                                                                      type={
                                                                        1
                                                                      }
                                                                      src={
                                                                        formik
                                                                          .values
                                                                          ?.questions_attributes[
                                                                          questionIndex
                                                                        ]
                                                                          .answer_choices_attributes[
                                                                          answerIndex
                                                                        ]
                                                                          .image_url
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <Upload
                                                                    label={
                                                                      quizzes?.upload
                                                                    }
                                                                    name={`questions_attributes[${questionIndex}].answer_choices_attributes[${answerIndex}].image_url`}
                                                                    accept="image/*"
                                                                    isImage={
                                                                      true
                                                                    }
                                                                    setFieldValue={
                                                                      formik.setFieldValue
                                                                    }
                                                                    isRequired={
                                                                      true
                                                                    }
                                                                    isMultiple={
                                                                      false
                                                                    }
                                                                  />
                                                                </div>
                                                              </Grid>
                                                            </div>
                                                            <div className="mx-2">
                                                              {arr?.filter(
                                                                (x) =>
                                                                  !x._destroy
                                                              )[0] && (
                                                                <div className="d-flex">
                                                                  <AddIcon
                                                                    className="me-2 mt-2 cursor-pointer"
                                                                    onClick={() => {
                                                                      push(
                                                                        {
                                                                          answer:
                                                                            "",
                                                                          currentTab:
                                                                            formik
                                                                              .values
                                                                              .questions_attributes[
                                                                              questionIndex
                                                                            ]
                                                                              .answer_choices_attributes[0]
                                                                              .currentTab,
                                                                          image_url:
                                                                            "",
                                                                          correct: false
                                                                        }
                                                                      );
                                                                    }}
                                                                  />
                                                                  <div className="mx-2 mt-2 cursor-pointer">
                                                                    {formik.values.questions_attributes[
                                                                      questionIndex
                                                                    ].answer_choices_attributes.every(
                                                                      (
                                                                        el
                                                                      ) =>
                                                                        el.correct ===
                                                                        false
                                                                    ) ===
                                                                    true ? (
                                                                      <CheckIcon
                                                                        color="success"
                                                                        className="me-2"
                                                                        onClick={() => {
                                                                          replace(
                                                                            answerIndex,
                                                                            {
                                                                              ...answer,
                                                                              correct: true
                                                                            }
                                                                          );
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      formik
                                                                        .values
                                                                        .questions_attributes[
                                                                        questionIndex
                                                                      ]
                                                                        .answer_choices_attributes[
                                                                        answerIndex
                                                                      ]
                                                                        .correct ===
                                                                        true && (
                                                                        <SettingsBackupRestoreIcon
                                                                          onClick={() => {
                                                                            replace(
                                                                              answerIndex,
                                                                              {
                                                                                ...answer,
                                                                                correct: false
                                                                              }
                                                                            );
                                                                          }}
                                                                        />
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                            {question.answer_choices_attributes.filter(
                                                              (x) =>
                                                                !x._destroy
                                                            ).length >
                                                              2 && (
                                                              <div className="mx-2 mt-2 cursor-pointer">
                                                                <RemoveIcon
                                                                  className="me-2"
                                                                  onClick={() =>
                                                                    replace(
                                                                      answerIndex,
                                                                      {
                                                                        ...answer,
                                                                        correct: false,

                                                                        _destroy: true
                                                                      }
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          </div>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                </div>
                                                <div
                                                  className={
                                                    question
                                                      ?.answer_choices_attributes[0]
                                                      .id &&
                                                    question
                                                      .answer_choices_attributes[0]
                                                      .image_url !==
                                                      "" &&
                                                    question
                                                      .answer_choices_attributes[0]
                                                      .answer !== ""
                                                      ? "tab-pane fade show active"
                                                      : "tab-pane fade"
                                                  }
                                                  id={`image_text_${questionIndex}`}
                                                  role="tabpanel"
                                                  aria-labelledby={`image_text_${questionIndex}`}
                                                >
                                                  {question.answer_choices_attributes.map(
                                                    (
                                                      answer,
                                                      answerIndex,
                                                      arr
                                                    ) => {
                                                      return (
                                                        !answer._destroy && (
                                                          <div
                                                            className="mx-5 d-flex"
                                                            key={JSON.stringify(
                                                              answerIndex
                                                            )}
                                                          >
                                                            <div className="flex-fill">
                                                              <Input
                                                                name={`questions_attributes[${questionIndex}].answer_choices_attributes[${answerIndex}].answer`}
                                                                label={
                                                                  quizzes?.answer
                                                                }
                                                                isRequired={
                                                                  true
                                                                }
                                                              />
                                                              <div className="flex-fill">
                                                                <Grid
                                                                  item
                                                                  xs={
                                                                    6
                                                                  }
                                                                  className="pb-0"
                                                                >
                                                                  <div className="d-flex flex-column align-items-center text-center">
                                                                    <div className="mb-3 d-flex flex-column align-items-center w-100">
                                                                      <MaterialPreview
                                                                        type={
                                                                          1
                                                                        }
                                                                        src={
                                                                          formik
                                                                            .values
                                                                            ?.questions_attributes[
                                                                            questionIndex
                                                                          ]
                                                                            .answer_choices_attributes[
                                                                            answerIndex
                                                                          ]
                                                                            .image_url
                                                                        }
                                                                      />
                                                                    </div>
                                                                    <Upload
                                                                      label={
                                                                        quizzes?.upload
                                                                      }
                                                                      name={`questions_attributes[${questionIndex}].answer_choices_attributes[${answerIndex}].image_url`}
                                                                      accept="image/*"
                                                                      isImage={
                                                                        true
                                                                      }
                                                                      setFieldValue={
                                                                        formik.setFieldValue
                                                                      }
                                                                      isRequired={
                                                                        true
                                                                      }
                                                                      isMultiple={
                                                                        false
                                                                      }
                                                                    />
                                                                  </div>
                                                                </Grid>
                                                              </div>
                                                            </div>
                                                            <div className="mx-2">
                                                              {arr?.filter(
                                                                (x) =>
                                                                  !x._destroy
                                                              )[0] && (
                                                                <div className="d-flex">
                                                                  <AddIcon
                                                                    className="me-2 mt-2 cursor-pointer"
                                                                    onClick={AddAnswer(
                                                                      push,
                                                                      formik.values,
                                                                      questionIndex
                                                                    )}
                                                                  />
                                                                  <div className="mx-2 mt-2 cursor-pointer">
                                                                    {formik.values.questions_attributes[
                                                                      questionIndex
                                                                    ].answer_choices_attributes.every(
                                                                      (
                                                                        el
                                                                      ) =>
                                                                        el.correct ==
                                                                        false
                                                                    ) ===
                                                                    true ? (
                                                                      <CheckIcon
                                                                        color="success"
                                                                        className="me-2"
                                                                        onClick={() => {
                                                                          replace(
                                                                            answerIndex,
                                                                            {
                                                                              ...answer,
                                                                              correct: true
                                                                            }
                                                                          );
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      formik
                                                                        .values
                                                                        .questions_attributes[
                                                                        questionIndex
                                                                      ]
                                                                        .answer_choices_attributes[
                                                                        answerIndex
                                                                      ]
                                                                        .correct ===
                                                                        true && (
                                                                        <SettingsBackupRestoreIcon
                                                                          onClick={() => {
                                                                            replace(
                                                                              answerIndex,
                                                                              {
                                                                                ...answer,
                                                                                correct: false
                                                                              }
                                                                            );
                                                                          }}
                                                                        />
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                            {question.answer_choices_attributes.filter(
                                                              (x) =>
                                                                !x._destroy
                                                            ).length >
                                                              2 && (
                                                              <div className="mx-2 mt-2 cursor-pointer">
                                                                <RemoveIcon
                                                                  className="me-2"
                                                                  onClick={removeAnswer(
                                                                    replace,
                                                                    answerIndex,
                                                                    answer
                                                                  )}
                                                                />
                                                              </div>
                                                            )}
                                                          </div>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </Form>
                                          );
                                        }}
                                      </FieldArray>
                                    )}
                                    <div className="col-12">
                                      <Input
                                        name={`questions_attributes[${questionIndex}].justification`}
                                        label={"Justification"}
                                        inputShape="textarea"
                                        rows="2"
                                      />
                                      <div className="mb-3 d-flex flex-row align-items-end w-50 gap-3">
                                        <MaterialPreview
                                          type={1}
                                          src={
                                            formik.values
                                              ?.questions_attributes?.[
                                              questionIndex
                                            ].justification_image_url
                                          }
                                        />
                                        {!readOnly && (
                                          <Upload
                                            label={quizzes?.upload}
                                            name={`questions_attributes[${questionIndex}].justification_image_url`}
                                            accept="image/*"
                                            isImage={true}
                                            setFieldValue={
                                              formik.setFieldValue
                                            }
                                            isRequired={true}
                                            isMultiple={false}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Paper>
                                </div>
                                {!readOnly && (
                                  <div className="col-md-2">
                                    {formik.values.questions_attributes.filter(
                                      (q) => !q._destroy
                                    ).length > 1 && (
                                      <IconButton
                                        onClick={() => {
                                          if (!question.id) {
                                            remove(questionIndex);
                                            return;
                                          }
                                          replace(questionIndex, {
                                            ...question,
                                            _destroy: true
                                          });
                                        }}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    )}
                                    {showAddButton && (
                                      <IconButton
                                        onClick={() =>
                                          push(newEmptyQuestion)
                                        }
                                      >
                                        <AddCircleIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                          );
                        }
                      );
                }}
              </FieldArray>
            </fieldset>
            {location?.state?.mode !== "view" && (
              <Button
                type="submit"
                className="primary-lg"
                data-toggle="tooltip"
                data-placement="top"
                disabled={!CanBeEditQuiz}
                text={buttons.submit}
                title={!CanBeEditQuiz ? quizzes.quizzeAnswered : ""}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                Save Quiz
              </Button>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

AddEditQuiz.propTypes = {
  location: PropTypes.object
};
export default withRouter(AddEditQuiz);

import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  singlePromoCode: null,
  promoCodesnames: [],
  searchKeyword: null
};

const promoCodesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_PROMOCODES_LIST_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.ADD_PROMOCODES_RESPONSE:
      return { ...state, addedBook: action.payload };
    case types.GET_SINGLE_PROMOCODE_RESPONSE:
      return { ...state, singlePromoCode: action.payload };
    case types.SET_PROMOCODES_FILTERS:
      return {
        ...state,
        searchKeyword: action.payload.searchKeyword
      };
    default:
      return state;
  }
};

export default promoCodesReducer;

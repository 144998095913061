import { ASC } from "../../utils/Constants";
import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  bookQuiz: {
    list: [],
    meta: {}
  },
  assignQuizBtn: false,
  CanBeEditQuiz: true,
  deleteQuizBtn: false,
  unassignBookQuizBtn: false,
  quizzesBookFilter: 0,
  quizzesBookSort: ASC,
  quizzesPage: 1
};

const quizzesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_QUIZZES_LIST:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.SET_BOOK_QUIZ:
      return {
        ...state,
        bookQuiz: {
          list: action.payload.list,
          meta: action.payload.meta
        }
      };
    case types.SET_QUIZ_DETAILS:
      return {
        ...state,
        quizDetails: action.payload
      };
    case types.ASSIGN_QUIZ_BTN:
      return {
        ...state,
        assignQuizBtn: action.payload
      };
    case types.DELETE_QUIZ_ACTION:
      return {
        ...state,
        deleteQuizBtn: action.payload
      };
    case types.UNASSIGN_BOOK_QUIZ_ACTION:
      return {
        ...state,
        unassignBookQuizBtn: action.payload
      };
    case types.CHECK_FOR_CAN_BE_EDIT_QUIZ_RESPONSE:
      return { ...state, CanBeEditQuiz: action.payload };
    case types.SET_QUIZZES_BOOK_FILTER:
      return {
        ...state,
        quizzesBookFilter: action.payload.id
      };
    case types.SET_QUIZZES_BOOK_SORT_METHOD:
      return {
        ...state,
        quizzesBookSort: action.payload.sortMethod
      };
    case types.SET_QUIZZES_PAGE:
      return {
        ...state,
        quizzesPage: action.payload.page
      };
    default:
      return state;
  }
};

export default quizzesReducer;

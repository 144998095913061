import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProgramRequest,
  setSelectedBooks,
  setSelectedDirector,
  setSelectedUsers
} from "../../../store/Programs/actions";
import AssignBooks from "./AssignBooks";
import AssignDirector from "./AssignDirector";
import AssignUsers from "./AssignUsers";
import EditUsers from "./EditUsers";

export default function ProgramsEdit() {
  const { id } = useParams();
  const { selectedProgram } = useSelector((state) => state.programs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProgramRequest(id));
  }, []);

  console.log(
    selectedProgram?.program_director,
    "selectedProgram",
    selectedProgram
  );

  useEffect(() => {
    dispatch(
      setSelectedBooks(selectedProgram?.books?.map((b) => b.id) ?? [])
    );
    dispatch(
      setSelectedUsers(selectedProgram?.users?.map((u) => u.id) ?? [])
    );
    dispatch(
      setSelectedDirector(selectedProgram?.program_director?.id ?? 0)
    );
  }, [selectedProgram]);

  return (
    <div>
      <h3 style={{ marginBottom: "2rem" }}>
        {selectedProgram?.name}
      </h3>
      <div>
        <AssignDirector id={id} />
        <AssignUsers id={id} />
        <EditUsers id={id} />
        <AssignBooks id={id} />
      </div>
    </div>
  );
}

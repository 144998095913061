import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import messages from "../../../assets/locale/messages";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import {
  addDirectorRequest,
  editDirectorRequest
} from "../../../store/Directors/actions";
import { ADD_AUTHOR_SCHEMA, EDIT_AUTHOR_SCHEMA } from "./validations";

const DirectorsForm = ({
  mode,
  selectedAuthor,
  isOpen,
  lang,
  setFormModalOpen
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const {
    directors: {
      addTitle,
      editTitle,
      directorNameLabel,
      directorEmailLabel
    }
  } = messages[lang];

  const handleSubmit = ({ name, email }) => {
    const addData = { name, email };
    const editData = {
      name,
      id: selectedAuthor?.id
    };
    mode === "add"
      ? dispatch(addDirectorRequest({ program_director: addData }))
      : dispatch(editDirectorRequest(editData));
    setFormModalOpen(false);
  };
  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Modal
      title={mode === "add" ? addTitle : editTitle}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={() => setFormModalOpen(false)}
    >
      <Formik
        initialValues={{
          name: selectedAuthor?.name,
          email: selectedAuthor?.email
        }}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={
          mode === "add" ? ADD_AUTHOR_SCHEMA : EDIT_AUTHOR_SCHEMA
        }
      >
        {() => (
          <Form>
            <Input name="name" label={directorNameLabel} />
            {mode === "add" && (
              <Input name="email" label={directorEmailLabel} />
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

DirectorsForm.propTypes = {
  mode: PropTypes.string,
  selectedAuthor: PropTypes.object,
  isOpen: PropTypes.bool,
  lang: PropTypes.string,
  setFormModalOpen: PropTypes.func
};

DirectorsForm.defaultProps = {
  mode: "add",
  isOpen: false
};

export default DirectorsForm;

import * as Yup from "yup";
import messages from "../../../assets/locale/messages";

export const ASSIGN_SUPPLEMENTARY_SCHEMA = (
  lang,
  book_page,
  isEdit
) => {
  const {
    supplementaries: {
      validation: { bookRequired, pageRequired, validPage }
    }
  } = messages[lang];

  return Yup.object().shape({
    book_id: isEdit
      ? Yup.number().optional()
      : Yup.number().required(bookRequired),
    book_page: Yup.string()
      .required(pageRequired)
      .test(
        "in book page rang",
        `${validPage} ${book_page}`,
        (value) => value > 0 && value <= book_page
      )
  });
};

export const getInitialValues = (page) => {
  return {
    book_id: "",
    book_page: page || ""
  };
};

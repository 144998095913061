import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./FinalTestForm.module.scss";
import Input from "../../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../components/Select";
import { formatOptions } from "../../../utils/Helpers";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  getBooksRequest,
  getSingleBookRequest
} from "../../../store/Books/actions";
import { useParams } from "react-router-dom";
import {
  ADD_EDIT_TEST_SCHEMA,
  getInitialValuesAdd,
  getInitialValuesEdit
} from "./validation";
import MaterialPreview from "../../../components/MaterialPreview";
import Upload from "./finalTestImageUploading";
import Button from "../../../components/Button";
import {
  editSingleFinalTestRequest,
  getAllFinalTestsRequest,
  getSingleFinalTestsRequest,
  setNewFinalTestRequest
} from "../../../store/FinalTests/actions";

import AnswerDisplay from "./AnswerDisplay";
import AnswerAddOrEdit from "./AnswerAddOrEdit";

import { showHideLoader } from "../../../store/Loader/actions";

function FinalTestFormAEV() {
  const [setDestroy] = useState(false);
  const [isDicom, setIsDicom] = useState(false);

  let params = useParams();
  const dispatch = useDispatch();
  const { locale, messages } = useIntl();

  let mode = params?.mode;
  const singleFinalTest = useSelector(
    (state) => state.finalTestsReducer.singleFinalTest
  );
  console.log("singleFinalTest", singleFinalTest);

  useEffect(() => {
    if (params?.id) {
      let id = parseInt(params.id);
      dispatch(getSingleFinalTestsRequest(id));
    }
  }, []);
  useEffect(async () => {
    setIsDicom(false);
    await dispatch(getBooksRequest());
  }, []);
  useEffect(async () => {
    await dispatch(getAllFinalTestsRequest());
  }, []);
  const finalTestList = useSelector(
    (state) => state.finalTestsReducer.list
  );

  const books = useSelector((state) => state.books.list);
  const existingBook = books.find(
    (book) => book.id === singleFinalTest?.book_id
  );
  const newOptions = books?.filter((book) => {
    return !finalTestList?.filter(
      (finalTest) => finalTest.book_id === book.id
    ).length;
  });

  const theme = useTheme();

  const {
    finalTest: {
      addFinalTest,
      editFinalTest,
      book,
      totalScore,
      notes,
      noteHint,
      questionTitle,
      answersOptions,
      upload,
      uploadImageLabel,
      explanation,
      questionPoints,
      viewFinalTest
    },
    buttons: { submit }
  } = messages;
  const AddRemoveNewQuestion = (push) => {
    return (
      <>
        <div className="mx-2 mb-3">
          <AddCircleIcon
            className="cursor-pointer"
            onClick={() =>
              push({
                question: "",
                points: null,
                answer_choices: [
                  {
                    currentTab: "text",
                    answer: "",
                    image_url: "",
                    correct: false,
                    justification: ""
                  },
                  {
                    currentTab: "text",
                    answer: "",
                    image_url: "",
                    correct: false,
                    justification: ""
                  }
                ]
              })
            }
          />
        </div>
      </>
    );
  };

  const RemoveQuestion = (
    question,
    remove,
    questionIndex,
    replace,
    mode,
    setDestroy
  ) => {
    return () => {
      if (!question.id) {
        remove(questionIndex);
        return;
      }
      replace(questionIndex, {
        ...question,
        _destroy: true
      });
      if (mode === "Edit") setDestroy(true);
    };
  };
  const handleSubmit = (values) => {
    const payload = { ...values };

    dispatch(showHideLoader(true));

    if (mode === "Add") {
      dispatch(setNewFinalTestRequest(payload));
    } else {
      dispatch(
        editSingleFinalTestRequest({
          final_test: payload,
          id: singleFinalTest.id
        })
      );
    }
  };
  const DoesQuestionHasAJustificationPhoto = (
    values,
    questionIndex
  ) =>
    mode !== "View"
      ? true
      : !!values?.details?.questions[questionIndex]
          .justification_image_url;
  return (
    <>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {mode === "Edit" ||
          JSON.parse(sessionStorage.getItem("bookSelected"))?.id
            ? editFinalTest
            : mode === "View"
            ? viewFinalTest
            : addFinalTest}
        </Typography>
      </div>
      <Formik
        initialValues={
          mode === "Add"
            ? getInitialValuesAdd()
            : getInitialValuesEdit(singleFinalTest)
        }
        onSubmit={handleSubmit}
        validationSchema={
          mode === "View" ? null : ADD_EDIT_TEST_SCHEMA(locale)
        }
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <fieldset
                disabled={mode === "View" ? true : false}
                className={
                  mode === "View" ? styles.disableFormEdit : null
                }
              >
                <Grid spacing={5}>
                  <Grid item className="pb-0">
                    <div className={styles.selectTitle}>
                      {mode !== "Add" && (
                        <label className={styles.label}>
                          {book} <span>*</span>{" "}
                        </label>
                      )}
                      <CustomSelect
                        options={[...newOptions, existingBook]
                          .filter((book) => book && book.id) // Filter out undefined/null or objects without id
                          .map((book) =>
                            formatOptions(book, "id", "title")
                          )}
                        placeholder={book}
                        name="book_id"
                        bgColor={theme.palette.primary.light}
                        isRequired={true}
                        isMenuRelative={true}
                        isSearchable={true}
                        onChange={(e) => {
                          console.log("ee", e);
                          setFieldValue("book_id", e.id);
                        }}
                        handleSelectChange={() => {
                          if (values.book_id)
                            dispatch(
                              getSingleBookRequest(values.book_id)
                            );
                        }}
                        containerClass="mb-4"
                      />
                    </div>
                    <Input
                      style={{ width: "100%" }}
                      name={`total_score`}
                      label={totalScore}
                      isRequired={true}
                      type="number"
                      step="0.1"
                    />

                    <div>
                      <p>{noteHint}</p>
                      <Input
                        inputShape="textarea"
                        showCounter={true}
                        charLimit={800}
                        name="note"
                        label={notes}
                        isRequired={false}
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <FieldArray name="details.questions">
                        {({ push, replace, remove }) => {
                          {
                            console.log("values", values);
                          }

                          return !values?.details.questions?.length
                            ? null
                            : values?.details.questions?.map(
                                (question, questionIndex) => {
                                  return (
                                    !question._destroy && (
                                      <div className="d-flex">
                                        <Paper
                                          style={{ padding: "2rem" }}
                                          className="row align-items-center justify-content-center flex-fill mb-4"
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              style={{
                                                width: "100%"
                                              }}
                                            >
                                              <Input
                                                name={`details.questions[${questionIndex}].question`}
                                                label={questionTitle}
                                                isRequired={
                                                  mode === "View"
                                                    ? false
                                                    : true
                                                }
                                              />
                                            </div>
                                            {mode !== "View" && (
                                              <a
                                                data-bs-toggle="collapse"
                                                href={`#collapseExample${questionIndex}`}
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                                style={{
                                                  width: "100px"
                                                }}
                                                className="ml-2 mb-4"
                                              >
                                                {uploadImageLabel}
                                              </a>
                                            )}
                                          </div>

                                          <div
                                            className={
                                              values?.details
                                                .questions?.[
                                                questionIndex
                                              ]?.image_url
                                                ? "collapse show p-2 mb-2"
                                                : "collapse p-2 mb-2"
                                            }
                                            id={`collapseExample${questionIndex}`}
                                          >
                                            <div className="d-flex align-items-center justify-content-between">
                                              <Grid
                                                item
                                                className="pb-0"
                                                xs={6}
                                              >
                                                <div className="text-center">
                                                  <div className="mb-3 d-flex flex-column align-items-center">
                                                    <MaterialPreview
                                                      type={1}
                                                      src={
                                                        values
                                                          ?.details
                                                          .questions[
                                                          questionIndex
                                                        ].image_url
                                                      }
                                                    />
                                                  </div>
                                                  <Upload
                                                    label={upload}
                                                    name={`details.questions[${questionIndex}].image_url`}
                                                    accept="image/*"
                                                    isImage={true}
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    isRequired={
                                                      mode === "View"
                                                        ? false
                                                        : true
                                                    }
                                                    isMultiple={false}
                                                  />
                                                </div>
                                              </Grid>
                                            </div>
                                          </div>
                                          <Input
                                            style={{ width: "100%" }}
                                            name={`details.questions[${questionIndex}].points`}
                                            label={questionPoints}
                                            isRequired={
                                              mode === "View"
                                                ? false
                                                : true
                                            }
                                            type="number"
                                            step="0.1"
                                          />

                                          <FieldArray
                                            name={`details.questions[${questionIndex}].answer_choices`}
                                          >
                                            {({
                                              push,
                                              replace,
                                              remove
                                            }) => {
                                              return (
                                                <Form>
                                                  <Typography
                                                    variant="h6"
                                                    component="h6"
                                                    className="text-secondary my-4"
                                                  >
                                                    {answersOptions}
                                                  </Typography>
                                                  {mode === "View" ? (
                                                    <AnswerDisplay
                                                      values={values}
                                                      questionIndex={
                                                        questionIndex
                                                      }
                                                      question={
                                                        question
                                                      }
                                                    />
                                                  ) : (
                                                    <AnswerAddOrEdit
                                                      question={
                                                        question
                                                      }
                                                      questionIndex={
                                                        questionIndex
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      mode={mode}
                                                      values={values}
                                                      push={push}
                                                      replace={
                                                        replace
                                                      }
                                                      remove={remove}
                                                      isDicom={
                                                        isDicom
                                                      }
                                                    />
                                                  )}

                                                  <div className="my-4">
                                                    <Input
                                                      name={`details.questions[${questionIndex}].justification`}
                                                      label={
                                                        explanation
                                                      }
                                                      isRequired={
                                                        false
                                                      }
                                                    />
                                                    {DoesQuestionHasAJustificationPhoto(
                                                      values,
                                                      questionIndex
                                                    ) && (
                                                      <div className="mb-3 d-flex flex-row align-items-end w-50 gap-3">
                                                        <MaterialPreview
                                                          type={1}
                                                          src={
                                                            values
                                                              ?.details
                                                              ?.questions[
                                                              questionIndex
                                                            ]
                                                              .justification_image_url
                                                          }
                                                        />
                                                        {mode !==
                                                          "View" && (
                                                          <Upload
                                                            label={
                                                              upload
                                                            }
                                                            name={`details.questions[${questionIndex}].justification_image_url`}
                                                            accept="image/*"
                                                            isImage={
                                                              true
                                                            }
                                                            setFieldValue={
                                                              setFieldValue
                                                            }
                                                            isRequired={
                                                              false
                                                            }
                                                            isMultiple={
                                                              isDicom
                                                                ? true
                                                                : false
                                                            }
                                                          />
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </Form>
                                              );
                                            }}
                                          </FieldArray>
                                        </Paper>
                                        {mode !== "View" && (
                                          <div
                                            className="d-flex flex-row mb-4  align-items-center mx-5"
                                            key={JSON.stringify(
                                              questionIndex
                                            )}
                                          >
                                            {values.details.questions
                                              .length > 1 &&
                                              !question?._destroy &&
                                              values.details.questions.filter(
                                                (x) => !x._destroy
                                              ).length !== 1 && (
                                                <div className="mx-2 mb-3">
                                                  <RemoveIcon
                                                    onClick={RemoveQuestion(
                                                      question,
                                                      remove,
                                                      questionIndex,
                                                      replace,
                                                      mode,
                                                      setDestroy
                                                    )}
                                                    className="cursor-pointer"
                                                    disabled={
                                                      values.details.questions.filter(
                                                        (x) =>
                                                          !x._destroy
                                                      ).length === 1
                                                    }
                                                  />
                                                </div>
                                              )}
                                            {AddRemoveNewQuestion(
                                              push
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  );
                                }
                              );
                        }}
                      </FieldArray>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="pb-0"></Grid>
                </Grid>
              </fieldset>
              {mode !== "View" && (
                <div className="d-block mt-3 ">
                  <Button
                    text={submit}
                    className="primary-lg"
                    type="submit"
                    data-toggle="tooltip"
                    data-placement="top"
                    // disabled={params.mode === "View" ? true : false}
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default FinalTestFormAEV;

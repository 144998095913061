import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import {
  getTestsRequest,
  setTestsFilters,
  getTestsResponse
} from "../../store/Tests/actions";
import EmptyState from "../../components/EmptyState";
import Pagination from "../../components/Pagination";
import messages from "../../assets/locale/messages";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import Button from "../../components/Button";
import Search from "../../components/Search";
import PreTestPostTestList from "./PreTestPostTestList";
import PreTestPostTestDelete from "./PreTestPostTestDelete";
import { deleteAction } from "../../store/ActionsBtn/actions";

const PreTestPostTest = () => {
  const perPage = 6;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const isLoading = useSelector((state) => state.loader);
  const meta = useSelector((state) => state.tests.meta);
  const lang = useSelector((state) => state.locale.lang);
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const searchKeyword = useSelector(
    (state) => state.tests.searchKeyword
  );
  const testList = useSelector((state) => state.tests.list);

  const {
    preTestPostTest: { title, addTest }
  } = messages[lang];
  const handleCancelDelete = () => {
    dispatch(deleteAction(0));
  };

  useEffect(() => {
    dispatch(
      getTestsRequest({
        page: 1,
        items: perPage,
        title: searchKeyword
      })
    );
  }, []);

  useEffect(() => {
    function filterTestsList() {
      const params = {
        page: 1,
        items: perPage,
        title: searchKeyword
      };
      dispatch(getTestsRequest(params));
    }
    filterTestsList();
  }, [searchKeyword]);
  useEffect(() => {
    return () => {
      dispatch(getTestsResponse({ list: [], meta: {} }));
    };
  }, []);
  useEffect(() => {
    return () => {
      dispatch(
        setTestsFilters({
          searchKeyword: ""
        })
      );
    };
  }, []);
  const handlePagination = (event, page) => {
    setPage(page);
    dispatch(
      getTestsRequest({
        page: page,
        items: perPage,
        paginate: true
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      setTestsFilters({
        searchKeyword: e.target.value
      })
    );
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h1">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3} className="mb-2">
        <Grid item xs={6}>
          <Formik initialValues={{ search: "" }}>
            {() => (
              <Form>
                <Search
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                <button className="d-none"></button>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          xs={6}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addTest}
            icon={<AddIcon className="me-2" />}
            isLink={true}
            onClick={() => sessionStorage.removeItem("bookSelected")}
            slug={ROUTE_PATHS["addPreTestPostTest"]}
          />
        </Grid>
      </Grid>
      <PreTestPostTestList
        list={testList}
        lang={lang}
        inBookView={false}
      />
      <PreTestPostTestDelete
        handleCancelDelete={handleCancelDelete}
        isOpen={!!deleteId}
        lang={lang}
      />
      {!isLoading && !testList.length && <EmptyState />}
      {meta.pages > 1 && (
        <Pagination
          count={meta.pages}
          page={meta.currentPage || page}
          handleChange={handlePagination}
        />
      )}
    </>
  );
};

export default PreTestPostTest;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { isRangeOverlap } from "range-overlap";
import { useParams } from "react-router-dom";

import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { add } from "../../../store/ActionsBtn/actions";
import {
  setBookSectionsRequest,
  setEditableModeSection,
  editBookSectionsRequest
} from "../../../store/Books/actions";
import { axiosInstance } from "../../../network/apis";
import History from "../../../routes/History";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

const AddSection = ({ isOpen, book_id }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const editMode = useSelector((state) => state.books.editMode);
  const sectionEditable = useSelector(
    (state) => state.books.sectionEditable
  );

  const singleBook = useSelector((state) => state.books.singleBook);

  const { messages } = useIntl();

  const {
    section: {
      addSection,
      editSection,
      nameRequired,
      fromPageRequired,
      toPageRequired,
      fieldNumber,
      name,
      from,
      to,
      maxSectionName,
      inRangRequired,
      lowerThan,
      duplicatedName,
      nameTaken,
      rangeUnique,
      rangeoverlaped
    }
  } = messages;

  let params = useParams();
  const [allSectionOptions, setallSectionOptions] = useState([]);
  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  let res;
  const sectionsInBookOptions = useSelector(
    (state) => state.books.sectionsInBookOptions
  );

  useEffect(async () => {
    res = await sectionsInBookOptionsToValidate();
    setallSectionOptions([...res?.data?.sections]);
  }, [sectionsInBookOptions]);

  const sectionsInBookOptionsToValidate = async () => {
    let res = await axiosInstance.get(
      `books/${params?.id}/sections`,
      {
        params: {
          page: -1
        }
      }
    );
    return res;
  };
  const handleSubmit = async ({ name, from, to }) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let test = params.get("test");

    const payload = {
      name,
      from,
      to,
      bookId: book_id
    };
    dispatch(add(false));
    if (editMode) {
      dispatch(
        setEditableModeSection({
          sectionEditable: {},
          editMode: false
        })
      );
      dispatch(
        editBookSectionsRequest({
          ...payload,
          id: sectionEditable.id
        })
      );
    } else {
      dispatch(setBookSectionsRequest(payload));
      if (test === "true")
        History.push(`${ROUTE_PATHS.addPreTestPostTest}?test=true`);
    }
  };

  const handleModalCancel = () => {
    dispatch(add(false));
    if (editMode)
      dispatch(
        setEditableModeSection({
          sectionEditable: {},
          editMode: false
        })
      );
  };
  const ADD_BOOK_SECTION_SCHEMA = (id = null) => {
    let allSectionOptionsEditable = id
      ? allSectionOptions?.filter((section) => {
          console.log(section);
          return section.id !== id;
        })
      : [...allSectionOptions];

    return Yup.object().shape({
      name: Yup.string()
        .max(100, maxSectionName)
        .test(
          `${duplicatedName} `,
          `${nameTaken}`,
          (value) =>
            !allSectionOptionsEditable?.find((o) => o.name === value)
        )
        .required(nameRequired),
      from: Yup.number()
        .typeError(fieldNumber)
        .required(fromPageRequired)
        .nullable()
        .integer()
        .min(1)
        .test(
          `${inRangRequired} `,
          `${lowerThan} ${singleBook?.number_of_pages}`,
          (value) =>
            value >= 0 && value <= singleBook?.number_of_pages
        )
        .test(
          `${rangeUnique} `,
          `${rangeoverlaped}`,
          (value, ctx) => {
            console.log();
            return allSectionOptionsEditable?.every(
              (o) =>
                !isRangeOverlap(value, ctx.parent.to, o.from, o.to)
            );
          }
        ),

      to: Yup.number()
        .typeError(fieldNumber)
        .required(toPageRequired)
        .nullable()
        .integer()

        .test(
          `${inRangRequired} `,
          `${lowerThan} ${singleBook?.number_of_pages}`,
          (value) => {
            return value >= 0 && value <= singleBook?.number_of_pages;
          }
        )
        .test(
          `${rangeUnique} `,
          `${rangeoverlaped}`,
          (value, ctx) => {
            return allSectionOptionsEditable?.every(
              (o) =>
                !isRangeOverlap(ctx.parent.from, value, o.from, o.to)
            );
          }
        )

        .min(Yup.ref("from"))
    });
  };

  const getInitialValues = () => {
    return {
      name: "",
      to: "",
      from: ""
    };
  };
  const getEditInitialValues = (section = {}) => {
    return {
      name: section.name,
      to: section.to,
      from: section.from
    };
  };
  return (
    <Modal
      title={editMode ? editSection : addSection}
      hasActions={true}
      isOpen={isOpen}
      handleModalSubmit={handleModalSubmit}
      handleModalCancel={handleModalCancel}
    >
      <Formik
        initialValues={
          editMode
            ? getEditInitialValues(sectionEditable)
            : getInitialValues()
        }
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={
          editMode
            ? ADD_BOOK_SECTION_SCHEMA(sectionEditable.id)
            : ADD_BOOK_SECTION_SCHEMA()
        }
      >
        {() => (
          <Form>
            <Input name="name" label={name} isRequired={true} />

            <Input
              name="from"
              label={from}
              isRequired={true}
              isNumber={true}
            />

            <Input
              name="to"
              label={to}
              isRequired={true}
              isNumber={true}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

AddSection.propTypes = {
  isOpen: PropTypes.bool,
  book_id: PropTypes.number
};

AddSection.defaultProps = {
  isOpen: false
};

export default AddSection;

import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  editedBook: null,
  addedBook: null,
  singleBook: null,
  booksInCategory: [],
  searchKeyword: null,
  selectedCategory: null,
  sectionsInBookOptions: [],
  sectionsInBookOptionsMeta: {},
  sectionEditable: {},
  editMode: false,
  supplementaries: {
    list: [],
    meta: {}
  }
};

const booksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_BOOKS_RESPONSE:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.ADD_BOOK_RESPONSE:
      return { ...state, addedBook: action.payload };
    case types.EDIT_BOOK_RESPONSE:
      return { ...state, editedBook: action.payload };
    case types.GET_SINGLE_BOOK_RESPONSE:
      return { ...state, singleBook: action.payload };
    case types.GET_BOOKS_IN_CATEGORY_RESPONSE:
      return { ...state, booksInCategory: action.payload };
    case types.SET_BOOKS_FILTERS:
      return {
        ...state,
        searchKeyword: action.payload.searchKeyword,
        selectedCategory: action.payload.selectedCategory
      };

    case types.GET_BOOK_SECTIONS_RESPONSE:
      return {
        ...state,
        sectionsInBookOptions: action.payload.list,
        sectionsInBookOptionsMeta: action.payload.meta
      };
    case types.GET_BOOK_SUPPLEMENTRAIES_RESPONSE:
      return {
        ...state,
        supplementaries: {
          list: action.payload.list,
          meta: action.payload.meta
        }
      };

    case types.SET_EDITABLE_MODE_SECTION:
      return {
        ...state,

        sectionEditable: action.payload.sectionEditable,
        editMode: action.payload.editMode
      };
    default:
      return state;
  }
};

export default booksReducer;

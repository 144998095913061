import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/supplementries";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  getSupplementariesResponse,
  getSupplementariesRequest,
  getSingleSupplementaryResponse
} from "./actions";
import { add, edit } from "./../ActionsBtn/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";
import { getBookSupplementraiesRequest } from "../Books/actions";

export function* getSupplementariesSaga({ payload }) {
  try {
    const response = yield call(apis.getSupplementaries, payload);
    yield put(
      getSupplementariesResponse({
        list: response.data.supplementary_materials,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteSupplementarySaga({ payload }) {
  try {
    const response = yield call(apis.deleteSupplementary, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .deleteSupplementarySuccess
      })
    );
    yield put(getSupplementariesRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addSupplementarySaga({ payload }) {
  try {
    const response = yield call(apis.addSupplementary, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .addSupplementarySuccess
      })
    );
    yield history.push(ROUTE_PATHS["supplementaries"]);
  } catch (err) {
    console.error(err);
  }
}

export function* assignSupplementaryToBookSaga({ payload }) {
  try {
    const response = yield call(
      apis.assignSupplementaryToBook,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .assignSupplementarySuccess
      })
    );
    yield put(add(false));
  } catch (err) {
    console.error(err);
  }
}

export function* unassignSupplementaryFromBookSaga({
  payload: { book_id, id }
}) {
  try {
    const response = yield call(
      apis.unassignSupplementaryFromBook,
      id
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .unassignSupplementarySuccess
      })
    );
    yield put(
      getBookSupplementraiesRequest({
        page: 1,
        items: 10,
        book_id
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* editBookPageForSupplementarySaga({
  payload: { data, book_id }
}) {
  try {
    const response = yield call(apis.editBookPageSupplementary, data);
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .editSupplementarySuccess
      })
    );
    yield put(
      getBookSupplementraiesRequest({
        page: 1,
        items: 10,
        book_id
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* editSupplementarySaga({ payload }) {
  try {
    const response = yield call(apis.editSupplementary, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .editSupplementarySuccess
      })
    );
    yield history.push(ROUTE_PATHS["supplementaries"]);
  } catch (err) {
    console.error(err);
  }
}

export function* getSingleSupplementarySaga({ payload }) {
  try {
    const response = yield call(apis.getSingleSupplementary, payload);
    yield put(
      getSingleSupplementaryResponse(
        response.data["supplementary material"]
      )
    );
  } catch (err) {
    console.error(err);
  }
}

export function* reassignSupplementaryToBookVersionSaga({ payload }) {
  try {
    const response = yield call(
      apis.reassignSupplementaryToBookVersion,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].supplementaries
            .reassignSupplementarySuccess
      })
    );
    yield put(
      getBookSupplementraiesRequest({
        page: 1,
        items: 10,
        book_id: payload.book_id
      })
    );
  } catch (err) {
    console.error(err);
  }
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { ADD_EDIT_INSTITUTE_SCHEMA } from "./validation";
import "react-phone-number-input/style.css";
import "./institution.scss";

import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import RemoveIcon from "@material-ui/icons/Remove";
import PhoneInput, {
  isPossiblePhoneNumber
} from "react-phone-number-input";
import { FieldArray, Form, Formik } from "formik";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme
} from "@material-ui/core";
import Input from "../../components/Input";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import { axiosInstance } from "../../network/apis";
import {
  addInstitutionsRequest,
  editInstitutionRequest
} from "../../store/Institutions/actions";
import moment from "moment";

const InstitutionAddEdit = () => {
  const dispatch = useDispatch();
  const { messages, locale } = useIntl();
  const [originalData, setOriginalData] = useState();
  const msgs = messages.institute;
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone_numbers: [
      {
        number: "",
        isValid: false,
        _destroy: false
      }
    ],
    emails: [
      {
        email: "",
        isValid: false,
        _destroy: false
      }
    ],
    subscription_start_date: "",
    subscription_end_date: "",
    ip_ranges_attributes: [
      {
        from: "",
        to: "",
        _destroy: false
      }
    ]
  });
  const { id } = useParams();

  const getInstituteData = async () => {
    const response = await axiosInstance.get(`/institutions/${id}`);
    const data = response.data.institution;
    setOriginalData(data);

    const instituteData = {
      ...initialValues,
      name: data.name,
      phone_numbers: data.phone_numbers.map((num) => ({
        number: "+" + num.toString(),
        isValid: true,
        _destroy: false
      })),
      emails: data.emails.map((email) => ({
        email,
        isValid: true,
        _destroy: false
      })),
      ip_ranges_attributes: data.IP_ranges.map((range) => ({
        id: range.id,
        to: range.to,
        from: range.from,
        isValid: true,
        _destroy: false
      })),
      subscription_start_date: data.subscription_start_date,
      subscription_end_date: data.subscription_end_date
    };
    setInitialValues(instituteData);
  };

  useEffect(() => {
    if (id) {
      getInstituteData();
    }
  }, [id]);

  const {
    inputs: { emailAddress },
    institute: { instituteName, ipFrom, ipTo, startDate, endDate }
  } = messages;

  const handleCreateNewInstitute = (values) => {
    const payload = {
      name: values.name,
      emails: values.emails
        .filter((email) => !email._destroy)
        .map((email) => email.email),
      phone_numbers: values.phone_numbers
        .filter((phone) => !phone._destroy)
        .map((number) =>
          number.number.replace("+", "").replace(" ", "")
        ),
      subscription_start_date: values.subscription_start_date,
      subscription_end_date: values.subscription_end_date,
      ip_ranges_attributes: values.ip_ranges_attributes
        .map((ip) => ({
          id: ip.id,
          from: ip.from,
          to: ip.to,
          _destroy: ip._destroy
        }))
        .filter(
          (ip) =>
            !(ip.from === "" && ip.to === "" && ip._destroy && !ip.id)
        )
    };

    if (!id) {
      dispatch(addInstitutionsRequest(payload));
    } else {
      dispatch(editInstitutionRequest({ data: payload, id: id }));
    }
  };

  return (
    <div>
      <div className="mb-4">
        <Typography variant="h4" component="h1">
          {id ? msgs.editInstitution : msgs.addInstitution}
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleCreateNewInstitute(values)}
        validationSchema={ADD_EDIT_INSTITUTE_SCHEMA()}
        enableReinitialize
      >
        {({ setFieldValue, values, setTouched, touched, errors }) => (
          <Form>
            <div>
              <Grid item xs={6} className="pb-0 ">
                <Input
                  name="name"
                  label={instituteName}
                  isRequired={true}
                />
              </Grid>
              <FieldArray name="phone_numbers">
                {({ push }) => (
                  <div className="phoneInputWrapper my-2">
                    {values.phone_numbers.map((phone, index, arr) => {
                      const lastActiveItem = [...arr]
                        .map((x) => x._destroy)
                        .map((x) => !!x)
                        .lastIndexOf(false);
                      const showAddButton = lastActiveItem === index;
                      return (
                        !phone._destroy && (
                          <Grid
                            key={JSON.stringify(index)}
                            className="d-flex align-items-center my-2 col-4"
                          >
                            <PhoneInput
                              className="input"
                              inpup
                              placeholder={
                                messages.institute.phoneNumber
                              }
                              value={phone.number}
                              onChange={(value) => {
                                setTouched({
                                  ...touched,
                                  phone_numbers: true
                                });
                                const phone = value || "";
                                const phone_numbers = [
                                  ...values.phone_numbers
                                ];
                                phone_numbers[index] = {
                                  number: phone,
                                  isValid:
                                    isPossiblePhoneNumber(phone),
                                  _destroy: false
                                };
                                setFieldValue(
                                  "phone_numbers",
                                  phone_numbers
                                );
                              }}
                            />
                            {phone.isValid && (
                              <CheckIcon className="me-2 mt-2 cursor-pointer text-success" />
                            )}
                            {arr.filter((item) => !item._destroy)
                              .length > 1 && (
                              <RemoveIcon
                                className="me-2 cursor-pointer"
                                onClick={() => {
                                  const phone_numbers = [
                                    ...values.phone_numbers
                                  ].filter((_x, i) => i !== index);
                                  setFieldValue(
                                    "phone_numbers",
                                    phone_numbers
                                  );
                                }}
                              />
                            )}
                            {showAddButton && (
                              <AddIcon
                                className="me-2 cursor-pointer"
                                onClick={() => {
                                  push({
                                    phone: "",
                                    isValid: false,
                                    _destroy: false
                                  });
                                }}
                              />
                            )}
                          </Grid>
                        )
                      );
                    })}

                    {touched.phone_numbers &&
                      !values.phone_numbers
                        .map((item) => item.isValid)
                        .every((isValid) => isValid) && (
                        <div className="error-msg mt-2">
                          {msgs.validation.invalidPhone}
                        </div>
                      )}
                  </div>
                )}
              </FieldArray>

              <Grid item xs={12} className="pb-0 mt-3">
                <Grid container xs={12}>
                  <FieldArray name="emails">
                    {({ push, remove }) => (
                      <div>
                        {values.emails.map((email, index, arr) => {
                          const lastActiveItem = [...arr]
                            .map((x) => x._destroy)
                            .map((x) => !!x)
                            .lastIndexOf(false);
                          const showAddButton =
                            lastActiveItem === index;
                          return (
                            !email._destroy && (
                              <div
                                key={JSON.stringify(index)}
                                className="d-flex align-items-center my-2"
                              >
                                <Input
                                  placeholder={
                                    messages.institute.email
                                  }
                                  name={`emails[${index}]['email']`}
                                  label={emailAddress}
                                />
                                {email.isValid && (
                                  <CheckIcon className="me-2 cursor-pointer text-success" />
                                )}
                                {arr.filter((item) => !item._destroy)
                                  .length > 1 && (
                                  <RemoveIcon
                                    className="me-2 cursor-pointer mb-3"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  />
                                )}
                                {showAddButton && (
                                  <AddIcon
                                    className="me-2 cursor-pointer mb-3"
                                    onClick={() => {
                                      push({
                                        email: "",
                                        isValid: false,
                                        _destroy: false
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            )
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>
                </Grid>
                <div className="d-flex mb-5 w-50">
                  <Grid item xs={8}>
                    <DatePicker
                      placeholder={startDate}
                      isDisabled={moment(
                        originalData?.subscription_start_date
                      ).isBefore(moment(new Date()))}
                      setFieldValue={setFieldValue}
                      name="subscription_start_date"
                    />
                    {touched.subscription_start_date &&
                      errors.subscription_start_date && (
                        <div className="error-msg mt-2">
                          {errors.subscription_start_date}
                        </div>
                      )}
                  </Grid>
                  <Grid item xs={8}>
                    <DatePicker
                      placeholder={endDate}
                      setFieldValue={setFieldValue}
                      name="subscription_end_date"
                    />
                    {touched.subscription_end_date &&
                      errors.subscription_end_date && (
                        <div className="error-msg mt-2">
                          {errors.subscription_end_date}
                        </div>
                      )}
                  </Grid>
                </div>
                <FieldArray name="ip_ranges_attributes">
                  {({ push }) => (
                    <div>
                      {values?.ip_ranges_attributes?.map(
                        (ipRange, index, arr) => {
                          const lastActiveItem = [...arr]
                            .map((x) => x._destroy)
                            .map((x) => !!x)
                            .lastIndexOf(false);
                          const showAddButton =
                            lastActiveItem === index;
                          return (
                            !ipRange._destroy && (
                              <div
                                className="d-flex gap-3"
                                key={JSON.stringify(index)}
                              >
                                <Input
                                  name={`ip_ranges_attributes[${index}].from`}
                                  label={ipFrom}
                                  isRequired={true}
                                />
                                <Input
                                  name={`ip_ranges_attributes[${index}].to`}
                                  label={ipTo}
                                  isRequired={true}
                                />
                                {arr.filter((item) => !item._destroy)
                                  .length > 1 && (
                                  <RemoveIcon
                                    className="me-2 cursor-pointer mt-2 mx-2"
                                    onClick={() => {
                                      const ip_ranges_attributes = [
                                        ...values.ip_ranges_attributes
                                      ].map((ip, i) =>
                                        i === index
                                          ? { ...ip, _destroy: true }
                                          : { ...ip }
                                      );
                                      setFieldValue(
                                        "ip_ranges_attributes",
                                        ip_ranges_attributes
                                      );
                                    }}
                                  />
                                )}
                                {showAddButton && (
                                  <AddIcon
                                    className="me-2 cursor-pointer mt-2 mx-2"
                                    onClick={() => {
                                      push({
                                        from: "",
                                        to: "",
                                        isValid: false,
                                        _destroy: false
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            )
                          );
                        }
                      )}
                    </div>
                  )}
                </FieldArray>
              </Grid>
            </div>
            <div className="d-block mt-3">
              <Button
                text={id ? msgs.editInstitution : msgs.addInstitution}
                className="primary-lg"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InstitutionAddEdit;

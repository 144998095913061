export const GET_QUIZZES_LIST = "GET_QUIZZES_LIST";
export const SET_QUIZZES_LIST = "SET_QUIZZES_LIST";
export const ADD_QUIZ = "ADD_QUIZ";
export const ASSIGN_QUIZ_TO_BOOK = "ASSIGN_QUIZ_TO_BOOK";
export const DELETE_QUIZ = "DELETE_QUIZ";
export const GET_BOOK_QUIZ = "GET_BOOK_QUIZ";
export const SET_BOOK_QUIZ = "SET_BOOK_QUIZ";
export const UNASSIGN_BOOK_QUIZ = "UNASSIGN_BOOK_QUIZ";
export const GET_QUIZ_DETAILS = "GET_QUIZ_DETAILS";
export const SET_QUIZ_DETAILS = "SET_QUIZ_DETAILS";
export const EDIT_QUIZ = "EDIT_QUIZ";
export const ASSIGN_QUIZ_BTN = "ASSIGN_QUIZ_BTN";
export const EDIT_ASSIGN_QUIZ_BTN = "EDIT_ASSIGN_QUIZ_BTN";
export const DELETE_QUIZ_ACTION = "DELETE_QUIZ_ACTION";
export const IMPORT_QUIZZES_TO_BOOK = "IMPORT_QUIZZES_TO_BOOK";
export const UNASSIGN_BOOK_QUIZ_ACTION = "UNASSIGN_BOOK_QUIZ_ACTION";
export const CHECK_FOR_CAN_BE_EDIT_QUIZ_REQUEST =
  "CHECK_FOR_CAN_BE_EDIT_QUIZ_REQUEST";
export const CHECK_FOR_CAN_BE_EDIT_QUIZ_RESPONSE =
  "CHECK_FOR_CAN_BE_EDIT_QUIZ_RESPONSE";
export const SET_QUIZZES_BOOK_FILTER = "SET_QUIZZES_BOOK_FILTER";
export const SET_QUIZZES_PAGE = "SET_QUIZZES_PAGE";
export const SET_QUIZZES_BOOK_SORT_METHOD =
  "SET_QUIZZES_BOOK_SORT_METHOD";

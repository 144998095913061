import { call, put } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/editors";
import { edit } from "../ActionsBtn/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  addEditorResponse,
  editEditorResponse,
  getEditorsRequest,
  getEditorsResponse
} from "./actions";

export function* getEditorsSaga({ payload }) {
  try {
    const response = yield call(apis.getEditors, payload);
    yield put(
      getEditorsResponse({
        list: response.data.editors,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteEditorsSaga({ payload }) {
  try {
    const response = yield call(apis.deleteEditor, payload);
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].editors.deleteEditorSuccess
      })
    );
    yield put(getEditorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addEditorSaga({ payload }) {
  try {
    const response = yield call(apis.addEditor, payload);
    yield put(addEditorResponse(response.data));
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].editors.addEditorSuccess
      })
    );
    yield put(getEditorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* editEditorSaga({ payload }) {
  try {
    const response = yield call(apis.editEditor, payload);
    yield put(editEditorResponse(response.data));
    yield put(edit(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].editors.editEditorSuccess
      })
    );
    yield put(getEditorsRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination as CustomPagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export const Pagination = ({
  count,
  defaultPage,
  handleChange,
  page
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CustomPagination
        count={count}
        defaultPage={defaultPage}
        page={page}
        onChange={handleChange}
        shape="rounded"
        color="primary"
      />
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  defaultPage: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func
};

Pagination.defaultProps = {
  count: 0,
  defaultPage: 1,
  page: 1,
  handleChange: () => {}
};

export default Pagination;

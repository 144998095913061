import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TableCell, TableRow } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Form, Formik } from "formik";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import EmptyState from "../../../components/EmptyState";
import {
  getSingleTestReportRequest,
  getSingleTestReportResponse,
  exportTestReport
} from "../../../store/Tests/actions";
import Table from "../../../components/Table";
import messages from "../../../assets/locale/messages";
import "./UserTestReport.scss";
import Modal from "../../../components/Modal";

const UserTestReport = () => {
  const lang = useSelector((state) => state.locale.lang);
  const isLoading = useSelector((state) => state.loader);

  const singleTestReport = useSelector(
    (state) => state.tests.singleTestReport
  );

  const [userList, setUserList] = useState(null);
  const [sectionList, setsectionList] = useState(null);
  const [remainingStatistics, setRemainingStatistics] =
    useState(null);

  const [mean, setMean] = useState(null);
  const [modalExport, setModalExport] = useState(false);

  let params = useParams();
  const dispatch = useDispatch();
  const formRef = useRef();
  const {
    preTestPostTest: {
      report: {
        title,
        id,
        pretestScore,
        postTestScore,
        change,
        meanChange,
        exportReport,
        userEmailRequired,
        sectionName,
        averagePreTestScore,
        averagePostTestScore,
        remainingStatisticsSection
      }
    },
    cols: { email }
  } = messages[lang];

  useEffect(async () => {
    if (params?.id) {
      await dispatch(
        getSingleTestReportRequest({ test_id: params?.id })
      );
    }
  }, [params]);

  useEffect(() => {
    setUserList(singleTestReport?.report_entries);

    setsectionList(singleTestReport?.sections_statistics?.data);
    setMean(singleTestReport?.mean);
    if (
      singleTestReport?.remaining_statistics
        ?.average_post_test_score ||
      singleTestReport?.remaining_statistics?.average_pre_test_score
    )
      setRemainingStatistics(singleTestReport?.remaining_statistics);
  }, [singleTestReport, params]);

  useEffect(() => {
    return () => {
      dispatch(getSingleTestReportResponse({}));
    };
  }, []);
  const getInitialValues = () => {
    return {
      email: ""
    };
  };

  const REPORT_EXPORT_SCHEMA = () => {
    return Yup.object().shape({
      email: Yup.string().email().required(userEmailRequired)
    });
  };

  const handleSubmit = (values) => {
    dispatch(exportTestReport({ ...values, id: params?.id }));
    setModalExport(false);
  };

  const handleModalCancel = () => {
    setModalExport(false);
  };
  const handleModalSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  return (
    <>
      <div className="mb-4">
        <Typography variant="h4" className="mb-4" component="h1">
          {title}
        </Typography>

        <p>
          * The percentage of change is calculated by ((
          <span className="bold"> Posttest</span> -{" "}
          <span className="bold">Pretest</span> ) /
          <span className="bold"> Pretest</span> )) * 100
        </p>
        <p className="mb-2">
          * It’s special case when the pretest is zero ({" "}
          <span className="bold">Posttest</span> /{" "}
          <span className="bold">Total score</span> ) * 100
        </p>
      </div>
      {userList ? (
        <>
          <Grid container spacing={3} className="mb-2">
            <Grid item xs={12} className="d-flex justify-content-end">
              <Button
                text={exportReport}
                onClick={() => setModalExport(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                headlines={[id, pretestScore, postTestScore, change]}
                hasActions={false}
                rows={userList.map((row) => (
                  <>
                    <TableRow className="table__row" key={row.id}>
                      <TableCell className="table__cell" align="left">
                        {row.user_id}
                      </TableCell>

                      <TableCell
                        className="table__cell table__actions"
                        align="left"
                      >
                        {row.pre_test_score}
                      </TableCell>
                      <TableCell
                        className="table__cell table__actions"
                        align="left"
                      >
                        {row.post_test_score}
                      </TableCell>
                      <TableCell
                        className="table__cell table__actions"
                        align="left"
                      >
                        <span>
                          {row.change === 0 ? (
                            <span className="m-1">
                              <RemoveCircleOutlineIcon color="primary" />{" "}
                            </span>
                          ) : row.change > 0 ? (
                            <span className="m-1">
                              <ArrowCircleUpIcon color="success" />
                            </span>
                          ) : (
                            <span className="m-1">
                              <ArrowCircleDownIcon
                                sx={{ color: "red" }}
                              />{" "}
                            </span>
                          )}
                        </span>
                        {Math.abs(row.change)} <span>%</span>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              />
              {userList?.length === 0 ? (
                <>
                  <h5 className="text-center m-4">
                    there is no reports yet
                  </h5>
                </>
              ) : (
                <>
                  <Grid>
                    <table className="table table_cell table-bordered">
                      <thead>
                        <tr>
                          <th>{meanChange}</th>
                          <th>{mean}</th>
                        </tr>
                      </thead>
                    </table>
                  </Grid>
                  {(sectionList.length > 0 ||
                    remainingStatistics) && (
                    <>
                      {" "}
                      <Grid item xs={12} className="my-2">
                        <Table
                          headlines={[
                            sectionName,
                            averagePreTestScore,
                            averagePostTestScore
                          ]}
                          hasActions={false}
                          rows={
                            <>
                              {sectionList?.map((row, i) => (
                                <>
                                  <TableRow
                                    className="table__row"
                                    key={i}
                                  >
                                    <TableCell
                                      className="table__cell"
                                      align="left"
                                    >
                                      {row.attributes.name}
                                    </TableCell>

                                    <TableCell
                                      className="table__cell table__actions"
                                      align="left"
                                    >
                                      {
                                        row.attributes
                                          .average_pre_test_score
                                      }
                                    </TableCell>
                                    <TableCell
                                      className="table__cell table__actions"
                                      align="left"
                                    >
                                      {
                                        row.attributes
                                          .average_post_test_score
                                      }
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}

                              {remainingStatistics && (
                                <TableRow className="table__row">
                                  <TableCell
                                    className="table__cell"
                                    align="left"
                                  >
                                    {remainingStatisticsSection}
                                  </TableCell>

                                  <TableCell
                                    className="table__cell table__actions"
                                    align="left"
                                  >
                                    {
                                      remainingStatistics.average_pre_test_score
                                    }
                                  </TableCell>
                                  <TableCell
                                    className="table__cell table__actions"
                                    align="left"
                                  >
                                    {
                                      remainingStatistics.average_post_test_score
                                    }
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          }
                        />
                      </Grid>{" "}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Modal
            title={exportReport}
            hasActions={true}
            isOpen={modalExport}
            handleModalSubmit={handleModalSubmit}
            handleModalCancel={handleModalCancel}
          >
            <Formik
              initialValues={getInitialValues()}
              innerRef={formRef}
              onSubmit={handleSubmit}
              validationSchema={REPORT_EXPORT_SCHEMA(lang)}
            >
              {() => (
                <Form>
                  <Input name="email" label={email} />
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      ) : (
        <>{!isLoading && <EmptyState />}</>
      )}
    </>
  );
};

export default UserTestReport;

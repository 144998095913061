/* eslint-disable no-unused-vars */
/* eslint-disable require-yield */
import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/surveys";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  getSurveysResponse,
  getSurveysRequest,
  getSingleSurveyResponse,
  getSingleSurveyStaticsResponse,
  checkForCanBeEditSurveyResponse
} from "./actions";
import { add, edit } from "../ActionsBtn/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import history from "../../routes/History";
import { getBookSupplementraiesRequest } from "../Books/actions";
import History from "../../routes/History";

export function* getSurveysSaga({ payload }) {
  try {
    const response = yield call(apis.getSurveys, payload);
    yield put(
      getSurveysResponse({
        list: response.data.surveys,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteSurveySaga({ payload }) {
  try {
    const response = yield call(apis.deleteSurvey, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].surveys.deleteSurveySuccess
      })
    );
    yield put(getSurveysRequest({ page: 1, items: 10 }));
  } catch (err) {
    console.error(err);
  }
}

export function* addSurveySaga({ payload }) {
  try {
    const response = yield call(apis.addSurvey, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].surveys.addSurveySuccess
      })
    );
    yield history.push(ROUTE_PATHS["surveys"]);
  } catch (err) {
    console.error(err);
  }
}

export function* editSurveySaga({ payload }) {
  try {
    const response = yield call(apis.editSurvey, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].surveys.editSurveySuccess
      })
    );
    yield history.push(ROUTE_PATHS["surveys"]);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  } catch (err) {
    console.error(err);
  }
}

export function* getSingleSurveySaga({ payload }) {
  try {
    const response = yield call(apis.getSingleSurvey, payload);
    yield put(getSingleSurveyResponse(response.data["survey"]));
  } catch (err) {
    if (err.response.status === 404) {
      History.push("/surveys");
    }
  }
}

export function* getSingleSurveyStatisticsSaga({ payload }) {
  try {
    const response = yield call(
      apis.getSingleSurveyStatisctics,
      payload
    );
    yield put(
      getSingleSurveyStaticsResponse(response.data["survey"])
    );
  } catch (err) {
    console.error(err);
  }
}

export function* checkForCanBeEditSurveySaga({ payload }) {
  try {
    const response = yield call(
      apis.checkForCanBeEditSurvey,
      payload.id
    );
    yield put(
      checkForCanBeEditSurveyResponse(response.data.can_edit_survey)
    );
  } catch (err) {
    console.error(err);
  }
}

export function* exportSurveyReportSaga({ payload }) {
  try {
    const response = yield call(apis.exportSurveyReport, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].surveys.userEmailSuccess
      })
    );
  } catch (err) {
    console.error(err);
  }
}

import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/quizzes";
import History from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { add } from "../ActionsBtn/actions";
import { showHideSnackbar } from "../Snackbar/actions";
import {
  getBookQuizzes,
  getQuizzesList,
  setBookQuizzes,
  setQuizDetails,
  setQuizzesListResponse,
  checkForCanBeEditQuizResponse
  // importQuizzesToBook
} from "./actions";

export function* getQuizzesListSaga({ payload }) {
  try {
    const response = yield call(apis.getQuizzesList, payload);
    console.log("response", response);
    yield put(
      setQuizzesListResponse({
        list: response.data.quizzes,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* addQuizSaga({ payload }) {
  try {
    yield call(apis.addQuiz, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "Quiz added successfully"
      })
    );
    yield History.push(ROUTE_PATHS["quizzes"]);
  } catch (err) {
    console.error(err);
  }
}
export function* assignQuizToBookSaga({ payload }) {
  try {
    yield call(apis.assignQuizToBook, payload);
    yield put(add(false));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "Quiz assigned to book successfully"
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* deleteQuizSaga({ payload }) {
  try {
    yield call(apis.deleteQuiz, payload);
    yield put(getQuizzesList({ page: 1, items: 10 }));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "Quiz deleted successfully"
      })
    );
    yield History.push(ROUTE_PATHS["quizzes"]);
  } catch (err) {
    console.error(err);
  }
}
export function* getBookQuizzesSaga({ payload }) {
  try {
    const response = yield call(apis.getBookQuizzes, payload);
    yield put(
      setBookQuizzes({
        list: response.data.book_quiz,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* unassignQuizFromBookSaga({ payload }) {
  try {
    yield call(apis.unassignQuizFromBook, payload.id);
    yield put(
      getBookQuizzes({
        page: payload.page,
        book_id: payload.book_id,
        title: payload.title
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "quiz is unassigned from book succesfully"
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* getQuizDetailsSaga({ payload }) {
  try {
    const response = yield call(apis.getQuizDetails, payload.id);
    yield put(setQuizDetails(response.data.quiz));
  } catch (err) {
    console.error(err);
  }
}
export function* editQuizSaga({ payload }) {
  try {
    yield call(apis.editQuiz, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "Quiz edited succesfully"
      })
    );
    yield History.push(ROUTE_PATHS["quizzes"]);
  } catch (err) {
    console.error(err);
  }
}
export function* editAssignQuizToBookSaga({ payload }) {
  try {
    yield call(apis.editAssignQuizToBook, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: "Quiz edited succesfully"
      })
    );
    yield put(getBookQuizzes({ book_id: payload.book_id, page: 1 }));
  } catch (err) {
    console.error(err);
  }
}

export function* checkForCanBeEditQuizSaga({ payload }) {
  try {
    const response = yield call(
      apis.checkForCanBeEditQuiz,
      payload.id
    );
    yield put(
      checkForCanBeEditQuizResponse(response.data.can_edit_quiz)
    );
  } catch (err) {
    console.error(err);
  }
}

export function* importQuizzesToBookSaga({ payload }) {
  try {
    const response = yield call(apis.importQuizzesToBook, payload);
    console.log("res from import", response);
    // yield put(importQuizzesToBook(response.data));
  } catch (err) {
    console.error(err);
  }
}

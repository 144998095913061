import * as types from "./types";

const INITIAL_STATE = {
  users: [],
  meta: {}
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_USERS_RESPONSE:
      return {
        ...state,
        users: action.payload.users,
        meta: action.payload.meta
      };
    default:
      return state;
  }
};

export default usersReducer;

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_RESPONSE = "GET_CATEGORIES_RESPONSE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_RESPONSE = "ADD_CATEGORY_RESPONSE";

export const EDIT_CATEGORY_REQUEST = "EDIT_CATEGORY_REQUEST";
export const EDIT_CATEGORY_RESPONSE = "EDIT_CATEGORY_RESPONSE";

export const SET_CATEGORY_SEARCH = "SET_CATEGORY_SEARCH";

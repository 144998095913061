import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import _ from "lodash";
import messages from "../../assets/locale/messages";
import CategoriesList from "./CategoriesList";
import Button from "../../components/Button";
import Search from "../../components/Search";
import { add } from "../../store/ActionsBtn/actions";
import CategoryForm from "./CategoryForm";
import CategoryDelete from "./CategoryDelete";
import {
  getCategoriesRequest,
  setCategorySearch
} from "../../store/Categories/actions";

const Categories = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const isAdd = useSelector((state) => state.actionsBtn.add);
  const editId = useSelector((state) => state.actionsBtn.edit);
  const deleteId = useSelector((state) => state.actionsBtn.delete);
  const list = useSelector((state) => state.categories.list);
  const selectedCategory = _.find(list, ["id", editId]);
  const perPage = 10;

  const {
    categories: { title, addTitle }
  } = messages[lang];

  const handleAddCategory = () => {
    dispatch(add(true));
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    dispatch(setCategorySearch(search ? search : ""));
    dispatch(
      getCategoriesRequest({
        name: search ? search : "",
        page: 1,
        items: perPage,
        paginate: true
      })
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Formik initialValues={{ search: "" }}>
            {() => (
              <Form>
                <Search
                  name="search"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid
          item
          xs={7}
          className="d-flex align-items-start justify-content-end"
        >
          <Button
            text={addTitle}
            icon={<AddIcon className="me-2" />}
            onClick={handleAddCategory}
          />
        </Grid>
      </Grid>

      <CategoriesList lang={lang} />

      <CategoryForm
        mode={editId ? "edit" : "add"}
        selectedCategory={selectedCategory}
        isOpen={isAdd || editId ? true : false}
        lang={lang}
      />

      <CategoryDelete isOpen={!!deleteId} lang={lang} />
    </>
  );
};

export default Categories;

import * as types from "./types";

const INITIAL_STATE = {
  Insights: {},
  list: [],
  meta: {},
  searchKeyword: null
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_INSIGHTS_REQUEST:
      return {
        ...state,
        Insights: action.payload
      };
    case types.SET_BOOKS_REQUEST:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };

    default:
      return state;
  }
};

export default dashboardReducer;
